import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/intl-women-blogs.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';
import GladysWestImg from '../assets/images/Gladys_West.jpeg';
import MargaretHamiltonImg from '../assets/images/Margaret_Hamilton.jpeg';
import HedyLamarrImg from '../assets/images/Hedy_Lamarr.png'

import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 22, 2022</span>
                                    <h1 className="header-title cw-dark-blue bungee">Women's History Month - <span>Incredible Women in Tech</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Nick Martellaro</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details incredible-women-2022">
                                        <div className="blogs_details_block center">
                                            <h2 className='cw-dark-blue'>In celebration of Women’s History Month, here are 3 incredible women in technology who changed the world.</h2>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee center">Gladys West (1930-)</h3>
                                            <br/>
                                            <div className='blog-inner-img center'><img src={GladysWestImg}/></div>
                                            <br/>
                                            <p>Gladys West’s groundbreaking work as a mathematician led us to a technology we now use every day - Global Positioning System, otherwise known as GPS. Gladys West made major contributions to the mathematical modeling of Earth’s shape that were later incorporated into modern GPS. West’s inspiring story started as a child when she made the decision to pursue education to avoid a lifetime of factory work like her parents. Gladys dedicated herself to academics and eventually graduated high school as a valedictorian, receiving a full scholarship to Virginia State University. <br /><br />Even from a young age, West was always intrigued by planets, our solar system, and outer space. In the early 1960s she took part in an award-winning study that examined Pluto’s orbit in comparison to Neptune, and soon after began analyzing satellite data to compile a detailed model of Earth’s shape. West was also a talented programmer in her day, utilizing an IBM computer in the 1970s and 1980s to calculate complex equations that would identify the earth’s shape while also taking variables into account (like tidal forces). These models would eventually become the basis for GPS!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee center">Margaret Hamilton (1936-)</h3>
                                            <br/>
                                            <div className='blog-inner-img center'><img src={MargaretHamiltonImg}/></div>
                                            <br/>
                                            <p>Margaret Hamilton’s incredible work as a computer scientist and systems engineer helped humans to first walk on the moon in 1969. From a young age, Hamilton took an interest in mathematics - and after graduating high school, she earned a college degree in mathematics with a minor in philosophy. Throughout the 1960s, Hamilton’s skill as a programmer became apparent to the US Air Force and MIT. During the famous moon landing of the Apollo 11 mission, the astronauts relied upon on-board flight software produced for NASA by Hamilton and her team at MIT. <br/> <br/>An important part of this software involved the use of Hamilton’s “priority display” system that would alert the astronauts in case of an emergency. Hamilton’s software ingeniously took into account the limits of the computer, and gave it the ability to recognize when it was “overloaded” with tasks. In this case, Hamilton’s priority displays would interrupt the astronauts’ normal displays, and the computer would disable non-essential tasks - focusing on only the most critical ones needed for safety. During the iconic moon landing itself, these alarms warned the astronauts that something was wrong - a radar switch had been left on, overloading the computer and preventing the landing sequence tasks from being completed on time. Hamilton’s inspirational work established the basis for reliable software design that is still essential to this day.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee center">Hedy Lamarr (1914-2000)</h3>
                                            <br/>
                                            <div className='blog-inner-img center'><img src={HedyLamarrImg}/></div>
                                            <br/>
                                            <p>Hedy Lamarr led an exciting life, and is often best known as a famous movie star during the golden age of Hollywood. Lamarr’s work as an actress earned her a star on the Hollywood Walk of Fame, but what many people don’t know is that she was also a resourceful inventor. As a little girl, Lamarr’s father inspired her fascination with inventions and would take her on walks to explain how different types of technology worked. <br/> <br/>During World War II, Lamarr worked with composer George Antheil to develop a radio guidance system that would detect enemy torpedoes using sophisticated spread spectrum and frequency hopping technology. Even more incredible is that the principles of this research are incorporated into modern Bluetooth technology, as well as WiFi and GPS! In 2014, Lamarr was inducted into the National Inventors Hall of Fame for her pioneering contributions to technology.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
