import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import CodingWithKids from '../assets/images/blog-pictures/coding-with-kids.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">November 3, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> CODING WITH KIDS: WHAT IS CODING FOR KIDS AND WHERE TO START </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>We all are surrounded by technology. Even without noticing, we all start our days using our smartphones when the alarm clock is helping us get rolling. We read the news on our mobile devices to see what has happened in the world while we were sleeping. We use social media to see what our family or friends did recently. Coding with kids helps them understand how all this technology is built.</b></p>
                                            <p>We drive to work using GPS navigation, we search for the products and shop online, we order products and write reviews, we book our holidays, we stream music and movies, we communicate with relatives and friends via messengers and social networks, we get inspiration for our appearance, interior and cooking, we upload pictures.</p>
                                            <p>We became intertwined with technology that we see developing rapidly with new software and mobile apps fighting for our attention every day. Coding is becoming especially important for our kids whose life will be bounded with technology even tighter. It’s vital for them to start learning to code from an early age. So which programming language to choose first?</p>
                                           



                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">What is coding for kids?</h3>
                                            <p>“What is coding for kids” is a question we are frequently asked by parents. Coding with kids is an important skill, and its importance has been recognized by young people all over the world. The basics of coding should be taught to children of young age to help them understand how the technology works and operates.</p>
                                        

                                                                             
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Which programming language to choose for kids?</h3>
                                            <p>Coder kids usually start learning to code from something simple like block coding. At the beginning of 90th, The MIT Media Lab introduced the block-based programming concept. The main idea of this concept was the drag and drop of puzzle blocks to perform complex programming commands. This is how the <a href="https://dev.thecodewiz.com/scratch-programming-for-kids/">Scratch</a> platform was created and quickly became very popular.</p>
                                            <p>One of the big-league programming languages that are great for kids starting to code is Python. It is characterized by mostly normal-language syntax and plenty of white space that makes it easier to understand. The great thing about Python is its versatility – it can be used in research, application development and many other tasks.</p>
                                            <p>C++ is one of the most significant programming languages and is often recommended to kids who start coding because of its fundamental influence on so many other programming languages. Being proficient in C++ opens shortcuts to learning other languages, but reaching this level is not an easy task. It might look a bit complicated for kids due to its complexity. Yet, lots of programmers started their successful career by learning to code with C++.</p>
                                            <p>Java occupies the sweet spot between Python and C++. It is a robust language offering endless growth opportunities for coders, yet it’s a friendlier language to learn than C++. Starting to learn to code with Java helps to gain experience to tackle even more complex languages.</p>
                                            <p>Swift is another programming language considered a “living entity,” because it influences other programming languages and, at the same time, is impacted by changes in other languages. Introduced by Apple in 2014, Swift is an exciting language to learn for coder kids and teens. </p>
                                            <p>Educational policies of some countries highlight the importance of skills needed for innovations. Some schools add specific digital technologies curricula for children to let them develop their computer and problem-solving skills and focus on the new trends, new developments of technologies, software and apps. But in the majority of countries of the world, supporting kids that want to learn to code is still only their parents’ task. Let’s have a look at how this can be done.</p>

                                            <img src={CodingWithKids} alt="coding with kids" />


                                       

                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">How to teach kids to code</h3>
                                            <p>Letting your kids learn coding is a good idea however you look at it. An important point is that there are several approaches to how to teach the kids to code. They can learn themselves; they can follow online courses, or they can learn coding at real classes near their homes.</p>
                                            <p>There are many examples of self-taught programmers who achieved great success. For example, Steve Wozniak, the co-founder of Apple, was interested in <a href="https://dev.thecodewiz.com/learn-math-playing-math-games/">math</a> and science from his early age. He learned how to program in Fortran by himself. Bill Gates taught himself to program in the middle school and wrote his first software program when he was thirteen. Mark Zuckerberg, the founder of Facebook, taught himself coding in the middle school as well. In 2004, he coded the Facebook website in his dorm at Harvard.  Self-education and right motivation are very important to learn coding. However, clearly structured courses help to get results faster and more effectively.</p>


                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Online coding courses for kids</h3>
                                            <p>Online coding courses for kids are usually gamified and very interesting. Kids learn how to code using online platforms. This is a perfect opportunity for a kid coder to follow the courses from home and gain new programming and coding skills. The only disadvantage is the fact that the kids spend too much time alone, without any communication with other kids in the real world.</p>
                                            <p>Focusing on online courses, kids spend their time at home without going out and without interaction with their peers. At the same time, the practice shows that online courses are an excellent opportunity to teach your kids the basics of programming and foster their knowledge of IT. It is important to compare all facts when choosing between online courses and real-world coding schools to <a href="https://dev.thecodewiz.com/boston-with-kids/">find the best option</a> of coding education for your kids.</p>
                                            <p>We will start with a short overview of the most prominent online coding courses.</p>
                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Tynker coding for kids</h3>
                                            <p>Tynker is a learning system for kids of all levels of knowledge, from beginners to more advanced coders. Tynker lets kids start with basics concepts of programming and then guides them to more complex programming languages and processes. Kids can code and learn to mod Minecraft and build 25 cool mods to host their own private server. Little coders can also create 3 classic games in Minecraft: Capture the Flag, Slimeball Soccer, and Tower Defense. They learn to create Minecraft Add-Ons with code, bringing 20 fun creatures to life.</p>
                                            <p>The online courses are introduced for kids from 7 years old. Kids learn the basics of coding and create DIY projects using guided tutorials with Tynker. A bit more complicated tasks are designed for the kids aged 9 years and older: block coding with new concepts is in focus at this stage.  Tynker offers other courses and programs as well: Connected Toys; WeDo Sensor Control; Drone Programming; Coding with Barbie™, and much more.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Code Kingdoms kids coding courses</h3>
                                            <p>Code Kingdoms is another online platform that is designed to teach coding skills through games. Kids of 8-14 years old have a chance to make their own Minecraft mods and Roblox games. Code Kingdoms adds new projects each month. Your little kid coder can become a coding professional and learn real Java code online using the simple interface.</p>
                                            <p>At Code Kingdoms, kids can even invite, play and battle with friends (up to 25 people) on their own private Minecraft server. The kids can also create Roblox games, create their own versions of their favorite games, publish their creations and play them with the peers. The good thing is the support provided by Code Kingdoms – professional staff is available seven days a week and is always ready to help with any questions about coding.</p>
                                            <p>Online courses are great, but their biggest downside is that your kids miss out on real-life communication with their peers.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Teach your kids to code with their peers</h3>
                                            <p>Another approach is to learn how to code at the coding classes near home. Local coding schools are the perfect way to teach your kids to code. Attending these schools, participating in the practical courses with other children around is a fantastic opportunity for your kids to learn from professionals and communicate with children of the same age. They learn and develop coding skills faster and better this way.</p>
                                            

                   
                                        
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Where do I start teaching my kid to code?</h3>
                                            <p>Parents often consider Scratch as the first step to teaching their kids to code. Kids can learn to program interactive stories, animations, games with Scratch. It helps to develop skills that will help to choose the future career of your little coder and efficiently develop problem-solving, complex decision-making, creative thinking and teamwork.</p>
                                            <p>Scratch focuses on kids from 8 to 16 years old – from elementary school to college. It is used in more than 150 countries and is available in over 40 languages. Scratch is a great first step that perfectly does its job of introducing kids to coding and motivating them to move forward.</p>

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Coding with kids – going forward</h3>
                                            <p>Your kids have several options on how to <a href="https://dev.thecodewiz.com/kids-coding-languages/">continue developing their skills</a> after they get acquainted with coding through Scratch. Two common directions are to move to Python or website development tools.</p>
                                            <p>The three core components of website development that kids can learn quite easily are HTML (Hypertext Markup Language) that creates the structure of the pages, CSS (Cascade Styling Sheets) that styles them and JavaScript that animates the pages and makes them interactive.</p>
                                            <p>HTML and CSS are quite easy to learn and limited to website user interface development. Meanwhile, JavaScript is a robust and versatile programming language that has numerous platforms and libraries that can help your kids grow in different directions after they gain some knowledge in the field.</p>
                                           

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Coding games with kids</h3>
                                            <p>Coding games is trendy among children. Why not learn thousands of new things <a href="https://dev.thecodewiz.com/kid-birthday-party-places-ideas/">while having fun</a>? Why not code your own computer games, create own modifications and play these games with your friends? Luckily, several platforms allow kids to create their own games and, at the same time, to develop knowledge in different areas.</p>
                                        

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Minecraft</h3>
                                            <p><a href="https://minecraft.net/">Minecraft</a> helps the kids to “explore a world of learning.” They take part in an open-world game that aims to develop creativity, problem-solving skills and collaboration. There is also a course that introduces kids to Computer Science with Minecraft and MakeCode.</p>
                                        

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Roblox</h3>
                                            <p><a href="https://www.roblox.com/">Roblox</a> is another online gaming platform and a home for more than 15 million games created by its users. This is a gaming place for the kids and the teens, where everyone can find something exciting and new to learn. Roblox takes its users on the adventure of an immersive 3D environment, regularly introducing new games and functions.</p>
                                            <p>Accessible from any device, Roblox offers learning and fun regardless of where you are located. Kids love creating their own games and having complete control of the way their games look like. Step by step children build their games, enhance their knowledge by tackling more complex challenges and coding more complicated elements.</p>
                                        

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Big game coding with kids</h3>
                                            <h3 className="bungee">GoDot</h3>
                                            <p>Godot provides a set of useful tools for the development of 2D and 3D games from a unified interface. It offers a fully integrated game environment, where you can create your own game, including content, music and graphics. The games can be exported to many other desktop and mobile platforms.</p>
                                        

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Unity</h3>
                                            <p>Unity, a real-time 3D (RT3D) development platform that provides tools to create interactive 2D, 3D, VR and AR experiences. The content creators around the world can benefit from a collection of tools, tutorials and projects offered by Unity. In addition to game creators, Unity provides all the necessary resources for quality content development for other industries, such as engineering, construction, architecture, film, and many more.</p>


                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Coding with kids is a great way to give them a crucial skill</h3>
                                            <p>Teaching kids to code is a perfect idea to develop their future-proof skills. Learning to code from childhood is the best investment in the future of your kid. The kids can choose how to learn to code. They can learn by themselves, online, or they follow the best practice and learn at coding courses near home.</p>
                                            <p><a href="https://codewiz.simplybook.me/v2/#book">Book your free trial class today.</a></p>
                                        

                   
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
