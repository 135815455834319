import React from 'react';
import LocationsPage from '../../locations/locations';
import MetaTags from 'react-meta-tags';

const FranchiseLocationsPage = () => (
    <div>
        <LocationsPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Locations</title>
        </MetaTags>
    </div>
);

export default FranchiseLocationsPage;