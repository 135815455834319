import React from 'react';
import Header from '../../../components/header/header.component';
import VirtualRobotics from '../../../components/virtual-robotics/virtual-robotics.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

const VirtualRoboticsPage = () => (
  <div>
    <Header location="kellereast" fulllocation="kellereast-tx"/>
    <VirtualRobotics franchisee="kellereast"/>
    <MetaTags>
        <title>Code Wiz - Keller East TX | Virtual Robotics</title>
    </MetaTags>
    <Helmet>
        <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
    </Helmet>
  </div>
);

export default VirtualRoboticsPage;