import React from 'react';
import Header from '../../../components/header/header.component';
import PrivacyPolicy from '../../../components/privacy-policy/privacy-policy.component';
import MetaTags from 'react-meta-tags';

const PrivacyPolicyPage = () => (
  <div>
    <Header location="arlington" fulllocation="arlington-ma"/>
    <PrivacyPolicy />
    <MetaTags>
        <title>Code Wiz - Arlington MA | Privacy Policy</title>
    </MetaTags>
  </div>
);

export default PrivacyPolicyPage;