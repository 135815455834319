import React from 'react';
import FallClassesPage from '../../fall-classes/fall-classes.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFallClassesPage = () => (
    <div>
        <FallClassesPage franchisee="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <MetaTags>
            <title>Code Wiz - Medford MA | Online Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseFallClassesPage;