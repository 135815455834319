import React from 'react';
import firebase from '../../components/Firebase/firebase.js';

import CodewizHeader from '../../assets/images/code-wiz-logo.png';
import FooterImage from '../../assets/images/rfc-form.jpeg';

import './rfc-form.styles.scss';


class AboutPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
        }
    }

    render() {
        
        return (
            <div className="container rfc-form">
                <div className="div-img">
                    <img src={CodewizHeader} />
                </div>
                <div className="rfc-form-desc">
                    <h3 className="bungee center cw-dark-blue">Request for Consideration</h3>
                    <p>We want to understand your background and goals. Complete this form to move to the next stage of the Franchise Discovery process.</p>
                    <p>No pressure, no obligation.  We'll read what you send us, then contact you to schedule a phone call and answer all your questions.  Heads up: our franchise offerings are selective, so brief or vague responses will be sent back.</p>
                </div>
                <div className="rfc-form-content">
                    <form id="cw-contact-form" className="contact-form cw-dark-blue" action="https://getform.io/f/33ea1a6c-b970-48c4-8092-70cc59617c86" method="POST" enctype="multipart/form-data">
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputEmail">Email&#42;</label>
                                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputFirst">First name&#42;</label>
                                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="" required/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputLast">Last name&#42;</label>
                                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputStreetAdd">Street address&#42;</label>
                                <input name="streetaddress" type="text" className="form-control" id="inputStreet" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCity">City&#42;</label>
                                <input name="city" type="text" className="form-control" id="inputCity" placeholder="" required/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputState">State/Province&#42;</label>
                                <input name="state" type="text" className="form-control" id="inputState" placeholder="" required/>
                                {/* <select name="state" className="form-control" id="inputState">
                                    <option value="inperson">In-Person Camps</option>
                                    <option value="online">Online Camps</option>
                                </select> */}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputCountry">Country&#42;</label>
                                <input name="country" type="text" className="form-control" id="inputCountry" placeholder="" required/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputPostal">Province&#42;</label>
                                <input name="postal" type="text" className="form-control" id="inputPostal" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputMobileno">Mobile phone number&#42;</label>
                                <input name="mobileno" type="text" className="form-control" id="inputMobileno" placeholder="" required/>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputSecondaryno">Secondary phone number</label>
                                <input name="secondaryno" type="text" className="form-control" id="inputSecondaryno" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputdesc">What best describes you?&#42;</label>
                                <select name="description" className="form-control" id="inputdesc" required>
                                    <option value="" disabled="" selected="">Please Select</option>
                                    <option value="other">Other</option>
                                    <option value="technical">Technical or Specialized Industry Background</option>
                                    <option value="sales-marketing">Sales or Marketing Professional</option>
                                    <option value="educator">Educator</option>
                                    <option value="enterpreneur">Entrepreneur</option>
                                    <option value="working-parent">Working Parent</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputdesc">Home Ownership&#42;</label>
                                <select name="description" className="form-control" id="inputdesc" required>
                                    <option value="" disabled="">Please Select</option>
                                    <option value="rent">Rent</option>
                                    <option value="own">Own</option>
                                    <option value="other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor="inputmaritalst">Marital Status</label>
                                <select name="martialstatus" className="form-control" id="inputmaritalst">
                                    <option value="" disabled="">Please Select</option>
                                    <option value="single">Single</option>
                                    <option value="married">Married</option>
                                    <option value="decline">Decline</option>
                                </select>
                            </div>
                            <div className="form-group col-md-6">
                                <label htmlFor="inputSpouse">Spouse Name</label>
                                <input name="spouse" type="text" className="form-control" id="inputSpouse" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputSpouseWork">Spouse Place of Work and Job Title</label>
                                <input name="spousework" type="text" className="form-control" id="inputSpouseWork" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputSpouseJobDesc">What is your current Employer Name, a brief Description of your Responsibilities, and length of employment?&#42;</label>
                                <input name="spousejobdesc" type="textarea" className="form-control" id="inputJobDesc" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputeducationlevel">Highest Education Level completed?&#42;</label>
                                <select name="highestleveleducation" className="form-control" id="inputeducationlevel" required>
                                    <option value="" disabled="">Please Select</option>
                                    <option value="High School Diploma or GED">High School Diploma or GED</option>
                                    <option value="Some College">Some College</option>
                                    <option value="Associate Degree">Associate Degree</option>
                                    <option value="Bachelors Degree">Bachelors Degree</option>
                                    <option value="Doctorate Degree">Doctorate Degree</option>
                                    <option value="Masters Degree">Masters Degree</option>
                                    <option value="Post Graduate">Post Graduate</option>
                                    <option value="Professional Degree">Professional Degree</option>
                                    <option value="Trade or Craft Certificate">Trade or Craft Certificate</option>
                                    <option value="Not Indicated">Not Indicated</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputCurrentIncome">Current Annual Income&#42;</label>
                                <input name="currentincome" type="text" className="form-control" id="inputCurrentIncome" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputsimilarproducts">Have you ever owned, operated or worked with any firm that provides products or services similar to ours?&#42;</label>
                                <select name="ownedoperatedsimilarproducts" className="form-control" id="inputsimilarproducts" required>
                                    <option value="" disabled="">Please Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                    <option value="Not Sure">Not Sure</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputstartbusiness">When are you looking to start this business?&#42;</label>
                                <select name="startbusiness" className="form-control" id="inputstartbusiness" required>
                                    <option value="" disabled="">Please Select</option>
                                    <option value="0-6 Months">0-6 Months</option>
                                    <option value="6-12 Months">6-12 Months</option>
                                    <option value="1-2 Years">1-2 Years</option>
                                    <option value="2+ Years">2+ Years</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputSupportFam">How long can you support yourself and your family without making withdrawals from your business (in months)&#42;</label>
                                <input name="supportfamily" type="text" className="form-control" id="inputSupportFam" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputcreditscore">Approximate Credit Score?&#42;</label>
                                <input name="creditscore" type="text" className="form-control" id="inputcreditscore" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputliquidcapital">Liquid Capital Available&#42;</label>
                                <select name="liquidcapital" className="form-control" id="inputliquidcapital" required>
                                    <option value="" disabled="">Please Select</option>
                                    <option value="0-6 Months">0-6 Months</option>
                                    <option value="6-12 Months">6-12 Months</option>
                                    <option value="1-2 Years">1-2 Years</option>
                                    <option value="2+ Years">2+ Years</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputassetsinvestments">Total Assets and Investments&#42;</label>
                                <input name="assetsinvestments" type="number" className="form-control" id="inputassetsinvestments" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputlongtermdebt">Total Long Term Debts&#42;</label>
                                <input name="longtermdebt" type="number" className="form-control" id="inputlongtermdebt" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputshorttermdebt">Total Short Term Debts&#42;</label>
                                <input name="shorttermdebt" type="number" className="form-control" id="inputshorttermdebt" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputunusedlineofcredit">Total Unused Lines of Credit for use in this business&#42;</label>
                                <input name="unusedlineofcredit" type="number" className="form-control" id="inputunusedlineofcredit" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputfranchisecapital">Amount of Capital for use in this franchise?&#42;</label>
                                <input name="frachisecapital" type="number" className="form-control" id="inputfranchisecapital" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputfranchisecapital">Amount of Capital for use in this franchise?&#42;</label><br/>
                                <input type="radio" id="yes" name="soleincome" value="yes" required/> 
                                <label for="yes"> &nbsp; Yes</label><br/>
                                <input type="radio" id="no" name="soleincome" value="no"/> 
                                <label for="no"> &nbsp; No</label><br/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputfinancefranchise">How would you finance your franchise?&#42;</label>
                                <input name="financefranchise" type="textarea" className="form-control" id="inputfinancefranchise" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputbusinesspartner">Will you have a business partner, spouse involvement, or other person involved?&#42;</label>
                                <input name="businesspartner" type="text" className="form-control" id="inputbusinesspartner" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputdaytodayoperations">Who will be running the day to day operations?&#42;</label>
                                <select name="daytodayoperations" className="form-control" id="inputdaytodayoperations" required>
                                    <option value="" disabled="">Please Select</option>
                                    <option value="Self">Self</option>
                                    <option value="Partner">Partner</option>
                                    <option value="Employee">Employee</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputbankruptcydebt">Have you or any company you have owned or managed ever gone through bankruptcy or compromised a debt?&#42;</label><br/>
                                <input type="radio" id="yes" name="bankruptcydebt" value="yes" required/> 
                                <label for="yes"> &nbsp; Yes</label><br/>
                                <input type="radio" id="no" name="bankruptcydebt" value="no"/> 
                                <label for="no"> &nbsp; No</label><br/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputlawsuit">Are you now, or have you ever been party to any lawsuit - either as defendant or plaintiff?&#42;</label><br/>
                                <input type="radio" id="yes" name="lawsuit" value="yes" required/> 
                                <label for="yes"> &nbsp; Yes</label><br/>
                                <input type="radio" id="no" name="lawsuit" value="no"/> 
                                <label for="no"> &nbsp; No</label><br/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputfelony">Have you ever been convicted of a felony or misdemeanor (other than a traffic violation) or are currently involved in a criminal proceeding?&#42;</label><br/>
                                <input type="radio" id="yes" name="felonymisdemeanor" value="yes" required/> 
                                <label for="yes"> &nbsp; Yes</label><br/>
                                <input type="radio" id="no" name="felonymisdemeanor" value="no"/> 
                                <label for="no"> &nbsp; No</label><br/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputwhycodewiz">Why are you interested in owning a Code Wiz franchise?</label>
                                <input name="whycodewiz" type="text" className="form-control" id="inputwhycodewiz" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputpersonalgoals">Why do you think this franchise will enable you to reach your personal goals?</label>
                                <input name="personalgoals" type="text" className="form-control" id="inputpersonalgoals" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputpersonalcharacteristics">Summarize your personal characteristics and experiences that would contribute to your success as a Code Wiz franchisee:</label>
                                <input name="personalcharacteristics" type="textarea" className="form-control" id="inputpersonalcharacteristics" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputdisappointment">What has been the greatest business or personal disappointment you've experienced?</label>
                                <input name="disappointment" type="textarea" className="form-control" id="inputdisappointment" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputmosthelpneeded">What do you think you will need the most help with in running this business?</label>
                                <input name="mosthelpneeded" type="textarea" className="form-control" id="inputmosthelpneeded" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputfinancefranchise">Please attach any relevant documentation about yourself, such as a resume/CV, or your company.</label>
                                <input type="file" name="resume" className="form-control"/>
                            </div>
                        </div>
                        
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputarealocation1">Area/Location Preference #1 &#42;</label>
                                <input name="area-location-preference-1" type="text" className="form-control" id="inputarealocation1" placeholder="" required/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputarealocation2">Area/Location Preference #2</label>
                                <input name="area-location-preference-2" type="text" className="form-control" id="inputarealocation2" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputarealocation3">Area/Location Preference #3</label>
                                <input name="area-location-preference-3" type="text" className="form-control" id="inputarealocation3" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputtopquestion1">Top Questions you have about the opportunity - Question #1</label>
                                <input name="top-question-1" type="text" className="form-control" id="inputtopquestion1" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputtopquestion2">Top Questions you have about the opportunity - Question #2</label>
                                <input name="top-question-2" type="text" className="form-control" id="inputtopquestion2" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputtopquestion3">Top Questions you have about the opportunity - Question #3</label>
                                <input name="top-question-3" type="text" className="form-control" id="inputtopquestion3" placeholder=""/>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-12">
                                <label htmlFor="inputcomments">Other Comments or Questions?</label>
                                <input name="comments-or-questions" type="text" className="form-control" id="inputcomments" placeholder="" />
                            </div>
                        </div>
                        <div className="form-group">
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="gridCheck">
                                    <p><input name="accept" type="checkbox" className="" id="inputaccept" placeholder="" required/>&nbsp; &nbsp;By checking this box, I agree: To the best of my knowledge all information provided is accurate. By completing this form, it is understood that I am under no obligation and that this information is provided to assist in evaluating my personal and professional qualifications as a prospective franchisee. &#42;</p>
                                    </label>
                                </div>
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="gridCheck">
                                    <p><input name="privacy" type="checkbox" className="" id="inputaccept" placeholder="" required/>&nbsp; &nbsp;The Company is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please check this box. &#42;</p>
                                    </label>
                                </div>
                                <br/>
                                <p>Code Wiz needs the contact information you provide to us to contact you about our products and services. You may unsubscribe from these communications at any time. For information on how to unsubscribe, as well as our privacy practices and commitment to protecting your privacy, please review our Privacy Policy.</p>
                            </div>
                        {/* <button type="submit">Send</button> */}
                        <button type="submit" className="btn orange-button" target="_blank">SEND</button>
                    </form>
                    
                </div>
                <div className="div-img">
                    <img className="footer-img" src={FooterImage} />
                </div>
            </div>
        );
    }
}
export default AboutPage;