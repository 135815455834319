import React from 'react';
import Acsl from '../../../components/acsl-competition/acsl-comp.component';
import Header from '../../../components/header/header.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

const FranchiseAcslPage = () => (
    <div>
        <Header location="quincy" fulllocation="quincy-ma"/>
        <Acsl franchisee="quincy"/>
        <MetaTags>
            <title>Code Wiz - Quincy MA | ACSL</title>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default FranchiseAcslPage;