import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';


//import Directory from '../../components/directory/directory.component';
//import AboutPageImg from '../../assets/images/Group-4.png';
import FindACourseCoding from '../../assets/images/coding-course.png';
import FindACourseGaming from '../../assets/images/gaming-course.png';
import FindACourseRobotics from '../../assets/images/robotics-course.png';
import FindACourseCompetition from '../../assets/images/competition-course.png';
//import FindACourseHeaderImage from '../../assets/images/find-my-course-header.png';
import FindACourseHeaderMobileImg from '../../assets/images/find-my-course-header-1.png';

import './find-a-course.styles.scss';
// import '../../assets/js/courses';
import Button from '../../components/button/button.component';
import Courses from '../../components/courses/courses.component';
import Header from '../../components/header/header.component';

//const FindACoursePage = () => (

class FindACoursePage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            }
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <Header location={this.state.location}/>
                <MetaTags>
                    <meta property="og:image" content={FindACourseHeaderMobileImg} />
                    <title>Find My Course - Kids Coding and Robotics School</title>
                    <meta name="description" content="To find course options that are available for your child, please start by selecting the appropriate age and browse options available under the Coding, Robotics, Gaming and Competiton Categories!"/>
                    <meta name="keywords" content="course, classes, coding classes, coding courses, coding for kids, kids, coding, coding camps"></meta>
                </MetaTags> 
                <div className="find-a-course-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-12 col-lg-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">FIND A COURSE</h2> 
                                    <h4 className="header-text cw-dark-blue">Find the course best suited for your child based on their age and interests. Use our tool below to narrow down and learn more about the courses we offer for budding coders!</h4>
                                    <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                    <Button color="light-blue" link={this.state.data.parentportal} size="medium">VIEW CLASS SCHEDULE</Button>
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={FindACourseHeaderMobileImg} alt="find a course"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper find-a-course-wrapper">
                        
                        <div className="container">
                            <div className="center">
                                <h3 className="bungee">Code Wiz Courses</h3>
                                <p>At Code Wiz, we believe that anyone can learn to code—and everyone will fall in love with the process! We create super-fun, highly individualized classes for children from ages 7-17. Whether your child is a budding coder or practically running Python in their sleep, we scale the level of difficulty to fit their skill level so that they continue to learn and grow.</p>
                                <p>Click below to learn more about the options available for your child or reach out to us today for guidance.</p>
                            </div>
                            <div className="center">
                                <h4 className="bungee">student's age</h4>
                                <Courses />
                            </div>
                            {/* <div className="center">
                                <h4 className="bungee">student's age</h4>
                                <div className="row select-student-age">
                                    <select id="select-age" className="form-control col-md-10">
                                        <option>Choose...</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                    </select>
                                    <button className="btn orange-button col-md-2" id="start">START</button>
                                </div>
                            </div> */}
                            <div className="row"> 
                                <div id="coding" className="col hidden">
                                    <div className="course">
                                        <div className="course-img"><img src={FindACourseCoding} alt="Find a coding course"/></div>
                                        <h4 className="cw-dark-blue">CODING</h4>
                                        <select id="selectcoding" className="form-control form-control-md"></select>
                                        <button id="coding-start" className="btn orange-button course-btn">SEE DETAILS</button>
                                    </div>
                                </div>
                                
                                <div id="gaming" className="col hidden">
                                    <div className="course">
                                        <div className="course-img"><img src={FindACourseGaming} alt="Find a gaming course"/></div>
                                        <h4 className="cw-dark-blue">GAMING</h4>
                                        <select id="select-gaming" className="form-control form-control-md"></select>
                                        <button id="gaming-start" className="btn orange-button course-btn">SEE DETAILS</button>
                                    </div>
                                </div>
                                
                                <div id="robotics" className="col hidden">
                                    <div className="course">
                                        <div className="course-img"><img src={FindACourseRobotics} alt="Find a robotics course"/></div>
                                        <h4 className="cw-dark-blue">ROBOTICS</h4>
                                        <select id="select-robotics" className="form-control form-control-md"></select>
                                        <button id="robotics-start" className="btn orange-button course-btn">SEE DETAILS</button>
                                    </div>
                                </div>

                                <div id="competitions" className="col hidden">
                                    <div className="course">
                                        <div className="course-img"><img src={FindACourseCompetition} alt="Find a competition"/></div>
                                        <h4 className="cw-dark-blue">COMPETITION</h4>
                                        <select id="select-competitions" className="form-control form-control-md"></select>
                                        <button id="competition-start" className="btn orange-button course-btn">SEE DETAILS</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="course-details container">
                        <div className="row">
                            
                        </div>
                    </div>
                    <div className="container center">
                        <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                        <Button color="light-blue" link={this.state.data.parentportal} size="medium">VIEW CLASS SCHEDULE</Button>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default FindACoursePage;