import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseInPersonClassesPage = () => (
    <div>
        <PopupModal location="medford" formType="coupon" owner="Code Wiz Medford"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <FallClassesPage franchisee="medford" fulllocation="medford-ma" formType="coupon" owner="Code Wiz Medford"/>
        <MetaTags>
            <title>Code Wiz - Medford MA | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;