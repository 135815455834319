import React from 'react';
import BlogPage from '../../blog/blog.component';
import MetaTags from 'react-meta-tags';

const FranchiseBlogPage = () => (
    <div>
        <BlogPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Blogs</title>
        </MetaTags>
    </div>
);

export default FranchiseBlogPage;