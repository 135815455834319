import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/in-demand-career-bubble.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">July, 2022</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> Code Wiz Guide: In-Demand Programming Careers for Students to Explore </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Theresa P.</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            {/* <h2 className="bungee">HOW LEARNING TO CODE PREPARES KIDS FOR THE 21st-CENTURY WORKPLACE</h2> */}
                                            <p>Learning with Code Wiz can lead to many professional opportunities opening up to students as they advance their studies and eventually their careers. The world of programming and the jobs available in this field are increasing at a steady rate. It’s an exciting and fun time to learn more about programming! Code Wiz offers basic and advanced coding courses, which act as a solid platform with which students can prepare themselves for pursuing this area of expertise. These courses include learning how to build websites with HTML/CSS, 2D-3D game design with Unity, Minecraft Coding, Python based text and visual coding, and more! Check out what are the most in-demand careers that are available in 2022!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            {/* <h2 className="bungee">HOW LEARNING TO CODE PREPARES KIDS FOR THE 21st-CENTURY WORKPLACE</h2> */}
                                            <h5>Top 7 Most In-Demand Programming Careers of 2022</h5>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">1. IT Security Specialist</h3>
                                            <p>An <b>IT Security Specialist</b> is a highly trained individual that works for the information technology department of an organization. They make sure that an organization's security measures are up to date and perform “ethical hacking” measures to test for any weaknesses in their security systems. They constantly monitor and improve the security of the network and computer systems for any breaches, securing the system infrastructure, and responding to any cyber attacks incurred.</p>                           
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">2. Network Analyst</h3>
                                            <p>A <b>Network Analyst</b> does everything from handling connectivity issues, to tracking network performance metrics, to installing, maintaining, and resolving the processes of a computer network system for a company. Their main drive is to optimize the network's performance metrics to be cost-effective and the data to be as free flowing as possible.</p>
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">3. Programmer Analyst</h3>
                                            <p>A <b>programmer analyst</b> is responsible for designing and coding new programs. They also create different diagrams and charts to show documentation of processes.</p>
                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">4. Systems Analyst</h3>
                                            <p>A <b>systems analyst</b> is just as the name implies. They oversee analyzing the IT systems and designs techniques to help problem-solve any issues that may arise. They have to make sure they are fixing the problems in a cost-effective manner. Constant testing of databases and programs to make sure that they are working as they should, is imperative to this position.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">5. Game Developer</h3>
                                            <p>A <b>game developer</b> is a specific programmer for creating games on the most current consoles and creating apps that are interactive. Game engines like Unity make game developers jobs much easier, in that they are available to assist with the design and development of the game along the way. This profession requires them to manage many different responsibilities and have fluency with many different programming languages.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">6. Data Scientist</h3>
                                            <p>A <b>data scientist</b> is one of the most in demand programming jobs. Several companies are required to analyze large sets of data, which a data scientist is critical to help with their programming skills. They effectively problem solve through a combination of programming, statistics, math skills, and analytics.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">7. Front-end developer</h3>
                                            <p>Just as the name suggests, a <b>front-end developer</b> works on the front side or visible side of a website. All the components that we interact with, from the design to the technology behind the design, is their handiwork. Most importantly, they ensure that a website performs well on multiple devices such as a cell phone, laptop, and a tablet. </p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className='dark-blue bungee'>Code Wiz Leads the Way to Programming Careers</h3>
                                            <p>These are just some of the many opportunities that are opening up for students in the field of programming. It’s an ever-evolving field, and one that is not going away any time soon! Be sure to check out all of our incredible and more importantly, fun classes and camps for learning basic and advanced coding, robotics, and more at <a href="https://thecodewiz.com/">Code Wiz</a>. The future is wide open! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
