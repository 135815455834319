import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import KeyFactors from '../assets/images/blog-pictures/15-factors.jpg';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">January 10, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>15 KEY FACTORS TO CONSIDER BEFORE BUYING A COMPUTER PROGRAMMING </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>Franchising is one of the easier ways of starting your own business and one of the most impactful ways of empowering the next generation to fulfill their human potential and significantly contribute to the advancement of mankind.</b></p>
                                            <p>Becoming a franchisee, especially for a successful outfit, seems like a shorter path to profitability. But when it comes to business – <i>the devil’s in the details.</i></p>
                                            <p>There’s more to a franchise opportunity than upfront costs and prior successes. You are buying into a system developed by the franchisor and must operate by their playbook.</p>
                                            <p>If you decide to become a franchisee in the education industry, expect to encounter some challenges common to all businesses that operate in this market.</p>
                                            <p>In our previous two blog posts, we detailed the <i><a href="https://dev.thecodewiz.com/top-10-reasons-why-a-coding-franchise-is-a-wise-investment/">top 10 Reasons Why a Coding Franchise is a Wise Investment how it can help Create the Next Generation of Inventors.</a></i></p>
                                            <p><i>Here we will highlight the important things to consider before investing in a computer programming Franchise.</i></p>
                                            <p>But before we dive in, let’s look at the characteristics of success and how you can prepare yourself for purchasing a computer programming franchise.</p>
                                            <p>In today’s business-focused world, there is a plethora of investment options to choose from. Understanding your abilities and goals is a major step toward deciding whether owning a computer programming franchise is right for you.</p>
                                            <p>Ideally, you want your investment to make you feel proud to represent it because it is in line with your values.</p>


                                        </div>


                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Who Should Consider Investing in a Computer Programming Franchise</h3>
                                            <p>If you do not have experience in the education industry, do not let that stop you from investing in a computer programming franchise. However, you should be prepared for certain demands and challenges to help position you and your franchise for success.</p>

                                            <ul>
                                            <li>You will have to be <b>comfortable working with children</b> if you’re going to buy any franchise in the education sector. You’ll have to be prepared to deal with the unique concerns and challenges of working with young children, as well as their parents, to succeed in this business.</li>
                                            <li>You <b>have patience and empathy</b>. You’ll have to be patient to help kids learn to code, which is significantly different from the traditional schooling that is far less dynamic.</li>
                                            <li>As we said before, being an expert at coding is not a pre-requisite to owning a computer programming franchise. But <b>it’s normally an advantage if you’re an expert in the subject matter</b> in which you provide instruction.</li>

                                            <p>Having first-hand experience studying and applying the curriculum that your education franchise teaches will enable you to better understand the learning process from your students’ perspectives.</p>
                                            <li>Even if you’re an expert in the field and passionate about teaching your franchise curriculum, it’s still a business. Therefore, <b>you’ll have to maintain a profitable operation</b> to remain afloat.</li>
                                            <p>So having experience and possessing an education in marketing or business management will be a major plus.</p>
                                            <li>Another advantage of the business end is having <b>strong organizational and administrative skills</b>. In a robust computer coding franchise, it can be hassling and time-consuming to keep tabs of the numerous administrative tasks as well as overseeing the physical aspects of managing the curriculum and the facility.</li>
                                            <li><b>You’re technologically savvy</b>. Computer programming is at the heart of the current technological revolution, so you’ll be using technology to assess and enhance the educational experience you provide.</li>
                                            </ul>
                             
                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">15 Key Factors to Consider Before Buying a Computer Programming Franchise</h3>
                                            <p><i>If you’re thinking of becoming a franchisee, here’s how you can prepare yourself:</i></p>
                                            
                                            <ol>
                                            <li><b>Take a Personality Test</b></li>
                                            <p>Military veterans are said to be successful franchisees because they’re used to following rules and operating within a highly regulated system. So if you’re the creative, renegade type, you may not be cut out to be a franchisee.</p>
                                            <p>Implementation trumps creation when it comes to investing in an education franchise.</p>
                                            <p>Some franchises need specific talents and personality types to be successful. So research who has already done well with a particular franchise. See if there are any common factors and if you possess the necessary characteristics.</p>

                                            <img src={KeyFactors} alt="15 key factors" />

                                            <p>Dealing with children comes with unique challenges. So you'll have to be good at working with them in order to succeed in the children education franchise industry.</p>
                                            <p>Do a personal inventory of your core strengths and skills, and whether or not you will fit with the franchise culture you will be partnering with.</p>
                                            <p>To bolster your self-assessment, ask friends and family to help you evaluate how well your personality matches the business you’re considering.</p>
                                            
                                            <li><b>Count Your Money</b></li>
                                            <p>Like all investments, investing in a franchise involves some financial risk. Franchisees must commit money and time to be successful.</p>
                                            <p>Getting a franchise up and running can involve hefty marketing costs and surviving on break-even books, or a period of net losses before your business catches on.</p>
                                            <p>So it’s wise to look beyond the usually listed minimum requirements such as the franchise fee and the cost of equipment for buying a franchise.</p>
                                            <p>According to the nation’s consumer protection agency, the Federal Trade Commission (FTC) guide, it may take up to a year to become profitable, some franchises never do.</p>
                                            <p>Do you have solid savings or sources of income to support yourself until the franchise is in a position to?</p>
                                            <p>Try to acquire capital that will cover business expenses for six months and personal living expenses for a year.</p>
                                            </ol>


                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee"> Investment </h3>
                                            <p>Begin with a personal inventory of how much you can comfortably invest.</p>
                                            <p>Be honest with yourself about what you can invest. Find out if you can afford to lose your entire investment before you decide to invest.</p>
                                            <p>If you don’t have enough liquid capital you risk coming in undercapitalized, and you’ll be more likely to fail and drain the resources of the franchise company.</p>
                                            <p>In fact, you probably should seek legal counsel to help you assess your financial health to see how it will be affected by the franchise arrangement before you sign a franchise contract.</p>
                                            <ol>
                                            <li><b>Do a Cost/Benefit Analysis</b></li>
                                            <p>Here is where that “good ole” pros vs cons list can come in handy. On one side of a sheet of paper write down the benefits you’ll be getting and on the other side list the costs and liabilities. Simple but very effective.</p>
                                            <p>A lopsided pros count can be reinforcement for interest in a computer programming franchise. While too many cons might be a warning sign that maybe you should reconsider.</p>
                                            
                                            <li><b>Read the entire Financial Disclosure Document (FDD)</b></li>
                                            <p>This can help significantly with the financial concerns you may have. All franchises file an <a href="https://www.investopedia.com/terms/f/franchise-disclosure-document.asp">FDD</a> with their complete business details.</p>
                                            <p>It offers a gold mine of information which can help you to be aware of costs that may not seem obvious, bankruptcy filings by the franchisor, litigation involving the company and/or its executives as well as the type of training the franchisor offers franchisees. </p>
                                            <p>Yes, we understand that financial-related regulatory documents can be intimidating, but the FTC’s online guide will help you to navigate through this document.</p>
                                           
                                            <li><b>Know the Franchisor’s Responsibilities</b></li>
                                            <p>Franchisors also have legal responsibilities. For example, franchisors must give potential franchisees important information in an FDD at least 14 days before a contract is signed or any payment is made.</p>
                                            <p>Some states even require a franchisor to submit its FDD to state examiners for review before it can lawfully sell the franchise in the state.</p>
                                            <p>They are spelled out in the Franchise Rule, which is enforced by the Franchise Rule.</p>
                                            
                                            <li><b>Do your Due Diligence</b></li>
                                            <p>Talk to franchisees. At least 10 actually.</p>
                                            <p>Ask about the pros, cons, and hidden costs.</p>
                                            <p>What did they learn after they became franchisees that they didn’t from their pre-ownership research?</p>
                                            <p>How long did it take them to become profitable?</p>
                                            <p>How much money was budgeted for their franchise, and how much did they end up spending?</p>
                                            <p>What was the toughest part of building the business?</p>
                                            <p>Ask if, given what they know now, they would do it again?</p>
                                            <p>Talk to as many franchisees as you can.</p>
                                            <p>FDDs include the names and phone numbers of current franchisees.</p>
                                            <p><b>Also, try to dig for dirt –</b> search for negatives about the franchise you’re considering by using sites like Sean Kelly’s Unhappy Franchisee.</p>
                                            <p>All the pros of owning a particular franchise are irrelevant if you find out a few months into owning the franchise that your personality doesn’t fit with the culture. So try to get some insight into the franchise’s culture.</p>
                                            <p>Research the company’s history, the officers and management of the franchise. You want the best team as your partner, so look for stability and experience.</p>
                                            <p>Articles 1-2-3 of the FDD will give you their history, ligation, and bankruptcy information. Also, Article 21 contains the franchise’s financial statements. But keep in mind that it is not forwarded until the franchisor has determined you are a qualified and serious candidate.</p>
                                            <p>Articles 1-2-3 of the FDD will give you their history, ligation, and bankruptcy information. Also, Article 21 contains the franchise’s financial statements. But keep in mind that it is not forwarded until the franchisor has determined you are a qualified and serious candidate.</p>
                                           
                                            <li><b>Study the Market</b></li>
                                            <p>First, find out if the market you’re thinking of going into is in growth mode or is on the decline. Completely understanding who you will serve helps to determine the viability, and ultimately the profitability of the franchise.</p>
                                            <p>Avail yourself on the ABCs of franchising. You can start by using publicly available information such as the FTC’s Guide to Buying a Franchise.</p>
                                            
                                            <li><b>Territory Exclusivity</b></li>
                                            <p>When considering investing in a franchise, find out if you can get ‘exclusive territory.’ </p>
                                            <p>‘Exclusive territory’ is a fixed geographic area in which a franchisee is given the right to operate, where the franchisor is restricted from appointing any other units. </p>
                                            <p>It is often very beneficial to receive an exclusive territory for a franchise because it prevents over-saturation of a particular brand in the same vicinity.</p>
                                            <p>Competition is usually the enemy in business – less competition usually increases the chances of profitability.</p>
                                            <p>So if you do not get territorial protection with the deal it will make it significantly harder to build a profitable business.</p>
                                            
                                            <li><b>You are  Comfortable with Working under a Franchisor’s Control</b></li>
                                            <p>Owning a franchise makes you a business owner yes. But you won’t operate independently. You must follow rules that call for uniformity within a network.</p>
                                            <p>Sometimes franchises have strict rules on how to operate the business. When evaluating a franchising opportunity find out the extent to which you’ll be allowed to modify operations. Will you be allowed to be innovative and explore new marketing strategies or product positioning? </p>
                                            <p>If exercising creativity is important to you, think about whether you would be comfortable working under a franchisor’s strict controls.  Make sure you are comfortable with what you won’t be allowed to change.</p>
                                          
                                            <li><b>Check for Proven Systems</b></li>
                                            <p>When you buy a franchise, you’re usually buying a proven business model.</p>
                                            <p>But if that franchise doesn’t come with efficient systems that work, then you’ll be flying blind. There should be systems for everything from payroll to marketing to client services and sales.</p>
                                            
                                            <li><b>Training and Support is Critical</b></li>
                                            <p>You’ll also want to find out if training is included or if you’ll be left to figure out the system on your own.</p>
                                            <p>A well-thought-out, structured and proven coaching program is an often overlooked, though extremely important, factor to consider when evaluating a franchising opportunity.</p>
                                            <p>Look for a support and training system that is comprehensive and up to date. You want and need guidance based on what’s actually happening in the marketplace.</p>
                                            <p>Great franchises will invest in their franchisees’ development because business savvy franchisors understand that their success hinges on each of their franchisee’s abilities to be proficient.</p>
                                            
                                            <li><b>Knowledge-Sharing Among Franchisees</b></li>
                                            <p>As we mentioned before, it’s imperative you speak to as many franchisees as possible when doing your due diligence. Speak with the other franchise owners in the system and learn from their experiences. They are a valuable resource as they can provide you with concrete solutions to real challenges enhancing your odds of success.</p>
                                            <p>Any good franchise will facilitate this. Try to franchise with an organization that connects all of its franchisees. Especially if territorial protection is provided, you should feel free asking questions and sharing information with other peer franchisees. Their experiences will help put you on the right track.</p>
                                           
                                            <li><b>Have an Exit Strategy</b></li>
                                            <p>Owning a franchise is a multi-year commitment. Franchise agreements generally last anywhere between 5 and 15 years.</p>
                                            <p>Once you commit to a franchise contract, you’re locked in. You’ll have to stick with it until the end of the contract, even if you’re not as successful as you expected.</p>
                                            <p>It can be difficult and very expensive to renege on an agreement. So ensure you’re prepared for the long haul or have your exit strategy in place before you even sign the contract.</p>
                                            <p>Try to think two steps ahead. For instance, if you get ill or have a personal crisis and can no longer run the business plan on how you would exit. Keep in mind there are costs associated with exiting early so ask about those costs upfront.</p>
                                           
                                            <li><b>Know the True Costs of Being a Franchisee</b></li>
                                            <p>There are often hidden fees in addition to the royalty payments, such as required marketing fees or training in many franchise agreements. To ensure the franchise opportunity is the best one for you, find out the true cost of being a franchisee.</p>
                                            
                                            <li><b>Examine Earnings Potential</b></li>
                                            <p>On the other end, try to find out your true earning potential before you decide to take on a franchising opportunity. But due to the difficulties in finding out the true profitability of other franchise locations, it can be tricky to evaluate the profitability of your potential franchise.</p>
                                            <p>However, if you can, get a comprehensive list of the financials from other franchises and investigate how the successful ones became profitable. </p>
                                            <p><i>Investing in a franchise is one of the smartest ways to start your own business and can be a shorter path to profitability. However, buying a franchise is a major financial investment and a serious personal commitment. So before you decide to take on a franchising opportunity, there are several things you need to take into consideration to prepare yourself for the challenge.</i></p>
                                            <p><i>We hope these key factors help in your evaluation of your franchise opportunity. If you need to find out more or have your mindset on a more cost-effective computer programming franchise for kids with minimal build-out requirements, <a href="https://dev.thecodewiz.com/contact-us/">contact us.</a></i></p>

                                        
                                            </ol>


                                        </div>
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
