import React from 'react';

import './button.styles.scss';

const Button = props => {
    //return <button className={props.color + "-button btn " + props.size}><a href={props.link} rel="noopener noreferrer">{props.children}</a></button>
    return <a href={props.link} rel="noopener noreferrer"><button className={props.color + "-button btn " + props.size}>{props.children}</button></a>
};


export default Button;
