import React from 'react';
import firebase from '../components/Firebase/firebase.js';
import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">December 8, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> 5 GIFT IDEAS FOR KIDS WHO CODE </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                            
                                            <p>Have you figured out what the perfect gift for your kid will be this year? It can be so challenging to sift through the countless options and decide what the best present will be. </p>
                                            <p>If your kid likes to code, we’ve got you covered. We asked our students what they wanted under the tree this year and they had a lot to say! We narrowed it down to provide you 5 gift ideas for the kid who likes to code.</p>

                                            <ol>
                                                <li><a href="https://www.amazon.com/LEGO-Creative-17101-Educational-Award-Winning/dp/B06Y6JCTKH/ref=sr_1_1?dchild=1&keywords=Lego+Boost+Creative+Toolbox+17101+Fun+Robot&qid=1607364786&sr=8-1">Lego Boost Creative Toolbox 17101 Fun Robot:</a> First up and a personal favorite, is the LEGO toolbox creative robot. This STEM toy includes 800+ LEGO pieces that kids can use to build and rebuild into 5 different models. Kids can construct and code Vernie the Robot to dance, rock out on the Guitar4000, foster Frankie the Cat, interact with the Autobuilder, or explore with the Multi-Tooled Rover 4. This present is best for kids ages 7-12 and does require a tablet or mobile phone.</li>
                                                <li><a href="https://www.amazon.com/ThinkFun-Master-Programming-Logic-Girls/dp/B014993TCI/ref=sr_1_3?dchild=1&keywords=ThinkFun+Code+Master+Programming+Logic+Game&qid=1607364655&sr=8-3">ThinkFun Code Master Programming Logic Game: Another favorite, the Code Master Logic Game helps to build  reasoning and planning skills. This board game contains 10 maps with 60 levels to solve, 12 guide scrolls, 12 action tokens, and 8 conditional tokens. This present is best for kids age 8 and up.</a></li>
                                                <li><a href="https://www.amazon.com/UBTECH-JRA0201-JIMU-Robot-Mythical/dp/B07GQJ1W4B/ref=sr_1_31?dchild=1&keywords=coding+for+kids&qid=1607364226&sr=8-31">UBTECH Mythical Series: Unicornbot Kit-App-Enabled Building & Coding Stem Learning Kit:</a> As an equestrian myself, I couldn’t help but be drawn to the Unicornbot Learning Kit from UBTECH. Kids can learn to use Blockly coding to program the UnicornBot’s special color sensor using eight different color cards. The PRP (pose, record, play) function allows the kids to create custom actions for the Unicornbot. This present is best for kids age 8 and up.</li>
                                                <li><a href="https://www.amazon.com/Learning-Resources-Botley-Activity-Programming/dp/B083T58PKM/ref=sr_1_2?dchild=1&keywords=coding+for+kids&qid=1607364463&sr=8-2">Learning Resources Botley The Coding Robot 2.0 Activity Set:</a> Botley the Coding Robot offers 16 fun interactions. To name a few, kids can transform Botley into a train, police car, and a ghost. Kids can code the colorful Botley through music, light, and movements. This present is best for kids age 5 and up.</li>
                                                <li><a href="https://www.amazon.com/MindWare-Science-Academy-Robot-Lab/dp/B07JN2SH5V">MindWare Science Academy Robot lab:</a> Kids can learn basic coding, programming and computer science with the MindWire Robot Lab. While using this toy, they’ll learn coding commands, test different hypotheses, and record their results. The lab also includes a mini robot that can be decorated and programmed. Once developed, kids can direct it through missions, obstacle courses, or experiments. This present is best for kids aged 8 and up.</li>
                                            </ol>
                                            <p><i>Bonus: Some honorable mentions were a <a href="https://www.amazon.com/Cute-soft-baby-plush-master/dp/B08HMF5CWJ/ref=asc_df_B08HMF5CWJ/?tag=hyprod-20&linkCode=df0&hvadid=475772723418&hvpos=&hvnetw=g&hvrand=9682768167164410414&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9001947&hvtargid=pla-1039125897973&psc=1">Yoda Plushie</a>, Skyblock Server, and Robux!</i></p>
                                            <p>Do you feel like the gifts we’ve laid out aren’t enough to satisfy your kid’s need to code?</p>
                                            <p><a href="https://codewizarlington.simplybook.me/v2/#book">Sign them up for a free trial class with Code Wiz</a>, and join a community of coders. Email <a href="arlington@thecodewiz.com">arlington@thecodewiz.com</a> with any questions.</p>

                                        

                                        </div>
                                   
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
