import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import MinecraftAndRoblox1 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds1.jpg';
import MinecraftAndRoblox2 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds2.jpg';
import MinecraftAndRoblox3 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds3.jpg';
import MinecraftAndRoblox4 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds4.jpg';
import MinecraftAndRoblox5 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds5.jpg';
import MinecraftAndRoblox6 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds6.jpg';
import MinecraftAndRoblox7 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds7.jpg';
import MinecraftAndRoblox8 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds8.jpg';
import MinecraftAndRoblox9 from '../assets/images/blog-pictures/minecraft-and-roblox-backgrounds9.jpg';



import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">May 12, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>MINECRAFT AND ROBLOX ZOOM VIDEO BACKGROUNDS – FREE DOWNLOAD!</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Claudia</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Love Minecraft and Roblox? Now you can show off your favorite character using Code Wiz’s personalized Minecraft and Roblox Zoom Backgrounds. Feel free to download and use these backgrounds during all your video calls and <a href="https://dev.thecodewiz.com/westford-ma/sessions/">Online classes,</a> we’d love to see how cool they look.<b> Be sure to send us screenshots!!</b></p>
                                            <p>Tag us on social media using your Code Wiz – Minecraft and Roblox Zoom backgrounds. To download click each image, then right-click and select download image or save image. Or use this link to download them all – <a href="https://drive.google.com/drive/folders/13hbc_oDiTalQm3ZG_pD9kWlWrxOYgyuz">Click Here</a></p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee center"> MINECRAFT AND ROBLOX ZOOM BACKGROUNDS - FREE DOWNLOAD</h3>
                                            <div className="row">
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox1} alt="Minecraft and Roblox Zoom Backgrounds 1" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox2} alt="Minecraft and Roblox Zoom Backgrounds 2" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox3} alt="Minecraft and Roblox Zoom Backgrounds 3" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox4} alt="Minecraft and Roblox Zoom Backgrounds 4" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox5} alt="Minecraft and Roblox Zoom Backgrounds 5" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox6} alt="Minecraft and Roblox Zoom Backgrounds 6" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox7} alt="Minecraft and Roblox Zoom Backgrounds 7" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox8} alt="Minecraft and Roblox Zoom Backgrounds 8" /></div>
                                                <div className="col-md-4 col-xs-12"><img src={MinecraftAndRoblox9} alt="Minecraft and Roblox Zoom Backgrounds 9" /></div>
                                            </div>
                                            <p className="center">Register for <a href="https://app.iclasspro.com/portal/codewiz/booking">Code Wiz Online Classes</a> or <a href="https://app.iclasspro.com/portal/codewiz/booking">Online Summer Camps</a>.</p>

                                        </div>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
