import React from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../../../components/header/header.component';
import Button from '../../../components/button/button.component';
import LearnToCodeHeaderImg from '../../../assets/images/bubble-33.jpg';
// import InPersonCamp from './assets/docs/in-person-camps-westford.pdf';
import InPerson from '../../../assets/docs/in-person-camps-westford.pdf';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const InPersonPage = () => (
    <div>
        <FacebookMessenger location="westford" FBPageId="514134288920101" />
        <Header location="westford" fulllocation="westford-ma"/>
        <MetaTags>
            <title>Code Wiz - Westford MA | In Person Brochure</title>
        </MetaTags>
        <div className="container header-text-container in-person-brochure">
            <div className="row">
                <div className="top-header col-sm-12 col-md-6"> 
                    <div className="text">
                        <h2 className="header-title cw-dark-blue bungee">Thank you for downloading our brochure!</h2> 
                        <h4 className="header-text cw-dark-blue">Please feel free to reach out with questions!</h4>    
                        <Button color="orange" link={InPerson} size="large">CLICK HERE TO ACCESS BROCHURE</Button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 expanded_image">
                    <img src={LearnToCodeHeaderImg} alt="online fall coding"/>
                </div>
            </div>
        </div>
    </div>
);

export default InPersonPage;