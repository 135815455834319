import React from 'react';
import firebase from '../Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../floating-menu/floating-menu.component';
import Button from '../button/button.component';
import WelcomeHeaderImg from '../../assets/images/bubble-36.png';
import WelcomeIcon1Img from '../../assets/images/welcome_ico1.png';
import WelcomeIcon2Img from '../../assets/images/welcome_ico2.png';
import WelcomeIcon3Img from '../../assets/images/welcome_ico3.png';
import WelcomeIcon4Img from '../../assets/images/welcome_ico4.png';




import './welcome-message.styles.scss';


class WelcomeMessage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            defaultdata: {
                state: "",
                city: "",
                territory: ""
            }
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        let aboutData = {};
        if(this.props.franchisee){
            aboutData = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } 
        else {
            aboutData = this.state.defaultdata;
        }
        return (
            <div className="special-page welcome-message-page" id=""> 
                <MetaTags>
                    <title>Coding For Kids and Robotics School in {aboutData.territory} - Code Wiz</title>
                    <meta name="description" content={`Coding for kids in ${aboutData.city}, ${aboutData.state}. Code Wiz is a top rated coding and robotics school in ${aboutData.territory} area. Get a free class today!`}/>
                    <meta name="keywords" content="try a free coding class, coding for kids, kids, coding, coding camps"></meta>
                </MetaTags>
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">Try an online coding class for FREE</h2> 
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={WelcomeHeaderImg} alt="welcome message"/>
                        </div>
                    </div>
                </div>
                <div className="blue_section welcome_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="welcome_video">
                                    <h2 className="bungee">Listen to Our Founder</h2>
                                    <div className="video_frame">
                                        <iframe width="100%" height="525" src="https://www.youtube.com/embed/KADKFbckuVY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                    <Button color="orange" link="" size="medium">TRY A FREE CLASS</Button>
                                </div>	
                                <div className="sessions_features">	
                                    <h2 className="bungee">Why learn to code?</h2>
                                    <p>Code Wiz online coding classes are designed to teach children the latest technology, as well<br/> as enrich basic skills, while learning about concepts that may not be taught in school.</p>
                                    <ul>
                                        <li>
                                            <img src={WelcomeIcon1Img} alt="welcome icon 1" />
                                            <p><span>Empower children through</span> imagination and confidence building</p>
                                        </li>
                                        <li>
                                            <img src={WelcomeIcon2Img} alt="welcome icon 2" />
                                            <p><span>Students build</span> socialization and collaboration skills</p>
                                        </li>
                                        <li>
                                            <img src={WelcomeIcon3Img} alt="welcome icon 3" />
                                            <p><span>Prepare your children for a</span> tech-filled future & develop interest in STEM</p>
                                        </li>
                                        <li>
                                            <img src={WelcomeIcon4Img} alt="welcome icon 4" />
                                            <p><span>Students develop</span> much needed critical thinking skills</p>
                                        </li>
                                        
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                <div className="welcome_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">	
                                    <div className="bottom_cta">
                                    <h3 className="bungee">REGISTER TO TRY A FREE CLASS</h3>
                                        <p>Use the link below to register.</p>						
                                        <Button color="orange" link="" size="medium">TRY A FREE CLASS</Button>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                {floatingdata}
            </div>
        );
    }
}

export default WelcomeMessage
