import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import KidBirthdayParty from '../assets/images/blog-pictures/kid-birthday-party.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">November 3, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> KID BIRTHDAY PARTY PLACES AND IDEAS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>Kid birthday party places and ideas for celebrations are an endless topic. Every kid loves parties! Every kid is waiting for their birthday parties for months. Planning a party always takes lots of time and may be quite overwhelming for the parents. You need to consider numerous details – the venue, theme and style, decorations, entertainment, food, and the guest list.</b></p>
                                            <p>The party theme depends on your kid’s interests and hobbies. You know what fascinates your kids and start planning based on their expectations. For example, you can think about a party at home or at the specific venue which is specialized in organizing themed parties for kids. Themed parties have become popular in recent years and give you an opportunity to bring to life every idea of the perfect birthday celebration.</p>
                                            <p>There are thousands of ideas for kid birthday parties and places to have a kid birthday party. You can have a party at home or hire professionals, take the kids to the aquarium or zoo, amusement center or trampoline park, restaurant or cafe with themed menus and decorations for your kids’ birthday party. </p>

                                            <img src={KidBirthdayParty} alt="kid birthday party" />
                                           



                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Kid birthday party places and ideas in Boston</h3>
                                            <p>Kid birthday party places and ideas in <a href="https://dev.thecodewiz.com/boston-with-kids/">Boston</a> include a variety of places to visit. The Public Garden of Boston, the 24-acre park, lying between Arlington and Charles Streets is one the most beautiful outdoor places to have a kid birthday party. Organize a celebration for your kids by offering them the onsite entertainment options. Younger kids will enjoy the swan boats.</p>
                                            <p>Another exciting thing to do at your kid’s birthday in Boston is taking them to the New England Aquarium, which is a real Boston’s must-visit. Here the kids can learn about our blue planet and have lots of fun. Exhibits, excursions, pictures, videos and interesting facts will take the guests of your kid’s birthday party to the endless world of aquatic animals.</p>
                                        

                                                                             
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Kid birthday party in Boston Public Garden</h3>
                                            <p>You can take your kids and their friends for a birthday party at the Boston Public Garden. It is highly recommended for locals and visitors of Boston, especially for spring and summertime. The Public Garden offers charming surroundings of nature – flowers, trees, lots of grass to run around, and beautiful swans and ducks in the pond. Plan a picnic, entertainment and enjoy time with your guests.</p>
                                            <p>Make riding the Swan boats one of the highlights of the birthday party. Open from April to September, the journey takes 15 minutes and moves you smoothly across the pond. Everyone in the family will enjoy the ride on the swan boats and the beauty of the Public Garden.  Near the lake, your kids will definitely like the bronze statues of Mrs. Mallard and ducklings Jack, Kack, Lack, Mack, Nack, Ouack, Pack and Quack.</p>

                                                                                 

                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">A place to celebrate kid birthday – New England Aquarium</h3>
                                            <p>The New England Aquarium is a beautiful place for the birthday party of your kids and their friends. Though there is no dedicated space for a party, the Aquarium offers lots of entertaining and educational activities which will fascinate your kids.</p>
                                            <p>The most popular option is having an IMAX birthday party for children ages 5 and older. The kids enjoy 3D films while having soda and popcorn. For an additional fee, you can also add a digital photo of the birthday kid displayed on the giant screen or order a special birthday greeting for your child before the movie.</p>
                                            <p>Another option offered by the New England Aquarium is visiting the exhibits and daily presentations, followed by lunch at the Aquarium’s Harbor View Café. You can take your kids and their guests to a behind-the-scenes tour, which is designed for up to 10 people for the kids aged 7 years and older.</p>
                                            <p>The kids will learn a lot about marine life at the Aquarium. They will be excited to find out what the animals eat, how sick animals are nursed back to health, how the tanks are maintained, where the Aquarium gets its salt and fresh water, and many other interesting facts. The kids will be encouraged to ask questions to develop curiosity and will have an interesting conversation with the professional educator of the Aquarium.</p>
                                            <p>The New England Aquarium also introduces Whale Watch excursions to view magnificent aquatic animals. The journey offers front-row seats to see whales, dolphins, seabirds and other kinds of marine life. If you want to take your kids and their friends on an unforgettable journey, the Whale Watch excursion is a wonderful idea for your kid’s party. The New England Aquarium is definitely one the best places to have a kid birthday party in Boston.</p>



                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Kid birthday party ideas</h3>
                                            <p>Kid birthday party ideas are endless. The main point is that every child wants to have fun at their birthday party. Kids remember many of the earlier birthdays for the rest of the lives. However, birthday parties can be dull without proper fun and entertainment.</p>
                                            <p>That’s why creative and educational parties for kids become more popular. For example, parents can throw a party at a ceramics studio, where the kids can paint their own pottery (plates, bowls or mugs) and keep them as a memory of a beautiful day spent with friends.</p>
                                            <p>If you want to show your kids where their favorite food comes from, plan a birthday party at one of the local factories. The factories often offer tours for kids and show them how the noodles, potato chips or soda are made. Afterward, the factory representatives often present gift bags with all the products the kids have learned about.</p>
                                            <p>The good idea is to take your kids to the ice cream stores, where they can learn how to make ice cream, add flavors and create their own unique taste. Similarly, the cooking classes at a kids’ birthday party become popular: from the early ages, the kids learn how to make pasta, pizza, and discover how to decorate their own cakes and cupcakes.</p>
                                            <p>Throwing a party, where every kid can create masterpieces using pencils, crayons, watercolors, brushes or even fingers under the supervision of a professional artist, is a perfect choice for all the art lovers. Little scientists will appreciate if their parents invite professionals to a science party and let their kids learn something new. They will enjoy doing scientific experiments for sure.</p>
                                            <p>If your kids love dancing, you can organize a dance birthday party with music and dancing masterclasses. Another excellent idea is to combine knowledge and fun at the kid’s birthday party. That’s why the birthday parties at the museums became popular as never before. The museums often have various offers for kids. The offers are educational, fun, and let the kids learn more about the world around us.</p>
                                            <p>To develop essential skills for their future and have fun at the same time, kids can get acquainted with electronics and robotics and learn about the basic <a href="https://www.dev.thecodewiz.com/kids-coding-languages/">concepts of coding</a>. For example, they can build simple circuits and boards, create their own robots and program their actions. Who knows – maybe your kid will love this process so much, that they will decide to build their future career in this field of knowledge. </p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Kid party ideas</h3>
                                            <p>Kid party ideas include doing something different at the birthday party. Why not have fun while building robots, dealing with electronics, creating and <a href="https://dev.thecodewiz.com/learn-math-playing-math-games/">playing games</a> with the guests?</p>
                                            <p>Code Wiz introduces several kid party ideas and sessions of an unforgettable event with pizza, drinks, desserts and cake! At Code Wiz, you can program Ozobots by using color markers and drawing paths for the robots to follow. It will be interesting for your kids to learn how to use ozocodes to program the robots and use mazes. The kids can also build motorized Lego models using kits and instructions. Working in teams, they learn about motors, belts and gears, but also get knowledge about the basics of <a href="https://dev.thecodewiz.com/coding-with-kids/">coding</a>.</p>
                                            <p>For the Minecraft lovers ages 7 years and older, Code Wiz introduces 90 minutes of Minecraft immersion with friends under the supervision of professional staff. Also, for the kids of the age 7 years and older, you can have your birthday party with <a href="https://dev.thecodewiz.com/scratch-programming-for-kids/">Tynker</a> at Code Wiz. They can build video games, animations, design their own characters, create comic books or dragon race games using a coding platform and drag and drop coding blocks.</p>
                                            <p>If your kids love electronics, plan their birthday party at Code Wiz. At Code Wiz, your kids and their friends will create their own gadgets and light them up with colorful LED lights and buzzers. The good thing – the kids will take their crafts home as a memory of the best birthday party ever!</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Kid party games indoors</h3>
                                            <p>Kid party games indoors are always fun! If you have decided to throw an indoor birthday party for your kids, think about the games which will bring them maximum fun! For example, you can organize a classic touch-and-feel game: take a big cardboard box with a hole at the top. Before the game starts, ask your kid and the guests to decorate the box, personalizing it with pictures, drawings and other decorations. As soon as the box is ready, put various objects into the box (for example, an apple, dried apricot, baby carrot, piece of pasta, raisins, etc.) and ask the kids to identify and guess the objects by feeling them with their hands. This game is designed to improve your kid’s sense of touch and descriptive language as the kids will try to explain what they were feeling inside the box.</p>
                                            <p>Another exciting game is “twenty questions,” where you need to think of an object or a person, and the others need to guess what you thought of by asking not more than 20 “yes or no” questions. The person who guessed the object or a person within 20 questions, has won the game. The game helps to improve confidence in asking questions, develop language skills and ability to think logically and create further questions based on the obtained information.</p>
                                            <p>One of the most popular types is the treasure hunt, a classic adventure game. It starts with parents hiding a big treasure (for example, a big box of candies or toys for everybody) and creating a set of clues which will guide the players from one spot to another, leading them to the big treasure they will find at the end of the game. The traces can be created based on the age and abilities of kids. The clues may include simple questions or short phrases leading to the specific object (for example, “if you want your teeth to shine, pick this up and spend some time”).</p>
                                            

                   
                                        
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Kid birthday party game</h3>
                                            <p>Kid birthday party games are different. Some of them can develop creativity skills. You can play piñata as one of the most popular and common ideas for kid birthday parties. Ask your kid to help you to prepare the piñata for the birthday party. You can easily get all the materials for your handcrafted piñata: balloons, paper, threads, wooden sticks, glue, watercolors, pencils and brushes, toys and candies to put them into piñata.</p>
                                            <p>As soon as it is done, put toys and candies inside and hang it somewhere high. Have the guests stand in a line three meters away. Blindfold the first kid in line, spin the piñata around three times and give the kid a wooden stick to hit it. Kids will have a chance to take a hit twice. As soon as it’s broken, they rush to gather the toys and candies. Piñata fascinates children bringing a lot of joy and fun to the birthday parties.</p>

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Kid party entertainment</h3>
                                            <p>Kid party entertainment can create unforgettable memories for your kids. Try inviting professional entertainers, performers and artists. Entertainment options for kids are changing and developing every year. These options can be tailored to every needs, wishes and interest of your kid. There are numerous options – from inflatable castles to interactive dancefloors.</p>
                                            <p>There is always something for every budget – from individual performers to big shows for children. For example, you can invite a magician with affordable prices yet amazing shows for the kids, from a quick magic act to a big illusion show. Depending on the size of the show, the magicians perform interactive close-up acts with the guests or even make an object disappear in front of the audience. Consider a huge soap bubbles show, this entertainment is very popular among the young audience. A LED show performed in the dark will take the kids into the world of light!</p>
                                            <p>There are a thousand ideas for your perfect kids’ party! You can organize a themed celebration based on specific characters or superheroes. How about Space-themed celebration or throwing an unforgettable Star Wars party? Little explorers will love a Safari or Jungle Party with all the appropriate decorations and entertainment.</p>
                                            <p>You can also take the kids and their friends to a farm to see and feed the animals. One of such farms is the <a href="https://chipinfarm.com/">Chip-In Farm</a> located in Belford. This is a great place which introduces a petting zoo, classes for kids and various games. Here you can enjoy fresh eggs, see the life at the farm, experience wagon ride and campfire. And of course, have lots of fun!</p>
                                            <p>Themed birthdays are fun, but there are party ideas which can sparkle a new interest and change your kids’ lives forever. Spending time at a place where your kids can learn something new, is a perfect idea for a birthday party.</p>
                                            <p>See how your kid is getting not only one year older but also smarter, and <a href="https://dev.thecodewiz.com/kids-birthday-party-boston/">get your discount for a party</a> at Code Wiz!</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
