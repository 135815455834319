import React from 'react';
import Acsl from '../../../components/acsl-competition/acsl-comp.component';
import Header from '../../../components/header/header.component';
import MetaTags from 'react-meta-tags';

const FranchiseAcslPage = () => (
    <div>
        <Header location="westborough" fulllocation="westborough-ma"/>
        <Acsl franchisee="westborough"/>
        <MetaTags>
            <title>Code Wiz - Westborough MA | ACSL</title>
        </MetaTags>
    </div>
);

export default FranchiseAcslPage;