import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import IcoCoachImg from '../../assets/images/ico_coach.png';
import IcoLaptopImg from '../../assets/images/ico_laptop.png';
import IcoCalendarImg from '../../assets/images/ico_Calendar.png';
import IcoDegreeImg from '../../assets/images/ico_degree.png';
import IcoEnrollImg from '../../assets/images/ico_enroll.png';

//import TeamMember from '../team-member/team-member.component';
 
export default function FallClassesMobileSlider(props) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    <Slider {...settings}>
      <div>
        <div className="common_white_block">
            <img src={IcoCoachImg} alt="coach"/>
            <h4 className="bungee blue_txt">Small coach to<br/> student ratio </h4>
            <p><span>Lots of individual attention</span> and opportunities to socialize</p>
        </div>						
      </div>
      <div>
        <div className="common_white_block">
            <img src={IcoLaptopImg} alt="laptop"/>
            <h4 className="bungee blue_txt">K-12 <br/>CS Standards</h4>
            <p><span> Alignment with the K-12 </span> Computer Science Framework.</p>
        </div>						
      </div>
      {/* <div>
        <div className="common_white_block">
            <img src={IcoCalendarImg} alt="calendar"/>
            <h4 className="bungee blue_txt">Monthly <br/>Pricing</h4>
            <p><span> Pricing starts at $145/mo giving </span> families 4 or 5 classes depending on the month.</p>
        </div>						
      </div> */}
      <div>
        <div className="common_white_block">
            <img src={IcoDegreeImg} alt="degree"/>
            <h4 className="bungee blue_txt">Montesorri <br/>Style Approach</h4>
            <p><span> Project based and student driven. </span> The learning path is tailored to child's interests.</p>
        </div>						
      </div>
      <div>
        <div className="common_white_block">
            <img src={IcoEnrollImg} alt="enroll"/>
            <h4 className="bungee blue_txt">Rolling <br/>Enrollments </h4>
            <p><span> You can start anytime! Need to stop? </span> Just give us a 30 day notice.</p>
        </div>						
      </div>
    </Slider>
  );
}