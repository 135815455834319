import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import ScreenTime from '../assets/images/blog-pictures/screen-time-for-kids.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">September 11, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>SCREEN TIME FOR KIDS: HOW TO MANAGE IT DURING DISTANCE LEARNING</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Wrightjermainejmw</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Around 1.5 billion children across the globe have been out of school since the current lockdown measures have been put in place to curb the spread of COVID-19. As a result, children are being encouraged to dive into digital learning like never before. And with no clear end to the pandemic insight, there’s a strong possibility that this will become the “new normal.” </p>
                                            <p>Missing the socialization and intimate connection to their peers that only the face-to-face traditional school setting can provide may be a little awkward for some kids. But with all the entertainment options at their disposal and a plethora of technological devices to enjoy them on, it’s not like they will lose their marbles over staying home all day. </p>
                                            <p>On the other hand, the lockdown has been a far bigger challenge for parents/guardians. </p>
                                            <p>As if the many disruptions caused by the pandemic aren’t already enough, now parents have to deal with the unwanted problems that come with homeschooling. One of the major ones is <a href="https://en.wikipedia.org/wiki/Screen_time">screen time</a> for kids. </p>
                                            <p>Digital homeschooling has provided a dependable alternative form of education in these unprecedented times. Educational institutions are relying heavily on digital platforms for continued teaching.  </p>
                                            <p>For many countries school lessons have moved online causing kids to watch video lessons, write papers, participate in video calls, etc. online using digital devices. This has significantly increased the time they spend in front of a screen, which was already a concern for parents. </p>
                                            <p>While the ubiquity of digital technology in society has its many benefits, as is being proven now with it being a solid substitute for traditional schooling, disengagement from its devices is an opposing issue. </p>
                                            <p>The phrase “screen time” and the question “how much” are often used together, taking turns in preceding each other. </p>
                                            <p>So how much screen time is too much screen time for your kids?</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">How much Screen Time for Kids?</h3>
                                            <p>Well, ironically, in this era of complex artificial intelligence (AI) and deep analytics, there’s no concrete answer. </p>
                                            <p>Organizations like the American Academy of Pediatrics (AAP) and the World Health Organization (WHO) have released screen limit guidelines. But in reality, there is no magic number for kids screen time that’s “just right.”</p>
                                            <p>Back in 1999, the AAP attempted to provide a cemented cut-and-dry approach to screen time. Its 2×2 policy recommends no screens in the first two years, and no more than two hours a day for children older than the age of two. But twenty years later with today’s kids being born into a world of technology, those policies now seem ancient. This has forced the organization to revamp its policies to become more flexible.</p>
                                            <p>This uncertainty has made it even more difficult for concerned parents/guardians of young children who are currently the first generation of moms and dads raising ‘digital natives.’</p>
                                            <p>At the moment one of the biggest challenges for researchers is understanding the impact of the extra screen time young people are experiencing during the lockdown. </p>
                                            <p>There’s no precedent for these current conditions and most of the research on screen time is still in the context of a child’s day being largely within a traditional school setting.</p>
                                            <p>More data is needed to fully understand how the extra screen time is affecting young people.</p>
                                            <p>But is too much screen time for kids really that bad?</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">The Benefits of Screen Time</h3>
                                            <p>If you’re a parent/guardian chances are you’ve heard the concerns about the ill-effects of too much screen time for kids. </p>
                                            <p>But not everything about the connection between heavy digital device use and kids is bad.</p>
                                            <p>There are benefits as well.</p>
                                            <h4 className="bungee">Screen time and brain development in children</h4>
                                            <p>According to the <a href="https://www.aap.org/en-us/advocacy-and-policy/aap-health-initiatives/Pages/Media-and-Children.aspx">AAP</a>, plenty of screen time can also help kids to learn.</p>
                                            <p>Developmentally optimized media is a proven learning tool for young children. It facilitates executive function, builds self-control and problem-solving skills, and improves children’s ability to follow directions.</p>
                                            <p>However, while the AAP recognizes that screen time can be beneficial for young children, they advise parents to manage it in a constructive and interactive way.</p>
                                            <p>And here lies the challenge for parents/guardians. </p>
                                            <img src={ScreenTime} alt="screen time for kids" />
                                            <p>This generation of children literally has the world wide web and everything it contains in their back pockets. And with distance learning likely to be a big feature of children’s lives for the foreseeable future, it will become increasingly difficult to limit screen time for kids.</p>
                                            <p>So how can parents/guardians adequately manage screen time for kids?</p>
                                            <p>Check out these guidelines.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Guidelines to Managing Screen Time for Kids</h3>
                                            <p><i>Here are some screen time recommendations for kids that you can try to implement in your household:</i></p>

                                            <h4 className="bungee">1. Make your own family Digital Screen time limitation plan</h4>
                                            <p>When used appropriately digital media can enhance your kids’ life. The key is making digital media work for you and within your family values and parenting style.</p>
                                            <p>Parenting rules can also be applied in the virtual environment. Don’t be afraid to limit children’s use of technology. Kids need and expect them.</p>
                                            <p>Set clear rules about screen time and how, when, and where your kids can use the internet. Communicate these boundaries and rules clearly with them.</p>

                                            <h4 className="bungee">2. Be vigilant</h4>
                                            <p>It’s imperative to know what platforms, software, and apps your children are using for remote learning. Do your own homework on the technology your kids use. Close to 100,000 apps are labeled as educational, but are the ones your kids use truly beneficial to learning?</p>
                                            <p>Use reputable review sources to guide you in properly assessing these tools.</p>
                                            <p>Also, know what they’re doing online – what sites they’re visiting on the web, and the friends they interact with.</p>

                                            <h4 className="bungee">3. Create a balance between online and offline activities</h4>
                                            <p>Un-moderated screen time can displace many important activities such as face-to-face interaction, family-time, outdoor-play, exercise, unplugged downtime, and sleep. So set limits and encourage physical leisure time.</p>
                                            <p>Unstructured and offline play stimulates creativity. Make unplugged playtime a daily priority, especially for younger children. </p>
                                            <p>According to the AAU, recess is an essential part of a child’s optimal development. Their research revealed that breaks from stationary learning and physical activity contribute to a child’s physical, emotional, and social development.</p>
                                            <p>Also, the AAO (American Academy of Ophthalmologists) discovered the potential link between spending time outdoors, especially in early childhood, and the slow progression of near-sightedness.</p>

                                            <h4 className="bungee">4. Engage with your children when they are using screens</h4>
                                            <p>Screen time shouldn’t always be alone time. As much as you can try to participate in your kids’ distance learning activities. Don’t just monitor your kid’s online activities, be a part of what they’re doing. It will encourage bonding and learning.</p>

                                            <h4 className="bungee">5. Create tech-free zones</h4>
                                            <p>Encourage more focused family time, healthier eating habits, and better sleep by keeping mealtimes, other family and social gatherings, as well as children’s bedrooms screen-free. </p>
                                            <p>Try to recharge tech devices overnight outside of your child’s bedroom to help avoid the temptation to use them when they should be sleeping.</p>
                                            <p>Also, turn off televisions that you aren’t watching or during home-schooling classes because background TV can get in the way of face-to-face time with kids. </p>

                                            <h4 className="bungee">6. Inform caregivers and other Family members</h4>
                                            <p>If you’re one of the lucky parents who still have a job and need a caretaker or family member to care for your kids during the day, inform them about the risk of excessive screen time, how to detect and prevent it.</p>
                                            

                                            <h4 className="bungee">7. Put preventative measures in place</h4>
                                            <p>As the saying goes prevention is better than cure. So ensure safety measures and parental control features are set-up and enabled for your kids’ online activities.</p>
                                            <p>Install the necessary monitoring software or program on devices used by children and set the privacy settings to high. </p>
                                            

                                            <h4 className="bungee">8. Explain screen limitation to your kids</h4>
                                            <p>Implementing screen time limitations is essentially asking your child to limit one of the main ways they entertain themselves, socialize with their peers, and learn.</p>
                                            <p>It’s highly likely that you being a stickler about the amount of time they spend using screens will create some tension between you and your child. So explain to them why limitations around screen time/online activities are necessary.</p>
                                            

                                            <h4 className="bungee">9. Empower your Kids</h4>
                                            <p>After explaining to them why limiting screen time is beneficial, don’t be afraid to give your kids a little responsibility as well. </p>
                                            <p>Teach your kids how to develop healthy better E-Learning habits. Empower them with knowledge and information about how to use the internet safely and ways they can prevent excessive screen time.</p>
                                            <p>Also teach them how to be vigilant about cyber fraud, cyberbullying, and how to seek support when facing these issues.</p>

                                            <h4 className="bungee">10. Have support systems in place</h4>
                                            <p>Despite your best efforts in following all of these guidelines and other precautionary methods, there still remains the possibility of them not working. We hope this isn’t the case for you but if it does it’s always good to be prepared for it. </p>
                                            <p>With that said try to set up online psychological support and counseling sessions for your kids in case, they’re struggling with screen time limitations.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Final Thoughts</h3>
                                            <p>Being concerned about the increasing amount of time your child spends in front of a digital screen, even if the time is spent on schoolwork, is a legitimate concern.</p>
                                            <p>We hope the aforementioned guidelines will help to alleviate your fears and concerns about managing your child’s screen time. </p>
                                            <p>If you’re considering enrolling your child in an online kids learn to code program <a href="/contact-us">contact us</a>. We have several kids coding virtual classes that your kids can enjoy in the confines of your home. Our coaches will assist you with any software setup necessary. </p>
                                            <p>We’ve also created specific online safety and parent guidelines to protect all our students. </p>
                                            <p>If you already have a child enrolled in one of our online digital learning classes and are unclear on any of the above screen time guidelines, don’t hesitate to <a href="/contact-us">get in touch with us as well</a>.</p>

                                            <p><b>P.S</b> <i>If your kids need help with graphical images for their digital homeschooling programs check out <a href="https://www.stickermule.com/">Sticker Mule</a>. Whether they need to increase the resolution of any graphic/photo instantly, or upgrade any image to a high resolution, or instantly remove the background from photos for free, Sticker Mule can help them create high quality, print-ready images. Check out these 3 recently released tools: <a href="https://www.stickermule.com/trace">Trace</a>, <a href="https://www.stickermule.com/upscale">Upscale</a>, & <a href="https://www.stickermule.com/redraw">Redraw</a> </i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
