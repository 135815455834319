import React from 'react';
import FindACoursePage from '../../find-a-course/find-a-course.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFindACoursePage = () => (
    <div>
        <FindACoursePage franchisee="kellereast" fulllocation="kellereast-tx"/>
        <FacebookMessenger location="kellereast" FBPageId="101044382898680" />
        <MetaTags>
            <title>Code Wiz - Keller East TX | Find A Course</title>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default FranchiseFindACoursePage;