import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">August 14, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> WHAT AGE IS APPROPRIATE FOR MINECRAFT? </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                            
                                            <p>A parent posted a question on our Facebook page. “What do you think is an appropriate age for a kid to start playing Minecraft?” It seems like the answer should be simple, # years old! Simple! But when I thought about the answer, so many things popped into my head. I remember when my kids were younger and I would try to decide if a movie was age appropriate. Even though I tried to be a good parent mistakes were made… there are stories… Now I listen to my friends who ponder, “are my kids old enough for Star Wars? Because I sure would like to show them Star Wars.” But I digress, back to the original question, what about Minecraft?</p>
                                            <p>Minecraft itself is an amazing game. It is a sandbox game which allows players incredible flexibility to create entire worlds. Players can work together to create great things! Check out <a href="https://www.youtube.com/watch?v=8_bW3ab8YAk">this video</a> out where they are creating the entire planet in Minecraft.</p>
                                            <p>There are different versions of play in Minecraft such as Creative Mode or Survival Mode. In Survival Mode each player has a certain amount of health and your player can “die” (no blood or hollywood special effects) and then respawn with an empty inventory losing everything they have collected and built. If you hear your kids howling in the background they are probably in this mode and may be a “creeper” which is a type of “mob”. A mob is really any living thing in Minecraft. You may notice there is a whole vocabulary that goes along with the virtual Minecraft world, which is part of the fun! Another mode is Creative Mode. While playing in Creative Mode the “mobs” can not hurt you. You are able to build and destroy structures freely and have unlimited resources. Both modes are fun. We offer both modes on our free playtime server.</p>
                                            <p>What should parents watch out for? </p>

                                            <ul>
                                                <li>There can be some mild violence as players fight off zombies and other creatures, but there is no blood, broken bones, or anything gruesome. It is even possible to attack other players.</li>
                                                <li>Some servers are public, where anyone can join. So there could be bad language, bullying or even predators. Not to worry though since there are plenty of kid friendly servers including our Code Wiz Arlington server. These servers should have a whitelist to restrict access. Ours is also monitored by a coach.</li>
                                            </ul>
                                        
                                            <p>Kids and some adults love to play Minecraft, because it is an incredible world where they can build whatever they want. At Code Wiz we can take their desire to play the game and turn that into programming skills. We have taught kids Minecraft drag n drop programming as young as 7 years old. Check out this review from Boston Moms which describes some of what we do! </p>
                                            <p>We also offer a free Minecraft play time every week with a coach who moderates, and we whitelist usernames so only “our” kids play on the private server. We have a plugin that changes bad language into the words “peanut butter” for the chat. I laugh every time I see that as it is fairly sensitive to language and sometimes changes perfectly reasonable words into “peanut butter”. Nothing is 100% foolproof so that is why our coach is there and we can kick someone out if necessary. But, so far, we have been lucky to just have great kids!</p>
                                            <p>We also offer a World Builder course where students work together to build a city within certain monetary and natural resources. They build a set of rules or a constitution for their town. Our coach acts as the DPW if they want to do larger projects and they will have to “pay” the coach with Minecraft resources that they find or start with for this service. Many of our kids have taken this camp multiple times. It is a lot of fun and often creates a desire to learn more about how Minecraft works and how to code or students may want to build one of their own servers!</p>
                                            <p>So, when should your kid start playing Minecraft? The official recommendation is 7 years old, but it will depend on your family and the maturity of your child. If your child does find a love for Minecraft and never wants to stop playing, we can help by turning that desire into valuable programming skills!</p>

                                        

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
