import React, {useState} from 'react';
import firebase from '../Firebase/firebase.js';
import Popup from 'reactjs-popup';

import AboutPageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
//import AboutSpark from '../../assets/images/image-2.png';
//import AboutGivingBack from '../../assets/images/giving-back-image.png';
import CityImage from '../../assets/images/location-pin.png';
import CodingRobotics from '../../assets/images/landing-page-coding-robotics.png';
import WhyCodewiz from '../../assets/images/landing-page-why-codewiz.png';

import FranchiseHeaderImg from '../../assets/images/FranchiseeHomePage.jpg';
import ComingSoonHeaderImg from '../../assets/images/cw-coming-soon.png';
//import MidBkgImage from '../../assets/images/landing-page-mid bkg.png';

import Button from '../../components/button/button.component';
import Review from '../../components/review/review.component';
import TeamMember from '../../components/team-member/team-member.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
//import TeamSlider from '../../components/slick-slider/teams-slider.component';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';
import NeedhamFamily from '../../assets/images/needham-fam.jpg';
import LawrencevilleFamily from '../../assets/images/cheris-family-lawrenceville.jpeg';
import PlanoFamily from '../../assets/images/naila-family-plano-tx.jpg';
import StjohnsFamily from '../../assets/images/eluid-rebeca-family-stjohns-1.jpeg';
import HoustonFamily from '../../assets/images/joy-houston.jpeg';
import TierrasantaFamily from '../../assets/images/Rich-and-Davon.jpg';

import GoogleMapNashuaComponent from '../../components/google-map/google-map.component-nashua';

import GoogleMapNeedhamComponent from '../../components/google-map/google-map.component-needham';
import GoogleMapLawrencevilleComponent from '../../components/google-map/google-map.component-lawrenceville';
import GoogleMapPlanoComponent from '../../components/google-map/google-map.component-plano';
import GoogleMapStjohnsComponent from '../../components/google-map/google-map.component-stjohns';
import GoogleMapHoustonComponent from '../../components/google-map/google-map.component-houston.jsx';
import GoogleMapDurhamComponent from '../../components/google-map/google-map.component-durham.jsx';
import GoogleMapCypressComponent from '../../components/google-map/google-map.component-cypress.jsx';
import GoogleMapTierrasantaComponent from '../../components/google-map/google-map.component-tierrasanta.jsx'
import GoogleMapCarmelComponent from '../../components/google-map/google-map.component-carmel.jsx'


import './franchisee.styles.scss';


class ComingSoonFranchisee extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.name,
            data: {},
            team: props.team,
            map: props.name + "-map",
            brochure: "https://getform.io/f/c7aeb3d8-3132-4a1d-8241-6142b5b305e8",
            grandOpeningUrl: "https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e",
            locationemail: props.name + "@thecodewiz.com",
            zoho: props.owner,
            isOpen : false
            // email: "esther@thecodewiz.com"
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal () {
        this.setState({isOpen: true})
    }

    closeModal () {
        this.setState({isOpen: false})
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        // const [open, setOpen] = useState(false);
        // const closeModal = () => setOpen(false);
        const formUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e"; //grandOpeningUrl
        const teamsArr = [];
        const teams = this.state.team;
        if(teams.length > 0){
            for (let i = 0; i < teams.length; i++) {
                teamsArr.push(<TeamMember id={i} key={i} name={teams[i].name} picture={teams[i].picture} title={teams[i].title}/>);
            }
        }
        
        let discountPrice = Math.floor(parseInt(this.state.data.classprice) * 0.68586);
        let zeeFamilyPicture = "";

        let zeeSummary = "";
        let zeeFullStory = "";
        let zeeName = "";
        let zeeFullStoryButton = false;
        

        const map = [];
        if(this.state.location === "nashua"){
            map.push(<GoogleMapNashuaComponent/>);
        }else if(this.state.location === "needham"){
            map.push(<GoogleMapNeedhamComponent/>);
            zeeFamilyPicture = NeedhamFamily;
           
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Needham, MA - Akshay and Shweta!</p>
                <p>Akshay and Shweta look forward to helping kids of Needham, MA unlock their inner genius!</p>
                <p>Using software/technology to scale solutions for some of the key issues that the world is facing is something they’re both passionate about! They believe that the current tech innovators and problem solvers need to pass on the baton to the next generation, so they can build and scale solutions in the long term!</p>
            </div>;
            zeeName = "Shweta & Akshay";
            zeeFullStoryButton = false;

        }else if(this.state.location === "lawrenceville"){
            map.push(<GoogleMapLawrencevilleComponent/>);
            zeeFamilyPicture = LawrencevilleFamily;            
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Lawrenceville, GA - Cheris!</p>
                <p>Cheris has been an educator for the last 16 years! Her interest in computer science, passion for helping students find confidence in learning and the personalized style of the code wiz approach is what made her decide to open up a  Code Wiz location in her community.</p>
                <p>Cheris is most excited about seeing kids get excited about learning, developing confidence and she looks forward to witnessing their wow moments! She is excited to help your kids love learning as much as she does! And she can’t wait to get started!</p>
            </div>;
            zeeName = "Cheris";
            zeeFullStoryButton = false;
        }else if(this.state.location === "plano"){
            map.push(<GoogleMapPlanoComponent/>);
            zeeFamilyPicture = PlanoFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Plano, TX - Naila and Ali!</p>
                <p>Naila and Ali are super excited to be part of an educational platform that enhances critical thinking, problem solving and creativity while having fun!</p>
                <p>As the world continues to be more digitalized, they are looking forward to seeing kids and families enjoy a skill set that will have a long lasting effect on their futures. They love kids and are excited to play, build, and have fun with coding and robotics!</p>
            </div>;
            zeeName = "Naila & Ali";
            zeeFullStoryButton = false;
        }else if(this.state.location === "stjohns"){
            map.push(<GoogleMapStjohnsComponent/>);
            zeeFamilyPicture = StjohnsFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz St. Johns, FL - Eliud and Rebeca!</p>
                <p>Eliud and Rebeca couldn’t be happier to have a positive impact on the kids in the St. Johns’ community through learning and development while also having fun! As Engineers themselves, they are really excited about joining the kids and their families on this learning journey and playing with some legos. They love legos! So get ready St. Johns, FL and surrounding areas, lots or robotics and coding classes for kids are coming your way!</p>
            </div>;
            zeeName = "Eliud & Rebeca";
            zeeFullStoryButton = false;
        }else if(this.state.location === "houston") {
            map.push(<GoogleMapHoustonComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Oak Forest, TX - Joy!</p>
                <p>Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.</p>
                <p>Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!</p>
            </div>;
            zeeName = "Joy";
            zeeFullStoryButton = false;
        }else if(this.state.location === "durham") {
            map.push(<GoogleMapDurhamComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Durham, NC - Teresa!</p>
                <p>Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.</p>
                <p>Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!</p>
            </div>;
            zeeName = "Joy";
            zeeFullStoryButton = false;
        }else if(this.state.location === "cypress") {
            map.push(<GoogleMapCypressComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Cypress North, TX - Ton!</p>
                <p>Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.</p>
                <p>Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!</p>
            </div>;
            zeeName = "Ton";
            zeeFullStoryButton = false;
        }else if(this.state.location === "tierrasanta") {
            map.push(<GoogleMapTierrasantaComponent/>);
            zeeFamilyPicture = TierrasantaFamily;
            zeeFullStory = <div>
                                <p>Are you ready to have a blast with Davon and Richard?! They both hail from Southeast San Diego but didn’t know each other at all despite walking similar paths. Davon and Richard both enlisted into the United States Navy shortly after high school. Richard became a commissioned officer, and Davon rapidly rose through the enlisted ranks to his senior leadership role as a Senior Chief, E-8. While honorably serving our country, they met, became friends, and eventually business partners. </p><p>During their service in the military, they did curriculum development, instructional design, and training for civilians, US, and foreign military personnel. Richard is currently still serving our country in the Navy Reserves and has a background in all things engineering, project management, and human resource management. After completing 20 years of service, Davon has retired and now has a civilian background in higher education/strategic partnerships. With over 34 years of combined service and after having success doing executive coaching and leadership consultation, they decided to focus on bringing educational opportunities to the future engineers and leaders!</p>
                                <p>Their common goals are to break down barriers, remove obstacles, and provide resources for success for youth within our communities. Code Wiz allowed the two of them to utilize the combination of their experience, strategy, ingenuity, and passion to fulfill their purpose. They are excited to bring the foundation of the ever-expanding world of tech, coding, and robotics to the community of San Diego, especially military families and other underserved populations. <br/><br/>
                                California Competes has stated that there will be over 2 million unfillable jobs in Southern California in the tech industry alone! Richard and Davon are determined to create a pathway for our local youth to learn, be prepared for careers in tech, and have fun while doing it.<br/>
                                </p><p>Whenever someone tells you that the sky's the limit, always respond with, “but there are footsteps on the moon.” Let our family here at Code Wiz Tierrasanta help unlock your child’s inner genius and potential without limits!<br/>
                                With their powers combined, they are Code Wiz Tierrasanta!</p>
                            </div>
            zeeSummary = <div>
                            <p>Are you ready to have a blast with Davon and Richard?! They both hail from Southeast San Diego. Davon and Richard both enlisted into the United States Navy shortly after high school. Richard became a commissioned officer, and Davon rapidly rose through the enlisted ranks to his senior leadership role as a Senior Chief, E-8. While honorably serving our country, they met, became friends, and eventually business partners. </p>
                            <p>Their common goals are to break down barriers, remove obstacles, and provide resources for success for youth within our communities. Code Wiz allowed the two of them to utilize the combination of their experience, strategy, ingenuity, and passion to fulfill their purpose. They are excited to bring the foundation of the ever-expanding world of tech, coding, and robotics to the community of San Diego, especially military families and other underserved populations.</p>
                        </div>;
            zeeName = "Davon & Richard";
            zeeFullStoryButton = true;
        
        }else if(this.state.location === "carmel") {
            map.push(<GoogleMapCarmelComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Carmel, IN - David & Ethel!</p>
                <p>Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.</p>
                <p>Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!</p>
            </div>;
            zeeName = "David & Ethel";
            zeeFullStoryButton = "";
        }

        let grandOpeningFrom = <form id="cw-contact-form grand-opening-form" className="contact-form" action={this.state.grandOpeningUrl} method="POST">
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputLast">Last Name *</label>
                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputEmail">Email *</label>
                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputPhone">Phone *</label>
                <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                <select name="mode" className="form-control" id="inputMode" required>
                    <option value="inperson">In-Person Classes</option>
                </select>
            </div>
            <div className="form-group col-md-6">
                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
            </div>
        </div>
        <div className="form-group">
            <div className="form-check">
                    <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                    <label className="form-check-label tiny-print" htmlFor="gridCheck">
                    {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                    <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                    <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                    </label>
                </div>
            </div>
        {/* <button type="submit">Send</button> */}
        
        <button type="submit" className="btn white-button" target="_blank">SEND</button>
    </form>

// console.log("Hours", this.state.data.friday)

        return (
            <div className="subsite franchisee-page" id={this.state.location}> 
                <div className='coming-soon-container'>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 ">
                            <img className='coming-soon-image' aria-hidden="true" src={ComingSoonHeaderImg} alt="Franchise coming soon header picture"/>
                            <h3 className='header-text cw-orange center bungee'>Fall 2022!</h3>
                            <h2 className="header-text cw-dark-blue map-pin center"><span><img aria-hidden="true" src={CityImage} alt="franchise city"/></span>  {this.state.data.citystate}</h2> 
                            
                            {/* <Button color="blue" link="/founding" size="x-large">LEARN ABOUT OUR FOUNDING MEMBER OFFER</Button> */}
                        </div>
                        <div className="col-sm-12 col-md-4 form-in-the-header">
                            <h4 className="bungee center">Lock in the founding member discount for life!</h4>
                            <p className="center">Applies to regular monthly classes only. Fill out this form below and we will be in touch!</p>
                            
                            {grandOpeningFrom}
                        </div>
                    </div>
                    <div className="as-seen-on">
                        <div className="row">
                            <div className="col-md-3 as-seen-on-div">
                                <h2 className="center cw-dark-blue">AS SEEN ON</h2>
                            </div>
                            <div className="col-md-9 as-seen-on-div">
                                <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                            </div> 
                        </div>   
                    </div>
                </div>
                <div className="body-wrapper cw-dark-blue">
                    <div className="container">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row">
                            
                            <div className="col-md-12 col-lg-6 zeeBio">
                                <h3 className="bungee">coding and robotics classes in {this.state.data.citystate}</h3>
                                {zeeSummary}
                                <p>Give your child the chance to learn life-shaping skills with fun, flexible programs offered online or at our {this.state.data.citystate} location.</p>
                                {/* <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                                {zeeFullStoryButton ?
                                <Popup trigger={<button className='btn blue-button'>Learn More About The Owners</button>} modal>
                                    <div className="col-sm-12 cw-dark-blue form-in-modal">
                                    <h4 className="bungee center">Meet the Owners {zeeName}</h4>
                                    {zeeFullStory}
                                    </div>
                                </Popup> : ""}
                            </div>
                            <div className="col-md-12 col-lg-6 zee-family-picture">
                                <img aria-hidden="true" className="familyImg" src={zeeFamilyPicture} alt="coding robotics"/>
                            </div>
                            
                        </div>
                        <div className="empty-space form-empty-space"></div>
                        <div className="row row-reverse coding-robotics">
                            <div className="col-md-12 col-lg-6 code-create-grow">
                                <h3 className="bungee">code. create. grow.</h3>
                                <p className="">At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem-solving. Best of all, anyone can learn to code—no matter their age, tech proficiency, or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                                {/* <Button color="orange" link="/" size="x-large">LEARN ABOUT OUR FOUNDING MEMBER OFFER</Button> */}
                                <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                                    <div className="col-sm-12 cw-dark-blue form-in-modal">
                                    <h4 className="bungee center">Lock in the founding member discount for life!</h4>
                                    <p className="center">Applies to regular monthly classes only. Fill out this form below and we will be in touch!</p>
                                        {grandOpeningFrom}
                                    </div>
                                </Popup>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <img aria-hidden="true" className="midImg" src={CodingRobotics} alt="coding robotics"/>
                            </div>
                        </div>
                        <div className="empty-space-half"></div>
                        <div className="row fr-whycodewiz">
                            <div className="col-md-12 col-lg-6 order-md-1 whycodewizimg">
                                <img aria-hidden="true" className="width-100 midImg" src={WhyCodewiz} alt="why codewiz"/>
                            </div>
                            <div className="col-md-12 col-lg-6 order-md-2">
                                <h3 className="bungee whycodewiztitle">why code wiz?</h3>
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">project-driven, student-steered learning</h6>
                                <p>Children create by completing projects rather than lessons or levels</p>
                                
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">really cool rewards</h6>
                                <p>We reward learning with Code Wiz gear and other awesome prizes</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">community connectivity</h6>
                                <p>Students can check their work online and get inspired by peer projects</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">progress at your fingertips</h6>
                                <p>Stay connected to your child's success with automated progress report emails</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">free, no-strings intro class</h6>
                                <p>See how learning to code can change your child's life-all classes are offered in person and online!</p>
                                <br/>

                                {/* <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="container contact-info-contact-page">
                    
                </div>
                <div className="col-md-12 center">
                    <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                        <div className="col-sm-12 cw-dark-blue form-in-modal">
                            <h4 className="bungee center">Lock in the founding member discount for life!</h4>
                            <p className="center">Applies to regular monthly classes only. Fill out this form below and we will be in touch!</p>
                            {grandOpeningFrom}
                        </div>
                    </Popup>
                </div>

                {/* <!-- Location Map --> */}
                {map}

                <div className="container">
                    <div className="row">
                        
                    </div>
                </div>
                
                {/* <FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/> */}
                
            </div>
        );
    }
}

export default ComingSoonFranchisee
