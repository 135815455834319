import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">July 29, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>WHY IS CODING SO IMPORTANT? BENEFITS FOR KIDS AND STUDENTS</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Digitalization has entered in every industry which means that digital experts are one of the most wanted professionals in the world. The future holds further development in the world of innovation and technology which is why coding is a great skill to have.</p>
                                            <p>Educational institutions and parents have recognized the importance of coding. That’s why they are motivating children and young adults to learn how to code. For the sake of making an informed decision, consider the following benefits of coding for kids and students.</p>
                                           
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Benefits of Coding for Kids and Students</h3>
                                            <h5 className="bungee">1. Coding Boosts Problem-Solving Skills</h5>
                                            <p>Detecting problems and issues and breaking them into different aspects for the best solution is one of the elements of coding. Children and students will learn how to systematically solve problems. In case of a problem, computer programmers have to test various codes until they find the solution. Not only does coding teach students how to approach a problem with logic but it also teaches them patience and persistence.</p>

                                            <h5 className="bungee">2. Coding Evokes Creativity</h5>
                                            <p>Through coding, students have a chance to experiment and create. The consistent need for creation will empower their creativity. Motivating the mind to work on a personal project and think outside the box will show the students that creativity is something that is rewarded. Typical education doesn’t allow that freedom which is why learning how to code will create a much-needed balance between standardized learning and creative freedom.</p>

                                            <h5 className="bungee">3. Coding Teaches How to Learn from Mistakes</h5>
                                            <p>Instead of looking at mistakes as something negative and embarrassing, students will observe mistakes as a way of learning. Each wrong possibility that they try out in programming will bring them one step closer to the right one. <i>“Coding has thought me that there is no wrong answer. There is only one less choice which makes your job easier. That’s the reason why I think that coding is great for children. They’ll learn that making mistakes is natural and even necessary for progress,”</i> says Johnathan Sully, a teacher of Front-End Development course and a technical writer at <a href="https://bestessayseducation.com/">Best Essays Education</a> and <a href="https://supremedissertations.com/">Supreme Dissertations.</a></p>

                                            <h5 className="bungee">4. Coding Helps with Computational Thinking</h5>
                                            <p>Nowadays, children are surrounded by computational devices from their phones to their laptops. Learning how to recognize data patterns and systematic ways in which computer behaves will inspire their computational thinking. They’ll be able to apply their learned skill to devices they use on a daily basis which makes coding that more interesting. That combination of encouraging computational thinking and practical use of learned skills will develop their cognitive skills.</p>

                                            <h5 className="bungee">5. Coding Promotes Algorithmic Thinking</h5>
                                            <p>Coding demands that students use strategy to decide which formulas they need to apply to get to the solution. That is, it motivates algorithmic thinking. Not only will their coding skills improve with time, but their math skills will also be enhanced. Consequently, students can show better results on standardized tests. According to <a href="https://code.org/files/CollegeBoardPreliminaryCSMemo.pdf"> College Board data </a>, students who take the AP Computer Science exam earn higher AP Calculus and Statistics scores relative to students who previously performed at a similar level in math.</p>

                                            <h5 className="bungee">6. Coding Provides Skills that Are Becoming a Necessity in the 21st Century</h5>
                                            <p>The importance of technology definitely won’t decline. Learning coding will ensure that the children are fully equipped to cope with a technology-oriented world.<i> “Our parents might have dodged the innovation-obsessed society, but starting from Millennials, there is no escape from computing. The sooner you walk into that world the better. Teaching children how to code will make their life easier in the future,”</i> expressed Zoe Terrell, computer programming teacher and contributor writer at <a href="https://www.trustmypaper.com/">Trust My Paper</a>.</p>

                                            <h5 className="bungee">7. Coding Gives More Job Opportunities</h5>
                                            <p>With coding skills, children won’t have to worry about their careers. Stories about developers who became millionaires or landed incredible job opportunities such as working for leading companies like Google and Amazon can give children hope for a promising future. Some of the computing coding jobs that kids can consider are:</p>

                                            <ul>
                                                <li>Web developer</li>
                                                <li>Computer programmer</li>
                                                <li>Software application developer</li>
                                                <li>Computer systems engineer</li>
                                                <li>Computer systems analyst</li>
                                                <li>Database administrator</li>
                                                <li>Software quality assurance (QA) engineer</li>
                                                <li>Business intelligence analyst</li>
                                            </ul>

                                            <p>As you can tell, the choices are many so the students’ possibilities won’t be limited. Once they learn the basics they will be able to find their place in the coding world and pursue that career.</p>

                                            <h5 className="bungee">8. Coding Supports Innovation</h5>
                                            <p>Not everyone can become the next Mark Zuckerberg or Bill Gates. However, children can learn that innovation is something they should look forward to. They will be less hesitant to accept change and experiment with different technologies when they understand how everything works. Coding will teach them that innovation shouldn’t scare them or make them feel ignorant. Students that learn coding will develop a mindset that supports innovation and looks at it as a crucial element of development.</p>

                                            <h5 className="bungee">9. Coding Encourages Teamwork</h5>
                                            <p>Going to coding schools will help children meet like-minded, creative individuals. Unlike self-thought programmers, attending a coding school will teach children about the importance of teamwork. At top-rated coding and robotics school such as <a href="https://dev.thecodewiz.com/">Code Wiz</a>, students develop communication and collaboration skills as well. Working in teams shows them that combined minds can come to the solution much faster. Students’ efficiency depends on their readiness to collaborate and communicate with peers and that is what coding will teach them.</p>

                                            <h5 className="bungee">10. Coding Can Help Children Discover Their Passion</h5>
                                            <p>When learning how to code, children will get to know machine learning, the development of artificial intelligence, robotics, and data mining. They will be acquainted with the fascinating world of technology and the exciting professions that come with it. Exposing children to coding will give them a chance to fall in love with it and discover their passion. Not many people have the willpower and desire to start learning coding on their own. However, if students are able to experience coding as children, they can assess whether that’s something they might be interested in.</p>


                                            <h5 className="bungee">Final Thoughts</h5>
                                            <p>Coding is the most important language of the 21st century and the future. It opens so many doors for the people who know it. The excitement and innovation that come with it can give students a good reason to consider coding as their career choice. What the above-mentioned benefits prove is that coding is a great method for cognitive development in children.</p>


                                            <p><i>Kristin Savage nourishes, sparks, and empowers using the magic of a word. Along with pursuing her degree in Creative Writing, Kristin was gaining experience in the publishing industry, with expertise in marketing strategy for publishers and authors. Besides working as a freelance writer at <a href="https://www.grabmyessay.com/">GrabMyEssay</a> she also does some editing work at <a href="https://www.topessaywriting.org/">TopEssayWriting</a> and <a href="https://classyessay.com/research-paper-writing-service">ClassyEssay</a>. In her free time, Kristin likes to travel and explore new countries around the world.</i></p>

                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
