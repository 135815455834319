import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import CodeAThon from '../assets/images/blog-pictures/code-a-thon.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">July 12, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>  CODE-A-THON  </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Join us as we <i>Code for a Cause and solve real-world problems with code!</i></h3>
                                            <p>Register now to be part of this challenge! There is no cost to participate! https://codewiz.typeform.com/to/wAiO8B</p>
                                            <p><b>Who can participate?</b></p>
                                            <p>Any kids between ages 7 and 16! You don’t have to be a Code Wiz student to participate. It’s open to everyone!</p>
                                            <p><b>What can I win?</b></p>

                                            <img src={CodeAThon} alt="code-a-thon" />
                                            <p>Sphero BB-8, <a href="https://www.yogibo.com/hugibo/">Yogibo – Avocado Friend</a>, <a href="https://ozobot.com/products/ozobot-evo">Evo Ozobot</a>, Brookstone Cat Ears Headphones, BOSE Speaker Cube, 30 minutes of Jump time at Jump on In!</p>
                                            <p>Ooohhh..what are the winning categories?</p>
                                            <p>Best project (ages 7 – 9)</p>
                                            <p>Best project (ages 10 – 16)</p>
                                            <p>Greatest impact toward your cause</p>
                                            <p>Best presentation</p>
                                            <p>Audience Favorite (online voting)</p>
                                            <p><b>What can I submit?</b></p>
                                            <p>It could be a game, a website, an app, a 3D printed image, an LED light display. It just needs to have been created by you or your team and have some coding element to it! You can use any platform you love.</p>
                                            <p>Not sure if your idea fits? please email us. info@thecodewiz.com</p>
                                            <p><b>I’m in! Who can I contact if I have more questions?</b></p>
                                            <p>Woohoo! Email us at <a href="mailto:codeathon@thecodewiz.com">codeathon@thecodewiz.com</a> if you have any questions!</p>
                                            <p>Register now to be part of this challenge! There is no cost to participate!</p>




                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee"><a href="https://codewiz.typeform.com/to/wAiO8B">CLICK HERE TO PRE-REGISTER!</a></h3>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
