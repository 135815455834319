import React from 'react';
import ContactPage from '../../contact/contact.component';
import MetaTags from 'react-meta-tags';

const FranchiseContactPage = () => (
    <div>
        <ContactPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Contact Us</title>
        </MetaTags>
    </div>
);

export default FranchiseContactPage;