import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="colleyville" fulllocation="colleyville-tx"/>
        <SummerCamps franchisee="colleyville" owner="Code Wiz Colleyville"/>
        <FacebookMessenger location="colleyville" FBPageId="114021905087703" />
        <MetaTags>
            <title>Code Wiz - Colleyville TX | Summer Camps</title>
            <title>February, April and Summer Vacation Programs in Colleyville TX - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Colleyville TX" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/colleyville-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Colleyville TX" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in colleyville, colleyville texas, robotics"></meta>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default SummerPage;

