import React from 'react';
import FallClassesPage from '../../fall-classes/fall-classes.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFallClassesPage = () => (
    <div>
        <FallClassesPage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | Online Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseFallClassesPage;