import React from 'react';
import Franchisee from '../../../components/franchisee/v4-franchisee.component';
import Header from '../../../components/header/header.component';
import AboutPageImg from '../../../assets/images/Group-4.png';
import CodewizImg from '../../../assets/images/Codewiz-team.png';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

import './medford.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';

class MedfordPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: 'Medford Location',
            coupon: 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg',
            team: [
                {id: 'rd-1', name: 'Ravi Meka', title: 'Co-owner', picture: CodewizImg},
                {id: 'rd-2', name: 'Sandeep Alluri', title: 'Co-owner', picture: CodewizImg},
                {id: 'rd-3', name: 'Lara Friedman', title: 'Center Director', picture: CodewizImg},
                {id: 'rd-4', name: 'Jessica Riley', title: 'Center Director', picture: CodewizImg}
            ]
        }
    }

    render() {
        return (
            <div>
                <PopupModal location="medford" formType="coupon" owner="Code Wiz Medford"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
                <Header location="medford" fulllocation="medford-ma"/>
                <Franchisee name="medford" team={this.state.team} owner="Code Wiz Medford"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in Medford MA | Code Wiz</title>
                    <link rel="canonical" href="http://thecodewiz.com/medford-ma" />
                    <meta name="description" content="Coding and Robotics For Kids in Medford MA" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/medford-ma" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in Medford MA" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in Medford Massachusetts" /> 
                    <meta name="keywords" content="coding, coding in medford, medford massachusetts, robotics"></meta>
                </Helmet>
            </div>
        );
    }
}

export default MedfordPage;

