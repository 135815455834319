import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const coupon = 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg';
const location = 'quincy';
const FranchiseInPersonClassesPage = () => (
    <div>
        <PopupModal location={location} formType="coupon" owner="Code Wiz Quincy"/>
        <FallClassesPage franchisee="quincy" fulllocation="quincy-ma" formType="coupon" owner="Code Wiz Quincy"/>
        <FacebookMessenger location="quincy" FBPageId="117243264741615" />
        <MetaTags>
            <title>Code Wiz - Quincy MA | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;