import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">January 21, 2021</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> CODING EXPLAINED: MINECRAFT</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                            
                                            <p>Minecraft was originally designed in 2009 by Swedish programmer Markus Persson. Now boasting over 100 million registered users, Minecraft is a video game in which players create and break apart various kinds of blocks in three-dimensional worlds. </p>
                                            <p>It is described as a first-person ‘sandbox game’ – meaning people can create their own experience. The game’s two main modes are Survival and Creative.</p>
                                            <ul>
                                                <li>In Survival mode, players find their own building supplies and food. They also interact with moving creatures, some are friendly and some can be dangerous.</li>
                                                <li>In Creative mode, players are given supplies and do not have to eat to survive. Imagination plays a big role, and players can create anything from a small mud hut to a giant city landscape.</li>
                                            </ul>
                                            <p>Due to the game complexity, we recommend Minecraft for kids aged 8 and up. When playing Minecraft, kids are exercising creative thinking as well as practicing geometry and even a little geology as they build and explore worlds of imaginative block structures. The game has an open-ended style allowing kids to construct anything they want.</p>
                                            <p>Our Minecraft class empowers kids (whether or not they know how to code) to imagine, create, and share amazing mods, texture packs, and schematics while learning coding concepts through badges and projects.</p>
                                            <p>Try a free trial Minecraft class by signing up here: <a href="https://codewizarlington.simplybook.me/v2/#book/service/19/count/1/">https://codewizarlington.simplybook.me/v2/#book/service/19/count/1/</a></p>

                                        

                                        </div>
                                   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
