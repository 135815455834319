import React from 'react';
import ProgramsPage from '../../programs/programs.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseProgramsPage = () => (
    <div>
        <ProgramsPage franchisee="reading" fulllocation="reading-ma"/>
        <FacebookMessenger location="reading" FBPageId="2544691755571962" />
        <MetaTags>
            <title>Code Wiz - Reading MA | Programs</title>
        </MetaTags>
    </div>
);

export default FranchiseProgramsPage;