import React from 'react';
import Header from '../components/header/header.component';
import OnlineCoding from '../components/online-coding/online-coding.component';
import MetaTags from 'react-meta-tags';

const OnlineCodingPage = () => (
  <div>
    <MetaTags>
      <title>Code Wiz | Online Coding</title>
    </MetaTags>
    <Header />
    <OnlineCoding />
  </div>
);

export default OnlineCodingPage;