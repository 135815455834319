import React from 'react';
import TechnovationPage from '../../technovation/technovation';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseTechnovationPage = () => (
    <div>
        <TechnovationPage franchisee="plano" fulllocation="plano-tx"/>
        <FacebookMessenger location="plano" FBPageId="103020612305144" />
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
    
);

export default FranchiseTechnovationPage;