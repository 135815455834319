import React from 'react';
import MetaTags from 'react-meta-tags';

//import Directory from '../../components/directory/directory.component';
//import HomePageImg from '../../assets/images/Group-4.png';
import HomePageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
import HomepageBodyWrapper from '../../assets/images/Group-14.webp';
//import HomepageHeaderImage from '../../assets/images/codewiz-homepage.png';
//import HomepageHeaderImage from '../../assets/images/Group-4.png';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
import HomepageHeaderImage from '../../assets/images/franchisehomepage.webp';
import HomepageProjectDrivenMobile from '../../assets/images/Group-6.png';
import HomepageScriptForSuccess from '../../assets/images/homepage-yasmine.webp';
import RewardsImg from '../../assets/images/Ellipse-orange.png';
import ProgressImg from '../../assets/images/Ellipse-green.png';
import CommunityImg from '../../assets/images/Ellipse-light-blue.png';
import FreeClassImg from '../../assets/images/Ellipse-purple.png';
import MontesorriImg from '../../assets/images/Ellipse-yellow.png';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';

import SimpleSlider from '../../components/slick-slider/simple-slider.component';
import Programs from '../../components/programs/programs.component';
import Header from '../../components/header/header.component';
import Swirls from '../../assets/images/headerSwirl.png';
import './homepage.styles.scss';
import Button from '../../components/button/button.component'; 

let floatingdata =[]; 
let defaultdata = {
    phone: "1(844) 263-3949",
    email: "info@thecodewiz.com",
    franchise: "yes",
    locations: "/locations" 
}
floatingdata.push(<FloatingMenu contactlink={defaultdata.email} franchise={defaultdata.franchise} phone={defaultdata.phone} location={defaultdata.locations}/>);
const HomePage = () => (
    <div>
        <Header />
        <MetaTags>
            <meta name="description" content="Coding and Robotics for Children" />
            <meta name="title" content="Coding and Robotics for Children" />
            <meta name="image" content="../../assets/images/codewiz-thumbnail.jpg" />
            <meta property="og:image" content={HomepageHeaderImage} />
            <title>Code Wiz | Home</title>
        </MetaTags>
        <div className="homepage-content">
            <div className="container header-text-container">
                <div className="row">
                    <div className="top-header col-sm-12 col-md-12 col-lg-6">
                        <div className="text">
                            <h2 className="header-title cw-dark-blue bungee">UNLOCK YOUR CHILD'S INNER GENIUS</h2> 
                            <h4 className="header-text cw-dark-blue">At Code Wiz, children learn, create, collaborate and gain the problem-solving savvy they need to compete in a changing world. </h4>
                            {/* <h5 className='cw-dark-blue'></h5> */}
                            <Button color="orange" link="/locations" size="medium">FIND A CENTER NEAR ME</Button>
                        </div>
                    </div>
                    {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                    <div className="col-sm-12 col-md-6 expanded_image">
                        <img src={HomepageHeaderImage} alt="Home"/>
                    </div>
                </div>
                {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
            </div>
            {/* As seen on section */}
            <div className="as-seen-on">
                <div className="row">
                    <div className="col-md-3 as-seen-on-div">
                        <h2 className="center cw-dark-blue">AS SEEN ON</h2>
                    </div>
                    <div className="col-md-9 as-seen-on-div">
                        <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                    </div> 
                </div>   
            </div>
            {/* End of as seen on */}
            <div className="body-wrapper">
                <div className="container in-our-code">
                    <div className="row row-reverse"> 
                        <div className="text col-md-12 col-lg-6">
                            <h3 className="bungee">ANYONE CAN LEARN TO CODE</h3>
                            <p>At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem solving. Best of all, <b>anyone can learn to code</b>—no matter their age, tech proficiency or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                            <Button color="orange" link="/locations" size="medium">FIND A CENTER NEAR ME</Button>
                        </div>
                        <div className="image col-md-12 col-lg-6">
                            <img src={HomepageBodyWrapper} alt="Home Page Body"/>
                        </div>
                    </div>
                </div>
                <div className="project-driven"> 
                    <div className="container">
                        <div className="row">
                            {/* <div className="container empty-space bw-1"></div>
                            <div className="container empty-space bw-2"></div> */}
                            <div className="image col-md-12 col-lg-6">
                                {/* <img className="d-none d-md-block d-lg-none" src={HomepageProjectDrivenMobile} alt="Home Page Body"/> */}
                                <img className="" src={HomepageScriptForSuccess} alt="Home Page Body"/>
                            </div>
                            <div className="text col-md-12 col-lg-6">
                                <h3 className="bungee">The code wiz script for success</h3>
                                <p className="cw-yellow bungee">PROJECT-DRIVEN, STUDENT-STEERED LEARNING</p>
                                <p className="desc">There is no limit to your child’s potential. We encourage them to create by completing projects rather than levels. This approach fosters creativity and fuels the entrepreneurial spirit that may later lead to world-changing technologies—you never know, you may be raising the next Bill Gates! </p>
                            </div>
                        </div>
                        
                    </div>
                    {/* <div className="container empty-space bw-3"></div>
                    <div className="container empty-space-large bw-4"></div> */}
                    <div className="whycodewiz">
                        <div className="container ">
                            <div className="row">
                                <div className="reason col-md-6">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={MontesorriImg} alt="Montesorri"/>
                                        </div>
                                        <div className="col-md-8 reason-text">
                                            <h5 className="bungee">MONTESORRI STYLE APPROACH</h5>
                                            <p>We firmly believe in Project-Driven, Student-Steered Learning. Our students create, learn and achieve by completing projects rather than levels. Learning paths and project outcomes are highly tailored to the child's interests.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="reason col-md-6">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={RewardsImg} alt="Rewards"/>
                                        </div>
                                        <div className="col-md-8 reason-text">
                                            <h5 className="bungee">REALLY COOL REWARDS</h5>
                                            <p>Our students love to learn—and we love to reward them for it! For the completion of each project (as well as little milestones along the way), students earn badges and “WizBucks,” which are redeemable for Code Wiz gear and other awesome prizes.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="reason col-md-6">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={ProgressImg} alt="Progree"/>
                                        </div>
                                        <div className="col-md-8 reason-text">
                                            <h5 className="bungee">PROGRESS AT YOUR FINGER TIPS</h5>
                                            <p>We know it’s important for you to stay connected to your child’s success. That’s why we send automated progress report emails that let you know exactly what your child is working on and when they have completed a project. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="reason col-md-6">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={CommunityImg} alt="Community"/>
                                        </div>
                                        <div className="col-md-8 reason-text">
                                            <h5 className="bungee">COMMUNITY CONNECTIVITY</h5>
                                            <p>For accountability and added inspiration, all students have access to a personalized web portal. Here, they can view their own work and check out projects completed by Code Wiz children across the nation. </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="reason col-md-6">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={FreeClassImg} alt="Free class"/>
                                        </div>
                                        <div className="col-md-8 reason-text">
                                            <h5 className="bungee">FREE, NO-STRINGS TRIAL CLASS</h5>
                                            <p>See how learning to code can change your child’s life. Start with a no-obligation introductory class that can be done from the comfort of your home today—all Code Wiz classes are currently being offered online!</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="reason col-md-6"></div>
                                <div className="reason col-md-6">
                                {/* <Button color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">TRY A FREE CLASS</Button> */}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="whycodewiz_mobile">
                        <div className="slider slider-nav">
                            <SimpleSlider />
                        </div> 
                         
                        {/* <Button color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">TRY A FREE CLASS</Button> */}
				    </div>
                </div> 
                {/* <img src={HomepageMidImg} class="body-wrapper-img"/> */}
            </div>
            
            <div className="container">
                <div className="container empty-space-half"></div>
                <h3 className="bungee center cw-dark-blue focused-programs">Focused Programs. infinite potential.</h3>
            </div>
    
            <Programs />
        </div>
        {floatingdata}
    </div>
);

export default HomePage;