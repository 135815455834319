import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';

const SummerPage = () => (
    <div>
        <Header location="arlington" fulllocation="arlington-ma"/>
        <SummerCamps franchisee="arlington" owner="Code Wiz Arlington"/>
        <MetaTags>
            <title>February, April and Summer Vacation Programs in Arlington MA - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Arlington MA" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/arlington-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Arlington MA" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in arlington, arlington massachusetts, robotics"></meta>
        </MetaTags>
    </div>
);

export default SummerPage;

