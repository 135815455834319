import React from 'react';
import Header from '../components/header/header.component';
import RentASpace from '../pages/misc-pages/rent-event-space';

const RentASpacePage = () => (
  <div>
    <RentASpace />
  </div>
);

export default RentASpacePage;