import React from 'react';
import GirlsScoutPage from '../../girls-scout/girls-scout.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseGirlsScoutPage = () => (
    <div>
        <GirlsScoutPage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | Girl Scouts</title>
        </MetaTags>
    </div>
);

export default FranchiseGirlsScoutPage;