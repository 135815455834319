import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="edison" fulllocation="edison-nj"/>
        <SummerCamps franchisee="edison" owner="Code Wiz Edison"/>
        <FacebookMessenger location="edison" FBPageId="277083298829332" />
        <MetaTags>
            <title>Code Wiz - Edison NJ | Summer Camps</title>
            <title>February, April and Summer Vacation Programs in Edison NJ - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Edison NJ" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/edison-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Edison NJ" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in edison, edison new jersey, robotics"></meta>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default SummerPage;

