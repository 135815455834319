import React from 'react';

import HeaderMenu from '../header-menu/header-menu.component';
import HeaderBkg from '../header-bkg-images/header-bkg-images.component';
import ReactPixel from 'react-facebook-pixel';
import CookieConsent, {Cookies} from 'react-cookie-consent';
import FacebookMessenger from '../../components/facebook-messenger/facebook-messenger';

//import Contact from '../contact-info/contact-info.component';

import './header.styles.scss';
// const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
//     const options = {
//     autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//     debug: false, // enable logs
//     };
//     ReactPixel.init('602788006596431', advancedMatching, options);

//     ReactPixel.pageView(); // For tracking page view
const Header = (props) => (
    <header className="page-header">
        <CookieConsent
            location="bottom"
            buttonText="I accept"
            cookieName="mycodewizcookie"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#fff", fontSize: "16px", background: "#F47D20", fontWeight: 500 }}
            >
            This website uses cookies to enhance the user experience.{" "}
            <span>View our <a style={{ textDecoration: "underline", color:"white" }} href="/privacy-policy">privacy policy</a> for more information</span>
        </CookieConsent>
        <HeaderMenu location={props.location} fulllocation={props.fulllocation}/>
        <HeaderBkg image={props.image}/>
        
    </header>
);

export default Header;

