import React from 'react';
import TechnovationPage from '../../technovation/technovation';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseTechnovationPage = () => (
    <div>
        <TechnovationPage franchisee="westborough" fulllocation="westborough-ma"/>
        <FacebookMessenger location="westborough" FBPageId="104621587747524" />
    </div>
);

export default FranchiseTechnovationPage;