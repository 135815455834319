import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';

import HeaderImage from '../../assets/images/bubble-35.png';
import RentSpace from '../../assets/images/rent_space.jpg'
import RentSpace2 from '../../assets/images/rent_space2.jpg'

import './misc-pages.styles.scss';
import Header from '../../components/header/header.component';


class RentASpace extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            // defaultdata: {
                state: "",
                city: "",
                territory: ""
            //}
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state;
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={HeaderImage} />
                    <title>{`Rent A Space at Code Wiz ${aboutdata.city} ${aboutdata.state} | The Code Wiz`}</title>
                    <meta name="description" content="Rent affordable space for your meeting, training, workshop, dance or yoga class. Rent by the day or by the week. Cheapest option in Westford-Acton MA!"/>
                    <meta name="keywords" content="rent a space, code wiz, events"></meta>
                </MetaTags>
                <Header />
                <div className="rent-a-space-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee"> RENT EVENT SPACE</h2> 
                                    
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={HeaderImage} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="rent_event_section blue_section">
                        <div className="container technovation-top">
                            <div className="row">
                                <div className="col-md-12">
                                    <h3 className="bungee orange_txt">
                                        HOST DAY EVENTS AND MEETINGS AT CODE WIZ!
                                    </h3>
                                    <p className="white_txt">We have partnered with Benetrends to jumpstart your entrepreneurial journey! Funding is one of the most important components in buying a franchise/business. Benetrends has been funding America’s entrepreneurs for over 35 years, offering a comprehensive suite of funding options covering nearly every type of business situation.  The most popular programs are the Rainmaker Plan® (IRA/Rollover) and SBA small business loan programs Benetrends also offers Securities-Backed Line of Credit and Equipment Leasing.</p>
                                    
                                    <div className="teaser_box row">
                                        <div className="image col-md-6 col-xs-12">
                                            <img src={RentSpace} />
                                        </div>
                                        <div className="text col-md-6 col-xs-12">
                                            <h2 className="bungee white_txt">ENGAGE YOUR PARTICIPANTS</h2>
                                            <p className="white_txt">Stodgy boardrooms are a snoozefest. Noisy diners are a distraction. Fancy hotel meeting rooms are a strain on the budget. Code Wiz’s vibrant space is the perfect place to energize and engage your participants.</p>
                                            <p className="white_txt"> Offering an intimate space that seats up to 28 people, Code Wiz is available weekdays from 8:00 a.m to 3:00 p.m. for a variety of events, such as:</p>						
                                        </div>					
                                    </div>
                                    <div className="rent_features">
                                        <div className="rent_features_row">
                                            <div className="rent_feature_single">
                                                Group training's
                                            </div>
                                            <div className="rent_feature_single">
                                                Client meetings
                                            </div>
                                            <div className="rent_feature_single">
                                                Club meetings
                                            </div>
                                            <div className="rent_feature_single">
                                                Team building exercices
                                            </div>
                                        </div>
                                        <div className="rent_features_row">
                                            <div className="rent_feature_single">
                                                Networking events
                                            </div>
                                            <div className="rent_feature_single">
                                                Planning sessions
                                            </div>
                                            <div className="rent_feature_single">
                                                Fundraisers
                                            </div>
                                            <div className="rent_feature_single">
                                                Meetups
                                            </div>
                                        </div>
                                        <div className="rent_features_row">
                                            <div className="rent_feature_single">
                                                Seminars
                                            </div>
                                            <div className="rent_feature_single">
                                                Workshops
                                            </div>
                                            <div className="rent_feature_single">
                                                Group meditation
                                            </div>
                                            <div className="rent_feature_single">
                                                Yoga
                                            </div>
                                        </div>
                                    </div>
                                    <div className="teaser_box mobile_reverse row">
                                        <div className="text col-lg-6 col-md-12">
                                            <h2 className="bungee white_txt">EASY ACCESS FOR ATTENDEES</h2>
                                            <p className="white_txt">Code Wiz is conveniently located along the Route 495 corridor in Westford’s Cornerstone Square, and features free parking and ADA accessibility.</p>
                                            <br/><br/><br/>
                                            <h2 className="bungee white_txt">PERFECT SIZE FOR SMALL GROUPS</h2>
                                            <p className="white_txt">Code Wiz offers a relaxed and welcoming meeting space for up to 28 participants Venue and equipment specifications and amenities include:</p>						
                                        </div>	
                                        <div className="image col-lg-6 col-md-12">
                                            <img src={RentSpace2} />
                                        </div>					
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default RentASpace;