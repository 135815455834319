import React from 'react';
import CompetitionsPage from '../../competitions/competitions.component';
import MetaTags from 'react-meta-tags';

const FranchiseCompetitionsPage = () => (
    <div>
        <CompetitionsPage franchisee="arlington" />
        <MetaTags>
            <title>Code Wiz - Arlington MA | Competitions</title>
        </MetaTags>
    </div>
);

export default FranchiseCompetitionsPage;