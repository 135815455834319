import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const coupon = 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg';
const location = 'needham';
const FranchiseInPersonClassesPage = () => (
    <div>
        {/* <PopupModal location={location} formType="grandopening" owner="Code Wiz Needham"/> */}
        <FallClassesPage franchisee="needham" fulllocation="needham-ma" formType="coupon" owner="Code Wiz Needham"/>
        <FacebookMessenger location="needham" FBPageId="108063508397395" />
        <MetaTags>
            <title>Code Wiz - Needham MA | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;