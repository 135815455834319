import React from 'react';
import Header from '../../../components/header/header.component';
import WelcomeMessage from '../../../components/welcome-message/welcome-message.component';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const WelcomeMessagePage = () => (
  <div>
    <Header location="kirkland" fulllocation="kirkland-wa"/>
    <FacebookMessenger location="kirkland" FBPageId="356771947508746" />
    <WelcomeMessage franchisee="kirkland"/>
    <Helmet>
        <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
    </Helmet>
  </div>
);

export default WelcomeMessagePage;