import React from 'react';
import Header from '../../../components/header/header.component';
import ThankYou from '../../../components/thank-you/thank-you.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const ThankYouPage = () => (
  <div>
    <Header location="westborough" fulllocation="westborough-ma"/>
    <FacebookMessenger location="westborough" FBPageId="104621587747524" />
    <ThankYou />
    <MetaTags>
        <title>Code Wiz - Westborough MA | Thank You</title>
    </MetaTags>
  </div>
);

export default ThankYouPage;