import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="kirkland" fulllocation="kirkland-wa"/>
        <SummerCamps franchisee="kirkland" owner="Code Wiz Kirkland"/>
        <FacebookMessenger location="kirkland" FBPageId="356771947508746" />
        <MetaTags>
            <title>Code Wiz - Kirkland WA | Summer Camps</title>
            <title>February, April and Summer Vacation Programs in Kirkland WA - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Kirkland WA" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/kirkland-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Kirkland WA" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in kirkland, kirkland washington, robotics"></meta>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default SummerPage;

