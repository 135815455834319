import React from 'react';
import Header from '../components/header/header.component';
import TutorDoctorUK from '../components/tutor-doctor/tutordoctor-uk.component';
import MetaTags from 'react-meta-tags';

const TutorDoctorUKPage = () => (
  <div>
    <MetaTags>
      <title>Code Wiz | Tutor Doctor UK</title>
    </MetaTags>
    <Header />
    <TutorDoctorUK />
  </div>
);

export default TutorDoctorUKPage;