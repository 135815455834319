import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="needham" fulllocation="needham-ma"/>
        <FacebookMessenger location="needham" FBPageId="108063508397395" />
        <SummerCamps franchisee="needham" owner="Code Wiz Needham"/>
        <MetaTags>
            <title>Code Wiz - Needham MA | Summer Camps</title>
            <title>February, April and Summer Vacation Programs in Needham MA - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Needham MA" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/needham-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Needham MA" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in needham, needham massachusetts, robotics"></meta>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default SummerPage;

