import React, {useState} from 'react';
import firebase from '../Firebase/firebase.js';
import Popup from 'reactjs-popup';

import AboutPageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
//import AboutSpark from '../../assets/images/image-2.png';
//import AboutGivingBack from '../../assets/images/giving-back-image.png';
import CityImage from '../../assets/images/location-pin.png';
import CodingRobotics from '../../assets/images/landing-page-coding-robotics.png';
import WhyCodewiz from '../../assets/images/landing-page-why-codewiz.png';

import FranchiseHeaderImg from '../../assets/images/FranchiseeHomePage.jpg';
import ComingSoonHeaderImg from '../../assets/images/cw-coming-soon.png';
//import MidBkgImage from '../../assets/images/landing-page-mid bkg.png';

import Button from '../../components/button/button.component';
import Review from '../../components/review/review.component';
import TeamMember from '../../components/team-member/team-member.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
//import TeamSlider from '../../components/slick-slider/teams-slider.component';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';
import NeedhamFamily from '../../assets/images/needham-fam.jpg';
import LawrencevilleFamily from '../../assets/images/cheris-family-lawrenceville.jpeg';
import PlanoFamily from '../../assets/images/naila-family-plano-tx.jpg';
import StjohnsFamily from '../../assets/images/eluid-rebeca-family-stjohns-1.jpeg';
import HoustonFamily from '../../assets/images/joy-houston.jpeg';
//import StjohnsFamily from '../../assets/images/eluid-rebeca-family-stjohns-3.JPG';

import GoogleMapNashuaComponent from '../../components/google-map/google-map.component-nashua';

import GoogleMapNeedhamComponent from '../../components/google-map/google-map.component-needham';
import GoogleMapLawrencevilleComponent from '../../components/google-map/google-map.component-lawrenceville';
import GoogleMapPlanoComponent from '../../components/google-map/google-map.component-plano';
import GoogleMapStjohnsComponent from '../../components/google-map/google-map.component-stjohns';
import GoogleMapHoustonComponent from '../google-map/google-map.component-houston.jsx';
import GoogleMapDurhamComponent from '../../components/google-map/google-map.component-durham.jsx';
import GoogleMapCypressComponent from '../../components/google-map/google-map.component-cypress.jsx';
import GoogleMapTierrasantaComponent from '../../components/google-map/google-map.component-tierrasanta.jsx'
import GoogleMapCarmelComponent from '../../components/google-map/google-map.component-carmel.jsx'


import './franchisee.styles.scss';


class ComingSoonFranchisee extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.name,
            data: {},
            team: props.team,
            map: props.name + "-map",
            brochure: "https://getform.io/f/c7aeb3d8-3132-4a1d-8241-6142b5b305e8",
            grandOpeningUrl: "https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e",
            grandOpeningMarchUrl: "https://getform.io/f/ba86d725-240d-4f8f-997c-01cb66530874",
            locationemail: props.name + "@thecodewiz.com",
            zoho: props.owner,
            isOpen : false
            // email: "esther@thecodewiz.com"
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal () {
        this.setState({isOpen: true})
    }

    closeModal () {
        this.setState({isOpen: false})
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        // const [open, setOpen] = useState(false);
        // const closeModal = () => setOpen(false);
        const formUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e"; //grandOpeningUrl
        const teamsArr = [];
        const teams = this.state.team;
        if(teams.length > 0){
            for (let i = 0; i < teams.length; i++) {
                teamsArr.push(<TeamMember id={i} key={i} name={teams[i].name} picture={teams[i].picture} title={teams[i].title}/>);
            }
        }
        
        let discountPrice = Math.floor(parseInt(this.state.data.classprice) * 0.68586);
        let zeeFamilyPicture = "";
        let zeeStory = "";
        let zeeStoryTwo = "";
        let zeeStoryThree = "";
        let zeeStoryFour = "";
        

        const map = [];
        if(this.state.location === "nashua"){
            map.push(<GoogleMapNashuaComponent/>);
        }else if(this.state.location === "needham"){
            map.push(<GoogleMapNeedhamComponent/>);
            zeeFamilyPicture = NeedhamFamily;
            zeeStory = "Meet the owners of Code Wiz Needham, MA - Akshay and Shweta!";
            zeeStoryTwo = "Akshay and Shweta look forward to helping kids of Needham, MA unlock their inner genius!";
            zeeStoryThree = "Using software/technology to scale solutions for some of the key issues that the world is facing is something they’re both passionate about! They believe that the current tech innovators and problem solvers need to pass on the baton to the next generation, so they can build and scale solutions in the long term!";
        }else if(this.state.location === "lawrenceville"){
            map.push(<GoogleMapLawrencevilleComponent/>);
            zeeFamilyPicture = LawrencevilleFamily;
            zeeStory = "Meet the owner of Code Wiz Lawrenceville, GA - Cheris!";
            zeeStoryTwo = "Cheris has been an educator for the last 16 years! Her interest in computer science, passion for helping students find confidence in learning and the personalized style of the code wiz approach is what made her decide to open up a  Code Wiz location in her community.";
            zeeStoryThree = "Cheris is most excited about seeing kids get excited about learning, developing confidence and she looks forward to witnessing their wow moments! She is excited to help your kids love learning as much as she does! And she can’t wait to get started!";
        }else if(this.state.location === "plano"){
            map.push(<GoogleMapPlanoComponent/>);
            zeeFamilyPicture = PlanoFamily;
            zeeStory = "Meet the owners of Code Wiz Plano, TX - Naila and Ali!";
            zeeStoryTwo = "Naila and Ali are super excited to be part of an educational platform that enhances critical thinking, problem solving and creativity while having fun! ";
            zeeStoryThree = "As the world continues to be more digitalized, they are looking forward to seeing kids and families enjoy a skill set that will have a long lasting effect on their futures. They love kids and are excited to play, build, and have fun with coding and robotics!";
        }else if(this.state.location === "stjohns"){
            map.push(<GoogleMapStjohnsComponent/>);
            zeeFamilyPicture = StjohnsFamily;
            zeeStory = "Meet the owners of Code Wiz St. Johns, FL - Eliud and Rebeca!";
            zeeStoryTwo = "Eliud and Rebeca couldn’t be happier to have a positive impact on the kids in the St. Johns’ community through learning and development while also having fun! ";
            zeeStoryThree = "As Engineers themselves, they are really excited about joining the kids and their families on this learning journey and playing with some legos. They love legos! So get ready St. Johns, FL and surrounding areas, lots or robotics and coding classes for kids are coming your way!";
        } else if(this.state.location === "houston") {
            map.push(<GoogleMapHoustonComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeStory = "Meet the owner of Code Wiz Houston, TX - Joy!";
            zeeStoryTwo = "Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.";
            zeeStoryThree = "Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!";
        }else if(this.state.location === "durham") {
            map.push(<GoogleMapDurhamComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeStory = "Meet the owner of Code Wiz Durham, NC - Teresa and Scott!";
            zeeStoryTwo = "Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.";
            zeeStoryThree = "Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!";
        }else if(this.state.location === "cypress") {
            map.push(<GoogleMapCypressComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeStory = "Meet the owner of Code Wiz Cypress, TX - Ton!";
            zeeStoryTwo = "Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.";
            zeeStoryThree = "Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!";
        }else if(this.state.location === "tierrasanta") {
            map.push(<GoogleMapTierrasantaComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeStory = "Meet the owner of Code Wiz Tierrasanta, CA - Davon!";
            zeeStoryTwo = "Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.";
            zeeStoryThree = "Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!";
        }else if(this.state.location === "carmel") {
            map.push(<GoogleMapCarmelComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeStory = "Meet the owner of Code Wiz Carmel, IN - David and Ethel!";
            zeeStoryTwo = "Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.";
            zeeStoryThree = "Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!";
        }

        let grandOpeningFrom = <form id="cw-contact-form grand-opening-form" className="contact-form" action={this.state.grandOpeningMarchUrl} method="POST">
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputLast">Last Name *</label>
                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputEmail">Email *</label>
                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputPhone">Phone *</label>
                <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                <select name="mode" className="form-control" id="inputMode" required>
                    <option value="inperson-class">In-Person Classes</option>
                    <option value="inperson-camp">In-Person Summer Camps</option>
                </select>
            </div>
            <div className="form-group col-md-6">
                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
            </div>
        </div>
        <div className="form-group">
            <div className="form-check">
                    <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                    <label className="form-check-label tiny-print" htmlFor="gridCheck">
                    {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                    <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                    <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                    </label>
                </div>
            </div>
        {/* <button type="submit">Send</button> */}
        
        <button type="submit" className="btn white-button" target="_blank">SEND</button>
    </form>

// console.log("Hours", this.state.data.friday)

        return (
            <div className="subsite franchisee-page" id={this.state.location}> 
                <div className='coming-soon-container'>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 ">
                            {/* <img className='coming-soon-image' aria-hidden="true" src={ComingSoonHeaderImg} alt="Franchise coming soon header picture"/> */}
                            <h3 className='header-text cw-dark-blue center bungee'>opening <span className='cw-light-blue'>summer 2022!!</span></h3>
                            {/* <h4 className='header-text cw-orange center bungee'>Camps & Classes for kids</h4> */}
                            <p className="center summer-camp-video"><iframe class="vidyard_iframe" src="//play.vidyard.com/uT8AsJL3k9h3wbvNifLdDk.html?" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen></iframe></p>
                            
                            <h2 className="header-text cw-dark-blue map-pin center"><span><img aria-hidden="true" src={CityImage} alt="franchise city"/></span>  {this.state.data.citystate}</h2> 
                            
                            <p className="center"><Button className="center" color="blue" link={this.state.data.parentportal} size="x-large">SIGN UP FOR SUMMER CAMPS</Button></p>
                        </div>
                        <div className="col-sm-12 col-md-4 form-in-the-header">
                            <h4 className="bungee center">sign up for summer camps and save!</h4>
                            <p className="center">Please fill out this form to learn more about our FOUNDING MEMBER offers</p>
                            
                            {grandOpeningFrom}
                        </div>
                    </div>
                    <div className="as-seen-on">
                        <div className="row">
                            <div className="col-md-3 as-seen-on-div">
                                <h2 className="center cw-dark-blue">AS SEEN ON</h2>
                            </div>
                            <div className="col-md-9 as-seen-on-div">
                                <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                            </div> 
                        </div>   
                    </div>
                </div>
                <div className="body-wrapper cw-dark-blue">
                    <div className="container">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row">
                            
                            <div className="col-md-12 col-lg-6 zeeBio">
                                <h3 className="bungee">coding and robotics classes in {this.state.data.city}, {this.state.data.state}</h3>
                                {/* 
                                <p>Meet the owners of Code Wiz {this.state.data.city}, {this.state.data.state} - Akshay and Shweta! <br/>Akshay and Shweta look forward to helping kids of Needham, MA unlock their inner genius!
                                <br/>Using software/technology to scale solutions for some of the key issues that the world is facing is something they’re both passionate about!
                                They believe that the current tech innovators and problem solvers need to pass on the baton to the next generation, so they can build and scale solutions in the long term! 
                                <br/></p> */}
                                <p>{zeeStory}</p>
                                <p>{zeeStoryTwo} <br/><br/>{zeeStoryThree}<br/>
                                {zeeStoryFour}</p>
                                <p>Give your child the chance to learn life-shaping skills with fun, flexible programs offered online or at our {this.state.data.city}, {this.state.data.state} location.</p>
                                {/* <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                            </div>
                            <div className="col-md-12 col-lg-6 zee-family-picture">
                                <img aria-hidden="true" className="familyImg" src={zeeFamilyPicture} alt="coding robotics"/>
                            </div>
                            
                        </div>
                        <div className="empty-space form-empty-space"></div>
                        <div className="row row-reverse coding-robotics">
                            <div className="col-md-12 col-lg-6 code-create-grow">
                                <h3 className="bungee">code. create. grow.</h3>
                                <p className="">At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem-solving. Best of all, anyone can learn to code—no matter their age, tech proficiency, or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                                {/* <Button color="orange" link="/" size="x-large">LEARN ABOUT OUR FOUNDING MEMBER OFFER</Button> */}
                                <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                                    <div className="col-sm-12 cw-dark-blue form-in-modal">
                                        <h4 className="bungee center">sign up for summer camps and save!</h4>
                                        <p className="center">Please fill out this form to learn more about our FOUNDING MEMBER offers</p>
                                        {grandOpeningFrom}
                                    </div>
                                </Popup>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <img aria-hidden="true" className="midImg" src={CodingRobotics} alt="coding robotics"/>
                            </div>
                        </div>
                        <div className="empty-space-half"></div>
                        <div className="row fr-whycodewiz">
                            <div className="col-md-12 col-lg-6 order-md-1 whycodewizimg">
                                <img aria-hidden="true" className="width-100 midImg" src={WhyCodewiz} alt="why codewiz"/>
                            </div>
                            <div className="col-md-12 col-lg-6 order-md-2">
                                <h3 className="bungee whycodewiztitle">why code wiz?</h3>
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">project-driven, student-steered learning</h6>
                                <p>Children create by completing projects rather than lessons or levels</p>
                                
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">really cool rewards</h6>
                                <p>We reward learning with Code Wiz gear and other awesome prizes</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">community connectivity</h6>
                                <p>Students can check their work online and get inspired by peer projects</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">progress at your fingertips</h6>
                                <p>Stay connected to your child's success with automated progress report emails</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">free, no-strings intro class</h6>
                                <p>See how learning to code can change your child's life-all classes are offered in person and online!</p>
                                <br/>

                                {/* <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="container contact-info-contact-page">
                    
                </div>
                <div className="col-md-12 center">
                    <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                        <div className="col-sm-12 cw-dark-blue form-in-modal">
                            <h4 className="bungee center">sign up for summer camps and save!</h4>
                            <p className="center">Please fill out this form to learn more about our FOUNDING MEMBER offers</p>
                            {grandOpeningFrom}
                        </div>
                    </Popup>
                </div>

                {/* <!-- Location Map --> */}
                {map}

                <div className="container">
                    <div className="row">
                        
                    </div>
                </div>
                
                {/* <FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/> */}
                
            </div>
        );
    }
}

export default ComingSoonFranchisee
