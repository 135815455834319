import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import CodewizHeader from '../../assets/images/code-wiz-logo.png';
import CodewizAddendum from '../../assets/docs/cw-parent-covid-handbook.pdf';
import FooterImage from '../../assets/images/rfc-form.jpeg';

import Header from '../../components/header/header.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';

import './covid-19.styles.scss';


class CovidAddendumPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            },
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }

    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <MetaTags>
                    <title>Code Wiz | COVID-19 </title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="container covid-page-content">
                    <div className="covid-page">
                        <h4 className="bungee center cw-dark-blue">Code Wiz is Getting Ready To Welcome Families Back In Person!</h4>
                        <p>The safety of our children, families, and staff is always our number one priority. As we are open for in-person programs and work through what our new "normal" will look like, we are here to support you and your family to smoothly transition back to in-person coding and are excited to have you!</p>
                        <p>Here are a few things to know that we here at Code Wiz will be implementing to help you feel comfortable about joining us in-person:</p>
                        
                        {/* <h5 className="bungee cw-dark-blue">Important Documents</h5>
                        <ul>
                            <li><a className="cw-light-blue bold" href={CodewizAddendum}>Code Wiz Parent Handbook Covid Addendum</a></li>
                        </ul> */}
                    </div>
                    <div className="welcome-back-info">
                        <ul>
                            <li>We will continue to monitor the situation and communicate guidance on mask-wearing and screening as the summer approaches. We will follow the guidance given us by the ECE.</li>
                            <li>All pick-up and drop-off will happen outside the center.</li>
                            <li>Your child's personal belongings, including jackets, lunch/snack, drinks, computer, etc, must be secured in a zippered backpack somehow labeled with your child's name.</li>
                            <li>Books, robots, legos, etc. should not be brought from home. Phones are fine.</li>
                            <li>We will intensify our routine cleaning, sanitizing/disinfecting practices, paying extra attention to frequently touched objects and surfaces.</li>
                            <li>Air purification systems will also be added in the center.</li>
                            <li>Handwashing is required by all children before and after new activities. We will also have hand sanitizer stations.</li>
                        </ul>
                        {/* <h5 className="bungee cw-dark-blue">Contact Information </h5>
                        <ul>
                            <li>During the re-enrollment process, you will be asked to provide us with updated registration forms so we will have the most up-to-date contact information for each child.  During this time, it is imperative the center is able to reach you or another authorized contact quickly in an emergency.  Make sure you have contacts documented, (other than yourselves), in case of emergency. </li>
                        </ul>
                        <h5 className="bungee cw-dark-blue">Screening</h5>
                        <ul>
                            <li>Before you show up at the center you will be asked to complete a Daily Screening form for your child. The self-screening will include checking for symptoms including fever, cough, shortness of breath, gastrointestinal symptoms, new loss of taste/smell, muscle aches, or any other symptoms of illness as well as household contact with COVID-19 or travel out of state or out of the country using public transportation.  </li>
                            <li>Once you arrive at the center, your child will bring that Daily Screening form to the check-in table and Code Wiz staff will ensure you have completed the form (parents are asked to please stay in the car). Your child will get a quick temperature check, attendance will be updated and parents will get the thumbs up letting them know their child is good to come into the Center. </li>
                            <li>Individuals who decline to complete the screening will not be permitted to enter the program space. </li>
                            <li>If a child does not pass the health screening they will not be signed into the program or allowed to stay. </li>
                            <li>Please do not leave until you have gotten the thumbs up from a staff member</li>
                        </ul>
                        <h5 className="bungee cw-dark-blue">Drop-off Procedures</h5>
                        <ul>
                            <li>In order to limit the number of people in our locations, we will do all pick-up and drop-off, outside Code Wiz. </li>
                            <li>Parents/Guardians will be asked to wait with their child for staff to conduct the medical screening. <span className="semi-bold">CHILDREN MAY NOT BE DROPPED OFF WITHOUT COMPLETING THE MEDICAL SCREENING.</span></li>
                            <li>Once your child has passed the health screening they will be signed in by a staff person and be able to go into the program.</li>
                            <li>Your child’s belongings will be stored in an individual cubby and they will wash their hands before engaging in activities.</li>
                            <li>Face masks must be worn by staff and children at all times. </li>
                        </ul>
                    

                        <h5 className="bungee cw-dark-blue">Pick-up Procedures </h5>
                        <ul>
                            <li>When you arrive to pick up your child, you will need to send a text message to let us know you are here to pick up your child. The phone number you are texting from <span className="semi-bold">MUST</span> be on file so that we can assure your child is being picked up by an authorized person. To update your authorized pick-up contacts, please log into your parent portal. Once the text is received please allow time for a staff member to gather your child’s belongings and escort your child to you. <span className="semi-bold">PARENTS AND GUARDIANS ARE RESPONSIBLE FOR SECURING CHILDREN INTO AGE APPROPRIATE CAR SEAT RESTRAINTS.</span></li>
                        </ul>
                        
                        <h5 className="bungee cw-dark-blue">Personal Belongings</h5>
                        <ul>
                            <li>Your child’s personal belongings, including jackets, lunch/snack, drinks, computer, etc, must be secured in a zippered backpack somehow labeled with your child’s name. </li>
                            <li>Books, robots, legos, etc. should not be brought from home. Phones are fine.</li>
                        </ul>
                    
                        <h5 className="bungee cw-dark-blue">Operating Hours</h5>
                        <ul>
                            <li>Our operating hours will be 9:00 AM - 4:00 PM Monday-Friday.</li>
                            <li><b>AM Drop-off:</b> 8:30 - 9:00 AM</li>
                            <li><b>AM Pick-up: </b> 12.00 PM and must be completed by 12:15 PM. </li>
                            <li><b>PM Drop off: </b> is 12:45 - 1:00 PM </li>
                            <li><b>PM Pick-up: </b> 4.00 PM and will end promptly at 4:30 PM. </li>
                        </ul> */}

                    </div>
                </div>
            </div>
        );
    }
}
export default CovidAddendumPage;