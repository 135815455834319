import React from 'react';
import firebase from '../Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import Button from '../button/button.component';
import FloatingMenu from '../floating-menu/floating-menu.component';
//import OnlineFallCodingHeaderImg from '../../assets/images/girl_coding.png';
import OnlineFallCodingHeaderImg from '../../assets/images/Rec1.png';
import CoursesBgImg from '../../assets/images/cropped/courses_bg.png';
import OrangeIcoImg from '../../assets/images/orange_ico.png';
import GreenIcoImg from '../../assets/images/green_ico.png';
import BlueIcoImg from '../../assets/images/blue_ico.png';
import PurpleIcoImg from '../../assets/images/purple_ico.png';
import DatesHeroImg from '../../assets/images/dates_hero.png';
// import DatesHero2Img from '../../assets/images/dates_hero2.png';
import DatesHero2Img from '../../assets/images/Rec3.png';
import DedicatedCourseBottomImg from '../../assets/images/dedicated_course_bottom.png';


import './online-fall-coding.styles.scss';


class OnlineFallCoding extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            defaultdata: {
                state: "",
                city: ""
            }
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        let aboutData = {};
        if(this.props.franchisee){
            this.state.isfranchisee = true;
            aboutData = this.state.data;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } 
        else {
            aboutData = this.state.defaultdata
            
        }
        return (
            <div className="special-page online-fall-coding" id=""> 
                <MetaTags>
                    <title>{`Code Wiz ${aboutData.city} ${aboutData.state} | Parks and Recreation Partnerships for Coding Classes`} </title>
                    <meta name="description" content="Code Wiz partners with many local parks and recreation departments to provide coding for kids classes for their communities. "/>
                    <meta name="keywords" content="Coding for kids parks and recreation, coding for kids, kids, coding, coding camps, parks and recs, parks and recreation"></meta>
                </MetaTags>
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">Parks and Recs Partnerships</h2> 
                                <h4 className="header-text cw-dark-blue">Online Coding Classes</h4>    
                                <p>Code Wiz is an award-winning coding school for<br/> children! We believe every child has an inner genius<br /> and tons of creativity just waiting to be unlocked!</p>
                                <Button color="orange" link={`mailto:${this.state.data.email}`} size="large">CONTACT US ABOUT PARTNERING</Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={OnlineFallCodingHeaderImg} alt="online fall coding"/>
                        </div>
                    </div>
                </div>
                <div className="dedicated_courses_section">
                    <div className="dedicated_courses_details_section online-fall-coding-bkg-img">
                    {/* <img className="virtual_bg" src={CoursesBgImg} alt="courses"/> */}
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="course_features">
                                        <div className="course_feature_block">
                                            <div className="course_feature_icon">
                                            <img src={OrangeIcoImg} alt="orange-icon"/>							
                                            </div>
                                            <div class="course_feature_txt">
                                                <h5>small coach to student ratio</h5>
                                                <p>Lots of individual attention and opportunities to socialize</p>
                                            </div>
                                        </div>
                                        <div class="course_feature_block">
                                            <div class="course_feature_icon">
                                            <img src={GreenIcoImg} alt="green-icon"/>							
                                            </div>
                                            <div class="course_feature_txt">
                                                <h5>k-12 cs standards</h5>
                                                <p>Alignment with the K-12 Computer Science Framework.</p>
                                            </div>
                                        </div>
                                        <div class="course_feature_block">
                                            <div class="course_feature_icon">
                                            <img src={BlueIcoImg} alt="blue-icon"/>							
                                            </div>
                                            <div class="course_feature_txt">
                                                <h5>pricing</h5>
                                                <p>Our six-week sessions are priced at $198/session.</p>
                                            </div>
                                        </div>
                                        <div class="course_feature_block">
                                            <div class="course_feature_icon">
                                            <img src={PurpleIcoImg} alt="purple-icon"/>							
                                            </div>
                                            <div class="course_feature_txt">
                                                <h5>montesorri style approach</h5>
                                                <p>Project based and student driven. The learning path is tailored to child’s interests.</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                    
                                    
                                    <div class="course_dates">
                                        <h2 class="bungee">DATES</h2>
                                        <ul>
                                            <li>
                                                <div class="image"><img src={DatesHero2Img} alt="dates-hero-2"/></div>
                                                <div class="txt">
                                                    <p><strong class="capital">Session 3</strong></p>
                                                    <p><strong class="capital">Mondays –</strong>Jan 25th - March 8th.<strong>Skip Feb 15th</strong></p>
                                                    <p><strong class="capital">Thursdays –</strong>Jan 28th - March 11th.<strong>Skip Feb 18th</strong></p>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="image"><img src={DatesHeroImg} alt="dates-hero-1"/></div>
                                                <div class="txt">
                                                    <p><strong class="capital">Session 4</strong></p>
                                                    <p><strong class="capital">Mondays –</strong>April 5th - May 17th.<strong>Skip April 19th</strong></p>
                                                    <p><strong class="capital">Thursdays –</strong>April 1st - May 13th.<strong>Skip April 22nd</strong></p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                    
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="dedicated_courses">
                                <h2 class="bungee">Course Name</h2>
                                    <ul>
                                        <li><a href="">Drag & Drop Coding (Ages 7-9)</a></li>
                                        <li><a href="">Minecraft Coding (Ages 8-12)</a></li>
                                        <li><a href="">Game-based Python (Ages 10-12)</a></li>
                                        <li><a href="">Minecraft World Builder (Ages 7-12)</a></li>
                                        <li><a href="">Animator - Create Animations<span>With Javascript (Ages 10+)</span></a></li>
                                        <li><a href="">Code your own Roblox Game (Ages 7-9)</a></li>
                                        <li><a href="">Intro/Advanced Python (Ages 13+)</a></li>
                                        <li><a href="">Code your own Roblox Game (Ages 10+)</a></li>
                                        <li><a href="">Intro/Advanced Java (Ages 13+)</a></li>
                                        <li><a href="">Game Development with Unity (Ages 10+)</a></li>
                                    </ul>
                                </div>
                                
                                
                                <div class="bottom_details mobile_reverse">
                                    <div class="text">
                                        <p>Code Wiz aligns its curriculum with the K–12 Computer Science Framework represents a vision in which all students engage in the concepts and practices of computer science. We maintain a low coach:student ratio of 1:3 for children ages 7 - 9 and 1:4 for children ages 10+. Minecraft, Roblox and Unity sessions require a PC or a Mac. All other sessions require a PC, Mac or Chromebook. Come be a part of the Code Wiz family and see what the excitement of coding is all about!</p>
                                    </div>
                                    <div class="image">
                                        <img src={DedicatedCourseBottomImg} alt="dedicated courses"/>
                                    </div>
                                </div>
                                
                                <div class="local_cta">
                                    {/* <h2 class="bungee cw-dark-blue">1 makeup class allowed</h2>
                                    <br /> */}
                                    <div className="col-md-12 center"><Button color="orange" link={`mailto:${this.state.data.email}`} size="large">CONTACT US ABOUT PARTNERING</Button></div>
                                    {/* <p><a href={this.state.data.webaddress}>{this.state.data.webaddress}</a></p> */}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                
                

                {floatingdata}
            </div>
        );
    }
}

export default OnlineFallCoding
