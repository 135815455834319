import React from 'react';
import Header from '../components/header/header.component';
import TutorDoctor from '../components/tutor-doctor/tutordoctor.component';
import MetaTags from 'react-meta-tags';

const TutorDoctorPage = () => (
  <div>
    <MetaTags>
      <title>Code Wiz | Tutor Doctor US & Canada</title>
    </MetaTags>
    <Header />
    <TutorDoctor />
  </div>
);

export default TutorDoctorPage;