import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseInPersonClassesPage = () => (
    <div>
        <PopupModal location="westborough" formType="coupon" owner="Code Wiz Westborough"/>
        <FacebookMessenger location="westborough" FBPageId="104621587747524" />
        <FallClassesPage franchisee="westborough" fulllocation="westborough-ma" formType="coupon" owner="Code Wiz Westborough"/>
        <MetaTags>
            <title>Code Wiz - Westborough MA | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;