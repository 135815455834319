import React from 'react';

import './verticals.styles.scss';
import Button from '../../components/button/button.component';

import codingprg from '../../assets/images/program-free-class.png';
import teambasedprg from '../../assets/images/program-community.png';
import holidaycampprg from '../../assets/images/program-montesorri.png';
import bdaypartyprg from '../../assets/images/program-rewards.png';
import girlscoutprg from '../../assets/images/program-finger-tips.png';
import foundationalV from '../../assets/images/foundational-coding.png';
import roboticsV from '../../assets/images/robotics.png';
import electronicsV from '../../assets/images/electronics.png';
import gamingV from '../../assets/images/gaming.png';

const Verticals = () => (
    <div className="container verticals">
        <div className="row desktop-programs-version">
            <div className="col-md-12">
                <ul className="nav md-pills nav-justified pills-rounded pills-purple-gradient cw-programs">
                    <li className="nav-item">
                    <a className="nav-link active" data-toggle="tab" href="#panel200" role="tab"><img src={foundationalV}/>  <b>Foundational Coding</b></a>
                    </li>
                    <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#panel201" role="tab"><img src={roboticsV}/>  <b>Robotics & Electronics</b></a>
                    </li>
                    {/* <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#panel202" role="tab"><img src={electronicsV}/>  <b>Electronics</b></a>
                    </li> */}
                    <li className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#panel203" role="tab"><img src={gamingV}/>  <b>Gaming</b></a>
                    </li>
                    {/* <li className="nav-item girlscoutprg">
                    <a className="nav-link" data-toggle="tab" href="#panel204" role="tab"><div></div> GIRL'S SCOUT</a>
                    </li> */}
                </ul>
            
                
                <div className="tab-content">
                    <div className="tab-pane fade in show active" id="panel200" role="tabpanel">
                        <div className="container">
                            <div className="detail-box col-md-12">
                                <div className="row">
                                    <div className="col-md-12 verticals-course-box"> 
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 7-8</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>Scratch</p></div>
                                    </div>
                                    <div className="col-md-4 verticals-course-box">
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 8-10</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>Visual Python</p></div>
                                    </div>
                                    <div className="col-md-4 verticals-course-box">
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 10+</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>Python</p></div>
                                    </div>
                                    <div className="col-md-4 verticals-course-box">
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 8-12</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>Games Based Python</p></div>
                                    </div>
                                    <div className="col-md-4 verticals-course-box">
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 13+</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>JAVA</p></div>
                                    </div>
                                    <div className="col-md-4 verticals-course-box">
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 13+</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>Apps for Alexa</p></div>
                                    </div>
                                    <div className="col-md-4 verticals-course-box">
                                        <div className='verticals-ages center'>
                                            <div className='verticals-ages-pill dark-blue'><span>Ages 13+</span></div>
                                        </div>
                                        <div className='verticals-course dark-blue center'><p>Intro to Artificial Intelligence</p></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="panel201" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-6 verticals-course-box"> 
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 7-8</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>First LEGO League Jr.</p></div>
                                </div>
                                <div className="col-md-6 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 8-9</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>First LEGO League Explore</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 9+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>First LEGO League Non-competitive</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 10+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>First LEGO League Competitive</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 6-7</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Makey Makey</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="panel202" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-12 verticals-course-box"> 
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 6-7</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Scratch</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 6-7</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Visual Python</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 6-7</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Python</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                   <div className="tab-pane fade" id="panel203" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-6 verticals-course-box"> 
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 7+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Roblox</p></div>
                                </div>
                                <div className="col-md-6 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 7+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Minecraft</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 10+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>LEGO Microgames in Unity</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 10+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Game Development in Unity</p></div>
                                </div>
                                <div className="col-md-4 verticals-course-box">
                                    <div className='verticals-ages center'>
                                        <div className='verticals-ages-pill dark-blue'><span>Ages 13+</span></div>
                                    </div>
                                    <div className='verticals-course dark-blue center'><p>Virtual Reality Game Development</p></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="panel204" role="tabpanel">
                        <div className="detail-box col-md-12">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 girls-scout">
                                    <div className="detail-img"></div>
                                </div>
                                <div className="col-md-12 col-lg-6 orange-bkg">
                                    <h4 className="bungee">Girl scouts</h4>
                                    <p>Encourage girls to explore STEM! We work closely with troop leaders to craft a custom experience for the girls based on their interests and troop size.</p>
                                    <Button color="light-blue" link="/programs" size="small">CHECK IT OUT</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
                </div>
            </div> 
            
        </div>
);

export default Verticals