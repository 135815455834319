import React from 'react';
import Header from '../components/header/header.component';
import CodingClassesCanada from '../components/tutor-doctor/coding-classes-canada.component';
import MetaTags from 'react-meta-tags';

const CodingClassesCanadaPage = () => (
  <div>
    <MetaTags>
      <title>Code Wiz | Coding Classes Canada</title>
    </MetaTags>
    <Header />
    <CodingClassesCanada />
  </div>
);

export default CodingClassesCanadaPage;