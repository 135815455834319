import React from 'react';
import firebase from '../Firebase/firebase.js';

import FloatingMenu from '../floating-menu/floating-menu.component';
//import RobloxHeaderImg from '../../assets/images/roblox-bubble.png';
import RobloxHeaderImg from '../../assets/images/bubble-roblox.png';
import RobloxTeaser1Img from '../../assets/images/roblox_hero_image.png';
import RobloxTeaser2Img from '../../assets/images/roblox_hero_image.png';
import RobloxTeaser3Img from '../../assets/images/roblox_hero_image.png';
import RobloxBigBannerImg from '../../assets/images/roblox_big_banner.jpg';




import './roblox.styles.scss';


class Roblox extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        if(this.props.franchisee){
            this.state.isfranchisee = true;
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }
        return (
            <div className="special-page roblox-page" id=""> 
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">ROBLOX</h2> 
                                <h4 className="header-text cw-dark-blue"> The gaming platform inspiring the next generation of innovators</h4>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={RobloxHeaderImg} alt="roblox"/>
                        </div>
                    </div>
                </div>
                <div className="blue_section roblox_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <h2 className="bungee">Want to code your own version of your favorite game?</h2>
				                <p>Now you can! Sign up for a Roblox coding class or a Roblox vacation session<br/> and enjoy coding mini-games on the Roblox studio platform!</p>
                                <div className="roblox_box">
                                    <div className="roblox_block">
                                        <div className="image">
                                            <img src={RobloxTeaser1Img} alt="roblox teaser"/>
                                        </div>
                                        <h3 className="bungee">roblox<br/>classes</h3>
                                        <a href="#"><i className="fa fa-chevron-right"></i></a>
                                    </div>
                                    
                                    <div className="roblox_block">
                                        <div className="image">
                                            <img src={RobloxTeaser2Img} alt="roblox teaser 2"/>
                                        </div>
                                        <h3 className="bungee">roblox <br/>vacation sessions</h3>
                                        <a href="#"><i className="fa fa-chevron-right"></i></a>
                                    </div>
                                    
                                    <div className="roblox_block">
                                        <div className="image">
                                            <img src={RobloxTeaser3Img} alt="roblox teaser 3"/>
                                        </div>
                                        <h3 className="bungee">roblox <br/>birthday parties</h3>
                                        <a href="#"><i className="fa fa-chevron-right"></i></a>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
                <div className="roblox_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="roblox_bottom_img">
                                <img src={RobloxBigBannerImg} alt="roblox banner"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {floatingdata}
            </div>
        );
    }
}

export default Roblox
