import React from 'react';
import AboutPage from '../../about-us/about-us.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseAboutPage = () => (
    <div>
        <AboutPage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | About Us</title>
        </MetaTags>
    </div>
);

export default FranchiseAboutPage;