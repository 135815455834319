import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import ScratchProgramming from '../assets/images/blog-pictures/scratch-programming.jpg';
import ScratchProgramming1 from '../assets/images/blog-pictures/scratch-programming1.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">November 3, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>  SCRATCH PROGRAMMING FOR KIDS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>Our world is so digital now, that involving kids in gadget-free activities becomes more and more challenging. We cannot deny the influence of modern technologies on the younger generation. So why not use it for their own good? Scratch programming for kids is a great first step!</b></p>
                                            <p>Your kid is obsessed with online games, chats and entertaining apps? Then it’s time to show him the useful side of the digital world. Upgrading the role from a regular user to a creator is <a href="https://dev.thecodewiz.com/boston-with-kids/">easy and fun</a>. Scratch programming for kids is one of the most popular tools with proven efficiency.</p>



                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Scratch programming for kids – what it is and why you should give it a try?</h3>
                                            <p>Traditional <a href="https://dev.thecodewiz.com/kids-coding-languages/">programming languages</a> are text-based and complicated. Scratch is a visual object-oriented programming language for children. Lifelong Kindergarten group at MIT Media Lab developed it to teach kids the fundamentals of coding in 2002 and launched for the open public in 2007.</p>

                                            <img src={ScratchProgramming} alt="scratch programming" />
                                            <p>Although it is still coding, there is no need to worry about forgetting a bracket or a comma as you do with other programming languages. The developers primarily designed Scratch for 8 to 16-year-olds but younger children with their parents can enjoy it too. Wonder what your child needs coding for? Think of it as of the primary foreign language of generation Z.</p>
                                            <p>The best thing about Scratch programming for kids is the simplicity of learning. Your kid doesn’t have to be a <a href="https://dev.thecodewiz.com/learn-math-playing-math-games/">math</a> genius and needs no prior preparation. They develop all programming skills through playing practice. Right, playing is the critical component as all parts of the code look like bright building blocks. Thus coding is made fun and entertaining, yet still educational.</p>

                                            <img src={ScratchProgramming1} alt="scratch programming1" />
                                            <p>Building on top of the drag-and-drop programming logic introduced in Scratch, a similar language called Snap! was developed at Berkeley University. Hopscotch language is another language used on iPads and iPhones that follows the same logic. However, the drag-and-drop programming language that gained the biggest momentum around the world is Tynker. Its biggest difference from Scratch is that it is based on HTML and JavaScript and can be used in any browser, while Scratch is based on Flash and requires an additional plugin to run.</p>
                                            <p>Scratch programming lessons are perfect for young learners but are also widely used by older enthusiasts. From college students to researchers and anyone in between, Scratch programming became a vital platform to create various interactive projects. Who knows, maybe one day your child’s hobby will inspire you to master the basics of programming too.</p>
                                            <p>So here are the main advantages of Scratch programming for kids:</p>
                                            <p>–    Becoming fluent with technologies: from consumer to creator;</p>
                                            <p>–    Boosting creativity and implementing ideas;</p>
                                            <p>–    Combining fun with benefit;</p>
                                            <p>–    Developing essential skills in project management and cooperation;</p>
                                            <p>–    Learning to code in a simple and enjoyable way.</p>
                                            <p>Still having doubts whether Scratch programming is a suitable activity for your child? Keep reading to learn how Scratch can get kids excited about creating their own games, animations and artworks. Here are the top five reasons why your child should start taking Scratch programming lessons.</p>

                                        

                                                                             
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">1. Scratch programming: learn to code and code to learn</h3>
                                            <p>Scratch programming for kids is an excellent digital tool of self-expression and growth. Your child will quickly learn the basic concepts of coding from the very beginning. Moving from one project to another, kids are doing a better job at creating more complex animations, greeting cards and games. They learn from their own mistakes, get advice and inspiration from others and find new features to implement.</p>
                                            <p>Coding opens the whole new world of possibilities for its users. It is a digital language, and like any other language, it is not the goal but rather the means for learning more. Scratch programming lessons will be useful not only for building a career in the technology field but also for supporting many other professional ambitions.</p>
                                            <p>First, your kid is learning to code, then he or she is coding to learn. So Scratch programming lessons will help your child to develop other important skills:</p>
                                            <p>– Understanding the process of creating different projects;</p>
                                            <p>– Experimenting with new ideas by breaking complex tasks into simpler parts;</p>
                                            <p>– Learning to solve problems by finding and fixing bugs;</p>
                                            <p>– Learning to ask and provide feedback;</p>
                                            <p>– Improving projects based on external recommendations;</p>
                                            <p>– Cooperating for better results;</p>
                                            <p>– Making logical conclusions and decisions;</p>
                                            <p>– Becoming a video blogger making <a href="https://www.youtube.com/watch?v=2trRXLO9w20&feature=youtu.be">Scratch tutorials</a>;</p>
                                            <p>– Making ideas real and sharing them with others.</p>
                                            <p>No matter what the passions of your child are: dinosaurs, robots, anime, makeup or science, coding with Scratch is an excellent platform for expressing the imagination of any kind. Scratch programming for kids helps to open their inner potential, then the interests and desires find a creative way out. Children create their own inspiring and bright stories in an interactive form while having fun and making progress.</p>
                                            <p>Read further to learn how your kids can design their own games using Scratch programming.</p>


                                                                                 

                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">2. Scratch snake game – imagine you could create it yourself in the childhood</h3>
                                            <p>Do you remember your first digital game? Were you hunting ducks or trying to feed forever hungry snake? We spent hours with our retro cell-phones carefully growing this 8-bit reptile! Simple as it is, but it must be a creation of some genius that has captured generations of gamers.</p>
                                            <p>What if your child could create the most popular arcade game of its time? Scratch lessons will give the necessary knowledge to design simple yet fun games and share them with the world. User-friendly interface, fast learning and endless opportunities for self-expression make Scratch the best option for young learners.</p>
                                            <p>Being a creator your kid will understand the value of his ideas. Wouldn’t it be amazing to share a self-made game with a group of friends and see who gets the best score? Anyone would feel undeniably proud about making something special for himself and his community.</p>
                                            <p>Such achievements are significant for kid’s self-esteem and socialization. Thus, they should be encouraged. Playing games is something that every child enjoys, but creating your own entertaining story takes gaming to the next level. The game doesn’t have to be revolutionary. Even the simplest designs require efforts, persistence and attention to details.</p>
                                            <p>There are so many applications of Scratch programming for kids, with gaming being only one of them. Snake is not the limit for little creative minds ready to impress the world. Discover other attractive opportunities for your child’s development further in the article.</p>



                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">3. Game ideas for Scratch are endless</h3>
                                            <p>Young coders may use skills from Scratch programming lessons in various ways. With gaming being the most fun one, kids may start their path by <a href="https://scratch.mit.edu/explore/projects/games/">recreating existing games</a> and go further making something unique. Not impressed with the old snake? Well, there are hundreds if not thousands of other ideas.</p>
                                            <p>Red-capped Super Mario may be a perfect prototype of a new hero. Again, raising score in Duck Hunt is very enjoyable so your kid may find new objects to hunt or collect. It is a journey of different scenarios, so let your child create the best one for himself.</p>
                                            <p>After learning the basics, kids will be ready to move to the next level of game development. Scratch programming can be used to create quizzes and puzzles, racing or sports simulators, labyrinths and much more. There are so many game ideas to explore!</p>
                                            <p>With Scratch programming for kids, the whole process of game creation becomes quite easy to execute. Depending on your preferences, Scratch enables you to add game controls of your choice. Discover the advantages of the keyboard over mouse control and try to use your voice.</p>
                                            <p>Another cool feature of Scratch games is a possibility to use webcams. Video integration will make games more vivid and fun: you can gain points by opening mouth or moving your arms. This option can show kids the power of augmented reality and bring a better understanding of this concept.</p>
                                            <p>Want to get more details on the future hobby of your kid? Keep reading to make sure that Scratch programming lessons are the best investment in your child’s potential.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">4. Best game on Scratch is the one your child makes</h3>
                                            <p>So, you already learned the importance of coding for your kid’s future success and self-fulfillment. Growing into a programmer is not the goal, coding serves as a means of raising curious and inventive children who are not afraid to share their ideas. Your kid will feel confident seeing how his or her creations come alive.</p>
                                            <p>Creating a game requires a lot of preparation and learning in practice. Scratch programming lessons will give your kid all the necessary fundamentals of game design. Children are mastering interactive storytelling by discovering its main components. They create objects and rules, develop conflict and play mechanics, set goals and design a feedback system.</p>
                                            <p>Step by step the simplest ideas get a storyline, characters and narrative choices. Whether they recreate a good old Tetris or conquer space in Elon Musk’s virtual spaceship, kids get fully engaged in the process of learning. Children learn to test and to debug, improving their own work or building on the work of others.</p>
                                            <p>Young coders are free in their desire to use additional resources for creating engaging games. They might want to integrate audio or video samples, use existing heroes or places. Every idea should find the best realization to make your kid fulfilled and happy. Yes, the best game on Scratch is the one your child makes. And you will be extremely proud!</p>
                                            <p>Scratch programming for kids provides more opportunities than just games. Kids will master animation, they can create touching cards for your birthday, develop a science project or an artwork. Scratch programming lessons help kids with testing boundaries and foster their growth as creative thinkers.</p>
                                            <p>Want your child to plunge into this fun and promising world of coding? We are ready to help you <a href="https://dev.thecodewiz.com/coding-with-kids/">start this fantastic digital journey</a>!</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">5. Scratch programming lessons at Code Wiz</h3>
                                            <p>As we discussed earlier, there are several programming languages perfect for kids that help them code by dragging and dropping the blocks of code. While Scratch is the oldest and most popular of them, Tynker is more appropriate for programming lessons.</p>
                                            <p>There are many more reasons why your kid should pursue programming with Tynker. But the most basic reason of all goes without any explanation: your kid will love it! Invest in your children’s future and offer them to join Tynker programming classes at Code Wiz. You will be surprised by all the fantastic things they can create!</p>
                                            <p>At Code Wiz, we are excited to help children learn the fundamentals of coding and develop abilities that can help them succeed in the technology-driven future. We offer personalized lessons for kids with different skill levels to suit their learning needs. Whether your child is a complete beginner or an advanced coder, there will be lots of new things to learn and to challenge themselves with. Children learn in small groups with a student to tutor ratio of 5:1.</p>
                                            <p>All our programming lessons are interactive. Actually, every lesson is like a game itself. Kids learn fundamental programming concepts while working on real projects and having fun. They build their own computer games, animated stories, and digital artwork.</p>
                                            <p>Children create their projects in a step-by-step fashion, try them out, and if it doesn’t work, they fix it. They learn to collaborate while working in a team, helping one another and asking for help and have a healthy dose of competition. Our students develop essential skills that help them succeed at school and be a better performer at whatever they try. Last but not least, they meet new friends with the same interests and ambitions.</p>
                                            <p>You can join Code Wiz lessons whenever you want: any time is the best time. Code Wiz offers free trials before signing up. Just <a href="https://codewiz.simplybook.me/v2/#book">choose the date</a>, and we will make it happen!</p>
                                            <p>Give your kids a chance to become digital natives in the best meaning of it! Join our classes and help them explore their talents in a good company!</p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
