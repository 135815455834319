import React from 'react';
import Header from '../components/header/header.component';
import Roblox from '../components/roblox/roblox.component';

const RobloxPage = () => (
  <div>
    <Header />
    <Roblox />
  </div>
);

export default RobloxPage;