import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';



import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">April 24, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> “I AM A CODER” YOUTUBE CHALLENGE 2020! </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Claudia</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>We are officially launching our “I am a coder” Youtube Challenge.</p>
                                            <p>We are looking for our Code Wiz coders to create either <b>“How to” or “Walkthrough”</b> videos that we can repost on our Youtube channel. If you want to <b>teach kids how to code, create a “How to” video.</b> You could do this with literally anything you imagine!</p>
                                            <p>Show us how to create a <b>Tynker character</b> and make it move, or show us <b>how to code a robot.</b> You can also do a “Walkthrough” video if you want to show off your code.</p>
                                            <p>Show us a project you really, really liked! Tell us why you liked it, explain the code, tell us what you might have had trouble with and show us the finish product.</p>
                                            <p><b><i>Important: You must get your parents permission to participate.</i></b></p>
                                            <p>Once you’ve gotten approval from your parents, send us your masterful video to <b>info@thecodewiz.com</b>, well post it on our <a href="https://www.youtube.com/channel/UCGYVHmt2H2qx97acjKJV20g">Youtube channel</a> and you’ll earn 100 Code Wiz Wizbucks for everyone of your videos we post.</p>
                                            <p>To enter email your coding video to info@thecodewiz.com. Visit our <a href="https://www.youtube.com/channel/UCGYVHmt2H2qx97acjKJV20g">Code Wiz Youtube Channel!</a></p>
                                            
                                            <div className="blog-videos"><iframe width="900" height="506" src="https://www.youtube.com/embed/b5CeTMpjeig" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>

                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
