import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/top-kids-coding-languages.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BestKidsCodingLanguages from '../assets/images/game-design-with-unity.jpeg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">June, 2022</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> TOP PROGRAMMING LANGUAGES AND PLATFORMS FOR KIDS IN 2022 </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Nick M.</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            {/* <h2 className="bungee">HOW LEARNING TO CODE PREPARES KIDS FOR THE 21st-CENTURY WORKPLACE</h2> */}
                                            <p>Is your child interested in learning to code? Coding is a wonderful way to express creativity while helping develop executive functioning skills. Here are Code Wiz’s top computer languages for kids to learn in 2022!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">1. Python</h3>
                                            <p>First appearing in 1991, Python is the oldest programming language on this list! The fact that Python is still one of the most widely used programming languages to this day speaks to its enormous utility. What we love about Python is that students often find Python easy to use, read, and write. Kids usually love learning Python because it’s one of the fastest programming languages out there, emphasizing simplicity. Today, Python is one of the most in-demand programming languages used for everything from software development to website building!</p>                           
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">2. Unity</h3>
                                            <p>The newcomer on this list, Unity was first released in 2005 – but don’t let that fool you! Unity is an incredible tool that made possible some of the best-selling video games of the last decade. Unity is a game engine that immediately appeals to many students who already enjoy video games as a pastime! With limitless possibilities and the freedom to create their own video game, Unity is one of the most popular learning platforms for kids.</p>
                                            <p>The video game industry is rapidly expanding, with <a href="https://www.investopedia.com/articles/investing/053115/how-video-game-industry-changing.asp">analysts</a> predicting the sector to generate over $260 billion in revenue by 2025. If you want to encourage your child to follow their passion for video games, just have them explore the wealth of famous titles that were produced on the Unity engine – Pokemon Go, Cuphead, Call of Duty: Mobile, Kerbal Space Program, Cities: Skylines, Subnautica, Rust, Hearthstone, Untitled Goose Game, Among Us, Escape from Tarkov…just to name a few! If your child is interested in game development, Unity is the perfect engine to learn.</p>
                                            <div className='blog-images'><img src={BestKidsCodingLanguages} alt="best kids coding languages" /></div>
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">3. Java</h3>
                                            <p>Java is also an older language with decades of support and continues to be a beneficial computer language used in nearly every modern technology sector. Java is a true programming language that allows it to perform functions, set conditions, and allow users to interact with technology in unique ways. Java is used for everything – and we mean that literally! Software, web applications, social media networks, mobile apps, online servers, financial services, online shopping platforms, the list goes on! In fact, you likely have this technology in your pocket right now as most cell phone SIM cards run on – you guessed it – Java! Kids who learn Java can get a massive head start on a highly valuable career skill while also having the ability to implement their knowledge into other areas of coding.</p>
                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">4. HTML</h3>
                                            <p>Officially known as HyperText Markup Language, HTML is likely the most well-known computer language to the general public. This recognition is well-deserved, of course – initially released in 1993, HTML has been an essential component of the internet’s development. HTML is used to make nearly every website you visit, and its current iteration (HTML5) continues to be the essential backbone of the creation of web pages. What’s great about HTML is that it’s relatively simple to learn for students of all ages. HTML code is based on syntax, with every element clearly defined and meant to perform a specific function. HTML is also a fantastic way to express individuality, and students are often surprised to see how easy it is for them to create their web page! Another added benefit of learning HTML is that it requires no special software or tools – any computer with a text editor and a web browser works!</p>
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className='dark-blue bungee'>Check out Code Wiz’s awesome <a href="https://thecodewiz.com/camps">summer programs for Unity, Python, HTML, and Java summer programs!</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
