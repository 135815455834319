import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';

import HeaderImage from '../../assets/images/bubble-35.png';

import './misc-pages.styles.scss';
import Header from '../../components/header/header.component';


class OnlineFallClassLocation extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            // defaultdata: {
                state: "",
                city: "",
                territory: ""
            //}
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state;
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={HeaderImage} />
                    <title>{`Online Fall Classes for Kids In ${aboutdata.city} ${aboutdata.state} | The Code Wiz`}</title>
                    <meta name="description" content={`With our Online Fall Classes for Kids In ${aboutdata.city} ${aboutdata.state}, you get access to highly personalized tech classes that are better than the best`}/>
                    <meta name="keywords" content={`Online Fall Classes for Kids In ${aboutdata.city}, ${aboutdata.state}`}></meta>
                </MetaTags>
                <Header />
                <div className="technovation-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee"> {`Explore the best online fall classes for kids in ${aboutdata.city}, ${aboutdata.state}`}</h2> 
                                    
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={HeaderImage} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper technovation-wrapper online-fall-classes-wrapper">
                        <div className="container technovation-top">
                            <div className="row">
                                <p>With the school closures extended in the US, parents are looking for productive activities to keep the children engaged. If you want to explore the best Online Fall Classes for Kids In {aboutdata.city} {aboutdata.state}, <a href="www.thecodewiz.com">CodeWiz</a> is the best choice. We offer personalized curriculums that are based on the age, skill levels and interests of the kids. So you can rest assured that you have value for money with coding classes that would keep your children engaged enough. The fact that we provide online classes makes us the best for the current times, when you would want your kids to learn at home rather than go out and be exposed. Even as we provide online education, there is no reason to worry about the quality of training because we go the extra mile to teach the students in the best way.</p>
                                <p><b>Trust Us For Coding And Robotics At Their Best</b></p>

                                <p>When it comes to coding and robotics for children, you need to hand over the responsibility only to experts. After all, only the best experience will keep the youngsters interested enough to learn and continue for the long term. At CodeWiz, we are experts who have been around and taught children of all age groups. Our seasoned coaches understand the skills and aptitude of each student and cater to their needs. Moreover, we have a proven curriculum that keeps the students one step ahead and opens unlimited learning potential for them. While online classes ensure individual attention, you can expect things to be good enough even as we are open for regular classes. We have a small coach to student ratio, which means that your child will get all the attention he or she deserves. Learning coding and robotics couldn’t get better for your kids than we make it.</p>
                                <p><b>Beyond The Best Curriculum And Coaches</b></p>
                                
                                <p>Apart from the best in curriculum and teaching, CodeWiz has a lot more to offer to students and their parents. With our Online Fall Classes for Kids In {aboutdata.city} {aboutdata.state}, you get access to highly personalized tech classes that are better than the best. Whether your child is just a beginner or has advanced skills, we will have just the right curriculum for them. We even give you a free trial class so that the child can decide whether they actually want to continue with the course. There are rolling enrolments which makes the program flexible. Further, you can enroll them for flexible timings of your choice. There couldn’t be a better way to keep your children busy through the fall because you will be building in them a skill that can make all the difference.</p>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default OnlineFallClassLocation;