import React from 'react';
import firebase from '../../components/Firebase/firebase';
import Button from '../button/button.component';
import FloatingMenu from '../floating-menu/floating-menu.component';
import SessionHeroImg from '../../assets/images/SummerCamps-orange.png';
import AbbrFaqs from '../../components/abbr-faqs/abbr-faqs.component';
//import SessionHeroImg from '../../assets/images/sessions_hero_image.png';
import SessionCodingImg from '../../assets/images/session_coding.png';
import SessionIconOne from '../../assets/images/session_ico1.png';
import SessionIconTwo from '../../assets/images/session_ico2.png';
import SessionIconThree from '../../assets/images/session_ico3.png';
import SessionIconFour from '../../assets/images/session_ico4.png';
import SessionIconFive from '../../assets/images/session_ico5.png';
import SessionIconSix from '../../assets/images/session_ico6.png';
import SessionIconSeven from '../../assets/images/session_ico7.png';
import SessionIconEight from '../../assets/images/session_ico8.png';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';

import CodeWizAdvImage from '../../assets/images/cw-advantage-no-qr.png';

import MetaTags from 'react-meta-tags';


import './sessions.styles.scss';


class SummerCamps extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            owner: props.owner,
            locationemail: props.franchisee + "@thecodewiz.com",
            leadsource: props.leadsource
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let formUrl = "https://getform.io/f/48226221-834b-4402-a52f-ccf127930b19";
        let floatingdata =[];
        const couponUrl ="https://getform.io/f/3f2a0e4c-03e3-4c9a-948d-6b79076b5b96";
        if(this.props.franchisee){
            this.state.isfranchisee = true;
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }

        let grandopeningoffer = "Founding families enjoy **31.4159%** off their memberships for the first 6 Months!";
        let couponForm = <form id="cw-contact-form grand-opening-form" className="contact-form" action={couponUrl} method="POST">
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputLast">Last Name *</label>
                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputEmail">Email *</label>
                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputPhone">Phone *</label>
                <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                <select name="mode" className="form-control" id="inputMode" required>
                    <option value="inperson">In-Person Classes</option>
                </select>
            </div>
            <div className="form-group col-md-6">
                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
            </div>
        </div>
        <div className="form-group">
            <div className="form-check">
                    <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                    <label className="form-check-label tiny-print" htmlFor="gridCheck">
                    {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                    <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                    <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                    </label>
                </div>
            </div>
        {/* <button type="submit">Send</button> */}
        
        <button type="submit" className="btn white-button" target="_blank">SEND</button>
    </form>
        return (
            <div className="special-page sessions-page" id="sessions-page"> 
                <MetaTags>
                    <meta name="description" content="Coding and Robotics for Children Ages 7-17" />
                    <meta property="og:title" content="Coding and Robotics for Children" />
                    <meta property="og:image" content={SessionHeroImg} />
                    <title>Day, Spring Break, Winter and Summer Camps - Code Wiz</title>
                </MetaTags>
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            {/* <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">VACATION PROGRAMS</h2> 
                                <h4 className="header-text sub_title">
                                    Camp from home or in person. Unlock your inner genius through coding and robotics.
                                </h4> 
                                <div className="camp-landing-page-btns">
                                    <Button color="orange" link={this.state.data.parentportal} size="medium">ONLINE CAMPS</Button><span className="hide">&nbsp;</span><span className="hide">&nbsp;</span>
                                    <Button color="blue" link={this.state.data.parentportal} size="medium">IN-PERSON CAMPS</Button>
                                </div>
                            </div> */}
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">VACATION CAMPS</h2> 
                                <h4 className="header-text sub_title">
                                    Join our camps in person! Unlock your inner genius through coding and robotics.
                                </h4> 
                                <div className="camp-landing-page-btns">
                                    <Button color="orange" link={this.state.data.parentportal} size="large">VIEW ALL CAMPS</Button>
                                    {/* <span className="hide">&nbsp;</span><span className="hide">&nbsp;</span> */}
                                    {/* <Button color="blue" link={this.state.data.parentportal} size="large">VIEW ALL VACATION PROGRAMS</Button> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={SessionHeroImg} alt="Sessions"/>
                        </div>
                    </div>
                </div>
                {/* As seen on section */}
                <div className="as-seen-on">
                    <div className="row">
                        <div className="col-md-3 col-sm-12 col-xs-12 as-seen-on-div">
                            <h2 className="center cw-dark-blue">AS SEEN ON</h2>
                        </div>
                        <div className="col-md-9 col-sm-12 col-xs-12 as-seen-on-div">
                            <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                        </div> 
                    </div>   
                </div>
                {/* End of as seen on */}
                <div className="cw-dark-blue sessions_section blue_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="online_coding_structure">
                                    <div className="col-md-12 col-lg-6">
                                        <h3 className="bungee cw-orange vacation-offered">Vacation Camps <br />Offered</h3>
                                        <h3 className="vacation-programs">
                                            <ul className="cw-white">
                                                <li><span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span> Day Camps</li>
                                                <li><span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span> Spring Break Camps</li>
                                                <li><span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span> Summer Camps</li>
                                                <li><span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span> Winter Camps</li>
                                            </ul>
                                        </h3>
                                    </div>
                                    				
                                        {/* <p>
                                        Join hundreds of students who are learning the latest technology, working together with friends online and building in demand-skills, all from the comfort of their home.</p> */}
                                    {/* <div>
                                        
                                    </div> */}
                                    <div className="col-md-12 col-lg-6 form-white">
                                        {/* <h3 className="bungee cw-dark-blue">download our summer program brochure ..</h3> */}
                                        <h3 className="bungee center dark-blue">Let's Stay in Touch!</h3>
                                        {/* <p className="center"><b>{grandopeningoffer}</b></p> */}
                                        <br/>
                                        {couponForm}
                                        
                                        {/* <form id="cw-contact-form" className="contact-form cw-dark-blue" action={formUrl} method="POST">
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputFirst">First Name</label>
                                                    <input name="name" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputLast">Last Name</label>
                                                    <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputEmail">Email</label>
                                                    <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                                                </div>
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputPhone">Phone</label>
                                                    <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="inputMode">What Are You Interested In?</label>
                                                    <select name="class-type" className="form-control" id="inputMode" required>
                                                        <option value="in-person-camps">In-Person Camps</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                                                    <label className="form-check-label tiny-print" htmlFor="gridCheck">
                                                    <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                                                    <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                                                <input type="hidden" id="zoho" name="zoho" value={this.state.owner}></input>
                                                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
                                                <input type="hidden" id="leadsource" name="lead-source" value={this.state.leadsource}></input>
                                            </div>
                                            <button type="submit" className="btn orange-button" target="_blank">SEND</button>
                                        </form> */}

                                    </div>
                                </div>
                                <div className="sessions_image_section">
                                    <div className="text">
                                        <h2 className="bungee">Start your learning today, safe and fun <span className="cw-orange">in-person</span></h2>
                                        <p>We have a lot of options for you! You’ll collaborate with other students, challenge your learning with the latest technology, Artificial Intelligence, Roblox, Java, Apps for Amazon and Alexa and more. Daily problem solving, socializing and fun with lot’s of course options to choose from to build solid skills for the future.</p>
                                    </div>
                                    <div className="image">
                                        <img src={SessionCodingImg} alt="Session Coding"/>
                                    </div>
                                </div>
                                <div className="sessions_features">
                                    <ul>
                                        <li>
                                            <img src={SessionIconOne} alt="Session Icon 1"/>
                                            <p><span>All skill levels welcomed! </span><br />Ages 7-9, 10-12 and 13+</p>
                                        </li>
                                        <li>
                                            <img src={SessionIconTwo} alt="Session Icon 2"/>
                                            {/* <p><span>Time options to choose from</span><br/> AM & PM Online Camps <br /> Full Day In-Person Camps</p> */}
                                            <p><span>Time options to choose from</span><br/> AM & PM or Full Day <br /> Camps </p>
                                        </li>
                                        <li>
                                            <img src={SessionIconThree} alt="Session Icon 3"/>
                                            <p><span>Expert Staff</span><br /> Well trained and background checked</p>
                                        </li>
                                        <li>
                                            <img src={SessionIconFour} alt="Session Icon 4"/>
                                            <p><span>Coach: Student Ratio</span><br /> Personalized learning with our small coach-to-student ratio</p>
                                        </li>
                                        <li>
                                            <img src={SessionIconFive} alt="Session Icon 5"/>
                                            <p><span>Safe and Secure<br/> In-Person Environment</span></p>
                                        </li>
                                        
                                        <li>
                                            <img src={SessionIconSix} alt="Session Icon 6"/>
                                            <p><span>Tons of course <br/> options to choose from</span></p>
                                        </li>
                                        <li>
                                            <img src={SessionIconSeven} alt="Session Icon 7"/>
                                            <p><span>Lots of socialization and <br/>collaboration opportunities</span> </p>
                                        </li>
                                        {/* <li>
                                            <img src={SessionIconEight} alt="Session Icon 8"/>
                                            <p><span>Affordable at $275/Week</span></p>
                                        </li> */}
                                    </ul>
                                </div>

                                <div className="course-options">
                                    <h2 className="bungee">Ready to see course options?</h2>
                                    <div className="cta-buttons">
                                        <Button className="cta-btn" color="orange" link={this.state.data.parentportal} size="large">VIEW ALL CAMPS</Button>
                                        {/* <span className="hide">&nbsp;</span><span className="hide">&nbsp;</span> */}
                                        {/* <Button className="cta-btn" color="blue" link={this.state.data.parentportal} size="large">IN-PERSON VACATION PROGRAMS</Button> */}
                                    </div>
                                </div>
                                {/* Code Wiz Advantage */}
                                <div className="sessions-cw-advantage container">
                                    <img className="cwadvantage" src={CodeWizAdvImage} alt="The Code Wiz Advantage"/>
                                </div>
                                <br />
                                <div className="camp-landing-page-btns center">
                                    <Button color="orange" link={this.state.data.parentportal} size="large">VIEW ALL CAMPS</Button>
                                    {/* <span className="hide">&nbsp;</span><span className="hide">&nbsp;</span> */}
                                    {/* <Button color="blue" link={this.state.data.parentportal} size="large">IN-PERSON VACATION PROGRAMS</Button> */}
                                </div>
                                {/* <div className="sessions_detail">
                                    <div className="text">
                                        <h2 className="bungee">Code Wiz Alignment with</h2>
                                        <h4>K – 12 Computer Science Framework</h4>
                                        <p>Code Wiz aligns its curriculum with the K–12 Computer Science Framework represents a vision in which all students engage in the concepts and practices of computer science.</p>
                                        <p>“Beginning in the earliest grades and continuing through 12th grade, students will develop a foundation of computer science knowledge and learn new approaches to problem solving that harness the power of computational thinking to become both users and creators of computing technology. By applying computer science as a tool for learning and expression in a variety of disciplines and interests, students will actively participate in a world that is increasingly influenced by technology”</p>
                                        <p>The framework promotes a vision in which all students critically engage in computer science issues; approach problems in innovative ways; and create computational artifacts with a practical, personal, or societal intent.</p>
                                        
                                        <div className="camp-landing-page-btns">
                                            <Button color="orange" link={this.state.data.parentportal} size="medium">ONLINE CAMPS</Button><span className="hide">&nbsp;</span><span className="hide">&nbsp;</span>
                                            <Button color="blue" link={this.state.data.parentportal} size="medium">IN-PERSON CAMPS</Button>
                                        </div>
                                    </div>
                                    <AbbrFaqs />
                                </div> */}
                                
                            </div>
                        </div> 
                    </div>
                </div>

                

                <div className="sessions_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">	
                                <h2 className="bungee">FREQUENTLY ASKED QUESTIONS</h2>
                                <div id="accordion">
                                    {/* <div className="card">
                                        <div className="card-header" id="generalFaqOne">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsOne" aria-expanded="true" aria-controls="collapseCampsOne">
                                            
                                                <h5 className="bungee cw-dark-blue"> What kind of equipment does my child need to participate? </h5>
                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsOne" className="collapse show" aria-labelledby="generalFaqOne" data-parent="#accordion">
                                            <div className="card-body">
                                                • A modern PC or Mac computer with at least 4GB of RAM and 256MB processor speed. Tablets are not supported for online class/camp use.<br/>
                                                • Minecraft, Roblox and Unity classes PCs or MACs. Chromebooks will not suffice<br/>
                                                • A USB headset<br/>
                                                • A web camera<br/>
                                                • High speed internet  
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="card">
                                        <div className="card-header" id="generalFaqTwo">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsTwo" aria-expanded="true" aria-controls="collapseCampsTwo">                                        
                                                <h5 className="bungee cw-dark-blue"> How do you ensure children are actually learning online? </h5>                                               
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsTwo" className="collapse" aria-labelledby="generalFaqTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                Our staff has been trained extensively in how to use Zoom to manage a classroom remotely. The classroom participants will meet limits and restrictions for features like chat, file sharing, and even speaking out of turn. Instructors have the ability to mute or remove disruptive students, and the entire session is recorded by default for accountability purposes.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="card">
                                        <div className="card-header" id="generalFaqThree">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsThree" aria-expanded="true" aria-controls="collapseCampsThree">
                                                <h5 className="bungee cw-dark-blue">What ages is coding classes designed for? </h5>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsThree" className="collapse" aria-labelledby="generalFaqThree" data-parent="#accordion">
                                            <div className="card-body">
                                                All of our classes are geared at children with a basic reading proficiency. They need to be able to read the instructions on the screen independently to fully participate in our courses.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqFour">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsFour" aria-expanded="true" aria-controls="collapseCampsFour">
                                                <h5 className="bungee cw-dark-blue"> My child is younger than 1st grade and not yet reading,  do you have anything for them? </h5>                      
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsFour" className="collapse" aria-labelledby="generalFaqFour" data-parent="#accordion">
                                            <div className="card-body">
                                                At this time, no. All our coding classes are designed for children who can read independently. However, we work with several other providers who are bringing classes for younger children, and we’re happy to connect you to them! Contact us to discuss more
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqFive">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsFive" aria-expanded="true" aria-controls="collapseCampsFive">                                            
                                                <h5 className="bungee cw-dark-blue"> Is this self-guided? </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsFive" className="collapse" aria-labelledby="generalFaqFive" data-parent="#accordion">
                                            <div className="card-body">
                                                While our courses are self paced, our classes offer an easy way for your child to participate in a classroom environment with a coach.           
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqSix">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsSix" aria-expanded="true" aria-controls="collapseCampsSix">                                            
                                                <h5 className="bungee cw-dark-blue"> Do you offer make up classes? </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsSix" className="collapse" aria-labelledby="generalFaqSix" data-parent="#accordion">
                                            <div className="card-body">
                                                We only offer make up classes for our evening classes. We are unfortunately unable to offer makeup classes for camps.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqSeven">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsSeven" aria-expanded="true" aria-controls="collapseCampsSeven">                                           
                                                <h5 className="bungee cw-dark-blue">  Who are your coaches?  </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsSeven" className="collapse" aria-labelledby="generalFaqSeven" data-parent="#accordion">
                                            <div className="card-body">
                                                Our instructors have backgrounds in computer science, engineering, and/or math as well as experience working with children. With a strong understanding of how to teach coding to young children, your child gets a fun, educational class experience. All our instructors undergo background checks.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqEight">
                                            <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsEight" aria-expanded="true" aria-controls="collapseCampsEight">                                           
                                                <h5 className="bungee cw-dark-blue">  Can we do a trial class? </h5>                                                
                                            </button>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsEight" className="collapse" aria-labelledby="generalFaqEight" data-parent="#accordion">
                                            <div className="card-body">
                                                Trial classes are available for our evening classes. To book a trial class, please <a class="cw-light-blue" href={this.state.data.tryfreeclass}>visit this page</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqNine">
                                            <h5 className="mb-0">
                                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapseCampsNine" aria-expanded="true" aria-controls="collapseCampsNine">                                            
                                                <h5 className="bungee cw-dark-blue"> Do you offer Sibling Discounts? </h5>                                                
                                            </button>
                                            </h5>
                                        </div>
                                        <div id="collapseCampsNine" className="collapse" aria-labelledby="generalFaqNine" data-parent="#accordion">
                                            <div className="card-body">
                                                Please reach out to your center to inquire about sibling discounts
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bottom_cta">
                                    <p>Please see our <a href="faqs">full FAQ </a> for answers to commonly asked questions.</p>
                                    <ul>
                                        <li><a href={`tel:${this.state.data.phone}`}><i className="fa fa-phone"></i>&nbsp; {this.state.data.phone}</a></li>
                                        <li><a href={`mailto:${this.state.data.email}`}><i className="fa fa-envelope"></i>&nbsp; {this.state.data.email}</a></li>
                                    </ul>
                                    {/* <Button color="orange" link={this.state.data.parentportal} size="large">REGISTER FOR ONLINE CAMPS</Button> */}
                                    <div className="camp-landing-page-btns">
                                        <Button color="orange" link={this.state.data.parentportal} size="large">VIEW ALL VACATION PROGRAMS</Button>
                                        {/* <span className="hide">&nbsp;</span><span className="hide">&nbsp;</span> */}
                                        {/* <Button color="blue" link={this.state.data.parentportal} size="large">IN-PERSON VACATION PROGRAMS</Button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
               

                {floatingdata}
                
            </div>
        );
    }
}

export default SummerCamps
