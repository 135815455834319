import React from 'react';
import Header from '../../../components/header/header.component';
import LegoLeague from '../../../components/lego-league/lego-league.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const LegoLeaguePage = () => (
  <div>
    <Header location="reading" fulllocation="reading-ma"/>
    <FacebookMessenger location="reading" FBPageId="2544691755571962" />
    <LegoLeague franchisee="reading" formType="coupon" owner="Code Wiz Reading"/>
    <MetaTags>
        <title>Code Wiz - Reading MA | FLL</title>
    </MetaTags>
  </div>
);

export default LegoLeaguePage;