import React from 'react';
import Header from '../../../components/header/header.component';
import VirtualRobotics from '../../../components/virtual-robotics/virtual-robotics.component';
import MetaTags from 'react-meta-tags';

const VirtualRoboticsPage = () => (
  <div>
    <Header location="westborough" fulllocation="westborough-ma"/>
    <VirtualRobotics franchisee="westborough"/>
    <MetaTags>
        <title>Code Wiz - Westborough MA | Virtual Robotics</title>
    </MetaTags>
  </div>
);

export default VirtualRoboticsPage;