import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import CodingCompetitions from '../assets/images/blog-pictures/coding-competitions-for-kids.jpg';
import CodingCompetitions1 from '../assets/images/blog-pictures/coding-competitions-for-kids1.jpg';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">January 25, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>CODING COMPETITIONS FOR KIDS – FIND THE RIGHT CODING COMPETITION FOR YOUR BUDDING TECHPRENEUR</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>We have already mentioned how beneficial competitive coding can be for coders of all ages from the school level to the professional world in our previous blog <i><a href="https://www.dev.thecodewiz.com/benefits-of-coding-competitions/">The Benefits of Coding Competitions</a>.</i></p>
                                            <p>We also gave you a detailed introduction to the world of competitive coding in another blog <i><a href="https://www.dev.thecodewiz.com/what-is-competitive-coding/">What is Competitive Coding? An introduction to Programming Competitions</a></i>. In that blog post, we mentioned some of the more popular coding competitions such as Codeforces, TopCoder, HackerRank, and CodeChef got mentioned.</p>
                                            <p>However, there is no shortage of programming competitions across the globe and different age groups. We even created one of our own “<b>Code to make a difference</b>,” because we at Codewiz believe that competitive coding can also be used to help encourage the next generation of coders to combine skills, collaboration, and creativity to produce things that can make a real difference in the world. You can find out more about it here.</p>
                                            <p><i>But this blog is not about us, it is to focus on highlighting some of the top coding competition for kids, from elementary to high school.</i></p>
                                            <p><i>We’ll provide important details on each so you can find the right competition for your future techpreneur.</i></p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">MIDDLE SCHOOL (or Earlier) Coding Competitions for Kids</h3>
                                            <h3 className="bungee">First Lego League</h3>
                                            <p>The <a href="https://www.firstlegoleague.org/">First Lego League</a> (FLL) is an international competition for elementary and middle school students of pages 9–14 in the United States and Canada, and ages 9–16 elsewhere. It is organized by US-based organization FIRST (For Inspiration and Recognition of Science and Technology), an international youth organization whose goal is to develop ways to inspire students in engineering and technology fields.</p>
                                            <p>The First Lego League is a partnership between FIRST and the LEGO Group. </p>
                                            <p>It also has a scaled-down robotics program for children ages 6–9 called FIRST LEGO League Jr.</p>
                                            <p>In August of each year, FLL introduces a scientific and real-world challenge based on a real-world problem for teams to focus and research on. FLL then gives teams complete freedom on how to complete the missions, providing that they are completed by a programmed LEGO Mindstorms robot with no outside assistance.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Robotics</h4>
                                            <p>The robotics section of the competition involves designing and programming LEGO Mindstorms robots to complete tasks.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className="bungee">How it works </h4>
                                            <p>The students work out a solution to a problem related to the theme, which changes every year, and then meet for regional, national and international tournaments to compete, share their knowledge, compare ideas, and display their robots.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Team activity</h4>
                                            <p>Teams are also asked to perform a teamwork activity, usually timed, to see how the team works together to solve a new problem.</p>

                                            <img src={CodingCompetitions} alt="coding competitions for kids" />

                                        </div>
                                         <div className="blogs_details_block">
                                            <h4 className="bungee">Challenges</h4>
                                            <p>The challenges for First Lego League are annually themed.</p>
                                
                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Global Innovation Award </h4>
                                            <p>Officially registered teams can be nominated by their region for the Global Innovation Award, where they could win a grant to make their innovative solution to the annual challenge theme’s problem a reality. As of the Animal Allies Season, the First Place team wins $20,000 and two other Finalists each win $5,000.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Cyber Robotics Coding Competition (CRCC)</h3>
                                            <p><a href="https://crcc.io/">CRCC</a> is an innovative robotics tournament that facilitates students through gaming and competition in STEM, coding and tech literacy. </p>
                                            <p>Students compete within their class, grade, school, district and state to win stages and progress to the finals. CRCC uses simulation of virtual 3D Robots that perform complex tasks and missions.</p>
                                            <p>It’s cloud-based and can be accessed from any computer. No travel or special hardware is required.</p>
                                            <p>Everyone can participate as no prior knowledge of coding or robotics is needed for educators or students. Each student can progress at their own pace as the competition is self-directed. </p>

                                        </div>

                                           <div className="blogs_details_block">
                                            <h4 className="bungee">Competition structure</h4>
                                            <p>Competitions range in size from twenty schools to hundreds of schools and can be held over several weeks or at a one-day event. Students can log-in to the CoderZ SaaS Platform from home and complete missions that introduce them to mechanics, physics, and programming accompanied by math, science, and engineering principles.</p>
                                            <p>Each mission challenges students to strategize, gain knowledge and score points to progress through the competition.</p>
                                            <p>For more information go to <a href="http://www.iscefoundation.org/">www.iscefoundation.org</a></p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">ACSL (American Computer Science League)</h3>
                                            <p>This is one of the oldest exam competitions in the USA. Participation is team-based via a school. ACSL organizes computer science contests and computer programming contests for elementary, junior, and senior high school students.</p>
                                            <p>There’s a written and programming portion of the exam.</p>
                                            <p>In the past, it has hosted over 300 teams in the United States, Canada, Europe, Africa, and Asia.</p>
                                            <p>It’s on the approved activities list of the National Association of Secondary School Principals (NASSP) and is also an institutional member of the CSTA (Computer Science Teachers Association).</p>

                                        </div>

                                         <div className="blogs_details_block">
                                            <h4 className="bungee">Competition structure</h4>
                                            <p>The competition consists of four contests. In each contest, students are given short theoretical and applied questions, and then a programming problem to solve within the following three days.</p>
                                            <p>CodeWiz administers the contest and results are returned to ACSL for tabulation. At the end of the year, an Invitational Team All-Star Contest, based upon cumulative scores, is held at a common site.</p>
                                            <p>The competition focuses on using already existing math knowledge to solve programming questions. </p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Who should enter</h4>
                                            <p> Kids with strong math skills and an interest in programming.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">KTByte 5k</h3>
                                            <p><a href="https://www.ktbyte.com/contests/5k201412">KTByte 5k</a> is a cash prize Processing.js programming contest based in the USA for Middle and High School Students. </p>
                                            <p>5k competitions use Processing.js and Processing Java. Applications must work in both, and the application must max 5kb of code.</p>
                                            <p>There are similar competitions such as the Java 4k competition or js1k javascript.</p>

                                        </div>

                                         <div className="blogs_details_block">
                                            <h4 className="bungee">Rules</h4>
                                            <ul>
                                            <li>All Entrants must currently be enrolled in middle or high school to enter.</li>
                                            <li>All program code must execute both in Processing.js (latest chrome and firefox) and Processing Java (latest Processing).</li>
                                            </ul>

                                        </div>

                                         <div className="blogs_details_block">
                                            <h4 className="bungee">Prizes</h4>
                                            <ul>
                                            <li>First prize is USD$300</li>
                                            <li>Second prize is USD$100</li>
                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Bebras Computing Challenge</h3>
                                            <p>The <a href="https://www.bebraschallenge.org/">Bebras Computing Challenge</a> introduces computational thinking to students from the 5th to 12th grade.</p>
                                            <p>It is organized in over 30 countries and designed to get students all over the world excited about computing.</p>

                                        </div>

                                         <div className="blogs_details_block">
                                            <h4 className="bungee">Competition</h4>
                                            <p>Competitors compete online in their own schools. Each participant gets 45 minutes to answer 15 multiple-choice questions that focus on computational and logical thinking.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Divisions</h4>
                                            <p><i>The challenge is available for five divisions this year:</i></p>
                                            <p>Kits         grades 3 and 4</p>
                                            <p>Benjamin     grades 5 and 6</p>
                                            <p>Cadet        grades 7 and 8</p>
                                            <p>Junior       grades 9 and 10</p>
                                            <p>Senior       grades 11 and 12</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">HIGH SCHOOL Coding Competitions for Kids</h3>
                                            <h3 className="bungee">First Tech Challenge (FTC)</h3>
                                            <p><a href="https://www.firstinspires.org/robotics/ftc">FIRST Tech Challenge</a> is one level above First Lego League (FLL), but slightly different from the smaller competition First Robotics.</p>
                                            <p>However, it’s way more than building robots. FTC teams (up to 15 team members, grades 7-12) are challenged to design, build, program, and operate robots to compete in a head-to-head challenge in an alliance format.</p>
                                            <p>FTC allows middle and high school students and their adult mentors to work and create together as a team to solve a common problem.</p>
                                            <p>Teams are challenged to design and build a robot using a kit of parts and within a common set of rules to play a sophisticated field game.</p>
                                            <p>Guided by adult coaches and mentors, students develop STEM skills and practice engineering principles while realizing the value of hard work, innovation, and teamwork.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Skills required</h4>
                                            <p>FIRST welcomes every student, technical and non-technical, with or without any special skills. Student and adult team members are encouraged to bring any skills they already have, like programming, electronics, metalworking, graphic design, web creation, public speaking, videography, etc.</p>

                                        </div>

                                         <div className="blogs_details_block">
                                            <h4 className="bungee">Season</h4>
                                            <p>The FIRST Tech Challenge season starts in May when teams generally form and registration opens. Then the season usually kicks-off in early September. The design and build season runs from September to January.</p>
                                            <p>Tournament season varies by region and can begin as early as State and Regional Tournaments advance teams to Super-Regional Championship Tournaments and winners earn spots at FIRST Championship at the end of April.</p>
                                            <p>There are also many off-season events where teams participate, strategize, hone their skills, learn new technology, meet other teams, and have fun!</p>
                                            <p><b>*Each team needs two or more adult mentors who are willing and motivated to coach the team through the build and competition season and beyond.</b></p>
                                            <p>Each season culminates with local and regional events where qualifying teams compete for awards and a spot in the FIRST Championship.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Cost to Participate</h4>
                                            <p>There are costs involved with fielding a FIRST Tech Challenge team and they can vary from team to team and region to region depending on what level of participation the team chooses.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Rewards</h4>
                                            <p>FIRST rewards teams for achievements both in and out of competition − excellence in design, demonstrated team spirit, Gracious Professionalism, and outreach efforts are some of the award criteria.</p>
                                            <p><b>Scholarship –</b> every participant is eligible to apply for a scholarship. There are 200 scholarship providers with over $80 million in available scholarships. </p>

                                            <ul>
                                            <li>High school students who have participated or are participating in high-school-level FIRST Robotics Competition and/or FIRST Tech Challenge teams can apply for FIRST Scholarships.</li>
                                            <li>Most FIRST Scholarship Providers request applications during a student’s senior year, but there are a few scholarships available to juniors or college students.</li>
                                            <li>Each scholarship may also have other eligibility requirements as set by the provider. Eligibility requirements are available at www.firstinspires.org/scholarships.</li>
                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">US First Robotics</h3>
                                            <p>More advanced than First Lego League, the <a href="https://www.firstinspires.org/robotics/frc">FIRST Robotics Competition</a> combines the excitement of sport with the rigors of science and technology.</p>
                                            <p>The competition challenges high school students and their adult mentors the opportunity to design and build a robot using a standard “kit of parts” and within a common set of rules to play a sophisticated field game − with limited time. </p>
                                            <p>Under strict rules, limited time and resources, it’s as close to real-world engineering as a student can get. The robot “game” changes every season and each season culminates with an exciting FIRST Championship. </p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Cost to Participate</h4>
                                            <p>There are costs involved with forming a FIRST Robotics Competition team and they can vary from team to team and region to region depending on what level of participation the team chooses. </p>

                                            <ul>
                                            <li>There are never any “per student” fees; all costs for individual team members are assumed by the team as a group.</li>
                                            <li>The annual fees for team registration, a robot kit of parts, and event participation are $5,000 – $6,000. Additional costs for travel, food, team shirts, and other optional items will vary.</li>
                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Funding</h4>
                                            <p>FIRST offers several fundraising opportunities for teams that need extra help.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Rewards</h4>
                                            <p>Teams are rewarded for various achievements such as excellence in design, demonstrated team spirit, Gracious Professionalism®, and the ability to overcome obstacles.</p>
                                            <p><b>Scholarship –</b> for each competition every participant is eligible to apply for college scholarships. </p>
                                            <p>High school students who have participated or are participating in high-school-level FIRST Robotics Competition and/or FIRST Tech Challenge teams can apply for scholarships.</p>
                                            <p>Scholarship providers are primarily four-year colleges and universities, but also include community colleges, technical schools, corporations, and associations.</p>
                                            <p><b> *The website’s Resource Library provides a wealth of information on everything from technical guides to fundraising ideas, or fun activities for your team.</b></p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">USACO (USA Computing Olympiad)</h3>
                                            <p>The highest level of algorithmic programming competition in the USA for pre-college students. </p>
                                            <p>The competition is set up in four categories – Platinum, Gold, Silver, and Bronze contests.</p>
                                            <p>At the 2019 Open Contest, a total of 2993 distinct users participated during its 4-day span. A total of 2309 participants submitted at least one solution, hailing from 64 different countries.</p>
                                            <p><a href="http://www.usaco.org/">USACO</a> provides roughly six on-line programming contests per year for students at all levels.</p>


                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Resources</h4>
                                            <p>USACO provides hundreds of hours of free on-line training resources that students can use to improve their programming and computational problem-solving skills.</p>
                                            <p>There is now support for submission of programs written in Python 2.7 or Python 3.6 on the training.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Rewards</h4>
                                            <p>The top four students in the USA are provided with the opportunity to represent their country at the International Olympiad in Informatics (IOI) – the most prestigious international algorithmic programming competition at the high school level.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Technovation Challenge</h3>
                                            <p>This competition is a bit different than the others – it’s strictly for girls who like to code. </p>
                                            <p>The <a href="https://technovationchallenge.org/">Technovation Challenge</a> is an international competition in which girls between the ages of 10 and 18, from more than 100 countries, participate. </p>
                                            <p>Under the mantra “Girls for a change”, every year, Technovation invites teams of girls from all over the world to learn and apply the skills needed to solve real-world problems through technology.</p>
                                            <p>The competition offers girls around the world the opportunity to learn the skills needed for them to become entrepreneurs and technology leaders. Working in teams of 1 to 5, girls find a problem in their community and build a mobile app to help solve it. </p>
                                            <p>Domestic violence, climate change, bullying are a few of the problems 2,000 Technovation Girls teams around the world tackled in 2019. Since 2010, more than 23,000 girls from 100+ countries have participated in the competition. </p>
                                            <p><b>*Official Start of 2020 Season: January 14, 2020</b></p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Eligibility</h4>

                                            <ul>
                                            <li>Any female (ages 10-18) interested in Technovation Girls is eligible to compete.</li>
                                            <li>To enter the competition, the contestant must create or join a team on the Technovation online platform, have a parent/guardian sign the consent form and meet the following Student Participation criteria:</li>
                                            <li>All students must be between the ages of 10 to 18 as of August 1, 2020.</li>
                                            <li>All students must identify as female. No males can enter the competition.</li>
                                            <li>Teams are composed of 1 to 5 students. Students can only join 1 team.</li>
                                            <li>Competition Divisions are based on the age of the oldest student on the team.</li>
                                            <li>Students can participate in Technovation Girls for multiple seasons until they reach the age of 18.</li>

                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Requirements</h4>

                                            <ul>
                                            <li>No prior programming experience is necessary for Technovation students.</li>
                                            <li>Technovation Challenge is free for all participants.</li>
                                            <li>Each team will need up to five girls (ages 10 to 18), a safe place to meet, a laptop with internet, and a smartphone or tablet.</li>
                                            <li>Be between the ages of 10 to 18 as of August 1, 2019, and identify as female.</li>
                                            
                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Curriculum</h4>
                                            <p>Technovation’s curriculum takes students through 4 stages of launching a mobile app startup, inspired by the principles of design thinking:</p>

                                            <ul>
                                            <li>Ideation: Identify a problem</li>
                                            <li>Technology: Develop a mobile app solution</li>
                                            <li>Entrepreneurship: Build a business plan to launch the app</li>
                                            <li>Pitch: Bring the business to market </li>
                                            
                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Rewards </h4>
                                            <p>The Winner gets $10,000 and a paid trip to San Francisco. </p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Imagine Cup </h3>
                                            <p>Dubbed the “Olympics of Technology” the <a href="https://imaginecup.microsoft.com/en-us/Events?id=0">Imagine Cup</a> is considered one of the top competitions and awards related to technology and software design. </p>

                                            <img src={CodingCompetitions} alt="Imagine Cup" />

                                            <p>Microsoft’s global technology competition for students began in 2003 with approximately 1,000 competitors from 25 countries and has grown to more than 350,000 competitors from over 150 different countries. </p>
                                            <p>Competitors create projects that address the Imagine Cup theme: “Imagine a world where technology helps solve the toughest problems.” </p>


                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Competition structure </h4>
                                            <p>There are several competitions and challenges within the Imagine Cup. The Software Design category is the primary competition in which its winners take home the Imagine Cup trophy. </p>
                                            <p>Software Design competition: it challenges participants to use technology to solve what they consider to be the toughest problems facing the world today. Using Microsoft tools and technology, participants develop, test, and build their ideas into applications that could help change the world.</p>
                                            <p>Game Design Competition: challenges participants to create a new game that illustrates the Imagine Cup theme. </p>
                                            <p>The Imagine Cup is more than just a tech competition, it’s the place to start for beginning coders and developer pros alike. It’s a chance for competitors to meet other students who want to make a difference, network with professionals, gain new skills, and showcases their work on a global stage.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Travel opportunities and other prizes </h4>
                                            <p>Courtesy of Microsoft, teams selected to advance in the competition will travel across the globe to regional finals in Singapore, Amsterdam, and Mexico City to pitch their projects live. Along the competition journey, the Imagine Cup brings together students who want to learn and innovate for the future. </p>
                                            <p>The Cup Regional Finals are co-located with <a href="https://www.microsoft.com/en-us/events">Microsoft Ignite | The Tour</a>, where Microsoft shares the latest in cloud technologies and developer tools. This provides an additional opportunity for finalists to showcase their passion and network with industry professionals. </p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Eligibility</h3>
                                            <p>You are eligible to enter if you meet the following requirements at the time of entry:</p>

                                            <ul>
                                            <li>A student who is currently enrolled at an accredited educational institution that grants high-school or college/university (or equivalent) degrees (including homeschools) that is at least 16 years of age. </li>
                                            <li>Residents of Cuba, Iran, North Korea, Sudan, Syria, and the Region of Crimea (due to U.S. export regulations) are not eligible to participate.</li>
                                            <li>Employees or interns of Microsoft Corporation, or a Microsoft subsidiary, at any time, are not eligible to participate.</li>
                                            <li>You are not involved in any part of the execution or administration of this Competition.</li>
                                            <li>You are not an immediate family member of (parent, sibling, spouse/domestic partner, child) or household member of a Microsoft employee, an employee of a Microsoft subsidiary, or a person involved in any part of the administration and execution of this competition.</li>
                                            
                                            </ul>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className="bungee">Rewards</h4>
                                            <h4 className="bungee">Imagine Cup Grant</h4>
                                            <p>A $3 million competitive grant program supports a select number of winning teams’ solutions to go to market and realize its potential to solve a critical global problem. </p>
                                            <p>The grant packages include US$75,000 for each team, as well as software, cloud computing services, solution provider support, premium Microsoft BizSpark account benefits and access to local resources such as the Microsoft Innovation Centers.</p>
                                            <p>Microsoft will also connect grant recipients with its network of investors, nongovernmental organization partners, and business partners.</p>
                                            <p><i>We hope that this blog gets you a step closer to selecting the right coding competition for your budding Techpreneur. If you need help in choosing the ideal one or are interested to find out more information on our in-house coding competition for kids, don’t hesitate to contact us.</i></p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
