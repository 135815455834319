import React from 'react';
import Franchisee from '../../../components/franchisee/v4-franchisee.component';
import Header from '../../../components/header/header.component';
import CodewizImg from '../../../assets/images/Codewiz-team.png';
import JimImg from '../../../assets/images/jim-s.jpg';
import BridgetteImg from '../../../assets/images/bridgette-s.jpg'
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

import './beaumont.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';

class BeaumontPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: 'Beaumont Location',
            coupon: 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg',
            sections: [{
                review: 'Code Wiz is awesome!',
                author: 'l. Smith',
                id: 1
            },
            {
                review: 'My children love Code Wiz',
                author: 'J. Johnson',
                id: 2
            }],
            team: [
                {id: 'wf-1', name: 'Jim Sullivan', title: 'Owner', picture: JimImg},
                {id: 'wf-2', name: 'Bridgett Sullivan', title: 'Co-Owner/Center Director', picture: BridgetteImg}
            ]
        }
    }

    render() {
        return (
            <div>
                <PopupModal location="beaumont" formType="coupon" owner="Code Wiz Beaumont"/>
                <Header location="beaumont" fulllocation="beaumont-tx"/>
                <FacebookMessenger location="beaumont" FBPageId="298754836657255" />
                <Franchisee name="beaumont" team={this.state.team} owner="Code Wiz Beaumont"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in Beaumont TX | Code Wiz</title>
                    <link rel="canonical" href="http://thecodewiz.com/beaumont-tx" />
                    <meta name="description" content="Coding and Robotics For Kids in Beaumont TX" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/beaumont-tx" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in Beaumont TX" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in Beaumont Texas" /> 
                    <meta name="keywords" content="coding, coding in beaumont, beaumont texas, robotics"></meta>
                    <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
                </Helmet>
            </div>
        );
    }
}

export default BeaumontPage;

