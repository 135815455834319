import React from 'react';
import Header from '../components/header/header.component';
import ThankYou from '../components/thank-you/thank-you.component';
import MetaTags from 'react-meta-tags';

const ThankYouPage = () => (
  <div>
    <Header />
    <ThankYou />
    <MetaTags>
        <title>Code Wiz | Thank You</title>
    </MetaTags>
  </div>
);

export default ThankYouPage;