import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BestKidsSummerCamp from '../assets/images/blog-pictures/best-kids-summer-camp.jpg';
import BestKidsSummerCamp1 from '../assets/images/blog-pictures/best-kids-summer-camp1.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 25, 2019</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>HOW TO FIND THE BEST KIDS SUMMER CAMP FOR YOUR CHILD </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h2 className="bungee">How to Find the Best Kids Summer Camp for Your Child</h2>
                                            <p><b>In our last <a href="https://dev.thecodewiz.com/blog/2019/03/02/why-you-should-send-your-kids-to-a-coding-for-kids-summer-camp/">blog</a>, we detailed the many profound benefits of enlisting your child in a coding for kids summer camp. Once you’ve determined that a coding summer camp is right for your child, the next step is to find the right program.</b></p> 
                                            <p>There are a plethora of coding <a href="https://en.wikipedia.org/wiki/Summer_camp">summer camps</a> out there to choose from. But with so many options, choosing the right one for your child can be quite a tricky task.</p> 
                                            <p>And with coding camps often demanding a high price tag (up to $3000 a week), getting it right can be the difference between you wasting your hard-earned money or making a wise investment – an investment that can galvanize the potential of your child, <a href="https://dev.thecodewiz.com/blog/2019/03/02/learning-to-code-prepares-kids-for-the-21st-century-workplace/">shaping their future</a> in a very positive way.</p>
                                            <p><i>In this blog, we will provide some guidelines that will make the summer camp selection process a little less complicated.</i></p>
                                            

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">The Elements of the Best Kids Summer Camps</h3>
                                            <p>First, you might be wondering what constitutes a good <a href="https://dev.thecodewiz.com/blog/2018/11/03/coding-with-kids/">kids coding summer camp.</a></p>
                                            <p>There are many positive elements to a good kids coding summer camp. You won’t find the top kids coding summer camps babysitting your child for sure.</p>
                                            <p>The primary objective of a kids coding summer camp should be to keep children learning while school is out. It should revolve around in-demand skill-building educational material most schools don’t or can’t teach.</p>
                                            <p>The best kids coding summer camps are designed with your kids’ creativity in mind and provide a sensible balance of hands-on, engaging education and fun extracurricular activities supervised by experienced tech-savvy camp instructors.</p>
                                            <p>Before we get into how to find one of those, let us first look at what not to do when searching for the best kids summer camp for your child.</p>
                                            <p>As adults, we should all have a list of make-or-break or must and must-nots when making important decisions – it usually makes the process a little easier.</p>


                                                                                 
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Mistakes Parents Make When Choosing a Coding for Kids Summer Camp</h3>
                                            <p><i>Here are some of the most common mistakes parents make when choosing a coding for kids summer camp:</i></p>
                                            <p><b>1. Not keeping their child’s interests in mind</b></p>
                                            <p>Engagement is key to learning, particularly for kids. And the best way to ensure engagement when it comes to children is choosing something they are really interested in.</p>
                                            <p>It happens in every facet of life – parents choosing for their kids, not what’s actually best for them but what they think is best. Sometimes these decisions border on selfishness, but a lot of times it’s good-natured.</p>

                                            <img src={BestKidsSummerCamp} alt="best kids summer camp" />
                                            <p><b>Observe your child to see what sparks their interest. The more the features of a summer coding camp aligns with your kid’s interests the more likely they will benefit from it.</b></p>
                                            <p><b>The first thing to do is to simply observe your child –</b></p>
                                            <p>• Does he or she love being outside and show little interest in technology and home entertainment? If so, maybe traditional camps with athletic activities or nature programs are best for your child.</p>
                                            <p>• Is he or she a budding programmer who is always fiddling with computers, electronic devices, playing video games or coming up with unique ideas? In that case, you might have a creative genius on your hands, and a coding for kids program will amplify that innate interest.</p> 
                                            <p>Talk to your child and use what you observe to short-list a few good summer camps. The more the features of a summer coding camp aligns with your kid’s interests, the more likely they will immerse themselves into it and the more likely they will benefit from it.</p> 

                                            <p><b>2. Not checking staffing qualifications</b></p> 
                                            <p>It’s imperative to thoroughly investigate the staffing qualifications at any camp you’re seriously considering. Determining the expertise of camp instructors before enlisting your child is difficult, but attainable</p> 
                                            <p>Some parents get consumed in the credibility of the program as a whole they fail to zero in on and do the requisite due diligence on the professional personnel in the program.</p> 
                                            <p>Great camp instructors will make your child’s summer camp experience magical and unforgettable.</p>

                                            <p><b>3. Sending your kid to a summer camp because their best friend is going</b></p>  
                                            <p>What kid wouldn’t love to spend the summer going to camp with their best friend? Kids tend to feel a stronger sense of attachment to some of their peers. But some of them are just too young to realize that, even though we all need each other, we also have our own identities.</p> 
                                            <p>Summer camp is a great opportunity for kids to learn how to meet new friends and expand their social circle. So, as a parent, if you choose a summer camp for your child based on what their friends are doing, you can deprive them of an opportunity to expand their interests and social circle.</p> 

                                            <p><b>4. Not letting kids get out of their comfort zone</b></p> 
                                            <p>Kids have a tendency to resist change. They want what they want and what they are used to. But, like adults, they grow when they’re challenged.</p> 
                                            <p>The best summer camps take kids out of their comfort zones by introducing them to new experiences and fresh learning opportunities.</p> 
                                            <p>Now that we have the what not to dos out of the way let’s focus on what you need to do to choose the best kids summer camp for your child.</p>
                                        
                                        
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">What to Do when Searching for the Best Kids Summer Camp for Your Child</h3>
                                            <p><b>1. Set your expectations for the summer camp</b></p>
                                            <p>Before you can choose the “right” summer camp, you first have to define what is “right” for your child. Ask yourself first — what do I want my child to gain from the experience?</p>

                                            <p><b>2. Select the right type of summer coding camp</b></p>
                                            <p>Once you’ve established, you’re expectations, and what you want your kid to gain from camp, it’s now time to shortlist the programs that can meet those needs.</p>
                                            <p>First consider whether your child is interested in developing a specific skill in a particular area, or whether they’d like to learn a variety of coding languages opportunities in one program.</p>

                                            <p><b>3. Decide on day camps or overnight camps</b></p>
                                            <p>This should come down to your child’s age and comfort-level with being away from home for long periods.</p>
                                            <p>Day camps provide an opportunity for kids to learn new skills, socialize, and participate in leisure activities, minus the overnight commitment.</p>
                                            <p>Overnight/Sleepaway or Residential Camps provide much of the same but with the key difference of kids sleep at camp and don’t return home until its completion.</p>
                                            <p>Such experiences provide an opportunity for kids to gain independence in a supervised setting. This is an excellent option for students who are more comfortable being away from home for long periods.</p>

                                            <p><b>4. Set your budget based on the desired outcome for your child</b></p>
                                            <p>Summer camp prices vary. Some camps are expensive, some are affordable.</p>
                                            <p>Keep in mind that given the type of hardware and software required for a complete learning experience, the cost of the camp is typically reflective of all that goes into it, plus the numerous benefits your kid will get out of it.</p>
                                            <p>When you consider the lengthy list of short-term and long-term benefits, summer coding camps are mostly worth the cost. That’s if you think of it as an investment.</p>
                                            <p>In that case, most camps might be worth the cost, but that doesn’t mean you should not be frugal.</p>
                                            <p>Added benefits can bump up the final cost for a camp. So contact the camp directly to find out all of what’s included in the final price.</p>
                                            <p>• Does the camp provide lunch?</p>
                                            <p>• Does it offer early pick-up and drop-off?</p>
                                            <p>Also, look out for discounts. Many camps offer seasonal savings, multi-week and sibling discounts.</p>

                                            <p><b>5. Research, research, research</b></p>
                                            <p>To ensure you make the correct decision always research, research, research. In this information era, there are many ways to learn more about summer coding camps.</p>
                                            <p>You can read customer reviews, browse websites, discuss program details with service reps or tap into your own social group for suggestions.</p>
                                            <p>Also, nothing beats a personal recommendation – ask around and see which camps your peers sent their kids.</p>

                                            <p><b>6. Find the right camp instructors</b></p>
                                            <p>Always find out about the instructors at the camp you’re considering. The experience of camp instructors is very important since they are the personnel who will be interacting with your kid the most.</p> 

                                            <img src={BestKidsSummerCamp1} alt="best summer coding camp" />
                                            <p><b>Engagement is key to learning, particularly for kids. The best summer coding camp instructors are able to get and keep their students’ attention.</b></p> 
                                            <p>What makes a good camp instructor you might be asking?</p>
                                            <p>The ideal instructor or camp counselor should be experienced, knowledgeable, and passionate about technology and the subjects they’re teaching. But more importantly, they must be great with teaching kids and act as a mentor who will ignite and support your child’s passion for technology.</p>
                                            <p>It’s also important that kids adore their instructors. So having a lighter side, being fun and upbeat will go a far way with keeping them engaged.</p>
                                            <p>Now how do you go about doing due diligence on instructors?</p>
                                            <p>Some camps have a section on their website explaining the various qualifications they require from potential instructors. Look out for instructor profiles, or in-depth editorial material about the staff. Ensure the camp runs background checks on all instructors.</p>
                                            <p>Also, reading student and parent reviews can give you first-hand accounts of the instructors. And if that’s not enough, you can go as far calling the camps and asking for parent references so you can talk to other parents about their experiences.</p>

                                            <p><b>7. Consider the environment and setting of the Kids Summer Camp</b></p>
                                            <p>Some camps collaborate with universities to provide their programs in a collegiate environment. Summer camps that primarily host at prestigious university locations expose kids to a college setting at a young age.</p>
                                            <p>This gives them a vivid glimpse into their potential future.</p>
                                            <p>Like college students, camp attendees have classes in the university labs, eat in the dining halls, tour campus, enjoy recreational time in campus facilities and overnight campers sleep in college dorms.</p>
                                            <p>Such a setting provides a stark contrast to the experience that might take place at the typical summer camp held at an elementary school.</p>
                                            <p>Each has its pros and cons, so it’s up to you to determine which is right for your child based on the expectations you set.</p>

                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Wrap up</h3>
                                            <p>By now, if you have read from the top, you should have a good understanding of the make-up of the ideal kids summer coding camp and how to go about finding them.</p>
                                            <p>But before you register there are a few critical questions you have to ask.</p>
                                            <p>In our next blog post (<a href="https://dev.thecodewiz.com/blog/2019/03/26/questions-to-ask-before-signing-your-child-up-for-a-summer-coding-camp/">Questions to Ask when choosing the Best Kids Summer Camp for your child</a>) in our series where we educate you about kids summer coding camps, we will go in detail on all the questions to ask before choosing the best kids summer coding camp.</p>
                                            <p>If you were wondering what to look out for when choosing a Summer Coding Camp for your child, I hope this article helps in your future planning.</p>
                                            <p><i>We implore you to check out our summer coding programs where your kids can discover the world of code on vacation, preparing them for the technology-driven future.</i></p>
                                            <p><i>Send us an email (<a href="info@thecodewiz.com)">info@thecodewiz.com</a>) or give us a call (978) 431-0064. We would love to tell you more about how our programs can unlock your kid’s inner code wiz.</i></p>

                   
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
