import React from 'react';
import Header from '../../../components/header/header.component';
import PrivacyPolicy from '../../../components/privacy-policy/privacy-policy.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const PrivacyPolicyPage = () => (
  <div>
    <Header location="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
    <PrivacyPolicy />
    <MetaTags>
        <title>Code Wiz - Medford MA | Privacy Policy</title>
    </MetaTags>
  </div>
);

export default PrivacyPolicyPage;