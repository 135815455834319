import React from 'react';
import Review from '../review/review.component';

import './reviews.styles.scss';

class Reviews extends React.Component {
    constructor(){
        super();

        this.state = {
            sections: [{
                review: 'My 11 yr old attended a session at Code Wiz and declared it the best session he has attended. Ruth, the owner, is fantastic and made sure the content matched the skill level. He came home each day excited to show what he learned and created. Highly, highly recommend!!"',
                author: 'L. Whitman',
                id: 1
            },
            {
                review: "CodeWiz is a HAPPY place where kids can't wait to create, collaborate, learn, discover, and have tons of fun. My daughter literally wants to live there she loves it so much! Can't say enough about the owner and staff. Absolutely top-notch - not only do they know what they're doing, but they CARE about every kid who walks through the door and go above and beyond to tailor the experience to their needs and interests. Thankful for CodeWiz!!",
                author: 'B. Barnett',
                id: 2
            }]
        }
    }

    render() {
        return (
            <div className="reviews">
                <div className="row">
                {
                    this.state.sections.map(({id,...otherSectionProps}) => (
                        <Review key={id} {...otherSectionProps} />
                    ))
                }
                </div>
            </div>
        );
    }
}

export default Reviews;

