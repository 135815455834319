import React from 'react';
import Header from '../../../components/header/header.component';
import TutorDoctor from '../../../components/tutor-doctor/tutordoctor.component';
import MetaTags from 'react-meta-tags';

const TutorDoctorPage = () => (
  <div>
    <MetaTags>
      <title>Code Wiz - Reading MA | Tutor Doctor US & Canada</title>
    </MetaTags>
    <Header location="reading" fulllocation="reading-ma"/>
    <TutorDoctor franchisee="reading" fulllocation="reading-ma"/>
  </div>
);

export default TutorDoctorPage;