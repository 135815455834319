import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

import LocationPageImg from '../../assets/images/find-a-location-header-image.png';
import LocationPageMobileImg from '../../assets/images/find-a-location-header-image-1.jpg';

import './locations.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';
import GoogleMapComponent from '../../components/google-map/google-map.component';


class LocationsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            },
            showModal: true
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        let showData = this.state.showModal;
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={LocationPageMobileImg} />
                    <title>{`Coding Schools for Kids | Coding Courses for Kids - ${aboutdata.city} ${aboutdata.state}`}</title>
                    <meta name="description" content="Are you interested in taking a coding class or kids coding classes near you? Click here to see a list of our locations and to find a location near you!"/>
                    <meta name="keywords" content="coding for kids, kids, coding, coding camps"></meta>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="locations-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">FIND A CENTER NEAR ME</h2> 
                                    <h4 className="header-text cw-dark-blue">We make it fun and easy to learn code, wherever you are! Code Wiz offers a range of coding classes, flexible schedules, and expert coaches in convenient locations.</h4>
                                    {/* <Button color="orange" link="https://www.firstlegoleague.org/" size="medium">FIND A CENTER NEAR ME</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">    
                                <img aria-hidden="true" src={LocationPageMobileImg} alt="Location image"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper locations-wrapper">
                        <div className="container find-a-location-container">
                            <div className="row"> 
                                <div className="text abs right col-md-12">
                                    {/* <h3 className="bungee cw-dark-blue">find a code wiz center near you</h3> */}
                                    {/* <input className="location-input" type="text" placeholder="ZIP, POSTAL CODE OR CITY"/> */}
                                </div>
                            </div>
                        </div>
                        <div className="container empty-space-half bw-4"></div>
                        <div className="container empty-space bw-5"></div>
                    </div>
                    {/* <div id="map" className="map"></div> */}
                    {/* <MapContainer /> */}
                    <GoogleMapComponent />
                    <div className="location-links-container container">
                        <div className="cw-dark-blue">
                            <p></p>
                            <h3 className="bungee center">Code Wiz Locations</h3>
                            <p></p>
                             <div id="accordion" className="codewizlocations">
                                <div className="card">
                                    <div className="card-header" id="massachusettslocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseMA" aria-expanded="false" aria-controls="collapseMA">
                                            <h5 className="bungee cw-dark-blue">Massachusetts</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseMA" className="collapse" aria-labelledby="massachusettslocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Westborough, MA</h4>
                                                    <p>30 LYMAN ST. </p>
                                                    <p>WESTBOROUGH, MA 01581</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:508-521-9096">(508)-521-9096</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:westborough@thecodewiz.com">westborough@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/westborough-ma" size="small">VISIT WEBSITE</Button>
                                                </div>

                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Arlington, MA</h4>
                                                    <p>TBD ARLINGTON MA, TBD</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)205-9865</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:arlington@thecodewiz.com">arlington@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/arlington-ma" size="small">VISIT WEBSITE</Button>
                                                </div>

                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Westford, MA</h4>
                                                    <p>175 LITTLETON RD. UNIT A </p>
                                                    <p>WESTFORD, MA 01886</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:978-496-1053">(978)496-1053</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:westford@thecodewiz.com">westford@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/westford-ma" size="small">VISIT WEBSITE</Button>
                                                </div>

                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Reading, MA</h4>
                                                    <p>347 MAIN STREET </p> 
                                                    <p>READING, MA 01867</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)205-9865</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:reading@thecodewiz.com">reading@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/reading-ma" size="small">VISIT WEBSITE</Button>
                                                </div>

                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Medford, MA</h4>
                                                    <p>TBD MEDFORD, MA TBD</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:781-205-9865">(781)205-9865</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:medford@thecodewiz.com">medford@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/medford-ma" size="small">VISIT WEBSITE</Button>
                                                </div>

                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Needham, MA</h4>
                                                    <p>333 Chestnut St.</p> 
                                                    <p>NEEDHAM, MA 02492</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:339-970-5077">(339)-777-6090</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:needham@thecodewiz.com">needham@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/needham-ma" size="small">VISIT WEBSITE</Button>
                                                </div>

                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Quincy, MA</h4>
                                                    <p>275 Hancock St #206</p> 
                                                    <p>QUINCY, MA</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:617-934-3669">(617) 934-3669</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:quincy@thecodewiz.com">quincy@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/quincy-ma" size="small">VISIT WEBSITE</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newhampshirelocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseNH" aria-expanded="false" aria-controls="collapseNH">
                                            <h5 className="bungee cw-dark-blue">New Hampshire</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseNH" className="collapse" aria-labelledby="newhampshirelocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Nashua, NH</h4>
                                                    <p> 341 Amherst St, Unit 3</p>
                                                    <p> NASHUA, NH 03063</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:603-318-2580">(603)-318-2580</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:nashua@thecodewiz.com">nashua@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/nashua-nh" size="small">VISIT WEBSITE</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="georgialocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseGA" aria-expanded="false" aria-controls="collapseGA">
                                            <h5 className="bungee cw-dark-blue">Georgia</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseGA" className="collapse" aria-labelledby="georgialocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Lawrenceville, GA</h4>
                                                    <p>3320-3370 Sugarloaf Pkwy, Ste G2</p> 
                                                    <p>LAWRENCEVILLE, GA</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:470-260-1358">(470) 260-1358</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:lawrenceville@thecodewiz.com">Lawrenceville@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/lawrenceville-ga" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Mableton, GA</h4>
                                                    <p>5015 Floyd Rd SW, Suite 730</p> 
                                                    <p>MABLETON, GA</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:770 809 6704">(770) 809-6704</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:mableton@thecodewiz.com">mableton@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/mableton-ga" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="floridalocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseFL" aria-expanded="false" aria-controls="collapseFL">
                                            <h5 className="bungee cw-dark-blue">Florida</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseFL" className="collapse" aria-labelledby="floridalocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">St. Johns, FL</h4>
                                                    <p>530 State Road 13 Unit #5</p> 
                                                    <p>FRUIT COVE, FL 32259</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:904-719-7490">(904) 719-7490</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:stjohns@thecodewiz.com">stjohns@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/stjohns-fl" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">FishHawk, FL</h4>
                                                    <p>16132 Churchview Dr Suite 105</p> 
                                                    <p>LITHIA, FL</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:813-820-6583">(813) 820-6583</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:fishhawk@thecodewiz.com">fishhawk@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/fishhawk-fl" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">North St. Petersburg, FL</h4>
                                                    <p>956 62nd Avenue North</p> 
                                                    <p>ST. PETERSBURG, FL</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:(727) 677-5233">(727) 677-5233</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:northstpete@thecodewiz.com">northstpete@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/northstpete-fl" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="californialocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseCA" aria-expanded="false" aria-controls="collapseCA">
                                            <h5 className="bungee cw-dark-blue">California</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseCA" className="collapse" aria-labelledby="californialocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Tierrasanta, CA</h4>
                                                    <p>10425 Tierrasanta Blvd. Suite 110</p> 
                                                    <p>SAN DIEGO, CA 92124</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:858-264-3482">858-264-3482</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:tierrasanta@thecodewiz.com">tierrasanta@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/tierrasanta-ca" size="small">VISIT WEBSITE</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="texaslocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseTX" aria-expanded="false" aria-controls="collapseTX">
                                            <h5 className="bungee cw-dark-blue">Texas</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseTX" className="collapse" aria-labelledby="texaslocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Plano, TX</h4>
                                                    <p>6905 Coit Road Suite #104</p> 
                                                    <p>PLANO, TX 75024</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:469-824-3463">(469) 824-3463</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:plano@thecodewiz.com">plano@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/plano-tx" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Oak Forest, TX</h4>
                                                    <p>2015 W 34th St suite g </p> 
                                                    <p>HOUSTON, TX 77018</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:832-924-5987">(832) 924-5987</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:oakforest@thecodewiz.com">oakforest@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/houston-tx" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Cypress, TX</h4>
                                                    <p>16718 House & Hahl Road Suite I</p> 
                                                    <p>CYPRESS, TX</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:832-653-9079">(832) 653-9079</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:cypress@thecodewiz.com">cypress@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/cypress-tx" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Katy, TX</h4>
                                                    <p>1315 Grand Parkway S, STE 111</p> 
                                                    <p>KATY, TX</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:346-483-3736">(346) 483-3736</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:katy@thecodewiz.com">katy@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/katy-tx" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Keller, TX</h4>
                                                    <p>790 S Main St, #409</p> 
                                                    <p>KELLER, TX</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:817-380-1352">(817) 380-1352</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:keller@thecodewiz.com">keller@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/keller-tx" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Colleyville, TX</h4>
                                                    <p>3930 Glade Road, Suite 128</p> 
                                                    <p>COLLEYVILLE, TX</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:817-696-9794">(817) 696-9794</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:colleyville@thecodewiz.com">colleyville@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/colleyville-tx" size="small">VISIT WEBSITE</Button>
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Beaumont, TX</h4>
                                                    <p>229 Dowlen Rd</p> 
                                                    <p>BEAUMONT, TX 77707</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:(409) 239-0440">(409) 239-0440</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:beaumont@thecodewiz.com">beaumont@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/beaumont-tx" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="newjerseylocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseNJ" aria-expanded="false" aria-controls="collapseNJ">
                                            <h5 className="bungee cw-dark-blue">New Jersey</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseNJ" className="collapse" aria-labelledby="newjerseylocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Rutherford, NJ</h4>
                                                    <p>310 Union Avenue</p> 
                                                    <p>RUTHERFORD, NJ</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:201-231-7576">(201) 231-7576</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:rutherford@thecodewiz.com">rutherford@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/rutherford-nj" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Plainsboro, NJ</h4>
                                                    <p>10 Schalks Crossing Rd, Suite 20</p> 
                                                    <p>PLAINSBORO, NJ</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:609-853-5674">(609) 853-5674</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:plainsboro@thecodewiz.com">plainsboro@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/plainsboro-nj" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Edison, NJ</h4>
                                                    <p>976 Inman Avenue Suite 1</p> 
                                                    <p>EDISON, NJ</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:(732) 837-2899">(732) 837-2899</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:edison@thecodewiz.com">edison@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/edison-nj" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="card">
                                    <div className="card-header" id="northcarolinalocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseNC" aria-expanded="false" aria-controls="collapseNC">
                                            <h5 className="bungee cw-dark-blue">North Carolina</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseNC" className="collapse" aria-labelledby="northcarolinalocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Durham, NC</h4>
                                                    <p>7001 Fayetteville Rd suite 133</p> 
                                                    <p>DURHAM, NC 27713</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:984-234-5603">(984) 234-5603</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:durham@thecodewiz.com">durham@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/durham-nc" size="small">VISIT WEBSITE</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="oklahomalocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseOK" aria-expanded="false" aria-controls="collapseOK">
                                            <h5 className="bungee cw-dark-blue">Oklahoma</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseOK" className="collapse" aria-labelledby="oklahomalocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Owasso, OK</h4>
                                                    <p>12500 East 86th St N Suite 102</p> 
                                                    <p>OWASSO, OK</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:918-212-5940"> (918) 212-5940</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:owasso@thecodewiz.com">owasso@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/owasso-ok" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="washingtonlocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseWA" aria-expanded="false" aria-controls="collapseWA">
                                            <h5 className="bungee cw-dark-blue">Washington</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseWA" className="collapse" aria-labelledby="washingtonlocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Kirkland, WA</h4>
                                                    <p>13131 NE 85th St.</p> 
                                                    <p>KIRKLAND, WA</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:(425) 629-0897">(425) 629-0897</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:kirkland@thecodewiz.com">kirkland@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/kirkland-wa" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Template for New State */}
                                {/* <div className="card">
                                    <div className="card-header" id="Connecticutlocations">
                                        <h5 className="mb-0">
                                        <span className="codewiz-state-loc collapsed" data-toggle="collapse" data-target="#collapseCT" aria-expanded="false" aria-controls="collapseCT">
                                            <h5 className="bungee cw-dark-blue">Locations Coming Soon</h5>
                                            <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                            <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                        </span>
                                        </h5>
                                    </div>
                                    <div id="collapseCT" className="collapse" aria-labelledby="Connecticutlocations" data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row address-boxes">
                                                
                                                <div className="col-md-5 box">
                                                    <h4 className="cw-dark-blue">Kirkland, CT</h4>
                                                    <p>Coming Soon!</p> 
                                                    <p>KIRKLAND, CT</p>
                                                    <p><span><i className="fa fa-phone"></i></span> <a href="tel:">Coming Soon</a></p>
                                                    <p><span><i className="fa fa-envelope"></i></span> <a href="mailto:carmel@thecodewiz.com">carmel@thecodewiz.com</a></p>
                                                    <Button color="light-blue" link="/carmel-in" size="small">VISIT WEBSITE</Button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                
                            </div>
                        </div>

                        <br />
                        <br />
                        <h4 className="bungee cw-dark-blue">don't see a code wiz in your location? Would you like to bring one to your location? Click <a className="cw-orange" href="https://codewizfranchise.com/#contact-us-form-franchise/?codewiz-website-location-page">here</a> for more info.</h4>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}
export default LocationsPage;