import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const coupon = 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg';
const location = 'fishhawk';
const FranchiseInPersonClassesPage = () => (
    <div>
        {/* <PopupModal location={location} formType="grandopening" owner="Code Wiz FishHawk"/> */}
        <FallClassesPage franchisee="fishhawk" fulllocation="fishhawk-fl" formType="coupon" owner="Code Wiz FishHawk"/>
        <FacebookMessenger location="fishhawk" FBPageId="104403299173761" />
        <MetaTags>
            <title>Code Wiz - FishHawk FL | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;