import React from 'react';
import Header from '../../../components/header/header.component';
import ThankYou from '../../../components/thank-you/thank-you.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const ThankYouPage = () => (
  <div>
    <FacebookMessenger location="westford" FBPageId="514134288920101" />
    <Header location="westford" fulllocation="westford-ma"/>
    <ThankYou />
    <MetaTags>
        <title>Code Wiz - Westford MA | Thank You</title>
    </MetaTags>
    <Helmet>
        <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
    </Helmet>
  </div>
);

export default ThankYouPage;