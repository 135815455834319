import React from 'react';
import ProgramsPage from '../../programs/programs.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseProgramsPage = () => (
    <div>
        <ProgramsPage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | Programs</title>
        </MetaTags>
    </div>
);

export default FranchiseProgramsPage;