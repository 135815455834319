import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">January 22, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>WHAT IS COMPETITIVE CODING? AN INTRODUCTION TO PROGRAMMING COMPETITIONS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>A lot is going on to encourage kids to code. Many prominent personalities are evangelizing ‘everyone should learn how to code’ because of the realization of how much of a necessary <a href="https://dev.thecodewiz.com/how-computer-programming-classes-for-teens-shape-a-teenagers-future/">21st-century</a> skill it is for the future.</b></p>
                                            <p>Getting kids to participate in coding competitions is one of the best ways to encourage them to learn how to code. We all know how motivating prizes can be. Besides, who does not like to win and to be rewarded for it?</p>
                                            <p>Coding competitions are a great way for students to apply what they know in a fun context and create something of importance to them. By entering a coding competition, kids can get that real-world experience of coding to solve a problem or hone their coding skills.</p>
                                            <p><i>If you need to have a better understanding of how getting your kids to participate in coding competitions can significantly benefit them in the long run, you can take a look at our previous blog</i><a href="https://www.dev.thecodewiz.com/benefits-of-coding-competitions/">The Benefits of Coding Competitions</a>.</p>
                                            <p>When some people hear the term ‘competitive coding’ what usually comes to mind are images of hackers pulling all-nighters trying to build the best app or website over a weekend (hackathons). So the purpose of this blog is to introduce you to the world of competitive coding, what it is, and how you can get your child involved in it.</p>
                                            <p><i>Here is a simple guide to what competitive coding is all about so you can get a better understanding and not be like those who are confusing it with hackathons.</i></p>


                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">What is Competitive Coding?</h3>
                                            <p>Competitive programming is an intellectual sport usually held over the Internet or a local network, involving participants competing using code according to provided specifications within a restricted timeframe.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">The Competitions</h3>
                                            <p>Programming competitions generally are all about algorithmic problem solving, data structure knowledge, and implementation skills.</p>
                                            <p><b>Typical categories of tasks</b>: <i>combinatorics, number theory, graph theory, geometry, string analysis, data structures, and artificial intelligence.</i></p>
                                            <p>Contestants, who are referred to as sport programmers varying in number from tens to several thousand, are required to write solutions (in any programming language they are comfortable with) capable of solving logical or mathematical problems presented by judges.</p>
                                            <p>Judging is done online and is based mostly upon the number of problems solved and time spent on writing successful solutions, quality of output produced, program size, etc.</p>
                                            <p>Competitive programming is recognized and supported by several multinational software and Internet companies, such as Google and Facebook, who host programming competitions regularly.</p>
                                            <p>The biggest competitive coding competitions consist of the best of the best gathering in one location to compete for medals, trophies, or even cash.</p>
                                            <p>Competitive programming is most popular among high school and college students overseas but has been growing in the United States.</p>


                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">History of Competitive Programming</h3>
                                            <p>Due to the growth of the Internet, interest in programming challenges has grown extensively since the beginning of the new millennium because it facilitates coding contest sites to host international contests online.</p>
                                            <p>The oldest contest is the <a href="https://icpc.baylor.edu/">ACM-ICPC</a> (International Collegiate Programming Contest), which originated in the 1970s and has grown to span across 88 countries.</p>


                                         </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Skills Required for Competitive Programming</h3>
                                            <p><b>Important Skills</b></p>

                                            <ul>
                                            <li>Algorithm Skills</li>
                                            <li>Basic Math Skills</li>
                                            <li>Programming Skills</li>
                                            <li>Typing Speed Skills</li>
                                            <li>Debugging Skills</li>
                                            </ul>

                                            <p><b>Important Capabilities</b></p>

                                            <ul>
                                            <li>Brute force searching using the entire search space</li>
                                            <li>Beating the time limit</li>
                                            <li>Optimizing the search</li>
                                            <li>Binary search</li>
                                            <li>Dividing the coding problem into smaller parts</li>
                                            <li>Dynamic programming</li>
                                            <li>Determining problem state</li>
                                            <li>Fast and elaborate recursive backtracking</li>                                            
                                            </ul>

                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">How to Get Started</h3>
                                            <p>The prerequisite for competitive programming is a good working knowledge of your favorite programming language, and some basic data structures.</p>
                                            <p><b>Step 1: Choose a programming language</b></p>
                                            <p>It’s recommended to choose a programming language you’re most comfortable with – one you have at least have 3–4 months of experience with.</p>
                                            <p>Due to its speed, C++ is by far the most popular language of choice for competitive programmers. Most resources/solutions will probably be written in <a href="http://www.cplusplus.com/doc/tutorial/">C++</a>. But if you’re new to coding, Python might be the best choice due to its easy syntax and predefined library functions.</p>
                                            <p><b>Step 2: Learn Algorithm concepts and basic Data Structures</b></p>
                                            <p>To reach an optimal solution nearly every coding problem will require the usage of a specific data structure or algorithms. Therefore, knowing the basics of these techniques before you start is compulsory.</p>
                                            <p><b>Reading about Algorithms</b></p>
                                            <p>The <a href="http://www.usaco.org/index.php?page=contests">USACO</a> Training Pages will provide you with a structured and organized way of learning the basic algorithms you need in competitive programming.</p>
                                            <p>The training pages provide tutorials on some basic but very important algorithms, such as Greedy, DFS/BFS, binary search, dynamic programming, etc. plus a host of practice problems to hone your skills.</p>
                                            <p>Outside of USACO training pages, there’s a lot of other material out there that can be useful for learning algorithms such as the CLRS Introduction to Algorithms textbook which is a good choice. However, textbooks aren’t usually the most efficient option as there will likely be some useless information in some textbooks.</p>
                                            <p>You can also look at slides and practice problems for Stanford’s <a href="http://web.stanford.edu/class/cs97si/">CS97SI</a>: Introduction to Programming Competitions course.</p>
                                            <p>The TJHSST Lecture Archive allows you to browse through years of lectures and high-quality handouts to learn about whatever topic you want to focus on.</p>
                                            <p><b>Step 3: Learn about Time and Space complexity</b></p>
                                            <p>There’s usually more than one solution to a problem. Online judges rank the optimal solution based on its performance.</p>
                                            <p>Performance is measured through Time complexity and Space complexity.</p>
                                            <p>Time complexity refers to the execution time of all the operations in a program. All operations of a computer take constant time. Execution time also depends on external factors like the hardware but these factors are ignored by online judges.</p>
                                            <p><i>The execution time is considered in three scenarios:</i></p>

                                            <ol>
                                            <li>Best case</li>
                                            <li>Average case</li>
                                            <li>Worst case</li>
                                            </ol>

                                            <p>The worst case is considered as the execution time for judging the performance of code most of the time.</p>
                                            <p><b>Step 4: Practice makes perfect</b></p>
                                            <p>Just reading about algorithms alone will not make you a better coder. You have to practice solving problems. Practice solutions and different approaches day and night and learn from experienced coders. The more you code, the more you’ll be able to find a suitable data structure and/or algorithm for a specific problem.</p>
                                            <p><b>Step 5: Compete</b></p>
                                            <p>And the most effective way to practice is by competing. There’s no substitution for real competition and the time crunch and pressure that comes with it.</p>
                                            <p>If you’re a teenager, ACM-ICPC is the premier programming competition for college students where they compete in teams of three to solve ten difficult problems in five hours.</p>
                                            <p>Regional contests are held once a year in the fall, and winners of those are invited to the World Finals in May to compete against other top universities from around the world.</p>
                                            <p>A great way to improve your skills when learning to code is by solving different types of coding challenges and puzzles.</p>
                                            <p>Codeforces is a pretty good choice if you want to compete regularly. It offers two-hour online contests once or twice every week. It has a great interface and you’ll get access to a huge collection of solutions to problems from their past competitions in case you get stuck.</p>
                                            <p>TopCoder also has a large collection of problems along with USACO Training Pages and USACO Past Contests. HackerRank and CodeChef also have periodic online contests.</p>
                                            <p>Google Code Jam and Facebook Hacker Cup are two of the largest sponsor programming competitions held yearly.</p>

                                        </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Wrap up</h3>
                                            <p>The key to maximizing the benefits of competitive programming is to be clear about your goals. There is no shortage of opportunities to learn and to participate in competitive programming. There are various kinds of programming contests for different tastes and interests.</p>
                                            <p>Competitive coding may not open the doors to the professional world to you, but it can help you to improve your logical and analytical skills.</p>
                                            <p>Plus programming competitions are a fun activity, so at worst, you should have fun if you’re into coding.</p>


                                         </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
