import React from 'react';
import Header from '../../../components/header/header.component';
import PrivacyPolicy from '../../../components/privacy-policy/privacy-policy.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const PrivacyPolicyPage = () => (
  <div>
    <Header location="rutherford" fulllocation="rutherford-nj"/>
    <FacebookMessenger location="rutherford" FBPageId="122093802086017502" />
    <PrivacyPolicy />
    <MetaTags>
        <title>Code Wiz - Rutherford NJ | Privacy Policy</title>
    </MetaTags>
    <Helmet>
        <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
    </Helmet>
  </div>
);

export default PrivacyPolicyPage;