import React from 'react';
import Header from '../components/header/header.component';
import TermsAndConditions from '../components/terms-and-conditions/terms-and-conditions';
import MetaTags from 'react-meta-tags';

const TermsAndConditionsPage = () => (
  <div>
    <MetaTags>
        <title>Code Wiz | Terms & Conditions</title>
    </MetaTags>
    <Header />
    <TermsAndConditions />
  </div>
);

export default TermsAndConditionsPage;