import React from 'react';
import FAQPage from '../../faqs/faqs';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFaqsPage = () => (
    <div>
        <FAQPage franchisee="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <MetaTags>
            <title>Code Wiz - Medford MA | FAQs</title>
        </MetaTags>    
    </div>
);

export default FranchiseFaqsPage;