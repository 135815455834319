import React from 'react';
import MetaTags from 'react-meta-tags';

//import Directory from '../../components/directory/directory.component';
//import MACampPageImg from '../../assets/images/Group-4.png';
import MACampPageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
import HomepageBodyWrapper from '../../assets/images/Group-14.webp';
//import HomepageHeaderImage from '../../assets/images/codewiz-homepage.png';
//import HomepageHeaderImage from '../../assets/images/Group-4.png';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
import HomepageHeaderImage from '../../assets/images/ma-camps-home.png';
import HomepageProjectDrivenMobile from '../../assets/images/Group-6.png';
import HomepageScriptForSuccess from '../../assets/images/homepage-yasmine.webp';
import RewardsImg from '../../assets/images/Ellipse-orange.png';
import ProgressImg from '../../assets/images/Ellipse-green.png';
import CommunityImg from '../../assets/images/Ellipse-light-blue.png';
import FreeClassImg from '../../assets/images/Ellipse-purple.png';
import MontesorriImg from '../../assets/images/Ellipse-yellow.png';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';

import SimpleSlider from '../../components/slick-slider/simple-slider.component';
import Programs from '../../components/programs/programs.component';
import Header from '../../components/header/header.component';
import Swirls from '../../assets/images/headerSwirl.png';
import './ma-camps.styles.scss';
import Button from '../../components/button/button.component'; 

let floatingdata =[]; 
let defaultdata = {
    phone: "1(844) 263-3949",
    email: "info@thecodewiz.com",
    franchise: "yes",
    locations: "/locations",
    westfordICP: "https://app.iclasspro.com/portal/codewiz/booking",
    readingICP: "https://app.iclasspro.com/portal/codewizreading/booking",
    needhamICP: "https://app.iclasspro.com/portal/codewizneedham/booking",
    quincyICP: "https://app.iclasspro.com/portal/codewizquincy/booking",
    westboroughICP: "https://app.iclasspro.com/portal/codewizwestborough/booking",
    videoLink: "https://player.vimeo.com/video/900221521?h=90926f60ac&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479",
    masummercamp: "https://getform.io/f/32b4d8d3-089b-4e6a-a2a4-98c5cbf18029"

}

let locationemail = "";
let zoho = "";
let location = "";

floatingdata.push(<FloatingMenu contactlink={defaultdata.email} franchise={defaultdata.franchise} phone={defaultdata.phone} location={defaultdata.locations}/>);
const MACampPage = () => (
    <div>
        <Header />
        <MetaTags>
            <meta name="description" content="Coding and Robotics for Children" />
            <meta name="title" content="Coding and Robotics for Children" />
            <meta name="image" content="../../assets/images/codewiz-thumbnail.jpg" />
            <meta property="og:image" content={HomepageHeaderImage} />
            <title>Code Wiz | Home</title>
        </MetaTags>
        <div className="homepage-content ma-camp-homepage-content">
            <div className="container header-text-container">
                <div className="row">
                    <div className="top-heade col-sm-12 col-md-12 col-lg-6">
                        <div className="text">
                            <h2 className="header-title cw-dark-blue bungee">Unlock your child's inner genius this summer with </h2> 
                            <h3 className="header-text cw-orange bungee">Coding and Robotics camps</h3>
                            {/* <h5 className='cw-dark-blue'></h5> */}
                            {/* <Button color="orange" link={defaultdata.locations} size="medium">FIND A CENTER NEAR ME</Button> */}
                        </div>
                    </div>
                    {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                    <div className="top-heade col-sm-12 col-md-12 col-lg-6 center">
                        <h2 className="center summer-camp-video"><iframe src={defaultdata.videoLink} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></h2>
                    </div>
                </div>
                {/* <img src={MACampPageImg} className="header-image hidden-sm"/> */}
            </div>
            <div className='benefits-of-summer'>
                <div className='container'>
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className='bungee cw-dark-blue center'>Benefits of leanring to Code with Code Wiz this summer!</h3>
                        </div>
                        <div className='col-lg- 6 col-md-6 col-sm-12'>
                            <div className='col-lg-12'>
                                <h5 className='cw-dark-blue'><span className='bungee'>1. </span>MONTESORRI STYLE APPROACH</h5>
                                <p>No two kiddos are the same and their learning paths shouldn’t be. Whether they’re interested in games or games and robotics or games and robotics and AI, we’ve got something for them. We offer student-driven, personalized learning paths for beginners and advanced students </p>
                            </div>
                            <div className='col-lg-12'>
                                <h5 className='cw-dark-blue'><span className='bungee'>2. </span>PROJECT-BASED LEARNING</h5>
                                <p>Children are full of ideas! Our project-based approach allows them to learn how to code while bringing their ideas to life!</p>
                            </div>
                            <div className='col-lg-12'>
                                <h5 className='cw-dark-blue'><span className='bungee'>3. </span>WE MAKE LEARNING FUN</h5>
                                <p>We mix the veggies in the fruit smoothie. While they are learning so much, they don’t realize it because they’re having so much fun. </p>
                            </div>
                            <div className='col-lg-12'>
                                <h5 className='cw-dark-blue'><span className='bungee'>4. </span>UNLOCK YOUR CHILD'S INNER GENIUS</h5>
                                <p>Our founder’s life changed for the better when she learned how to code and together we are dedicated to helping kids in our communities unlock their inner genius through coding and robotics.</p>
                            </div>
                        </div>
                        <div className='col-lg- 6 col-md-6 col-sm-12 ma-camp-form'>
                            <form id="cw-contact-form" className="contact-form cw-dark-blue" action={defaultdata.masummercamp} method="POST">
                                <h4 className='bungee cw-orange center'>Contact Us to learn more!</h4>
                                <p className="cw-dark-blue">Required fields are indicated with "*".</p>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                                        <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="required-label" htmlFor="inputLast">Last Name *</label>
                                        <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label className="required-label" htmlFor="inputEmail">Email *</label>
                                        <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label className="required-label" htmlFor="inputPhone">Phone *</label>
                                        <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
                                    </div>
                                </div>
                                <div className="form-row">
                                    {/* <div className="form-group col-md-6">
                                        <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                                        <select name="mode" className="form-control" id="inputMode" required>
                                            <option value="inperson">In-Person Classes</option>
                                        </select>
                                    </div> */}
                                    <div className="form-group col-md-6">
                                        <label className="required-label" htmlFor="inputMode">What Location Are You Interested In? *</label>
                                        <select name="location" className="form-control" id="location" required>
                                            <option value="">Select a location</option>
                                            <option value="needham">Needham, MA</option>
                                            <option value="quincy">Quincy, MA</option>
                                            <option value="reading">Reading, MA</option>
                                            <option value="westborough">Westborough, MA</option>
                                            <option value="westford">Westford, MA</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-6">
                                        {/* <input type="hidden" id="location" name="location" value={this.state.location}></input> */}
                                        {/* <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                                        <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input> */}
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="form-check">
                                        <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                                        <label className="form-check-label tiny-print" htmlFor="gridCheck">
                                        {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                                        <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                                        <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                                        </label>
                                    </div>
                                </div>
                                {/* <button type="submit">Send</button> */}
                                
                                <button type="submit" className="btn orange-button" target="_blank">SEND</button>
                            </form>
                        </div>

                    </div>
                </div>
            </div>

            <div className='ma-summer-camp-offers'>
                <div className='container'>
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className='bungee cw-dark-blue center'>We are offering camps in: </h3>
                        </div>
                        <div className='col-md-12'>
                            <div className='row ma-camp-offers'>
                                <div className='col-sm-12 col-md-6 col-lg-4'>
                                    <div className='orange-bkg offer-box'>
                                        <h4 className='bungee'>Game Development</h4>                             
                                        <b><p> &#8658; Roblox<br />
                                        &#8658; Minecraft Coding<br />
                                        &#8658; 2D/3D Game Development in Unity<br />
                                        &#8658; Virtual Reality in Unity</p></b>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-4 mid-margin'>
                                    <div className='purple-bkg offer-box'>
                                        <h4 className='bungee'>Foundational Coding</h4>
                                        <b><p>&#8658; Coding with Scratch<br/>
                                        &#8658; Intro to Advanced Python<br/>
                                        &#8658; Intro to Advanced Java</p></b>
                                    </div>
                                </div>
                                <div className='col-md-3 col-md-show box-top-margin'></div>
                                <div className='col-sm-12 col-md-6 col-lg-4 box-top-margin'>
                                    <div className='green-bkg offer-box'>
                                        <h4 className='bungee'>Physical Computing</h4>
                                        <b><p>&#8658; LEGO Robotics<br/>
                                        &#8658; 3D Priting<br/>
                                        &#8658; Circuit Genius - Invention with Makey Makey</p></b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <div className='ma-summer-camp-locations'>
                <div className='container'>
                    <div className="row">
                        <div className='col-md-12'>
                            <h3 className='bungee cw-dark-blue center'>Offered in five different code wiz locations across massachusetts </h3>
                        </div>
                        <div className='col-md-12'>
                            <div className='row ma-camp-location'>                    
                                <div className='col-sm-12 col-md-6 col-lg-4 location'>
                                    <div className='green-outline camp-location-box'>
                                        <h4 className='bungee cw-dark-blue'>Code Wiz Needham</h4>
                                        <p className='cw-dark-blue'><a href='https://maps.app.goo.gl/ZvjFRsVKamUGVqCq7'>333 Chestnut Street <br/>
                                        Needham, MA 02492</a><br/>
                                        <a href='tel:339-777-6090'>339-777-6090</a><br/>
                                        <a href='mailto:needham@thecodewiz.com'>needham@thecodewiz.com</a></p>
                                        <Button color="blue" link={defaultdata.needhamICP} size="medium">VIEW CAMPS</Button>
                                    </div>
                                </div>
                                {/* <div className='col-md-12 col-lg-1'></div> */}
                                <div className='col-sm-12 col-md-6 col-lg-4 location'>
                                    <div className='blue-outline camp-location-box'>
                                        <h4 className='bungee cw-dark-blue'>Code Wiz Quincy</h4>
                                        <p className='cw-dark-blue'><a href='https://maps.app.goo.gl/nVp6aq36jbhKrNdi8'>275 Hancock Street #206 <br/>
                                        Quincy, MA 02171</a><br/>
                                        <a href='tel:617-934-3669'>617-934-3669</a><br/>
                                        <a href='mailto:quincy@thecodewiz.com'>quincy@thecodewiz.com</a></p>
                                        <Button color="blue" link={defaultdata.quincyICP} size="medium">VIEW CAMPS</Button>
                                    </div>
                                </div><div className='col-sm-12 col-md-6 col-lg-4 location'>
                                    <div className='purple-outline camp-location-box'>
                                        <h4 className='bungee cw-dark-blue'>Code Wiz Reading</h4>
                                        <p className='cw-dark-blue'><a href='https://maps.app.goo.gl/voeYuvjhb2P1HN9r9'>347 Main Street <br/>
                                        Reading, MA 01867</a><br/>
                                        <a href='tel:781-205-9865'>781-205-9865</a><br/>
                                        <a href='mailto:reading@thecodewiz.com'>reading@thecodewiz.com</a></p>
                                        <Button color="blue" link={defaultdata.readingICP} size="medium">VIEW CAMPS</Button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-4 location'>
                                    <div className='yellow-outline camp-location-box'>
                                        <h4 className='bungee cw-dark-blue'>Code Wiz Westborough</h4>
                                        <p className='cw-dark-blue'><a href='https://maps.app.goo.gl/utKuhJckS5o1HdJM6'>30 Lyman Street<br/>
                                        Westborough, MA 01581</a><br/>
                                        <a href='tel:508-521-9096'>508-521-9096</a><br/>
                                        <a href='mailto:westborough@thecodewiz.com'>westborough@thecodewiz.com</a></p>
                                        <Button color="blue" link={defaultdata.westboroughICP} size="medium">VIEW CAMPS</Button>
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-6 col-lg-4 location'>
                                    <div className='orange-outline camp-location-box'>
                                        <h4 className='bungee cw-dark-blue'>Code Wiz Westford</h4>
                                        <p className='cw-dark-blue'><a href='https://maps.app.goo.gl/FmokgXVQBucWa1SQ9'>175 Littleton Road Unit A <br/>
                                        Westford, MA 01886</a><br/>
                                        <a href='tel:978-496-1053'>978-496-1053</a><br/>
                                        <a href='mailto:westford@thecodewiz.com'>westford@thecodewiz.com</a></p>
                                        <Button color="blue" link={defaultdata.westfordICP} size="medium">VIEW CAMPS</Button>
                                    </div>
                                </div>
                                <div className='col-md-12 col-lg-1'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="body-wrapper">
                
            </div> */}
            
            {/* <div className="container">
                <div className="container empty-space-half"></div>
                <h3 className="bungee center cw-dark-blue focused-programs">Focused Programs. infinite potential.</h3>
            </div>
    
            <Programs /> */}
        </div>
        {floatingdata}
    </div>
);

export default MACampPage;