import React from 'react';
import TechnovationPage from '../../technovation/technovation';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseTechnovationPage = () => (
    <div>
        <TechnovationPage franchisee="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
    </div>
);

export default FranchiseTechnovationPage;