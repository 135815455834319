import React from 'react';
import FindACoursePage from '../../find-a-course/find-a-course.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFindACoursePage = () => (
    <div>
        <FindACoursePage franchisee="westborough" fulllocation="westborough-ma"/>
        <FacebookMessenger location="westborough" FBPageId="104621587747524" />
        <MetaTags>
            <title>Code Wiz - Westborough MA | Find A Course</title>
        </MetaTags>
    </div>
);

export default FranchiseFindACoursePage;