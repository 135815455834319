import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import TechLogo1Img from '../../assets/images/tech_logo1.jpg';
import TechLogo2Img from '../../assets/images/tech_logo2.jpg';
import TechLogo3Img from '../../assets/images/tech_logo3.jpg';
import TechLogo4Img from '../../assets/images/tech_logo4.jpg';
import TechLogo5Img from '../../assets/images/tech_logo5.jpg';
import TechLogo6Img from '../../assets/images/tech_logo6.jpg';
import TechLogo7Img from '../../assets/images/tech_logo7.jpg';
import TechLogo8Img from '../../assets/images/tech_logo8.jpg';
import TechLogo9Img from '../../assets/images/tech_logo9.jpg';
import TechLogo10Img from '../../assets/images/tech_logo10.jpg';

//import TeamMember from '../team-member/team-member.component';
 
export default function OnlineCodingSlider(props) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };
  return (
    <Slider {...settings}>
      <div><img src={TechLogo1Img} /></div>
      <div><img src={TechLogo2Img} /></div>	
      <div><img src={TechLogo3Img} /></div>
      <div><img src={TechLogo4Img} /></div>
      <div><img src={TechLogo5Img} /></div>
      <div><img src={TechLogo6Img} /></div>
      <div><img src={TechLogo7Img} /></div>	
      <div><img src={TechLogo8Img} /></div>
      <div><img src={TechLogo9Img} /></div>
      <div><img src={TechLogo10Img} /></div>	
    </Slider>
  );
}