import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BenefitsOfCoding from '../assets/images/blog-pictures/benefits-of-coding.jpg';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">January 18, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>THE BENEFITS OF CODING COMPETITIONS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>Coding competitions have become more and more popular in recent years. Competitive programming websites like <a href="https://www.topcoder.com/">TopCoder</a>,<a href="https://www.hackerrank.com/">HackerRank</a>, etc. have built great platforms that attract lots of competitive programmers of various ages. Participating in programming competitions can be fun, interesting, but more importantly, rewarding.</b></p>
                                            <p>Some critics believe that practicing competitive programming could lead to bad habits because it requires an entirely different approach to building real-world products. Despite the fact that there is some truth to this school of thought, coding competitions provide much more pros than it does cons.</p>
                                            <p>The real-world impact of coding competitions shows kids and teenagers what computing can achieve beyond the classroom and helps them see the power of open collaboration. </p>
                                            <p><i>That is just one of the many benefits Competitive Coding provides.</i></p>


                                        </div>


                                        <div className="blogs_details_block">
                                            <h3 className="bungee">1. Enhances Problem-solving Skills</h3>
                                            <p>Competitive Programming is a brain game that tests a programmer’s problem-solving skills. By solving progressively hard problems in live-contests, a participant’s analytical and rational thinking intensifies.</p>
                                            <p>Based on the time limit, Competitive Programming teaches contestants to find the easiest solution in the quickest possible way, enhancing their problem-solving and debugging skills.</p>
                                            <p>What makes this benefit so vital is that good problem-solving skills can empower children to not only become better professionals in the future but also better individuals in general. Problem-solving is a basic life skill and is essential to our day-to-day lives.</p>

                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className="bungee">2. It’s Guaranteed Brain Exercise</h3>
                                            <p>Competitive Programming is like a brain training app, but for real skills. By practicing to solve problems regularly, programmers can ensure that the coding part of their brain receives a regular workout.</p>
                                            <p>Solving challenging problems in a fast-paced environment is definitely a great exercise to boost gray cells. Whether it’s competing in a live contest or practicing during leisure time, participants are sure to utilize a lot of your brainpower.</p>
                                            <p>Even if coders end up in a job that doesn’t necessarily require the skills competitive coding provides, practicing competitive programming on the side is a great way to constantly challenge their thinking skills and exercising those gray cells.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee"> 3. It’s a way to Practice Fast Coding</h3>
                                            <p>Contest programmers are usually faster coders. Solving challenging problems in a short period of time will get any programmer accustomed to strict deadlines, which is usually an advantage in the professional work pace.</p>
                                            <p>In the professional world, development work deadlines are usually measured in weeks or months, while in competitive programming, the deadlines are measured in minutes. Contestants must literally have language syntax and algorithm knowledge at their fingertips as there isn’t time during any contest to search the web for answers.</p>
                                            <p>Assuming a programmer can produce quality code, there is a benefit to being able to code quickly as they are seen as more valuable.</p>


                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">4. More Attractive to Major Companies</h3>
                                            <p>Contest programmers develop many skills that are key to software engineering. The best software companies still value candidates based on their technical aptitude. Some consider technical aptitude a good proxy for general smartness and include it as part of their screening process.</p>
                                            <p>A programmer might not get chosen explicitly based on their performance in competitive programming competitions or on portals like TopCoder or CodeChef by a firm. But it’s still a great addition to their CV and will prepare them well for technical interviews.</p>

                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">5. Provides a Significant Technical Edge during Job Interviews</h3>
                                            <p>If shortlisted for a big computer programming related job, candidates are bound to face challenging algorithm problems in one or more interview rounds. Contest programmers will have a substantial advantage as the standard coding interview has much in common with a programming competition. </p>

                                            <img src={BenefitsOfCoding} alt="benefits of coding" />

                                            <p>Though they’re a few differences, they both require solving a clearly-defined programming puzzle, reward quick solutions and tend to focus on well-known algorithms rather than domain-specific technologies.</p>
                                            <p>Most general programmers dread the coding aspect of an interview, while experienced competitive programmers tend to find it rather straightforward as competition problems are usually harder to solve.</p>
                                            <p>Some believe that the skills developed from competitive coding are overkill for typical software development jobs.</p>
                                            <p>But what separates the great programmers from the mediocre ones isn’t their ability to do the 80% that’s straightforward better, but their unique ability to solve the 20% (or less) of the difficult and unusual tasks that regular programmers aren’t able to. Almost all developers occasionally run into situations where their baseline skills aren’t sufficient to solve a problem.</p>
                                            <p>According to technical interview expert Gayle Laakmann McDowell, this is one reason companies use algorithms questions when hiring for positions that don’t require it.</p>


                                        </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">6. It’s a way to Focus on the Fundamentals</h3>
                                            <p>Expert coders typically never lose sight of the fundamentals no matter how advanced they become. In many ways, understanding the fundamentals better than everybody else is the main reason they are actually more advanced.</p>
                                            <p>Since traditional programming competitions use problems that require math and algorithms skills, practicing competitive programming is a way for programmers to maintain a focus on computer science fundamentals.</p>


                                         </div>

                                            <div className="blogs_details_block">
                                            <h3 className="bungee">7. Boosts Programmers to write Cleaner and Better Code</h3>
                                            <p>Clean code is critical in the professional world as it is perceived as what makes or breaks a project. Many coding contest websites allow contestants to review the solution to problems of their competitors post-competition.</p>
                                            <p>Most top online platforms like GitHub allow viewing of red coders/highly-rated programmers codes. This is a good opportunity for coders to see how other coders approached the same problems because, in the real world, it’s rare that two of them will each implement the same solution to an identical problem.</p>
                                            <p>Competitive coding helps kids learn how to write code, which data structure to use and how to simplify the decision making statements in coding.</p>


                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">8. Willingness to Accept “Failure” Increases</h3>
                                            <p>Competitive Programming instructs to focus on the Process instead of the Outcome.</p>
                                            <p>The nature of computer programming competitions is all about failing and trying again, solving problems, then optimizing it. There’s no easy route. It teaches programmers to fight until the problem is solved thus your child will develop tenacity and determination.</p>


                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">9. It’s a way to Publicly Demonstrate Programming Skills</h3>
                                            <p>Most programmers don’t have anything tangible to verify some of the claims on their resume, especially if they’re fresh out of college or seeking internships as a high school student.</p>
                                            <p>Results from online coding competitions show where a programmer stacks up compared to their peers. Competitive programming communities have leaderboards that list competitors’ performance in contests. </p>
                                            <p>So competing in programming competition can provide a significant advantage for programmers because a good ranking demonstrates the ability to solve problems under pressure.</p>


                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">10. Competitive Programming has an Active Community</h3>
                                            <p>Online coding competitions have an active community on the major coding challenge websites. Plus it’s easy for competitive coding beginners to get questions answered on Quora. Having a community to hang out with and provide assistance can be of huge encouragement.</p>
                                            <p>This also provides kids and teenagers an opportunity to improve their network of friends, who are also passionate about programming. By engaging in this community they’ll be exposed to an elite group of coders.</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">11. You’ll really Learn your Chosen Language</h3>
                                            <p>It’s common knowledge that the best way to master human languages is immersion – once you know the bare minimum, put yourself in a situation where you have no choice but to use the language such as hanging out with the locals in a country where the language is spoken.</p>
                                            <p>The best way to test knowledge of a programming language is to use it in a coding contest. There won’t be any time to consult a phrasebook or dictionary. So the coder will find out soon enough if they’re actually fluent.</p>
                                            <p>If not, then coding competitions will push them to get there, whether by learning the syntax for common library methods or just writing a lot more code.</p>


                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">12. Convenient for Deliberate Practice</h3>
                                            <p>Most deliberate practice begins with the tools of the trade and a source of practice material. Like basketball players need a basketball, a hoop, and a court, speakers need a speech sheet, a stage or a teleprompter, computer programmers need a computer and a description of what to build.</p>
                                            <p>Online programming contests are a convenient source of practice material. They provide problems, test cases, and an active community. The practitioner can focus on building good study habits and refining their learning process. Pick a problem, solve it, reflect on how your process worked, then repeat the process.</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">13. Encourages Participants to be a Team Player</h3>
                                            <p>Many coding competitions require team participation. Participants will learn how to work in a team. They’ll learn how to develop a team mentality and show genuine commitment by contributing to a team’s effort. It encourages them to think “WE,” instead of “ME.”</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">14. Increase Focus</h3>
                                            <p>Based on the intricate and abbreviated nature of competitive programming, it takes intense focus to excel at it. As a by-product of competing and strict, continual training, competitive programmers usually develop the ability to focus better and longer.</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">15. Great at Debugging</h3>
                                            <p>Debugging is a crucial aspect of contest programming so contest programmers are usually great debuggers. Most regular programmers are mostly satisfied with a 99% working solution. But for contest programmers, it’s more binary – it either works correctly all the time, or it doesn’t work at all.</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">16. Chance to win Prizes and Money</h3>
                                            <p>Winning prizes is not the main goal for the majority of programming contestants. The main purpose of entering competitive programming competitions is to improve their fundamental programming skills and a chance to work on the most interesting projects.</p>
                                            <p>However, a little fruit as a reward for the labor won’t hurt. Experienced competitors can make a bit of money by winning contests on top platforms such as TopCoder.</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">17. It’s Fun</h3>
                                            <p>Just because coding is mostly tedious, curious and serious doesn’t mean it can’t also be fun. Participating in coding competitions doesn’t have to only be about developing hard skills and career progression. It can also be a great stress reliever, a source of entertainment and a constructive hobby.</p>

                                         </div>

                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Wrap Up </h3>
                                            <p>Coding competitions are a great way for young coders to apply what they know in a fun context and improve their analytical and problem-solving skills. By entering coding competitions, kids can get that real-world experience of coding to solve a problem or hone their coding skills.</p>
                                            <p>We at Codewiz believe that competitive coding can also be used to help encourage the next generation of coders to combine skills, collaboration, and creativity to produce things that can make a real difference in the world.</p>
                                            <p>That’s why we started our own coding contest <b><i>“Code to make a difference.”</i></b> You can find out more about it <b>here</b> and about <a href="https://dev.thecodewiz.com/technovation-challenge/">other coding for kids competitions</a> we facilitate.</p>


                                         </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
