import React from 'react';
import firebase from '../Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import Button from '../button/button.component';
import FloatingMenu from '../floating-menu/floating-menu.component';
import CodingClassesCAHeaderImg from '../../assets/images/FallSession.png';
//import LearnToCodeHeaderImg from '../../assets/images/learn_to_code.png';
import ScreencastImg from '../../assets/images/screencast.jpg';

import OnlineCodingSlider from '../../components/slick-slider/online-coding-slider.component';
import OnlineCodingMobileSlider from '../../components/slick-slider/online-coding-slider-mobile.component';

import CodeWizLogoImg from '../../assets/images/codewiz-logo-512.png';
import TutorDoctorImg from '../../assets/images/tutor-doctor-logo.png';

import IcoDegreeImg from '../../assets/images/ico_degree.png';
import IcoLaptopInterfaceImg from '../../assets/images/ico_laptop_interface.png';
import SessionIcoImg from '../../assets/images/session_ico2.png';
import IcoCalendarImg from '../../assets/images/ico_Calendar.png';
import IcoCalendarAlertImg from '../../assets/images/ico_calendar_alert.png';
import IcoListOptionImg from '../../assets/images/ico_list_option.png';
import IcoMedalImg from '../../assets/images/ico_medal.png';
import IcoProgress2Img from '../../assets/images/ico_progress2.png';

import AbbrFaqs from '../../components/abbr-faqs/abbr-faqs.component';




import './tutor-doctor.styles.scss';


class TutorDoctorUK extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            tutordoctorsignup: "https://app.iclasspro.com/portal/codewiztutordoctor/camps/16",
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations",
                tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
                state: "",
                city: "",
                territory: ""
            }
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            this.state.isfranchisee = true;
        } 
        if(this.state.isfranchisee){
            aboutdata = this.state.data;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div className="special-page online-coding tutor-doctor" id="">
                <MetaTags>
                    <title>Code Wiz | Tutor Doctor UK - Coding For Kids</title>
                    <meta name="description" content="Code Wiz has partnered with Tutor Doctor to provide coding and robotics classes to Tutor Doctor families in the United Kingdom"/>
                    <meta name="keywords" content="coding for kids tutor doctor, coding for kids, kids, coding, coding camps"></meta>
                </MetaTags> 
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">THIS SUMMER, GET AHEAD</h2>
                                <h4 className="sub_title">And stay ahead by learning to code!</h4>   
                                <Button color="orange" link={this.state.tutordoctorsignup} size="x-large">REGISTER WITH YOUR TUTOR DOCTOR CODE</Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={CodingClassesCAHeaderImg} alt="coding classes canada"/>
                        </div>
                    </div>
                </div>
                <div className="codewiz-tutordoctor-logo">
                    <div className="logo-images"><img src={CodeWizLogoImg} alt="code wiz logo"/></div>
                    <div className="logo-images"><img src={TutorDoctorImg} alt="tutor doctor logo"/></div>
                </div>
                <div className="blue_section online_coding_camps_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="online_coding_desciption">
                                    
                                    <h1 className="center bungee">United Kingdom</h1>
                                    <p className="white_txt">Structured, personalized and fun learning your kids will love. A chance to collaborate with other students, build your own apps, challenge your skills and test the latest technology. A safe place for kids to learn and make friends and memories for life</p>
                                    <h3 className="bungee white_txt center">All skill levels welcomed</h3>
                                    <h4 className="bungee orange_txt center">Ages 7-9, 10-12 and 13+</h4>
                                </div>
                                
                                <div className="online_coding_tech_slider">
                                    <div className="slider technologies_slider desktop-slider">
                                        <OnlineCodingSlider />
                                    </div>
                                    <div className="slider technologies_slider mobile-slider">
                                        <OnlineCodingMobileSlider />
                                    </div>
                                </div>
                                <div className="online_coding_structure">
                                    <p>You’ll show off your Minecraft skills, collaborate with other students, challenge your learning with the latest technology, Minecraft, Roblox, Scratch, Python, Java, Apps for Amazon, and Alexa and more. Daily problem solving, socializing, and fun with lots of course options to choose from to build solid skills for the future.</p>	
                                    <div className="titles">
                                        <h2 className="bungee">Start your learning today, safe and fun all from home!</h2>
                                    </div>					
                                </div>
                                <div className="online_coding_features">
                                    <h2 className="bungee">All camp times are in EST <br/>and billed in USD</h2>
                                    <ul className="col4">
                                        <li>
                                            <img src={IcoDegreeImg} />
                                            <p>All skill levels welcomed! Personalized learning forages 7-9, 10-12 and 13+</p>
                                        </li>
                                        <li>
                                            <img src={IcoLaptopInterfaceImg} />
                                            <p>Morning: (10AM – 12PM), Afternoon: Options (1PM – 3PM) Evening: Options (4PM – 6PM)</p>
                                        </li>
                                        <li>
                                            <img src={SessionIcoImg} />
                                            <p>Affordable at USD $230/week (after TutorDoctor Discount)</p>
                                        </li>
                                        <li>
                                            <img src={IcoCalendarImg} />
                                            <p>Expert, Computer Science trained staff</p>
                                        </li>
                                        <li>
                                            <img src={IcoCalendarAlertImg} />
                                            <p>Small Student : Coach Ratio with tons of interaction</p>
                                        </li>
                                        <li>
                                            <img src={IcoListOptionImg} />
                                            <p>Safe and Secure online environment</p>
                                        </li>
                                        <li>
                                            <img src={IcoMedalImg} />
                                            <p>Tons of course options to choose from for all ages and skills</p>
                                        </li>
                                        <li>
                                            <img src={IcoProgress2Img} />
                                            <p>Lots of socialization and collaboration opportunities</p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="sessions_detail" style={{marginTop: 50}}>
                                    <div className="text">
                                        <h2 className="bungee">Code Wiz Alignment with</h2>
                                        <h4>K – 12 Computer Science Framework</h4>
                                        <p>Code Wiz aligns its curriculum with the K–12 Computer Science Framework represents a vision in which all students engage in the concepts and practices of computer science.</p>
                                        <p>“Beginning in the earliest grades and continuing through 12th grade, students will develop a foundation of computer science knowledge and learn new approaches to problem solving that harness the power of computational thinking to become both users and creators of computing technology. By applying computer science as a tool for learning and expression in a variety of disciplines and interests, students will actively participate in a world that is increasingly influenced by technology”</p>
                                        <p>The framework promotes a vision in which all students critically engage in computer science issues; approach problems in innovative ways; and create computational artifacts with a practical, personal, or societal intent.</p>
                                    
                                        <Button color="orange" link={this.state.tutordoctorsignup} size="x-large">REGISTER WITH YOUR TUTOR DOCTOR CODE</Button>
                                    </div> 
                                    <AbbrFaqs />
                                </div>                             
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="online_coding_camp_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">			
                                <h2 className="bungee">FREQUENTLY ASKED QUESTIONS</h2>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqOne">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseOne" aria-expanded="true" aria-controls="codingClassesCollapseOne">
                                                <h5 className="bungee cw-dark-blue"> What kind of equipment does my child need to participate? </h5>   
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseOne" className="collapse show" aria-labelledby="codingClassesFaqOne" data-parent="#accordion">
                                            <div className="card-body">
                                                • A modern PC or Mac computer with at least 4GB of RAM and 256MB processor speed. Tablets are not supported for online class/camp use.<br/>
                                                • Minecraft, Roblox and Unity classes PCs or MACs. Chromebooks will not suffice<br/>
                                                • A USB headset<br/>
                                                • A web camera<br/>
                                                • High speed internet  
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqTwo">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseTwo" aria-expanded="true" aria-controls="codingClassesCollapseTwo">
                                                <h5 className="bungee cw-dark-blue"> How do you ensure children are actually learning online? </h5> 
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseTwo" className="collapse" aria-labelledby="codingClassesFaqTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                Our staff has been trained extensively in how to use Zoom to manage a classroom remotely. The classroom participants will meet limits and restrictions for features like chat, file sharing, and even speaking out of turn. Instructors have the ability to mute or remove disruptive students, and the entire session is recorded by default for accountability purposes.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqThree">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseThree" aria-expanded="true" aria-controls="codingClassesCollapseThree">
                                                <h5 className="bungee cw-dark-blue">What ages is virtual coding classes designed for? </h5>  
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseThree" className="collapse" aria-labelledby="codingClassesFaqThree" data-parent="#accordion">
                                            <div className="card-body">
                                                All of our online classes are geared at children with a basic reading proficiency. They need to be able to read the instructions on the screen independently to fully participate in our online learning courses.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqFour">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseFour" aria-expanded="true" aria-controls="codingClassesCollapseFour"> 
                                                <h5 className="bungee cw-dark-blue"> My child is younger than 1st grade and not yet reading,  do you have anything for them? </h5>   
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseFour" className="collapse" aria-labelledby="codingClassesFaqFour" data-parent="#accordion">
                                            <div className="card-body">
                                            At this time, no. All our online coding classes are designed for children who can read independently. However, we work with several other providers who are bringing classes online for younger children, and we’re happy to connect you to them! Contact us to discuss more
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqFive">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseFive" aria-expanded="true" aria-controls="codingClassesCollapseFive">
                                                <h5 className="bungee cw-dark-blue"> Is this self-guided? </h5>  
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseFive" className="collapse" aria-labelledby="codingClassesFaqFive" data-parent="#accordion">
                                            <div className="card-body">
                                            While our courses are self paced, our online classes offer an easy way for your child to participate in a classroom environment with a real, live coach. We offer the same great instruction that is available in our center based classes.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqSix">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseSix" aria-expanded="true" aria-controls="codingClassesCollapseSix">
                                                <h5 className="bungee cw-dark-blue"> Do you offer make up classes? </h5>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseSix" className="collapse" aria-labelledby="codingClassesFaqSix" data-parent="#accordion">
                                            <div className="card-body">
                                            We only offer make up classes for our evening classes. We are unfortunately unable to offer makeup classes for camps.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqSeven">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseSeven" aria-expanded="true" aria-controls="codingClassesCollapseSeven">
                                                <h5 className="bungee cw-dark-blue">  Who are your coaches?  </h5> 
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseSeven" className="collapse" aria-labelledby="codingClassesFaqSeven" data-parent="#accordion">
                                            <div className="card-body">
                                            Our instructors have backgrounds in computer science, engineering, and/or math as well as experience working with children. With a strong understanding of how to teach coding to young children, your child gets a fun, educational class experience. All our instructors undergo background checks.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="codingClassesFaqEight">
                                            <h5 className="mb-0">
                                            <span data-toggle="collapse" data-target="#codingClassesCollapseEight" aria-expanded="true" aria-controls="codingClassesCollapseEight"> 
                                                <h5 className="bungee cw-dark-blue">  Can we do a trial class? </h5>   
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="codingClassesCollapseEight" className="collapse" aria-labelledby="codingClassesFaqEight" data-parent="#accordion">
                                            <div className="card-body">
                                            Trial classes are available for our evening classes. To book a trial class, please visit our <a className="faq-link" href={aboutdata.tryfreeclass}>booking page</a>
                                            </div>
                                        </div>
                                    </div>						 
                                </div>
                                <div className="bottom_cta">
                                    {/* <p>Have more questions? Please see our full class <a href={`${this.state.fulllocation}/faqs`}>FAQ</a></p> */}
                                    <p>Have more questions? Please see our full class <a href="faqs">FAQ</a></p>
                                    <ul>
                                        <li><a href={`tel:${aboutdata.phone}`}><i className="fa fa-phone"></i>&nbsp; {aboutdata.phone}</a></li>
                                        <li><a href={`mailto:${aboutdata.email}`}><i className="fa fa-envelope"></i> {aboutdata.email}</a></li> 
                                    </ul>
                                    <Button color="orange" link={this.state.tutordoctorsignup} size="x-large">REGISTER WITH YOUR TUTOR DOCTOR CODE</Button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                {floatingdata}
            </div>
        );
    }
}

export default TutorDoctorUK
