import React from 'react';
import FallClassesPage from '../../fall-classes/fall-classes.component';
import MetaTags from 'react-meta-tags';

const FranchiseFallClassesPage = () => (
    <div>
        <FallClassesPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Online Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseFallClassesPage;