import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/autism-blogs.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';
import GladysWestImg from '../assets/images/Gladys_West.jpeg';
import MargaretHamiltonImg from '../assets/images/Margaret_Hamilton.jpeg';
import HedyLamarrImg from '../assets/images/Hedy_Lamarr.png'

import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">April 2, 2022</span>
                                    <h2 className="header-title cw-dark-blue bungee">Reasons Why Children With Autism Can Excel at Coding</h2> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Nick Martellaro</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details incredible-women-2022">
                                        <div className="blogs_details_block ">
                                            <p className='cw-dark-blue'>Students who are on the autism spectrum often have a deeper understanding of technology and tend to succeed in logic-based activities. Here are some reasons why children with autism excel at coding!</p>
                                            <p className='cw-dark-blue'>Before we start, it’s important to remember that autism is a spectrum in the truest sense of the word. Just like anyone else, people with autism are each special and unique in their own ways with individual strengths, personality traits, and interests. And while research does tend to show that successful coding requires many of the same strengths people with autism can possess, we want to reiterate that every student is different!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee ">Problem-solving ability</h3>
                                            <br/>
                                            <p>Social cues and self-expression can sometimes be difficult for individuals with autism spectrum disorder (ASD). Coding requires problem-solving skills, and the predictable nature of programming languages - as well as the ability for coders to have full control over the outcome - is often appealing to students who are on the spectrum. In some cases, individuals with ASD may find writing code itself (and problem-solving) without having to worry about uncertainties can be a calming activity. At Code Wiz, we encourage children with ASD to embrace these differences! We want students to feel like they have superpowers - and given the right tools and support systems, there are no limits to what they can accomplish. Code Wiz integrates real-world problem solving skills into our programs using popular games that already appeal to many students. From Minecraft to Roblox, students are able to approach problems with their builds with direct support from classmates and coaches!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee ">Self-expression</h3>
                                            <br/>
                                            <p>Individuals with autism may have difficulty developing language and expressing themselves verbally. When communicating, autistic individuals may script out what they want to say or use non-verbal methods. Because coding is predictable in nature while also allowing an infinite number of possibilities, autistic children may enjoy the structured nature of self-expression that programming allows. We understand that many parents deal with communication challenges when it comes to their children with ASD. Children with autism may find that self-expression is difficult, so we encourage our students to unleash their inner superhero and channel their creativity in the ways they feel most comfortable!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee ">Focus and precision</h3>
                                            <br/>
                                            <p>Individuals with autism think differently than most people. We encourage people with autism to embrace these differences as part of their individualism! Although caring for a child with autism can be challenging for a parent, we believe these differences should be celebrated. In our experiences, children with ASD often excel when surrounded by supportive peers and engaging activities they find interesting. As many parents know, people with autism may have strong interests in very specific subjects, or be extremely detail-oriented when it comes to their interests. In addition, people with autism can be highly creative - and when overwhelmed, they may have difficulty processing their feelings and surroundings. Also, individuals with ASD may feel most comfortable with a routine and prefer to avoid unexpected changes. Once again, the predictable nature of code-writing can be naturally appealing for people on the spectrum who prefer structured activities and dislike dealing with surprises. At the same time, coding is incredibly unique in itself - despite being logical and systematic by nature, programming encourages coders to use their imaginations! Children with autism are all unique in their own ways, and coding can unleash their inner superhero by allowing them to engage in structured activities while also letting their creativity run wild! Coding is a wonderful way to express individualism, and learning to program is a wonderful way to foster a creative spark in children with autism while also empowering the incredible qualities that make them unique.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 class="bungee ">Thinking ahead</h3>
                                            <br/>
                                            <p>As an added bonus, programming skills are in high demand. It’s no secret that technology is a rapidly growing field - and year after year, the need for talented developers increases. Parents of students with ASD may wish to encourage their child to pursue interests in technology that might be valuable in the future.</p>
                                        </div>
                                        <h3 className='cw-dark-blue center'>Want to learn more about Code Wiz’ awesome programs? <a href="https://thecodewiz.com/after-school-coding-programs">Click here!</a></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
