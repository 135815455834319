import React, { useState }from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import './popup-modal.styles.scss';

const couponUrl ="https://getform.io/f/3f2a0e4c-03e3-4c9a-948d-6b79076b5b96";
const grandOpeningUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e";
const contactrl ="https://thecodewiz.us17.list-manage.com/contact-form?u=8c039a12a3ba141ffe61aa309&form_id=666e2fdfae00b993642b23e56dbdf114";
const cyberSalesUrl = "https://getform.io/f/4e628ef8-f8b5-4d19-a244-8a235f3bd9dc";

const PopupModal = ({location, formType, owner, leadsource, heading, description}) => {
    let formHeader = "";
    let formHeaderDesc = "";
    let formHeaderDesc2 = "";
    let formUrl ="";
    let coupontype="Cyber Sales";
    const locationemail= location + "@thecodewiz.com";
    const [open, setOpen] = useState(false);
    const closeModal = () => setOpen(false);
    React.useEffect(() => {
        setTimeout(() => {
            setOpen(true)
        }, 5000)
    }, [setOpen])

    if(formType == "grandopening"){
        formUrl = grandOpeningUrl;
        if(heading){
            formHeader = heading;
        } else {
        formHeader = "Grand Opening Sale!!";
        }
        if(description){
            formHeaderDesc = description;
        } else {
        formHeaderDesc = "Founding families enjoy **31.4159%** off their memberships for the first 6 months!";
        // formHeaderDesc2 = "Discounts for vacation and summer camps also available!";
        }
    } 
    else if(formType == "coupon"){
        formUrl = couponUrl;
        formHeader = "We'd Love to Keep You Updated!";
        formHeaderDesc = "We'll send you a coupon for 5% OFF as a Thank You!";
        // formUrl = cyberSalesUrl;
        // formHeader = "Cyber Sales Week!!";
        // formHeaderDesc = "Get 20% Off Code Wiz Gift Cards!";
    } else if(formType == "grandopeninglifetime") {
        formUrl = grandOpeningUrl;
        if(heading){
            formHeader = heading;
        } else {
        formHeader = "Grand Opening Sale!!";
        }
        if(description){
            formHeaderDesc = description;
        } else {
        formHeaderDesc = "Founding families enjoy **31.4159%** off their memberships for life!";
        // formHeaderDesc2 = "Discounts for vacation and summer camps also available!";
        }
    }
    
    return (
        <div className="popupDiv">
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
            {/* <iframe src={url} className="coupon-popup" width="500" height="500" scrolling="“no”" frameBorder="“0”" allowtransparency = "“true”"></iframe> */}
            <button type="button" className="close" aria-label="Close" onClick={() => setOpen(o => !o)}>
            <span aria-hidden="true">&times;</span>
        </button>
            <div className="cw-coupon-form">
                <div className="cw-coupon-form-header">
                    <h3 className="bungee cw-dark-blue center">{formHeader}</h3>
                    <h4 className="center cw-dark-blue">{formHeaderDesc}</h4>
                    <h5 className="center cw-orange">{formHeaderDesc2}</h5>
                </div>
                <form id="cw-contact-form" className="contact-form cw-dark-blue" action={formUrl} method="POST">
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label className="required-label" htmlFor="inputFirst">First Name *</label>
                            <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required-label" htmlFor="inputLast">Last Name *</label>
                            <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required-label" htmlFor="inputEmail">Email *</label>
                            <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                        </div>
                        <div className="form-group col-md-6">
                            <label className="required-label" htmlFor="inputPhone">Phone *</label>
                            <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
                        </div>
                        <label>Required fields are indicated with "*"</label>
                        <div className='form-group col-md-12'>
                            <div className="form-check">
                                <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                                <label className="form-check-label tiny-print" htmlFor="gridCheck">
                                {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                                <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                                <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                                </label>
                            </div>
                        </div>
                        {/* <p>Required fields are indicated with "*".</p> */}
                    </div>

                    {/* <h5 className="center cw-dark-blue">{formHeaderDesc2}</h5> */}
                    <div className="form-row">
                        <input type="hidden" id="location" name="location" value={location}></input>
                        <input type="hidden" id="zoho" name="zoho" value={owner}></input>
                        <input type="hidden" id="locationemail" name="location-email" value={locationemail}></input>
                        <input type="hidden" id="leadsource" name="lead-source" value={leadsource}></input>
                        <input type="hidden" id="coupontype" name="coupon-type" value={coupontype}></input>
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            
                        </div>
                    </div>
                    <div className="form-group">
                            <div className="form-check">
                                <label className="form-check-label" htmlFor="gridCheck">
                                {/* By submitting this form, you are consenting to be contacted by Code Wiz and you will be added to their mailing list. */}
                                </label>
                            </div>
                        </div>
                    {/* <button type="submit">Send</button> */}
                    <button type="submit" className="btn orange-button" target="_blank">GET OFFER</button>
                </form>
            </div>
        </Popup>
        </div>
    );
};

export default PopupModal;