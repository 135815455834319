import React from 'react';
import FindACoursePage from '../../find-a-course/find-a-course.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFindACoursePage = () => (
    <div>
        <FindACoursePage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | Find A Course</title>
        </MetaTags>
    </div>
);

export default FranchiseFindACoursePage;