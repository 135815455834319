import React from 'react';
import Franchisee from '../../../components/franchisee/v4-franchisee.component';
import Header from '../../../components/header/header.component';
import CodewizImg from '../../../assets/images/Codewiz-team.png';
import StpeteFamImg from '../../../assets/images/northstpete-family-square.jpg';
import ScottImg from '../../../assets/images/jim-s.jpg';
import JenniferImg from '../../../assets/images/bridgette-s.jpg'
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

import './northstpete.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';

class NorthStPetePage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: 'North St Pete Location',
            coupon: 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg',
            sections: [{
                review: 'Code Wiz is awesome!',
                author: 'l. Smith',
                id: 1
            },
            {
                review: 'My children love Code Wiz',
                author: 'J. Johnson',
                id: 2
            }],
            team: [
                // {id: 'wf-1', name: 'Scott Callison', title: 'Owner', picture: ScottImg},
                // {id: 'wf-2', name: 'Jennifer Callison', title: 'Co Owner/Center Director', picture: JenniferImg},
                {id: 'wf-2', name: 'Scott & Jennifer Callison', title: 'Owners', picture: StpeteFamImg}
            ]
        }
    }

    render() {
        return (
            <div>
                <PopupModal location="northstpete" formType="coupon" owner="Code Wiz North St Pete"/>
                <Header location="northstpete" fulllocation="northstpete-fl"/>
                <FacebookMessenger location="northstpete" FBPageId="298754836657255" />
                <Franchisee name="northstpete" team={this.state.team} owner="Code Wiz North St Pete"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in North St Pete FL | Code Wiz</title>
                    <link rel="canonical" href="http://thecodewiz.com/northstpete-fl" />
                    <meta name="description" content="Coding and Robotics For Kids in North St Pete FL" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/northstpete-fl" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in North St Pete FL" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in North St Pete Florida" /> 
                    <meta name="keywords" content="coding, coding in northstpete, northstpete florida, robotics"></meta>
                    <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
                </Helmet>
            </div>
        );
    }
}

export default NorthStPetePage;

