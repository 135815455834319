import React from 'react';
import ComingSoonFranchisee from '../../../components/franchisee/coming-soon-franchisee-post-summer.component';
import Header from '../../../components/header/header.component';
import CherisImg from '../../../assets/images/cheris-lawrenceville.jpeg';
import CodewizImg from '../../../assets/images/Codewiz-team.png';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

import './lawrenceville.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';

class LawrencevilleComingSoonPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: 'Lawrenceville Location',
            coupon: 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg',
            sections: [{
                review: 'Code Wiz is awesome!',
                author: 'l. Smith',
                id: 1
            },
            {
                review: 'My children love Code Wiz',
                author: 'J. Johnson',
                id: 2
            }],
            team: [
                {id: 'wf-2', name: 'Cheris South', title: 'Owner', picture: CherisImg},
            ]
        }
    }

    render() {
        return (
            <div>
                {/* <PopupModal location="lawrenceville" formType="grandopening" owner="Code Wiz Lawrenceville"/> */}
                <Header location="lawrenceville" fulllocation="lawrenceville-ga"/>
                <ComingSoonFranchisee name="lawrenceville" team={this.state.team} owner="Code Wiz Lawrenceville"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in Lawrenceville GA | Code Wiz</title>
                    <link rel="canonical" href="http://thecodewiz.com/lawrenceville-ga" />
                    <meta name="description" content="Coding and Robotics For Kids in Lawrenceville GA" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/lawrenceville-ga" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in Lawrenceville GA" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in Lawrenceville Georgia" /> 
                    <meta name="keywords" content="coding, coding in lawrenceville, lawrenceville georgia, robotics"></meta>
                    <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
                </Helmet>
            </div>
        );
    }
}

export default LawrencevilleComingSoonPage;

