import React from 'react';

import './team-member.styles.scss';

const TeamMember = (props) => (
    <div className="col mb-3">
        <div className="teammate center">
            <img src={props.picture} className="team-picture" alt={props.name}/><br/>
            <span className="team-member-name center bungee">{props.name}</span><br/>
            <span className="team-member-position center">{props.title}</span>
        </div>
    </div>
);

export default TeamMember;