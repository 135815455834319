import React from 'react';
import ContactPage from '../../contact/contact.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseContactPage = () => (
    <div>
        <ContactPage franchisee="kirkland" fulllocation="kirkland-wa"/>
        <FacebookMessenger location="kirkland" FBPageId="356771947508746" />
        <MetaTags>
            <title>Code Wiz - Kirkland WA | Contact Us</title>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default FranchiseContactPage;