import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import LearnMath from '../assets/images/blog-pictures/learn-math.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">November 3, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> LEARN MATH PLAYING MATH GAMES </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h2 className="bungee">HOW LEARNING TO CODE PREPARES KIDS FOR THE 21st-CENTURY WORKPLACE</h2>
                                            <p><b>“Learn math playing math games” – tell this your kids and they will think that it’s a wonderful idea! Every kid loves playing games, don’t they? Supporting their play time alone or with other kids is giving them a chance to help your dear ones grow skills in various areas. Playing games is not only fun. Games develop imagination, creativity and are healthy for brain building.</b></p>
                                            <p>Playing with the peers, the kids learn to interact socially and how to be part of the world of people around them. Playing games kids improve their cognitive and emotional strength, <a href="https://www.dev.thecodewiz.com/coding-with-kids/">start to learn</a> how to deal with problems and difficulties. Games teach kids how to win. Also, they learn how to follow the rules, how to lose and how to deal with the feelings accompanying it.</p>
                                            <p>We all started by playing simple games, such as matching colors and shapes, reading the letters and words, finding the right card or picture. Later, we progressed to more complicated games that required our mathematical, logical and creative thinking.</p>
                                            <p>The games help the kids to develop their problem-solving, future-thinking skills from a young age. These skills are essential for kids’ future life and careers. Growing older, kids start playing games which require more creativity, more reading, negotiating, calculating and managing resources.</p>
                                            <p>Online math games have become popular in recent years. Playing math games and working with numbers, kids learn math and discover problem-solving strategies, mathematical concepts and counting sequences.  Understanding the numbers, kids learn the concepts of operations and engage in computation practice to develop their mathematical fluency. There are plenty of math games for the kids nowadays. The games can be easily found online. Some of them can be downloaded, others are only available for playing online.</p>
                                            <p>However, not all the games aim to develop the skills of your child. Thousands of games are just pointless. Spending time playing them does not make sense, it is just the worst waste of time. Of course, you kids will be busy while playing. Yet, playing these games will not bring them anything useful for the development of their skills – these games are simply stealing your kid’s time.</p>
                                            <p>So why not invest the precious time of your kid into something interesting, into something that will help the kids and be useful in the future? Luckily, there are games, which will help your kids to grow their skills in different subjects. Let’s have a look at the best ones – Prodigy and IXL Maths.</p>
                                           



                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Prodigy Math Game</h3>
                                            <p><a href="https://www.prodigygame.com/">Prodigy Math Game</a>, or as they call themselves “the most engaging math platform in the world,” is a place where you can obtain, develop and evaluate your math skills. Prodigy covers grades 1-8 addressing the individual needs of every child with over 1200 crucial math skills. It also provides content from all major topics and ensures the kids are ready for standardized testing.</p>
                                            <p>It clearly highlights areas where you kids need additional math support, what they learned, where they succeeded and what skills they need to master to improve their knowledge. Prodigy is free, adapted to the curriculum (for example, standardized testing) and lets the kids progress from one level to another, keeping the kids interested and motivated.</p>
                                        

                                                                             
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">IXL Math</h3>
                                            <p><a href="https://www.ixl.com/math">IXL Math</a> and its team of teachers, researchers and technologists provide a broad content of educational resources in math for kids and students of all ages and grades. IXL Math aims to spark curiosity, build confidence and develop skills by asking questions and teaching to understand the numbers, experience and learn math. IXL Math is useful for kids of all ages from pre-K kids to eighth-grade children, offering additional development of Algebra 1 and 2 skills, Precalculus and Calculus skills. Though IXL Math is offered on tasubscription basis, it is totally worth it! IXL Math is used by numerous schools worldwide. The gamification embedded in the flow makes kids want to progress beyond their home tasks.</p>
                                            <p>There are plenty of math games online; however, not all of them are good. They are good for killing time and entertainment. Don’t we always want to help our kids spend as much time as possible learning new things? There are math games that just <a href="https://dev.thecodewiz.com/boston-with-kids/">entice kids to play</a>. These games steal lots of time and do not teach the kids anything.</p>
                                            <p>So why not play, learn and have fun at the same time? For example, your kids can join local courses where they will make new friends, improve their teamwork skills, and learn something new. The classes can encompass coding, robotics and creation of games. This is the best opportunity to learn and have fun at the same time for kids.  </p>

                                            <img src={LearnMath} alt="learn math" />


                                       
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Cool Math games – waste of time hidden behind a seemingly good name</h3>
                                            <p>Many companies try to grow the consumer base by introducing products and games for the kids. Some of them introduce online math games that become popular. However, the majority of such games are a waste of time in terms of developing math skills or learning something new. We analyzed Google search queries and reviewed the most popular games which offer entertainment, but no to little math skills for the kids.</p>


                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Cool Math game Ninja Painter</h3>
                                            <p>Cool math game ninja painter is offered by Coolmath.com and gets over 500 searches monthly. All your kid needs to do here is to grab the right paint color and roll it on the marked wall areas. The kid will aim to collect all the stars and get to the exit to reach the next level. The game has nothing to do with math and does not teach your kid any skills related to math.</p>
                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">B cubed Cool Math game</h3>
                                            <p>B cubed Cool Math game is similar to ninja painter. It does not develop any math skills of your kid. Playing the game, you need to slide the cube using arrows on the keyboard. The goal of the game is to pass over every single square on the way to the final square. The only skills the game can develop are the spatial skills of your kid. Also, this is the only game in the list, which can be considered to be a necessary foundation for the development of future strategy and problem-solving skills.</p>
                                            <p>If you want your kid to develop these skills faster and more effective, register for Scratch programming classes – your kid will learn a lot while having fun: get a free trial class today.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Cool Math game Ninja Miner</h3>
                                            <p>Cool Math game ninja miner is another game which does not teach kids anything yet gets a significant number of kids to search for it. It is a pure time killer game, wasting your kid’s time. Here you need to use arrow keys to move the ninja miner around to collect gems and stars. As soon as this mission is completed, you move to the next level. The idea and the goal are pretty much the same as for ninja painter. And the result is the same: this is another arcade game which does not teach the kids anything and has nothing to do with math.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Papa Cheeseria Cool Math game</h3>
                                            <p>Papa Cheeseria Cool Math game is another game, where your kids need to take customers’ orders, make custom grilled sandwiches and give them to the clients. In case the kids did everything correctly, they get tips. The task is getting more complicated when there is more than one customer to serve. Here the kids get a chance to earn new clothes, to modify your lobby, however, the game does not develop any useful skills and is a classic time killer which can be somewhat addictive.</p>
                                            <p>Instead of wasting time with a game like this, why not create a game by yourself? Your kids can build and design their own games, fine-tune them to their preferences and desires, and simply have fun playing! During this process kids learn the basics of coding, developing new skills and knowledge that will definitely come useful in the future. Book a free trial class now.</p>
                                            

                   
                                        
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Red Ball Cool Math game</h3>
                                            <p>Red ball cool math game is also introduced by Cool Math. In this game, you need to use arrow keys to move a ball to the goal and be sure to knock out all the stars while playing. Besides, the “bad guys” will make everything possible to prejudice your success. This kind of a “Super Mario” game is quite entertaining; however, it is pseudo-educational, and, regarding the development of kids’ skills and knowledge, is just a big disappointment. Will the kids learn here anything? Of course, not. It is way better to help your kids <a href="https://dev.thecodewiz.com/kids-coding-languages/">create a similar game themselves</a> and learn future-proof skills along the way.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Cool Math game Wheely 4</h3>
                                            <p>Cool Math game wheely 4 is another game which does not teach the kids anything at all. Wheely is a small car that travels through time by getting to the red flag. Kids can click the car and other objects to make Wheely move along its way. This game has nothing to do with the development of any knowledge and skills of the kids. The only thing your kids may benefit from is the improvement of their reaction. In other words, plenty of time is wasted, and there is nothing to learn.</p>
                                            <p>Some kids play these games, and some kids <a href="https://dev.thecodewiz.com/scratch-programming-for-kids/">code the games by themselves</a>! They create the games, play them and invite their friends to play. The result: everybody is having fun! The process of coding the games is simple, so let your kids learn it from the early ages: book your free trial class today!</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Cool Math game Papa Pizzeria</h3>
                                            <p>Cool Math game Papa Pizzeria is similar to Papa Cheeseria. The game is focused on pizza creation. The player takes customer orders, creates toppings, monitors the time of cooking, cuts pizza and serves it to the clients. If the process is done correctly, the player gets a virtual tip. Again, there might be more than one customer to serve at the same time. This makes the gameplay more complicated. The game is entertaining and takes lots of time without the development of any creative or problem-solving skills or knowledge of your kid.</p>
                                            <p>Some googling shows us that there are thousands of math games online. They are often presented as educational games facilitating learning different subjects or the development of new skills. However, lots of these games are a total waste of time. These games steal time and are often very addictive. In some cases, kids may even dumb their abilities and will find it difficult to concentrate on their homework or other important tasks and activities of their lives. Moreover, such games prevent children from using their free time efficiently and in some cases can even harm their mental health.</p>
                                            <p>Luckily, some games focus on the development of math skills from early ages and are useful. For example, kids can learn math through games at platforms like Prodigy or IXL. These games can teach kids math and keep them motivated for everyday learning of something new.</p>
                                            <p>So why not involve your kid in creating something new and in the meantime have fun and learn a lot? The best way is to turn your kids’ interest in games to coding. They will create their own games and <a href="https://dev.thecodewiz.com/kid-birthday-party-places-ideas/">have fun</a> playing them with their new friends.</p>
                                            <p>Code Wiz is a perfect opportunity to teach your kids coding and robotics. They will learn how to create their own mods, mini-games and then progress to more complicated games and tasks. Your kids will love coding and creating their games, developing teamwork skills, imaginative and creative thinking and critical problem-solving at the same time.</p>
                                            <p><a href="https://codewiz.simplybook.me/v2/#book">Book your free trial today!</a></p>

                   
                                        
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
