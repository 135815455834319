import React from 'react';
import Header from '../../../components/header/header.component';
import OnlineFallCoding from '../../../components/online-rec-centers/online-fall-coding.component';

const OnlineFallCodingPage = () => (
  <div>
    <Header location="medford" fulllocation="medford-ma"/>
    <OnlineFallCoding franchisee="medford"/>
  </div>
);

export default OnlineFallCodingPage;