import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import MakeTheSummerCount from '../assets/images/blog-pictures/make-the-summer-count.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 2, 2019</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>MAKE THE SUMMER COUNT </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h2 className="bungee">Why You should send your Kids to a Coding for Kids Summer Camp</h2>
                                            <p><b>It is never easy to send your kid away for weeks at a time as a parent. But if they will return home better versions of themselves, it is worth the anxiety.</b></p> 
                                            <p>A computer programming summer camp for kids might not evoke memories of the campfires, songs, and pranks of those magical sleep-away camps you might have enjoyed during your youth, but the built-in benefits are hard to deny.</p>
                                            <p>A coding for kids <a href="https://en.wikipedia.org/wiki/Summer_camp">summer camp</a> is a unique venue for growth – providing kids opportunities to build that self-confidence, responsibility, and independence they’re going to need in life.</p>
                                            <p>The characteristics born of mastering new skills can shape your child into a competent adult further down the road.</p>
                                            <p>The benefits of sending your child to a coding for kids summer camp, outside of the self-serving ones like getting some ‘me time,’ are many.</p>
                                            <p>These benefits are backed up by research. A <a href="ahs.uwaterloo.ca/~tdglover/PDF%20Files/CSCRP%202011.pdf">2010 University of Waterloo study</a>, found that kids who attend summer learning camps not only develop healthier attitudes toward physical activity, but also saw significant positive development in self-confidence, social integration, and emotional intelligence.</p>
                                            <p>Here are some of these benefits:</p>

                                            <ul>
                                                <li>Can improve a child’s social and relationships skills</li>
                                                <li>It provides an opportunity for children to learn how to be independent.</li>
                                                <li>Can boost a child’s confidence</li>
                                                <li>Provides an opportunity for them to learn new, valuable skills such as advanced math, computer programming, <a href="https://dev.thecodewiz.com/blog/2018/11/03/kids-coding-languages/">coding languages,</a> and design.</li>
                                                <li>Learning those skills will help to develop their ability to solve problems</li>
                                                <li>Helps to build a unique interest</li>
                                                <li>It provides an opportunity to explore a potential career path</li>
                                                <li>It can provide a new perspective, helping to build appreciation and gratitude.</li>
                                                <li>Helps to build creativity</li>
                                                <li>Encourages teamwork</li>


                                            </ul>  
                                            <p><i>Here is a more detailed look at some of those benefits:</i></p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Coding for Kids Summer Camp helps to build Resilience and Independence</h3>
                                            <p>With parents sidelined, kids get opportunities to learn resilience by occasionally failing when they try new activities.The saying ‘everyone will fail at something in life’ is widely believed by many people.</p>
                                            <p>Now it might be hard for some parents to explain this to their kids when they are crestfallen from their team losing the state soccer championship game. But them learning to handle failure on their own will likely be more effective than any detailed explanation.</p>
                                            <p>They will learn how to do things on their own which will help them develop confidence and independence.</p>


                                                                                 
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Coding for Kids Summer Camp helps children develop better Social Skills</h3>
                                            <p>Chances are that you’re still friends with people you met at camps years ago. Summer coding camps are a great place for your child to make real friends and boost their social skills.</p>
                                            <p>Summer coding camps are a completely different experience compared to going to school or living at home. It’s usually a close-knit community where children sleep together, eat together, learn together and of course, have fun together, although sometimes in small groups.</p>


                                            <img src={MakeTheSummerCount} alt="make the summer count" />
                                            <p>While it’s not quite the in-person group chats around a campfire of the traditional camp, the social dynamics of meeting children from diverse backgrounds and solving problems in groups can go a far way in building their relationships and networking skills.</p>
                                            <p>And like the camps you attended back in your youth, it will likely build potential lifelong friendships. Students who interact with like-minded peers on activities rooted in similar interests are better able to make lasting friendships.</p>
                                            <p>Coding summer camps can lead to a different type of friendship-building – a network to call on when doing a future coding project, finding a job, or even starting a new business.</p> 

                                        
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Coding for Kids Summer Camp provides Mental Stimulation and builds Creativity</h3>
                                            <p>As a parent, if your child loves video games and spends way too much time playing them, you’re surely not alone. It can be quite annoying to see your kids so engulfed into some video game that they forget even to eat much less do homework or their chores.</p>
                                            <p>But what if they use up some of that wasted time making them instead of just playing them?</p>
                                            <p>A coding for kids summer camp gives kids a chance to get off the couch and into courses that will teach them how to make video games.</p>
                                            <p>It helps with mental stimulation and encourages creativity because kids don’t have to worry about getting a failing grade. It is only when they are free of the fear of failure that their creativity can flourish.</p>
                                            <p>Building video games is an expression of creativity, and a learn to code summer camp facilitates the deep dive into learning the game development skills needed to fuel that creativity.</p>
                                            <p>It provides an opportunity to explore a potential career path in lucrative fields that is experiencing <a href="https://medium.com/swlh/10-interesting-2018-video-game-industry-statistics-trends-data-499050f976b3">remarkable growth</a>.</p>
                                            <p>According to new data released by the Entertainment Software Association and The NPD Group, <a href="https://techcrunch.com/2019/01/22/video-game-revenue-tops-43-billion-in-2018-an-18-jump-from-2017/">video game revenue in 2018</a> reached a new peak of $43.8 billion, an 18 percent increase from previous years. It surpassed the projected total global box office for the film industry.</p>


                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Coding for Kids Summer Camp builds Appreciation and Gratitude</h3>
                                            <p>In their effort to be kind and gentle, some parents let their little darlings get the upper hand. And when that happens things can get out of hand.</p>
                                            <p>Sometimes when kids become churlish it’s because of a lack of appreciation and gratitude.</p>
                                            <p>Extended time away from home pushes kids out of their comfort zone. This helps kids to truly appreciate home, their parents, their belongings and all that’s given to them daily.</p>
                                            <p>Kids running you ragged?</p>
                                            <p>Sending them off to a coding for kids summer camp can instill the appreciation and gratitude that could improve their attitude.</p>

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Coding for Kids Summer Camp can give children a New Perspective</h3>
                                            <p>Another way to build appreciation and gratitude in your kids is to facilitate a more rounded perspective of the world and life in itself.</p>
                                            <p>We have all been shaped by our environments in one way or another. But what if that shaping is not necessarily good for your child?  Unless they get out it will be difficult for them to “break out”.</p>
                                            <p>Sending your child to a coding summer camp, especially in a different state or overseas, provides an extraordinary opportunity for them to meet children they likely would not usually come across at home.</p>
                                            <p>The world is a big place and is becoming increasingly interconnected as a result of <a href="https://www.forbes.com/sites/quora/2017/08/28/is-globalization-creating-a-single-world-culture/#3749fb6a3bd3">globalization and the internet</a>. And the aim of making computer science a “new basic” skill is more of a global initiative.</p>
                                            <p>A coding for kids summer camp is a great opportunity to expose your kids to new cultures. Children who are exposed to different people from different backgrounds, cultures, and religions will be well-equipped to better handle what is slated to become an even more interconnected world in the future.</p>
                                            <p>So spending the summer in a different environment learning to code with other kids from diverse backgrounds, your child will likely return home with a more rounded perspective.</p>

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Wrap up</h3>
                                            <p>A coding for kids summer camp is your opportunity as a parent to make your child’s summer really count. It offers a structured platform for children to, not only develop their interest in and appreciation for computers and related technologies but to grow as a person.</p>
                                            <p>Chances are they will come back home armed with life-long skills, both hard and soft, that he or she will find invaluable in their future years.</p>
                                            <p>All of the benefits mentioned above can contribute to the development of your child into a prudent, <a href="https://dev.thecodewiz.com/blog/2019/03/02/learning-to-code-prepares-kids-for-the-21st-century-workplace/">technically competent</a> adult.</p>
                                            <p><i>If you were wondering what summer coding camps do for kids, I hope this article helps. If you need to learn more, you can check out our other <a href="https://dev.thecodewiz.com/blog/">blog posts</a> on summer coding camps or contact us. Already convinced and thinking about enlisting your child into a coding for kids summer camp? You can choose from our many <a href="https://dev.thecodewiz.com/summer-coding-programs-for-kids/">summer coding programs for kids</a> options. </i></p>

                   
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
