import React from 'react';
import ProgamsPage from '../../programs/programs.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseProgramsPage = () => (
    <div>
        <ProgamsPage franchisee="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <MetaTags>
            <title>Code Wiz - Medford MA | Programs</title>
        </MetaTags>
    </div>
);

export default FranchiseProgramsPage;