import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';
import AboutPageImg from '../../assets/images/Group-4.png';
// import CampHome from '../../assets/images/camps-camp-from-home.png';
// import CampSummer from '../../assets/images/camps-summer-camps.png';
import CampHome from '../../assets/images/bubble-35.png';
import CampSummer from '../../assets/images/vbubble-orange-4.png';
import CampHeaderImage from '../../assets/images/bubble-27.jpg';
import CodeWizAdvImage from '../../assets/images/cw-advantage-no-qr.png';

import './camps.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';


class CampsPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            }
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            this.state.calltoaction = aboutdata.tryfreeclass;
            this.state.calltoactionlabel = 'TRY A FREE CLASS';
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }

        // const [numPages, setNumPages] = 1//useState(null);
        // const [pageNumber, setPageNumber] = useState(1);
        //const pageNumber = 1;

        // function onDocumentLoadSuccess({ numPages }) {
        //     setNumPages(numPages);
        // }

        return (
            <div>
                <MetaTags>
                    <meta name="description" content="Coding and Robotics for Children Ages 7-17" />
                    <meta property="og:title" content="Coding and Robotics for Children" />
                    <meta property="og:image" content={CampHeaderImage} />
                    <title>February, April and Summer Vacation Programs - Code Wiz</title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="camps-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-12 col-lg-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">CAMPS</h2> 
                                    <h4 className="header-text cw-dark-blue">Whether you're looking for online or in-person vacation and summer camps for your child, we have you covered!</h4>
                                    {/* <Button color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">FIND A CENTER NEAR ME</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">    
                                <img src={CampHeaderImage} alt="Camps"/>
                            </div>
                        </div>
                        
                    </div>
                    <div className="body-wrapper camps-wrapper">
                        <div className="container camps-top">
                            
                            <div className="row"> 
                                <div className="image col-md-6 order-md-1 cw-dark-blue">
                                    <img src={CampHome} alt="Camp"/>
                                </div>
                                <div className="text col-md-6 order-md-2 cw-dark-blue">
                                    <div className="text-details">
                                        {/* <h3 className="bungee">camp from home</h3> */}
                                        <h3 className="bungee">online camps</h3>
                                        {/* <h5 className="bungee cw-orange">overview</h5> */}
                                        <p>Encourage your child to learn invaluable skills, make friends and have the best summer ever-all from the safety and comfort of your home. Our staff have been trained extensively on how to use Zoom to manage a classroom remotely. While our courses are self paced, our online classes offer an easy way for your child to participate in a classroom environment with a real, live coach. We offer the same great instruction that is available in our center based classes. With our coach to student ratio being 1:3 for younger students and 1:4 for older students, your child will get the proper attention they need from their coaches. </p>
                                        <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-reverse"> 
                                <div className="text col-md-6 cw-dark-blue">
                                    <div className="text-details">
                                        <h3 className="bungee">in-person camps</h3>
                                        {/* <h5 className="bungee cw-orange">about camps</h5> */}
                                        <p>Our in-person camps are perfect for keeping children active when school isn't in session. One week-long sessions are tailored toward what your child wants to learn. Children can build and program robots, drones and more using drag-and-drop technology like Scratch or more advanced technology like Python, Java, and Artificial Intelligence. We have small coach to student ratios to make sure your child gets the proper attention they need. We are taking necessary safety precautions to keep your child safe while they are at one of our centers.</p>
                                        <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                                    </div>
                                </div>
                                <div className="image col-md-6">
                                    <img src={CampSummer} alt="Summer Camp"/>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="container cwadvantage-div">
                        <img className="cwadvantage" src={CodeWizAdvImage} alt="The Code Wiz Advantage"/>
                    </div>
                    <br />
                    <div className="container center">
                        <Button color="orange" link={this.state.calltoaction} size="medium">{this.state.calltoactionlabel}</Button>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}
export default CampsPage;