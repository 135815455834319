import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BostonWithKids from '../assets/images/blog-pictures/boston-with-kids.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">November 3, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> BOSTON WITH KIDS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h2 className="bungee">HOW LEARNING TO CODE PREPARES KIDS FOR THE 21st-CENTURY WORKPLACE</h2>
                                            <p><b>Boston with kids is always fun! The city offers a variety of things to do and places to visit. Everyone has a chance to experience their favorite activities, ranging from the standard attractions for kids and cultural events to regular sports activities. Parents will find their fun in Boston with kids as well.</b></p>
                                            <p>Our city offers plenty of parks, playgrounds, climbing structures, slides and swings, as well as lots of options of kids’ food – from ice cream, pizza, sandwiches and burgers to picnicking spots. All kids enjoy their outdoor meals with their parents. It’s especially fun in the middle of one of Boston’s public parks and gardens.</p>
                                           



                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Best things to do in Boston for kids interested in STEM</h3>
                                            <p>Boston is also an excellent place for physical activities with kids. Fresh air and social interactions are essential for children of all ages. It is a fact that kids can also <a href="https://dev.thecodewiz.com/kid-birthday-party-places-ideas/">have fun while learning</a>. Don’t we all enjoy discovering new things? The concept of “learning by doing” is trending. Talking about the kids, “learning by playing” approach is getting more and more popular among parents of children of all ages. There is no doubt that kids in Boston can find lots of experiences that help them learn and develop their skills from their young age.</p>
                                            <p>As technologies are rapidly taking over different areas of our lives, parents need to make sure that their kids are ready for the future. STEM education, which stands for science, technology, engineering, and math, focuses on the development of future-proof skills. Some schools tend to introduce more STEM classes to their curriculum. However, lots of parents want their children to develop their STEM skills faster and choose additional free time activities as well.</p>
                                        

                                                                             
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Boston kids’ events</h3>
                                            <p>Boston kids’ events prepare them for the technology-driven future. The activities in Boston vary from museums introducing children and their parents to the history of technology and its current state of development to hands-on experiences with modern tools.</p>
                                            <p>Kids and the teens in Boston can benefit from regular STEM happenings. It’s a great pastime option during your free time on weekends. The Boston kids’ events aim to inspire creative thinking in the fields of <a href="https://dev.thecodewiz.com/learn-math-playing-math-games/">mathematics</a>, physics and life sciences from the early ages. For example, at the MakerBot Retail Store (144 Newbury Street, Boston, MA), the kids have an opportunity to take a class that will introduce them to the magical world of 3D printing. Here you can 3D-print your own toy and get motivated for future discoveries in the domain of technology.</p>
                                            <p>Younger kids will be excited by everything that the Discovery Museum has to offer (177 Main Street, Acton, MA). It is located just 26 miles away from Boston city center. Your kids can join real scientists and engineers who show their work and offer kids a chance to participate in the hands-on activities that explain the way specific technologies work.</p>
                                            <p>In May 2018 over 4000 students and teachers had a chance to learn about space and 7 of NASA’s missions during the STEM day event in the historic Fenway Park, home of the Boston Red Sox Major League Baseball team. The event took place with the participation of Johnson Space Center, Goddard Space Flight Center and many more. It stood out among other activities you could visit in Boston with kids due to the speaking panel of former astronauts and current NASA professionals. All boys dreamt of being astronauts when they were kids, didn’t they?</p>


                                       
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">BeaverDash</h3>
                                            <p>One of the biggest Boston kids’ events is for high school students and takes place at MIT. BeaverDash is an annual design and engineering competition, where teams of 4-5 high school students compete to think quickly, creatively and try to solve real-world problems.</p>
                                            <p>Duck ‘n’ Hover, is an annual full-day event organized at the Museum of Science in Boston, located in 1 Science Park, Boston, MA. The event offers engineering activities for teens aged 13-19. The kids will have an opportunity to engineer real-world devices in teams led by mentors.</p>
                                            <p>The 3D Design and Printing lab offers kids new knowledge about the physics of an object. The little ones get to 3D print themselves at the Verizon innovative learning lab in Boston (745 Boylston Street, Boston, MA).</p>


                                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">TouchTomorrow</h3>
                                            <p>TouchTomorrow is a perfect opportunity to spend your time with kids in Worcester, MA. TouchTomorrow, introduced by the Worcester Polytechnic Institute (WPI), is a family-friendly event which combines science, space, technology, and robotics. The festival includes research activities, presents interactive exhibits for visitors of all ages. TouchTomorrow reveals the world of technology where your kids can take part in the hands-on experiences and research lab activities.</p>
                                            <p>In June 2018, around 10,000 attendees visited the seventh annual festival TouchTomorrow. There are plenty of interesting STEM activities for kids – from robotics to 3D prototypes. The festival covered different fields of science and technology, including physics, biotechnology, biomedical engineering and chemistry.  All the activities are closely supervised by professional staff.</p>
                                            <p>During the last edition, children were excited to create new things with their own hands and get acquainted with a broad range of science activities. They discovered things through the microscope, designed a boat that can bear additional weight and float on the water, created a geodesic dome. The kids built a kaleidoscope, discovered how light waves can change our perception of an image and explored electrical circuits.</p>
                                            <p>TouchTomorrow is a great educational opportunity to increase knowledge and skills from young ages. TouchTomorrow opens its doors again for the kids and their families in 2019. The parents highly recommend the festival – there are tons of positive reviews and plans to visit the festival every year.</p>
                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Boston with kids: attractions</h3>
                                            <p>Boston attractions offer a vast variety of things to do in Boston with kids. There are always numerous Boston kids’ events and activities, which you can find in the neighborhood. Museums, parks, the aquarium and zoo, beaches, theatres, ballet performances, shows for kids, bike tours, waterfront attractions and multiple playgrounds with different activities for the kids of all ages are what makes Boston so child-friendly. There are hundreds of indoor and outdoor destinations for kids’ fun in Boston that help children discover and learn new fascinating things.</p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">MIT Museum tour – one of the best things to do in Boston for a kid</h3>
                                            <p>MIT Museum tour is an excellent idea to spend your time in Boston with kids. The MIT Museum is a place your children will always enjoy. Located at 265 Massachusetts Avenue, Building N51, in Cambridge, MA, the MIT Museum takes kids on a journey of research and innovation. It offers various exhibitions, performances, workshops, galleries and demos.</p>
                                            <p>The MIT Museum introduces regular programs, as well as one-time unique events on technology and innovation topics. It also offers hands-on practical activities for students, teens and children. There are various workshops and educational classrooms, where kids can see how technologies are developed in the 21st century. Here you can take your kid on a guided tour and let the museum educators tell them about the history of science and technology, its advances, key innovations and introduce them to the technological wonders that happened in the recent years.</p>
                                            <p>The MIT Museum showcases MIT engineers’ work and experiments, which fascinate the kids. Interactive scientific installations, workshops, kinetic sculptures, holography, robotics, artificial intelligence exhibits and the museum store with games and smart gadgets will make your child want to visit the museum again and again. </p>
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Museum of Sports</h3>
                                            <p>Museum of Sports is a place that your kids will definitely love! This is one the best things to do in Boston with kids. Physical activity and sports are essential for kids of all ages because it reduces harmful health effects and helps to build character. The Sports Museum, located on Levels 5 and 6 of the TD Garden at 100 Legends Way, Boston, MA, is a wonderful place to take your kid to learn more about the history of Boston sports.</p>
                                            <p>The Museum introduces various educational programs attended by more than 25,000 upper elementary and middle school students each year. The Boston vs. Bullies program, for example, acts against bullying in schools and communities. The Stand Strong program lasts 12 weeks and helps kids develop a character based on core values, such as responsibility, teamwork, fairness, courage and determination.</p>
                                            <p>The Museum uses sports as a base to teach the values of respect and develop the leadership skills of the kids. Here you can find dedicated exhibits for hockey, basketball, football, baseball and plenty of additional information on boxing, rugby as well as artifacts from the Boston Marathon. The Museum also presents life-size statues of famous sportsmen and thousands of other artifacts related to sports.</p>
                                            

                   
                                        
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">New England Aquarium</h3>
                                            <p>New England Aquarium is one the most fascinating places for kids in Boston. Visiting the Aquarium is one the best things to do in Boston for a kid. On the weekend, spend your time in Boston with kids at 1, Central Wharf where New England Aquarium houses thousands of aquatic animals from around the world. It attracts 1.3 million visitors each year fascinating adults and children of all ages. The kids love spectating the life of seals, sea turtles, little blue penguins, leafy seadragons, sea lions, octopuses, and many other species. The Aquarium is perfect for families: it offers immersive animal encounter programs that help your children learn more about the planet and the marine life, feed the animals and perch on top of the Giant Ocean Tank.</p>
                                            <p>One of the most popular educational programs organized by the Aquarium is the Whale Watch excursions. They include trips to Stellwagen Bank, located 30 miles away from Boston. In this natural marine sanctuary, you have a unique chance to see dolphins, whales, and other aquatic animals in their usual habitat. Aquarium offers programs for kids of all ages from 12 months to teenagers.</p>
                                            <p>Various classroom fun activities can also be adapted to use at home. The kids have a chance to take a behind the scenes tour where they will learn what the aquatic animals eat and how their tanks are maintained. The New England Aquarium has an IMAX® Theater featuring movies of animals and other immersive 3D films. The kids love their time at The New England Aquarium and always learn plenty of new exciting facts about the marine life.</p>

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Boston Museum of Science</h3>
                                            <p>Boston Museum of Science at 1, Science Park in Boston, MA is one of the best educational things to do in Boston with kids. Visiting the museum is a perfect way to have kids’ fun in Boston and learn more about the science and latest technologies. Take your kids on a fascinating journey of exhibitions, live demonstrations, presentations, movies about our planet and its scientific achievements.</p>
                                            <p>The Museum offers permanent exhibits where every kid will find thousands of interesting facts to discover. Your children will enjoy the immersive bird’s world virtual tour of Acadia National Park and learn how to interpret the language of birds. Next, visit the butterfly garden and walk among free-flying butterflies at the exotic plants exhibit. Under the same roof, your kids learn how the wind is transformed into green energy, discover the world of dinosaurs and try to distinguish the bones of a bird, an iguana and a dinosaur. They learn about mapping the world by creating their own maps.</p>
                                            <p>At the Discovery Center, your kids can enjoy hands-on activities combining playing and education. The teaching staff of the Center will explain science, math and engineering activities using age-appropriate tools. Your children will learn more about nanotechnologies, make models, enjoy various science experiments and engineering challenges.</p>
                                            <p>At the most technologically advanced digital theater in New England, the Charles Hayden Planetarium, the kids will explore the universe during one of the shows. For an unforgettable experience take your kids to a 4D movie screening where 3D movies are combined with special effects like wind and snow. The movies are family-friendly and engaging, offering diverse film content.</p>

                                            <img src={BostonWithKids} alt="boston with kids" />
                                            

                   
                                        
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Code Wiz coding school for kids</h3>
                                            <p>Code Wiz, the coding school for kids, is located in Westford at 9, Cornerstone Square, within 50 minutes’ drive from Boston city center. Let your kids dive into the magical world of code and robotics. Coding develops numerous skills like critical problem-solving, perseverance and focus. <a href="https://dev.thecodewiz.com/kids-coding-languages/">Coding helps kids develop</a> creativity, practice imaginative thinking and unlock their talents.</p>
                                            <p>These skills will help your kids choose their future career path and make effective decisions. Code Wiz is all about projects – here you will find everything for everyone – classes are divided into different age groups where personalized instruction is given to every child to understand technologies and complicated projects better. Code Wiz teaches the basics of computer science, building video games, creating music videos, building physics simulations and much more.</p>
                                            <p>The kids have a chance to work with actual code and learn the basics of building websites. The teens can go on to develop interactive sites with full functionality! Code Wiz offers various projects and programs for the kids of all ages and levels of knowledge. Children learn how to interact with other kids and have great kids’ fun in Boston together!</p>
                                            <p>At Code Wiz children can also have personalized lessons based on their needs and level of skills. Code Wiz is also home to robotics classes. The kids can design, build and program their own robots. The only limit is kid’s imagination. The kids can also learn 3D printing, <a href="https://dev.thecodewiz.com/coding-with-kids/">coding and game design</a> with Minecraft or Roblox as well as many other exciting things.</p>
                                            <p>Did you ever think of going out to have your <a href="https://dev.thecodewiz.com/after-school-programs-boston-westford-ma/">grown-up time</a>? Boston offers various places where you can leave your kid with professional staff and spend some time out! The best is to use this time for your kid to learn something new. For example, Code Wiz is a one the of most exciting child care choices near Boston. While you are having the time to yourself, your kids can get <a href="https://www.dev.thecodewiz.com/scratch-programming-for-kids/">initiated with coding</a> or robotics and motivated to learn more. Who knows, maybe this time off will change their life for better in the future?</p>
                                         
                   
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
