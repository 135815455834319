import React from 'react';
import Header from '../../../components/header/header.component';
import VirtualRobotics from '../../../components/virtual-robotics/virtual-robotics.component';
import MetaTags from 'react-meta-tags';

const VirtualRoboticsPage = () => (
  <div>
    <Header location="medford" fulllocation="medford-ma"/>
    <VirtualRobotics franchisee="medford"/>
    <MetaTags>
        <title>Code Wiz - Medford MA | Virtual Robotics</title>
    </MetaTags>
  </div>
);

export default VirtualRoboticsPage;