import React from 'react';
import FAQPage from '../../faqs/faqs';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFaqsPage = () => (
    <div>
        <FAQPage franchisee="westborough" fulllocation="westborough-ma"/>
        <FacebookMessenger location="westborough" FBPageId="104621587747524" />
        <MetaTags>
            <title>Code Wiz - Westborough MA | FAQs</title>
        </MetaTags>
    </div>
);

export default FranchiseFaqsPage;