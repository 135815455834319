import React from 'react';
import firebase from '../Firebase/firebase.js';
import './contact-info.styles.scss';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';


class ContactInfo extends React.Component {
    constructor(props){
        super(props);
        
        this.state = {
            location: props.location,
            data: {},
            facebookPixel: '4099536006730022',
            googleAnalytics: 'UA-125815483-1',
            //googleAnalytics: 'UA-155867448-3'
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            }
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    
    

    render() {
        
        const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
        };
        
        if(this.state.location !== undefined){
            const tagManagerArgs = {
                gtmId: this.state.data.gtm
            }
            //ReactPixel.init(this.state.facebookPixel, advancedMatching, options);
            ReactPixel.init(this.state.data.facebookPixel, advancedMatching, options);
            ReactPixel.pageView();
            ReactGA.initialize(this.state.data.googleAnalytics);
            ReactGA.pageview(window.location.pathname + window.location.search);
            TagManager.initialize(tagManagerArgs);
            
            if(this.props.mobile === "true") {
                return (
                    <div className={this.props.componentclass}>
                        <ul className={this.props.ulclass}>
                            {/* <li><a href={this.state.data.facebook}><i className="fa fa-facebook-square cw-orange" aria-hidden="true"></i></a></li>
                            <li><a href={this.state.data.instagram}><i className="fa fa-instagram cw-orange" aria-hidden="true"></i></a></li> */}
                            <li><i className="fa fa-map-marker cw-orange" aria-hidden="true"></i> <a target="_blank" href={this.state.data.googleMapsAddressLink}>{this.state.data.city}, {this.state.data.state}</a></li>
                            <li><i className="fa fa-phone" aria-hidden="true"></i>  <a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a></li> 
                            <li><a href={`mailto:${this.state.data.email}`}><i className="fa fa-envelope cw-orange" aria-hidden="true"></i></a></li>
                            {/* <li><i className="fa fa-mail" aria-hidden="true"></i>  <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a></li>  */}
                            {/* <li className="divider"> | </li> */}
                            {/* <li><i className="fa fa-user-circle" aria-hidden="true"></i> <a href={this.state.data.parentportal}>Parent Portal</a></li> */}
                        </ul>
                    </div>
                );
            } else {
                return (
                    <div className={this.props.componentclass}>
                        <ul className={this.props.ulclass}>
                            <li><a href={this.state.data.facebook}><i className="fa fa-facebook-square cw-orange" aria-hidden="true"></i></a></li>
                            <li><a href={this.state.data.instagram}><i className="fa fa-instagram cw-orange" aria-hidden="true"></i></a></li>
                            <li><i className="fa fa-map-marker cw-orange" aria-hidden="true"></i> <a target="_blank" href={this.state.data.googleMapsAddressLink}>{this.state.data.addressstreet} {this.state.data.city}, {this.state.data.state}, {this.state.data.zip}</a></li>
                            <li><i className="fa fa-phone" aria-hidden="true"></i>  <a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a></li>
                            <li><a href={`mailto:${this.state.data.email}`}><i className="fa fa-envelope cw-orange" aria-hidden="true"></i></a></li>
                            {/* <li><i className="fa fa-mail" aria-hidden="true"></i>  <a href={`mailto:${this.state.data.email}`}>{this.state.data.email} </a></li> */}
                            <li>|</li>
                            <li><i className="fa fa-user-circle" aria-hidden="true"></i> <a href={this.state.data.parentportal}>Parent Portal {this.state.mobile}</a></li>
                        </ul>
                    </div>
                );
            }
        } else {
            //ReactPixel.init(this.state.facebookPixel, advancedMatching, options);
            //ReactPixel.pageView();
            //ReactGA.initialize(this.state.googleAnalytics);
            //ReactGA.pageview(window.location.pathname + window.location.search);
            return (
            <div className={this.props.componentclass}>
                <ul className={this.props.ulclass}>
                    <li><a href="https://www.facebook.com/codewizfranchising/"><i className="fa fa-facebook-square cw-orange" aria-hidden="true"></i></a></li>
                    <li><a href="https://www.instagram.com/codewizfranchising/"><i className="fa fa-instagram cw-orange" aria-hidden="true"></i></a></li>
                    {/* <li><i className="fa fa-phone" aria-hidden="true"></i> <a href="tel:844-263-3949">1 (844) CODE WIZ</a></li> */}
                </ul>
            </div>
            );
        }
        
    }
}

export default ContactInfo;

