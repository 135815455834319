import React from 'react';
import Header from '../../../components/header/header.component';
import OnlineFallCoding from '../../../components/online-rec-centers/online-fall-coding.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const OnlineFallCodingPage = () => (
  <div>
    <Header location="westborough" fulllocation="westborough-ma"/>
    <FacebookMessenger location="westborough" FBPageId="104621587747524" />
    <OnlineFallCoding franchisee="westborough"/>
  </div>
);

export default OnlineFallCodingPage;