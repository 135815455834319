import React from 'react';
import Acsl from '../../../components/acsl-competition/acsl-comp.component';
import Header from '../../../components/header/header.component';
import MetaTags from 'react-meta-tags';

const FranchiseAcslPage = () => (
    <div>
        <Header location="medford" fulllocation="medford-ma" />
        <Acsl franchisee="medford"/>
        <MetaTags>
            <title>Code Wiz - Medford MA | ACSL</title>
        </MetaTags>
    </div>
);

export default FranchiseAcslPage;