import React from 'react';
import Franchisee from '../../../components/franchisee/v4-franchisee.component';
// import Franchisee from '../../../components/franchisee/franchisee.component';
import Header from '../../../components/header/header.component';
import AboutPageImg from '../../../assets/images/Group-4.png';
import CodewizImg from '../../../assets/images/Codewiz-team.png';
import hannahImg from '../../../assets/images/hannah-ryan.jpg';
import madisonImg from '../../../assets/images/madison.jpg';
import sudhirImg from '../../../assets/images/sudhir.jpg';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

import './westborough.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';

class WestboroughPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: 'Westborough Location',
            coupon: 'https://forms.zohopublic.com/ruth5/form/GrandOpening/formperma/26rFqdekciHYpF_nIEmEIqw1CkGwKdmJJe0KxEwaTwU',
            sections: [{
                review: 'Code Wiz is awesome!',
                author: 'l. Smith',
                id: 1
            },
            {
                review: 'My children love Code Wiz',
                author: 'J. Johnson',
                id: 2
            }],
            team: [
                {id: 'wb-1', name: 'Sudhir Chaudhary', title: 'Owner', picture: sudhirImg},
                {id: 'wb-3', name: 'Madison Ducote', title: 'Center Director', picture: madisonImg}
            ]
        }
    }

    render() {
        return (
            <div>
                <PopupModal location="westborough" formType="coupon" owner="Code Wiz Westborough"/>
                <FacebookMessenger location="westborough" FBPageId="104621587747524" />
                <Header location="westborough" fulllocation="westborough-ma"/>
                <Franchisee name="westborough" team={this.state.team} owner="Code Wiz Westborough"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in Westborough MA | Code Wiz</title>
                    <link rel="canonical" href="http://thecodewiz.com/westborough-ma" />
                    <meta name="description" content="Coding and Robotics For Kids in Westborough MA" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/westborough-ma" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in Westborough MA" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in Westborough Massachusetts" /> 
                    <meta name="keywords" content="coding, coding in westborough, westborough massachusetts, robotics"></meta>
                </Helmet>
            </div>
        );
    }
}

export default WestboroughPage;

