import React from 'react';
import BirthdayPartyPage from '../../birthday-parties/birthday-parties.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseBirthdayPartiesPage = () => (
    <div>
        <BirthdayPartyPage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | Birthday Parties</title>
        </MetaTags>
    </div>
);

export default FranchiseBirthdayPartiesPage;