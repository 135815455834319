import React from 'react';
import FAQPage from '../../faqs/faqs';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFaqsPage = () => (
    <div>
        <FAQPage franchisee="nashua" fulllocation="nashua-nh"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | FAQs</title>
        </MetaTags>
    </div>
);

export default FranchiseFaqsPage;