import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';
import { Helmet } from 'react-helmet';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    render(){       
        return (
            <div className="blogs_details_page">
                <Helmet>
                    <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/9189418.js"></script>
                </Helmet>
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 13, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"><span>Top 10 reasons why a coding franchise is a wise investment</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p className=""><b>According to <a className="cw-light-blue" href="https://www.entrepreneur.com/magazine">Entrepreneur magazine</a>, the franchising sector has been consistently growing its contribution to the country’s Gross Domestic Product (GDP) from 9.7% in 2014 to 13.3% in 2018 – an upward trend that is most likely to continue.</b></p>
                                            <p>In today’s business-focused world, there are so many investment options out there, so why should you go with a coding franchise?</p>
                                            <p>Ideally, you want your investment to make you feel proud to represent it because it is in line with your values. </p>
                                            <p>Many franchisees who have purchased an educational franchise say they derive tremendous satisfaction from helping children to prepare for the future in addition to owning their own business.</p>
                                            <p>That is awesome! But business is business and often times a wise investment is not built on sentiments.</p>
                                            <p>It means entering a market that is in high demand with strong longterm growth potential. In fact, according to the Forum on Child and Family Statistics, with the number of children under age 18 expected to grow to 75 million by 2025, education franchises can expect long-term growth.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Why a Coding Franchise is a Wise Investment</h3>
                                            <p>The demand for franchises that help children academically is quite high. In fact, One is truly never done learning</p>
                                            <p>And most importantly, the educational franchise sector is relatively recession-proof because giving children as many opportunities as possible to succeed in life is often a priority for families even when finances are tight.</p>
                                            <p>But why should you invest in a coding franchise over a traditional educational franchise?</p>
                                            <p>We often hear the saying, ‘education is the key.’ But not every person finds traditional learning easy or exciting. Some get bored and want to explore more modern subjects such as robotics or augmented reality, which their school might not offer.</p>
                                            <p>The demand for skills in computer science is growing worldwide, which is one of the main reasons why several global initiatives are advocating that every child should learn the fundamentals of computer coding.</p>
                                            <p>As a coding franchise owner, you can help the future generation learn how to code and prepare themselves for the 21st-century workforce.</p>
                                            <p><i>Here are further reasons why you should invest in a coding franchise:</i></p>

                                            <p><b>1. You’ll be Working for Yourself</b></p>
                                            <p>When you become a franchisee, you will become an entrepreneur, which means you’ll be working for yourself.</p>
                                            <p>This is perhaps the most straightforward reason, but a compelling one nonetheless. Being your own boss is pretty empowering – the list of advantages is quite long. </p>
                                            <p>Being in control, an opportunity to make more money, and the option of a more flexible lifestyle are some of the more alluring benefits.</p>

                                            <p><b>2. Recession-resistant</b></p>
                                            <p>A report in Entrepreneur magazine identified child-related franchises in the education industry as being almost “recession-proof.” This is primarily so because regardless of the state of the economy, children-based educational services will always remain in demand.</p>
                                            <p>Parents believe and trust in the power of education, which is why it’s one of the last things they cut back on during trying economic times. </p>

                                            <p><b>3. Affordability</b></p>
                                            <p>Educational franchises are usually easier to finance than an independent business.</p>
                                            <p>Even though learning to code institutions requires more initial working capital compared to traditional educational franchises due to infrastructural costs, the necessary amount of investment is still often quite reasonable. </p>
                                            <p>Larger franchises require a significant investment since they are run out of a permanent location and have several employees. However, these tend to generate more revenue than home-based franchises.</p>

                                            <p><b>4. Unique Business Opportunity</b></p>
                                            <p>A coding franchise is a different type of business opportunity.</p>
                                            <p>It provides you with a great chance to connect with your community and support others in a way that owning another type of franchise simply doesn’t.</p>
                                            <p>Working with students and families is something that many find more satisfying than the typical business investments. </p>

                                            <p><b>5. No Experience Needed</b></p>
                                            <p>If you’re worried that you might need a background in computer science or education businesses to start your own coding franchise, you’ll be relieved to know that you actually don’t.</p>
                                            <p>All you really need is a mind for business and a drive to succeed. As long as you intend to provide great programs and excellent customer service to them and their parents/caregivers, you’ll have a leg up when it comes to being the owner of a kid’s coding franchise.</p>
                                            

                                            <p><b>6. Higher Profitability and Earnings</b></p>
                                            <p>Educational franchises costs are generally reasonable post initial investment in infrastructure. Because of the moderate running or recurring expenses, learn to code franchises tend to make higher profits.</p>
                                            

                                            <p><b>7. Guaranteed Cash Flow</b></p>
                                            <p>Thankfully there are no annoying delayed payments because educational franchises usually charge upfront. And for situations where there isn’t upfront payment, there’s often at least a monthly payment plan in place, so there is always good cash flow.</p>
                                            

                                            <p><b>8. Guaranteed Growth</b></p>
                                            <p>With the proliferation of technology, the need for computer science skills will only increase in the future. Parents are becoming increasingly aware of the fact that practical education in S.T.E.M (Science, Technology, Engineering, and Mathematics) will give their children an advantage in the very competitive job market.</p>
                                            <p>So as long as you run a tight ship, growth is pretty much guaranteed.</p>
                                            

                                            <p><b>9. A History of Success</b></p>
                                            <p>You’ll be investing in a proven system.</p>
                                            <p>Educational franchises have a history of success both profit-wise, and with customer satisfaction, hence success is always within reach.</p>
                                            

                                            <p><b>10. A Rewarding Experience</b></p>
                                            <p>Investing in an educational franchise can be far more than financially rewarding. Many franchisees who invest in a coding franchise find doing so to be quite a rewarding experience. </p>
                                            <p>They enjoy being able to help prepare students for the 21st-century workplace, better their lives, and build long-term relationships with them and their families. Plus it’s a great opportunity to provide your community with much-needed educational support.</p>
                                            <p><i>With significant future demand, a thriving market, and a proven business model, owning a coding franchise is a wise investment.</i></p>
                                            <p><i>We at Code Wiz have built a cost-effective way of starting your business. With minimal build-out requirements, you can spend less in getting your business up and running fast.</i></p>
                                            <p><i>Your entrepreneurial dreams don’t have to wait!</i></p>
                                            <p><i>If you want to make a difference in peoples’ lives while still capitalizing on this growing industry, this is your chance. Email us now at <b><a className="cw-light-blue" href="mailto:franchise@thecodewiz.com">franchise@thecodewiz.com</a></b> to find out how.</i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
