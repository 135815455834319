import React from 'react';
import BlogPage from '../../blog/blog.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

const FranchiseBlogPage = () => (
    <div>
        <BlogPage franchisee="durham" fulllocation="durham-nc"/>
        <MetaTags>
            <title>Code Wiz - Durham NC | Blogs</title>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default FranchiseBlogPage;