import React from 'react';
import Franchisee from '../../../components/franchisee/v4-franchisee.component';
import Header from '../../../components/header/header.component';
import AboutPageImg from '../../../assets/images/Group-4.png';
import CodewizImg from '../../../assets/images/Codewiz-team.png';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

import './nashua.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';

class NashuaPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: 'Nashua Location',
            coupon: 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg',
            sections: [{
                review: 'Code Wiz is awesome!',
                author: 'l. Smith',
                id: 1
            },
            {
                review: 'My children love Code Wiz',
                author: 'J. Johnson',
                id: 2
            }],
            team: [
                {id: 'rd-1', name: 'Ravi Meka', title: 'Co-owner', picture: CodewizImg},
                {id: 'rd-2', name: 'Sandeep Alluri', title: 'Co-owner', picture: CodewizImg},
                {id: 'rd-3', name: 'Lara Friedman', title: 'Center Director', picture: CodewizImg},
                {id: 'rd-4', name: 'Jessica Riley', title: 'Center Director', picture: CodewizImg}
            ]
        }
    }

    render() {
        return (
            <div>
                {/* <PopupModal location="nashua" formType="coupon" owner="Code Wiz Nashua" description="8 week in-person sessions starting in November for $199. Online classes available too!"/> */}
                <PopupModal location="nashua" formType="coupon" owner="Code Wiz Nashua"/>
                <FacebookMessenger location="nashua" FBPageId="100301158862533" />
                <Header location="nashua" fulllocation="nashua-nh"/>
                <Franchisee name="nashua" team={this.state.team} owner="Code Wiz Nashua"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in Nashua NH | Code Wiz</title>
                    <meta name="description" content="Coding and Robotics For Kids in Nashua NH" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/nashua-nh" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in Nashua NH" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in Nashua New Hampshire" /> 
                    <meta name="keywords" content="coding, coding in nashua, nashua new hampshire, robotics"></meta>
                </Helmet>
            </div>
        );
    }
}

export default NashuaPage;

