import React from 'react';
import HeaderMenu from '../components/header-menu/header-menu.component';
import Button from '../components/button/button.component';
import codewizImg from '../assets/images/Codewiz-team.png';

const NotFound = () => (
  <div className="notfound center">
    <HeaderMenu />
    <img src={codewizImg} alt="codewiz image"/>
    <h2 className="bungee cw-dark-blue">Oops! <br/>This Page Does Not Exist</h2>
    <Button color="orange" link="/" size="medium">CLICK TO GO HOME</Button>
  </div>
);

export default NotFound;