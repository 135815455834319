import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/coding-from-a-young-age.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">August, 2022</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> Coding From a Young Age </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Theresa P.</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            {/* <h2 className="bungee">HOW LEARNING TO CODE PREPARES KIDS FOR THE 21st-CENTURY WORKPLACE</h2> */}
                                            <p>Learning to code is like learning any other language; whether it’s programming a computer or learning to speak Italian, kids have shown a huge propensity to learn and absorb these languages faster when introduced to them at an early age.</p>
                                            <p>As parents begin to discover the benefits of kids learning to code, many of their worries are put to rest. Kids can handle it! Even at a young age, when coding is put in a framework, they can understand it. They are on it like a bee on honey.</p>
                                            <p>Coding is fun, and it gets kids excited that they can create something all by themselves–sometimes creating things that most adults don’t even know how to put together!</p>
                                            <p>We will go over all the common questions, and concerns parents have when it comes to their child learning to code, so let’s get started!</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">What is Coding All About?</h3>
                                            <p>Coding is nothing more than a coder (aka a computer programmer or software developer) instructing a computer on how to perform certain tasks. These instructions are sent to the computer using code to guide it step by step.</p>                           
                                            <p>Our daily life is surrounded by technology and, thus–surrounded by code! Something as simple as when you turn on the dishwasher or the microwave or even using your cell phone as an alarm clock to wake up–these menial tasks that we do every day and take for granted are operated by tiny computer chips that are functioning the way we want them to by using code.</p>
                                            <p>How long to microwave your popcorn, what setting you want your dishwasher to operate under, what time you want to wake up for work the following day- all of these are tasks that you are creating and are delivered via codes.</p>
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">At What Age Can Children Learn to Code?</h3>
                                            <p>Experts have concluded that kids can learn to code as young as 5 years old! In 1991, Swiss psychologist Jean Piaget determined that at age 5, children are in a pre-operational phase of learning. This means that they can understand the world through the use of mental imagery represented by words or images. </p>
                                            <p>Some parents may be concerned that this sort of instruction is just too technical for a child’s mind to understand. Some kids get easily frustrated and overwhelmed when learning new things, or parents believe their child is not at the right stage of development to handle learning this skill, as it appears to them to be far too technical.</p>
                                            <p>Do not worry! There are gamified methods of introducing coding to youngsters that are fun and engaging, that kids take to immediately, as it is as simplified a process as them stacking blocks on top of each other. In fact, it is called Block Based Coding, and there are several different apps and websites that teach kids coding using this kid-friendly method. Colorful and vibrant blocks that they can move around to give different commands so they can learn the foundation of what coding is all about.</p>
                                            <p>Every child learns at a different pace. At The Code Wiz, our Drag N’Drop coding courses start at the age of 7 years old. If you want to introduce your child to coding at home earlier, you are setting them on the right path to learning a valuable skill!</p>
                                        </div>
                                         <div className="blogs_details_block">
                                            <a href="http://thecodewiz.com/findmycourse">Code Wiz Tip : Want to learn about our block based coding instruction for kids?</a>
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>For more information on how to get your child started in the world of coding, click here to find your nearest Code Wiz location and register today!</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
