import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import CodewizCommitment1 from '../assets/images/blog-pictures/codewiz-commitment1.jpg';
import CodewizCommitment2 from '../assets/images/blog-pictures/codewiz-commitment2.jpg';
import CodewizCommitment3 from '../assets/images/blog-pictures/codewiz-commitment3.jpg';
import CodewizCommitment4 from '../assets/images/blog-pictures/codewiz-commitment4.jpg';
import CodewizCommitment5 from '../assets/images/blog-pictures/codewiz-commitment5.jpg';
import CodewizCommitment6 from '../assets/images/blog-pictures/codewiz-commitment6.jpg';
import CodewizCommitment7 from '../assets/images/blog-pictures/codewiz-commitment7.jpg';
import CodewizCommitment8 from '../assets/images/blog-pictures/codewiz-commitment8.jpg';
import CodewizCommitment9 from '../assets/images/blog-pictures/codewiz-commitment9.jpg';
import CodewizCommitment10 from '../assets/images/blog-pictures/codewiz-commitment10.png';

import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">April 18, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>CODE WIZ’S COMMITMENT TO THE COMMUNITY | PRINTS 3D MASKS AMID COVID-19 </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Claudia</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Code Wiz is committed to supporting our community during these uncertain times. We understand COVID-19 has made it difficult for many. Sadly we have all witnessed how this virus has impacted our families, our communities, and our life. What we are facing is difficult, but we believe in supporting each other. Everyday acts of kindness is what keeps our community and people strong.</p>
                                            <p>While we remain optimistic, we have made strides in helping our community any way we can. We wanted to let you know that we are here for each other.</p>
                                            <p>Our franchised location, <a href="https://dev.thecodewiz.com/reading-ma/">Code Wiz Reading</a>, has came up with a plan to support the Health Care Community amid COVID-19. It all began with one question, how can we help the community using what we know and have around us?. They then came up the idea of using their location’s 3D printer to print their own face shield designs.</p>
                                            <p>Code Wiz Reading stated, “Since the healthcare professionals have been wearing the masks all day, the bands are chewing into their ears. It puts pressure on the head and causes friction against the ears. – some of the nurses were complaining about the mask straps giving horrible headaches, so we started 3D Printing these straps to go around the back of the head. We are calling them “Ear Savers” for the lack of a better word.”</p>
                                            <p>So far, they have <b>printed more than 1000 and distributed them as needed.</b> They encouraged people to spread the word to help support those health professionals in need.</p>
                                            <p>“Happy to stay up all night printing!”, said Sandeep and Ravi from <a href="https://dev.thecodewiz.com/reading-ma/">Code Wiz Reading.</a> “It’s really nice to see the entire community come together to help our medical heroes and first responders. We encourage everyone to find a way, small or big, to support the fight against COVID-19.”</p>
                                            <p><b>“A nurse on front lines at Beth Israel Deaconess Medical Center in Boston using the “Ear Saver”.</b></p>
                                            <div className="row">
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment1} alt="Codewiz Commitment 1" />
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment2} alt="Codewiz Commitment 2" />
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment3} alt="Codewiz Commitment 3" />
                                            </div>
                                            

                                            <p>“Very happy to play a small part. We are grateful for medical professionals like you, we are truly humbled to see this use come from our 3D printing. This is just another classic example of why one should embrace new technology and it’s application.”  Code Wiz Reading’s idea of 3D Printed Face shield designs have been inspiring.  They have provided some help to a couple of ER nurses, they have done their part in a very small way and with limited resources and we appreciate their support.</p>
                                            <div className="row">
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment4} alt="Codewiz Commitment 4" />
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment5} alt="Codewiz Commitment 5" />
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment6} alt="Codewiz Commitment 6" />
                                            </div>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">THE SUPPPORT CONTINUES AT CODE WIZ WESTFORD</h3>
                                            <p>The fight against COVID-19 has been a personal one for our CEO Ruth Agbaji. Besides her business being severely impacted, her husband, sister and brother in law are all Physicians and are at the frontlines of the fight against Coronavirus.</p>
                                            <p>“It can be paralyzing to think about all the potential negative outcomes and the “what if” scenarios but taking a cue from our neighbor franchise <a href="https://web.facebook.com/codewizreading/?eid=ARA9eSbZ7pRytYSGffhKJpqu7Dr2xVmJTwZzdJltew8AhoKOT-IAT8jxLOOqj4bLb_K7iX35zUN8LJ1V&fref=mentions&__xts__[0]=68.ARDStiDcyaKIH1U7pjRbPgG8OAgF3CxM4RZHeiXZIM8xVCwTaIq-B9Q4o36kATss-OzN3q_KyjBSjwIsMK5Pvm3y6QSaitslQPC_-4Rb9D9HTt_Ep61beKPBwyqY2eN7ti-VnuM8LvoC0yyHXMm4gBWDEqr80jz-p8wMQEWye-61Zam1Ol8SQwbxFfLre9gLv__reXzz4M7kOfjtghRUeEcSebu88tbIfRFIZj4F-dFPcn84lvq0b1Y4WlBzGKYfe3u6yHn-EnH46htIT0Vg1vNGoojHUYItcPGlEBgR37kpQsc1TcmGMkR9luQZFvufvASM8bc-O0BXIx9m8aUyFbvDgw&_rdc=1&_rdr">Code Wiz Reading MA</a>, we’re doing something about it! We have decided to create and print Ear Supporting Straps and Mask/Visor straps. These straps have been created with a 3D printer and are easy to use. Our first batch of straps we ready to go last week, wiped, sanitized and sent with lots of thoughts and prayers to our friendly nurse and medical staff.”</p>
                                            <p>“We’re certainly not set up to mass-produce this but if we <b>can produce 100-500 of these and give them out for FREE</b>, then we know that we contributed at least a little and did not let fear paralyze us. Ruth’s husband (pictured) especially appreciated the mask strap as his ears hurt a lot from wearing the mask all day.”</p>
                                            <div className="row">
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment7} alt="Codewiz Commitment 7" />
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment8} alt="Codewiz Commitment 8" />
                                                <img className="col-md-4 col-xs-12" src={CodewizCommitment9} alt="Codewiz Commitment 9" />
                                            </div>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Our kids are not left out! Everyone lent a hand to help out! </h3>

                                            <iframe width="900" height="506" src="https://www.youtube.com/embed/OJjFjNhoA9Y" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                                            <iframe src="https://web.facebook.com/plugins/video.php?href=https%3A%2F%2Fweb.facebook.com%2Fcodewizreading%2Fvideos%2F975432099521445%2F&show_text=0&width=476" width="476" height="476" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe>

                                            <p>We also launched another initiative targeted towards neighborhood kids. We launched a safe and private server for the super popular Minecraft game. The goal was to provide a safe haven and virtual playground for the kids to hang out online and have fun for 2 hours a day while parents tried to get some work done!</p>

                                            <img src={CodewizCommitment10} alt="Codewiz Commitment 10" />


                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
