import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseInPersonClassesPage = () => (
    <div>
        <PopupModal location="nashua" formType="coupon" owner="Code Wiz Nashua"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <FallClassesPage franchisee="nashua" fulllocation="nashua-nh" formType="coupon" owner="Code Wiz Nashua"/>
        <MetaTags>
            <title>Code Wiz - Nashua NH | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;