import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

import LocationPageImg from '../../assets/images/find-a-location-header-image.png';
import LocationPageMobileImg from '../../assets/images/find-a-location-header-image-1.jpg';

import PricingPageImg from '../../assets/images/pricing.jpg';

import './pricing.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';
import MonthlyClassImg from '../../assets/images/monthly-classes.png';
import InPersonCampsImg from '../../assets/images/in-person-camps.png';
import OnlineCampsImg from '../../assets/images/online-camps.png';


class PricingPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            defaultdata: {
                phone: "1(844) 263-3949",
                email: "info@thecodewiz.com",
                franchise: "yes",
                locations: "/locations" 
            },
            showModal: true
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        let showData = this.state.showModal;
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state.defaultdata;
            floatingdata.push(<FloatingMenu contactlink={aboutdata.email} franchise={aboutdata.franchise} phone={aboutdata.phone} location={aboutdata.locations}/>);
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={PricingPageImg} />
                    <title>{`Coding Schools for Kids | Coding Courses for Kids - ${aboutdata.city} ${aboutdata.state}`}</title>
                    <meta name="description" content="Are you interested in taking a coding class or kids coding classes near you? Click here to see a list of our locations and to find a location near you!"/>
                    <meta name="keywords" content="coding for kids, kids, coding, coding camps"></meta>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="pricing-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">Pricing</h2> 
                                    <h4 className="header-text cw-dark-blue">We make it fun and easy to learn code, wherever you are! Code Wiz offers a range of coding classes, flexible schedules, and expert coaches in convenient locations as well as online.</h4>
                                    {/* <Button color="orange" link="https://www.firstlegoleague.org/" size="medium">FIND A CENTER NEAR ME</Button> */}
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">    
                                <img src={PricingPageImg} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper pricing-wrapper">
                        <div className="container find-a-location-container">
                            <div className="row"> 
                                <div className="text abs right col-md-12">
                                    {/* <h3 className="bungee cw-dark-blue">find a code wiz center near you</h3> */}
                                    {/* <input className="location-input" type="text" placeholder="ZIP, POSTAL CODE OR CITY"/> */}
                                </div>
                            </div>
                        </div>
                        <div className="container empty-space-half bw-4"></div>
                        {/* <div className="container empty-space bw-5"></div> */}
                    </div>
                    
                    <div className="pricing-links-container ">
                        <div className="row offer-pricing-boxes">
                            <div className="col-md-3 box"> 
                                <img className="pricing-image" src={MonthlyClassImg}/>
                                <h4 className="cw-dark-blue bungee center">Monthly Classes</h4>
                                <h3 className="cw-orange center"><b>$145.00 / Month</b></h3>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-users"></i></span> Student-To-Coach Ratio: 4:1</h5>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-clock-o"></i></span> Class Length: 1 hour</h5>
                                <p>We can have some text here if we want to explain a bit more</p>
                                <Button color="light-blue" link="" size="small">REGISTER NOW</Button>
                            </div>

                            <div className="col-md-3 box"> 
                                <img className="pricing-image" src={InPersonCampsImg}/>
                                <h4 className="cw-dark-blue bungee center">In Person Camps</h4>
                                <h3 className="cw-orange center"><b>$349.00 / Week</b></h3>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-users"></i></span> Student-To-Coach Ratio: 4:1</h5>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-clock-o"></i></span> Class Length: 1 hour</h5>
                                <p>We can have some text here if we want to explain a bit more</p>
                                <Button color="light-blue" link="" size="small">REGISTER NOW</Button>
                            </div>

                            <div className="col-md-3 box"> 
                                <img className="pricing-image" src={OnlineCampsImg}/>
                                <h4 className="cw-dark-blue bungee center">Online Camps</h4>
                                <h3 className="cw-orange center"><b>$285.00 / Week</b></h3>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-users"></i></span> Student-To-Coach Ratio: 4:1</h5>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-clock-o"></i></span> Class Length: 1 hour</h5>
                                <p>We can have some text here if we want to explain a bit more</p>
                                <Button color="light-blue" link="" size="small">REGISTER NOW</Button>
                            </div>

                            <div className="col-md-3 box"> 
                                <img className="pricing-image" src={MonthlyClassImg}/>
                                <h4 className="cw-dark-blue bungee center">Birthday Parties</h4>
                                <h3 className="cw-orange center"><b>$145.00 / Month</b></h3>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-users"></i></span> Student-To-Coach Ratio: 4:1</h5>
                                <h5 className="cw-dark-blue"><span><i className="fa fa-clock-o"></i></span> Class Length: 1 hour</h5>
                                <p>We can have some text here if we want to explain a bit more</p>
                                <Button color="light-blue" link="" size="small">REGISTER NOW</Button>
                            </div>
                        
                        </div>
                        <br />
                        <br />
                        <h4 className="bungee cw-dark-blue"></h4>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}
export default PricingPage;