import React from "react";
import ReactDOM from "react-dom";
import { MessengerChat } from "react-messenger-chat-plugin";
import './facebook-messenger.styles.scss';

const FacebookMessenger = ({location, FBPageId}) => {
    return (
        <div className="FacebookMessengerDiv">
        <MessengerChat
            pageId={FBPageId}
            language="en_US"
            themeColor={"#06324e"}
            bottomSpacing={50}
            loggedInGreeting="Hello! Do you have any questions for us?"
            loggedOutGreeting=""
            greetingDialogDisplay={"show"}
            debugMode={true}
            onMessengerShow={() => {
            console.log("onMessengerShow");
            }}
            onMessengerHide={() => {
            console.log("onMessengerHide");
            }}
            onMessengerDialogShow={() => {
            console.log("onMessengerDialogShow");
            }}
            onMessengerDialogHide={() => {
            console.log("onMessengerDialogHide");
            }}
            onMessengerMounted={() => {
            console.log("onMessengerMounted");
            }}
            onMessengerLoad={() => {
            console.log("onMessengerLoad");
            }}
        />
        </div>
    )
};
export default FacebookMessenger;