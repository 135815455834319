import React from 'react';
import FallClassesPage from '../../fall-classes/fall-classes.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFallClassesPage = () => (
    <div>
        <FallClassesPage franchisee="reading" fulllocation="reading-ma"/>
        <FacebookMessenger location="reading" FBPageId="2544691755571962" />
        <MetaTags>
            <title>Code Wiz - Reading MA | Online Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseFallClassesPage;