import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


//import TeamMember from '../team-member/team-member.component';
 
export default function ReviewSlider(props) {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true
  };
  return (
    <Slider {...settings}>
      <div className="review-slide"><div className="row"><div className="col-md-12">I don't write reviews often, if ever really. I do have to write one for CodeWiz. My son has been taking classes here for several months. It's the highlight of week! The instructors are WONDERFUL and have really taken the time to help nurture and expand my son's knowledge of coding. The manager is fabulous and doesn't just run it as a business, but has truly taken the time to get to know my son's skills to place him in the right groups. Anyone who is thinking about their children joining classes here should do it!</div></div></div>
      <div className="review-slide"><div className="row"><div className="col-md-12">My 11 yr old attended a session at Code Wiz and declared it the best session he has attended. Ruth, the owner, is fantastic and made sure the content matched the skill level. He came home each day excited to show what he learned and created. Highly, highly recommend!!</div></div></div>
      <div className="review-slide"><div className="row"><div className="col-md-12">My kids have thoroughly enjoyed the camps and classes they have taken at Code Wiz. They are one of the first places I look when setting up camps each summer.</div></div></div>
      <div className="review-slide"><div className="row"><div className="col-md-12">We love CodeWiz! My 8 year old has been attending First Lego League Jr and virtual Minecraft Coding for several years and he really  looks forward to his weekly classes. The staff are all incredibly responsive and are a pleasure to interact with.</div></div></div>
      <div className="review-slide"><div className="row"><div className="col-md-12">My son has learned a tremendous amount in learning how to program gaming with Unity.   He also looks under the hood to make minor adjustments to the source code.  Life long skills learned here!</div></div></div>
      <div className="review-slide"><div className="row"><div className="col-md-12">Our son has attended camp here and has had two birthday parties at Code Wiz. He always has a great time here! We love the friendly and knowledgeable staff who go out of their way to make sure everyone is having fun.</div></div></div>
      
    </Slider>
  );
}