import React from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../../../../components/header/header.component';
import Button from '../../../../components/button/button.component';
import LearnToCodeHeaderImg from '../../../../assets/images/bubble-33.jpg';
// import InPersonCamp from './assets/docs/in-person-camps-westford.pdf';
import CodingInterestBlog from '../../../../assets/docs/westboro-coding-interest.pdf';
import PopupModal from '../../../../components/popup-modal/popup-modal.component';

const CodingInterestBlogPage = () => (
    <div>
        <PopupModal location="westborough" formType="grandopening" owner="Code Wiz Westborough"/>
        <Header location="westborough" fulllocation="westborough-ma"/>
        <MetaTags>
            <title>Code Wiz - Westborough MA | How To Get Your Child Interested In Coding</title>
        </MetaTags>
        <div className="container header-text-container in-person-brochure">
            <div className="row">
                <div className="top-header col-sm-12 col-md-6"> 
                    <div className="text">
                        <h3 className="header-title cw-dark-blue bungee">how to get your child interested in coding</h3> 
                        <h4 className="header-text cw-dark-blue">Enjoy Your Reading!</h4>    
                        <Button color="orange" link={CodingInterestBlog} size="large">CLICK HERE TO READ BLOG</Button>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6 expanded_image">
                    <img src={LearnToCodeHeaderImg} alt="online fall coding"/>
                </div>
            </div>
        </div>
    </div>
);

export default CodingInterestBlogPage;