import React from 'react';
import Header from '../../../components/header/header.component';
import TutorDoctor from '../../../components/tutor-doctor/tutordoctor.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

const TutorDoctorPage = () => (
  <div>
    <MetaTags>
      <title>Code Wiz - Westford MA | Tutor Doctor US & Canada</title>
    </MetaTags>
    <Header location="westford" fulllocation="westford-ma"/>
    <TutorDoctor franchisee="westford" fulllocation="westford-ma"/>
    <Helmet>
        <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
    </Helmet>
  </div>
);

export default TutorDoctorPage;