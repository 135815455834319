import React from 'react';
import ThankYouImg from '../../assets/images/thankyou_girl.png';
import './thank-you.styles.scss';

const ThankYou = () => (
  <div className="thankyou_section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="thankyou_message">
            <img src={ThankYouImg} className="" /><br/>
            <h1 className="bungee orange_txt">THANK YOU</h1>
            <p className="black_txt">Thank You For Contacting Us. We Will Get Back To You Shortly.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ThankYou;