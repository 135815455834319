import React from 'react';
import Header from '../../../components/header/header.component';
import LegoLeague from '../../../components/lego-league/lego-league.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const LegoLeaguePage = () => (
  <div>
    <Header location="lawrenceville" fulllocation="lawrenceville-ga"/>
    <LegoLeague franchisee="lawrenceville" formType="coupon" owner="Code Wiz Lawrenceville"/>
    <FacebookMessenger location="lawrenceville" FBPageId="103895748883361" />
    <MetaTags>
          <title>Code Wiz - Lawrenceville GA | FLL</title>
      </MetaTags>
      <Helmet>
        <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
      </Helmet>
  </div>
);

export default LegoLeaguePage;