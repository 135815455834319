import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 13, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>MINECRAFT AS AN EDUCATIONAL TOOL: THE EDUCATIONAL ASPECT OF THE BEST SELLING GAME EVER  </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth Agbaji</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Video games have become increasingly popular since the turn of the millennium. Players, particularly kids, spend an enormous amount of hours playing them.</p>
                                            <p>Contrary to popular belief that playing video games makes kids lazy, research has shown that some video games can be significantly beneficial.</p>
                                            <p>Educational technology researchers have found that video games have <a href="https://doi.org/10.1177/004005990503700304">positive effects on a person’s thinking</a> as well as <a href="https://doi.org/10.1016/S0749-3797(02)00570-6">providing particularly favorable conditions for learning. </a></p>
                                            <p>Minecraft, the bestselling video game of all time (180 million), is one of those video games.  </p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">MINECRAFT AS AN EDUCATIONAL TOOL - <i>IN THE CLASSROOM</i></h3>
                                            <p>Not only has Minecraft captivated a generation of children (over 100 million users log on per month), teachers have also found enormous <a href="https://www.thecodewiz.com/the-benefits-of-minecraft-for-kids/">benefits</a> from incorporating the game into their classrooms as a teaching tool.</p>
                                            <p>Since the launch of the education edition in 2016, thousands of classrooms around the world from schools in America and Canada to New Zealand, the UK, and Australia have adopted Minecraft as an educational tool.</p>
                                            <p>The Victorian Department of Education in Australia even declared it a ‘must-have’ software package, ensuring all teachers and students have free access to the software in schools.</p>
                                            <p>However, there is still skepticism about the ubiquity of online games such as Minecraft and Roblox. Not everyone has found the openness of the online world of these games so hospitable.</p>
                                            <p>Furthermore, researchers have cautioned on the possible negative impacts of youth exposure to inappropriate behavior or problems associated with gaming addiction. But others think these concerns are exaggerated and it boils down to a matter of balance. </p>
                                            <p>It’s pretty rare for a global sensation of a video game-like Minecraft to be of true educational value. So, given its immense appeal among youngsters, it’s plausible to question Minecraft’s educational value. </p>
                                            <p>But many are largely unaware of the educational aspects of Minecraft and even more, are oblivious of the educational foundations on which it was built.</p>
                                        </div>
                                          <div className="blogs_details_block">
                                            <h3 className="bungee">EUROPEAN INFLUENCE</h3>
                                            <p>For those unfamiliar with Minecraft, the popular game is like digital LEGO – a digital version of classic block building, where players create interesting new environments, then go on adventures with what they build.</p>
                                            <p>Playing with blocks has deep cultural roots in Europe.</p>
                                            <p>In European tradition, block-play was regarded as a “wholesome” activity that appeased fears of children losing touch of physical skills due to the industrialization and the mechanization of society back then. </p>
                                            <p>European philosophers have long promoted block-based games as a form of play that cultivates abstract thought and as an effective way of educating children.</p>
                                            <p>The inventor of Kindergarten Friedrich Froebel developed block-based toys that he claimed would illustrate the spiritual connectedness of all things.</p>
                                            <p>This led to educators like Maria Montessori, who invented the <a href="https://amshq.org/Families/Why-Choose-Montessori">Montessori Method of Education</a>, picking up on the concept and pioneering the teaching of math through wooden devices.</p>
                                            <p>A native of Sweden, the inventor of Minecraft and founder of Mojang Markus Persson was later inspired by such cultural influences.</p>
                                            <p>The old European philosophy of “Making Things With Your Hands Makes You Smarter” (Sloyd Education) of construction play where children would start with simple blocks, then build up to more complex patterns are evident in Minecraft.</p>
                                            <p>Players are given tools to start to build a civilization they can live. And wood is one of the first resources new players of Minecraft gather upon entering the game, using their avatar’s hand to chop down trees to produce blocks of it. </p>
                                        </div>
                                            <div className="blogs_details_block">
                                            <h3 className="bungee">TEACHING WITH MINECRAFT</h3>
                                            <p>Though Minecraft was not designed with any educational purpose in mind, its link between manual skill and cognitive development has allowed it to succeed Lego as the heir to the heritage of playing with blocks, and in a much more impactful way.</p>
                                            <p>In this technological era where policymakers are eager to increase kids’ interest in the STEM (Science, Technology, Engineering, and Math) fields with several ‘teach kids how to code’ initiatives, Minecraft has become one of the most convenient and effective educational tools.</p>
                                            <p>The game’s structure facilitates a transition from playing with virtual blocks to learning how to write code. So educators have incorporated Minecraft into the classroom as a teaching tool. From math to history or to explaining physics concepts like gravity, teachers are using the game to teach their students.</p>
                                            <p>Even libraries have installed Minecraft on their computers.</p>
                                            <p>The popular sandbox game is beloved by educators because, like the Sloyd or the Montessori Method of Education, Minecraft enables students to imagine, explore and create in a completely different way than in a traditional classroom.</p>
                                            <p>And so far the adoption of Minecraft as an educational tool in classrooms has proven to be effective.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">THE IMPACT OF USING MINECRAFT AS AN EDUCATIONAL TOOL</h3>
                                            <p>In a survey (Griffith University – Australia) that asked teachers about the usefulness of Minecraft as an educational tool in the classroom, there was an overwhelmingly positive response for using Minecraft as a teaching tool.</p>
                                            <p>Teachers reported “high levels of motivation and participation” from students when they used the game as a teaching tool. The survey showed that 94 percent of respondents of the study agreed that students are more motivated and have higher participation.</p>
                                            <p>This is seen as significant because motivation and willingness to participate are key components of effective learning. Kids tend to enjoy participation in video games and enjoyment also improves motivation, which in turn improves learning.</p>
                                            <p>Another study of elementary students using Minecraft in school, found significant improvements in creativity, collaboration, problem-solving, and computational thinking skills, with 98 percent of teachers surveyed cited problem-solving as the top skill their students learn from the game.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">CONCLUSION</h3>
                                            <p>When Minecraft is used as an educational tool, kids are doing more than just playing a game, they’re also engaged in an engaging educational experience that encourages problem-solving, creativity, planning, execution and learning how to code. </p>
                                            <p>If you want your child to take Minecraft beyond the basics, you can enlist them in one of our summer coding camps for kids programs.</p>
                                            <p>Our <a href="https://www.thecodewiz.com/westford-ma/sessions/">Minecraft summer camps</a> will empower your kid to imagine, create, and share amazing mods, texture packs, and schematics while learning coding concepts through badges and projects. </p>
                                            <p>You can <a href="https://www.thecodewiz.com/westford-ma/contact-us/">contact us</a> to learn more. </p>
                                        </div>
                                    
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
