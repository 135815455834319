import React from 'react';
import CompetitionsPage from '../../competitions/competitions.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseCompetitionsPage = () => (
    <div>
        <CompetitionsPage franchisee="nashua"/>
        <FacebookMessenger location="nashua" FBPageId="100301158862533" />
        <MetaTags>
            <title>Code Wiz - Nashua NH | Competitions</title>
        </MetaTags>
    </div>
);

export default FranchiseCompetitionsPage;