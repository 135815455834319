import React from 'react';
import AboutPage from '../../about-us/about-us.component';
import MetaTags from 'react-meta-tags';

const FranchiseAboutPage = () => (
    <div>
        <AboutPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | About Us</title>
        </MetaTags> 
    </div>
);

export default FranchiseAboutPage;