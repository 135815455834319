import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">June 22, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>  AFTER SCHOOL CODING CLASSES </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Seodeveloper </a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">EASY STRATEGIES FOR SETTING UP YOUR CHILD FOR CODING SUCCESS</h3>
                                            <p>Coding is a futuristic skill that can open immense opportunities for young learners. It makes a smart choice for parents who want their kids to derive clear benefits at present and in the future. By sending your kid for after school coding classes, you can expect them to become sharper and more confident right now. And there is the obvious advantage of bright career prospects once they grow up. But ultimately it boils down to setting up your child for coding success because you wouldn’t just want them to imbibe the skill but use it in the real sense. Here are some easy strategies that can help.</p>

                                            <h3 className="bungee">SELECT THE RIGHT PROGRAM</h3>
                                            <p>When you enrol your child for coding classes, pay special attention to the program you choose for them. Not all coding programs are the same and you need to pick one that matches the age, aptitude and skill level of the student. Look for a school that doesn’t work on a standard curriculum but offers tailored courses.  Check the skill levels of the coaches as well because having someone knowledgeable and experienced to teach your child would be worthwhile.</p>

                                             <h3 className="bungee">PAY ATTENTION TO THE LEARNING ENVIRONMENT</h3>
                                            <p>While the right curriculum matters, you cannot be less concerned about the right learning environment as well. Though coding should be a learning experience, it should be fun as well. Sending your child to <a href="https://dev.thecodewiz.com/after-school-coding-classes/">after school coding classes</a> is a great idea because it can be a dedicated place for specialized learning. A place with a positive environment is ideal. Your child will get access to the best coaches and like-minded students which can be helpful in enhancing their skills and interest in coding.</p>

                                            <h3 className="bungee">ENCOURAGE CREATIVITY AND AUTONOMY</h3>
                                            <p>Though coding sounds more like math and science, it has a lot to do with creativity. So if you want your child to really enjoy the subject and think of training in it for the long run, encourage creativity and autonomy. Give them the freedom to choose their course curriculum or decide the school they would want to join. A good learning center will replicate this approach as well.</p>

                                            <h3 className="bungee">LEARN THE LANGUAGE</h3>
                                            <p>As a parent, speaking the same language as your child does makes you capable of understanding their challenges and encouraging them. Why not learn the basics of coding so that you can be with them all along their learning journey. Even if you don’t have the background, try accessing online resources to pick the basics. You may even consider signing up for adult classes.</p>
                                            <p>When young children learn to code, it is more like learning a new language. Surely, they would need time, patience and consistent practice to polish the skill. Be there for them and encourage them to keep going. And look for a coding school that works with the same mindset and principles. Let them enjoy the learning!</p>

                                            

                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
