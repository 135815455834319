import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">February 3, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>HOW ROBLOX CODING TEACHES KIDS TRANSFERABLE SKILLS THAT GROOMS THEM FOR THE FUTURE</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>If you have kids who indulge in computer games chances are they have spent a great deal of time playing Roblox.</b></p>
                                            <p>It’s wildly popular, and with over 90 million monthly active users,  it’s ranked number one in online entertainment for kids under 13, and second for teens ages 13 – 17.</p>
                                            <p>The popular kids’ online gaming platform has its critics. But as with every product, there are positives and negatives. And in the case of Roblox, the pros outweigh the cons.</p>
                                            <p>In this blog, we will look at how learning Roblox Coding, the programming engine that runs the online gaming platform, is providing kids with highly marketable skills that can facilitate opportunities for them in the future.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">THE BENEFITS OF ROBLOX</h3>
                                            <p>Clearly, there is room for improvement with Roblox, but the online gaming platform is a great example of how today’s youth are learning to code by playing games.</p>
                                            <p>Combining education and gaming is nothing new as, if you’re a parent, you might be familiar with Sim City – the classic open-ended city-building video game series.</p>
                                            <p>However, with advances in both computing and graphical power, today’s educational games offer children a lot more. Roblox is a great example of how a tech company can capitalize on this trend.</p>
                                            <p>They’ve taken a different approach by making coding a large component of its platform.</p>
                                            <p>Roblox is a UGC (User-generated Content) platform that doesn’t produce any game but instead fuels the imaginations of the more than 2 million independent developers and creators to build the over 56 million worlds. These interactive 3D experiences make Roblox unique.</p>
                                            <p>By facilitating such a collaborative, immersive space for shared experiences, Roblox has created an instinctive need in its young users to channel their imagination and tap into their creativity to create their own experiences.</p>
                                            <p>This is what makes Roblox unique.</p>
                                            <p>Its combination of robust tools, ease of multiplayer development, and entrepreneurial marketplace make it one of the most exciting kids coding platforms in the world.</p>
                                            <p><i>With that said, let’s look at some of the main benefits Roblox provides to kids.</i></p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">1. LEARNING ROBLOX CODING TEACHES KIDS TRANSFERABLE TECH SKILLS</h3>
                                            <p>Roblox developers learn the important skill of problem-solving by fixing errors or debugging, which is an essential part of software development.</p>
                                            <p>Video game programming uses the same languages and coding concepts widely employed in database programming, machine learning, or even web development.</p>
                                            <p>In Roblox coding, users will learn concepts such as loops, arrays, switch statements, methods, inheritance, and more.</p>
                                            <p>The game designing aspect of Roblox Studio allows users to tap into their creative side and teaches the increasingly valuable skill of UI/UX (User Experience/User Interface Design).</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">2. ROBLOX CODING FEATURES LUA</h3>
                                            <p>On Roblox Studio users will learn <a href="https://en.wikipedia.org/wiki/Lua_(programming_language)">Lua</a> – the programming language used on Roblox. It’s the language that the platform’s native code editor uses.</p>
                                            <p>Lua is a versatile scripting language that has been used to make thousands of games, business applications, and other software.</p>
                                            <p>When using Lua, kids will learn important coding concepts such as conditionals, variables, loops, etc.</p>
                                            <p>It’s a great beginner language to learn when kids are ready to move past visual block coding to real text-based computer programming.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h2 className="bungee">WE AT CODEWIZ UNDERSTAND THE IMPORTANCE OF KIDS LEARNING THE ROBLOX CODING LANGUAGE THUS WE ARE HOSTING A <a href="https://dev.thecodewiz.com/roblox-kids-coding/">KIDS CODING SUMMER CAMP</a> THIS JUNE. </h2>
                                            <h3 className="bungee">3. ROBLOX IS INVESTING BIG INTO STEAM EDUCATION</h3>
                                            <p>Roblox has been building an impressive computer science and game design education division that could soon rival that of Microsoft’s Minecraft.edu.</p>
                                            <p>Currently, <a href="https://education.roblox.com/en-us">Roblox Education</a> is developing several resources such as tutorials, handouts, lesson plans, etc. for educators and camp directors.</p>
                                            <p>STEAM Education is an approach to learning that uses Science, Technology, Engineering, the Arts and Mathematics to encourage students to engage in experiential learning, persist in problem-solving, embrace collaboration, and work through the creative process. These are the innovators,</p>
                                            <p>The objective of Roblox Education is to teach kids of all ages real coding, game design, digital citizenship, and entrepreneurial skills that will propel them to become innovators and leaders of the 21st century.</p>
                                            <p>We went into more depth on how Roblox can be used by educators as an effective teaching tool in our blog entitled Roblox as a Teaching Tool.</p>

                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">4. ROBLOX STUDIO IS A POWERFUL GAME DEVELOPMENT ENGINE</h3>
                                            <p>Roblox is not your typical 2D pixel art game development platform. Roblox Studio’s creative suite provides kids with virtually unlimited tools to make enticing, professional-quality 3D games that are addictive to play and instantly shareable with friends.</p>
                                            <p>It’s a kid-friendly yet powerful, yet game development engine. It makes multiplayer game development much easier than professional engines such as Unity and Unreal Engine 4 by handling the more monotonous aspects of coding such as client-server communication and rendering.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">5. FACILITATES ENTREPRENEURSHIP</h3>
                                            <p>Users can earn money from selling in-game upgrades in exchange for Robux – a sort of virtual currency that can be converted to cash.</p>
                                            <p>Roblox’s creative suite adds an entrepreneurial component that allows gamers to sell user-created content.</p>
                                            <p>Roblox paid $30 million to its user-developers in 2017. As more users create more content for the platform the pay-outs are expected to grow.</p>
                                            <p>It takes ingenuity and persistence to make serious money on the platform. But thousands have already succeeded, including several teenage developers who have become millionaires.</p>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">BOTTOM LINE</h3>
                                            <p>Roblox may have its flaws, but it allows kids to create, play, explore, socialize and, most importantly, learn in a self-directed way.</p>
                                            <p>Ultimately, the platform can be very beneficial for kids, providing an opportunity for them to gain valuable skills they can use to build for the future.</p>
                                            <p><i>If you want your child to take advantage of these benefits you can check out our Roblox Coding classes and Roblox Vacation Sessions <a href="https://www.dev.thecodewiz.com/how-roblox-coding-teaches-kids-skills-that-grooms-them-for-the-future/blank">here</a>.</i></p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
