import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';
import HeaderImage from '../../assets/images/bubble-36.png';

import './misc-pages.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';


class CodingSchoolForKidsLocation extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            // defaultdata: {
                state: "",
                city: "",
                territory: ""
            //}
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state;
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={HeaderImage} />
                    <title>{`Coding School For Kids ${aboutdata.city} ${aboutdata.state} | The Code Wiz`}</title>
                    <meta name="description" content={`If you are looking for the top Top Coding School For Kids ${aboutdata.city} ${aboutdata.state}, to open this amazing opportunity for your child, Code Wiz is the name to trust.`}/>
                    <meta name="keywords" content={`Coding School For Kids ${aboutdata.city} ${aboutdata.state}`}></meta>
                </MetaTags>
                <Header />
                <div className="technovation-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee"> {`Explore the best online fall classes for kids in ${aboutdata.city}, ${aboutdata.state}`}</h2> 
                                    
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={HeaderImage} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper technovation-wrapper online-fall-classes-wrapper">
                        <div className="container technovation-top">
                            <div className="row">
                                <p>The recent years have witnessed coding and robotics gaining immense popularity. While kids find these skills compelling, parents want their children to learn them for best career prospects. If you are looking for the top Top Coding School For Kids {aboutdata.city} {aboutdata.state} to open this amazing opportunity for your child, <a href="http://www.thecodewiz.com">Code Wiz</a> is the name to trust. We have been around for many years and have trained a large number of students in these years. With our industry experience, we understand the value of tailored curriculum and integrate the system in our training approach. Rather than taking a one size fits all approach, we understand the skill levels, aptitude and preferences of each of the students and provide a tailored curriculum for them. So you can be sure that your child learns exactly what they want to and they want to learn even more. While we offer after school coding classes, there is also the option of summer classes. And the child can enrol whenever you want them to, with no long term contracts rather a monthly subscription model. What’s more, we even have online classes to keep your kids engaged during the COVID season.</p>
                                <p><b>Encouraging Learning By Creating Interest</b></p>
                                <p>When it comes to teaching coding and robotics skills, everything boils down to capturing the attention of the students and holding their interest. That is exactly what we do at Code Wiz, with a tailored curriculum that encourages the kids to want even more. You will find that your child would develop creative skills and even become better at academics as well. The best thing about sending your child to our center is that they get personalized attention, with a small student to coach ratios. We also have the best coaches to train your children and all of them are skills, qualified and experienced. Be sure that your child will get the value for money and all the time they spend here and everything they learn with us will show in their academic performance as well. Rather than spending the extra hours after school on frivolous activities, they can actually learn a skill that can help them create a better future. Moreover, we offer a free class so that children can realize their potential and continue only if they find interest in coding and robotics. Send your kid to us and we will help them discover a new world.</p>
                                
                                <p><b>Family Environment That Kids Would Love</b></p>
                                
                                <p>Apart from the opportunity to learn the amazing skills, Code Wiz also offers the most wonderful, family-friendly environment they would love. As a parent, you will be happy to send your child to the best Top Coding School For Kids {aboutdata.city} {aboutdata.state}and can also be stress free about their safety. We go the extra mile with making our students feel special, whether it is about summer camps or birthday parties. We even let them leave the kids with us overnight so that you can enjoy the Friday nights without worrying about their safety. With multiple locations and flexible hours, we give you a learning venue that works according to your needs. Moreover, we take kids as young as seven years of age and up to seventeen years, which means that it is never too early or too late to enrol your child for coding and robotics training with Code Wiz.  We have special groups for each age group so that the young geniuses learn what they should. Just give us the chance to get your child’s future on track and we will make sure that they learn new skills that drive success.</p>
                                
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default CodingSchoolForKidsLocation;