import React from 'react';
import Franchisee from '../../../components/franchisee/v4-franchisee.component';
import Header from '../../../components/header/header.component';
import AboutPageImg from '../../../assets/images/Group-4.png';
import CodewizImg from '../../../assets/images/Codewiz-team.png';

import './arlington.styles.scss';

import PopupModal from '../../../components/popup-modal/popup-modal.component';
//import '../../assets/js/arlington-map.js';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";

class ArlingtonPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            coupon: 'https://forms.zohopublic.com/ruth5/form/EmailSubscription/formperma/v31WTNptFf6pvFczlljmqbA7IzH7P7iThEPDgiP42lg',
            sections: [{
                review: 'Code Wiz is awesome!',
                author: 'l. Smith',
                id: 1
            },
            {
                review: 'My children love Code Wiz',
                author: 'J. Johnson',
                id: 2
            }],
            team: [
                {id: 'rd-1', name: 'Ravi Meka', title: 'Co-owner', picture: CodewizImg},
                {id: 'rd-2', name: 'Sandeep Alluri', title: 'Co-owner', picture: CodewizImg},
                {id: 'rd-3', name: 'Lara Friedman', title: 'Center Director', picture: CodewizImg},
                {id: 'rd-4', name: 'Jessica Riley', title: 'Center Director', picture: CodewizImg}
            ]
        }
    }

    render() {
        return (
            <div>
                <PopupModal location="arlington" formType="coupon" owner="Code Wiz Arlington" />
                <Header location="arlington" fulllocation="arlington-ma"/>
                <Franchisee name="arlington" team={this.state.team} owner="Code Wiz Arlington"/>
                <Helmet>
                    <title>Coding and Robotics For Kids in Arlington MA | Code Wiz</title>
                    <link rel="canonical" href="http://thecodewiz.com/arlington-ma" />
                    <meta name="description" content="Coding and Robotics For Kids in Arlington MA" />
                    <meta property="og:type" content="website" /> 
                    <meta property="og:url" content="www.thecodewiz.com/arlington-ma" />
                    <meta property="og:title" content="Coding and Robotics for Kids Ages 7 - 17 in Arlington MA" />
                    <meta property="og:description" content="Coding and Robotics for Kids Ages 7 - 17 in Arlington Massachusetts" /> 
                    <meta name="keywords" content="coding, coding in arlington, arlington massachusetts, robotics"></meta>
                </Helmet>
            </div>
        );
    }
}

export default ArlingtonPage;

