import React from 'react';
import FAQPage from '../../faqs/faqs';
import MetaTags from 'react-meta-tags';

const FranchiseFaqsPage = () => (
    <div>
        <FAQPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | FAQs</title>
        </MetaTags>
    </div>
);

export default FranchiseFaqsPage;