import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';




import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">October 29th, 2017</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> THE CODE ADVANTAGE: PROBLEM-SOLVING SKILLS IN THE REAL WORLD </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>When most parents think of teaching their kids coding, they jump to the long-term career prospects and financial benefits of learning such a valuable skill at a young age. While the financial payoff is certainly there, it’s also important for parents to realize an even greater benefit of programming—excellent problem-solving skills that work just as well in the real world as on the screen. Even though most people think that the process of developing code is only between a person and a computer, there are actually dozens of different types of people who get involved in the design and development process. Kids who code develop those essential problem-solving skills earlier and better because programming forces them to not only solve problems within the code but also with their real-world collaborators.</p>

                                            

                                            <p><b>Thinking Logically about a Problem</b></p>
                                            <p>At its core, computer programming is breaking a complex action down into steps that a computer can understand. In order to get from a blank document to a fully functioning program, kids have to think about all of the logic behind what they are trying to accomplish. In computer science, these little instructions are called “algorithms”, and they translate what a human wants to do into a language that the computer can understand.</p>
                                            <p>By developing algorithms and working through code, kids learn logical problem-solving skills that stick with them for the rest of their lives. Decisions like choosing a college, starting and ending friendships, and changing your career all follow the same basic pattern of computer science thinking. Is this the most efficient decision? Will the long-term benefits outweigh the momentary costs? Is this work leading me towards my ultimate goal? Getting kids to think like this at a young age will positively impact their problem-solving skills both immediately and later in life.</p>



                                            <p><b>Getting Creative with Solutions</b></p>
                                            <p>Arguably the greatest part of computer programming is that there’s not one right answer. Because everyone’s minds work differently, the potential solutions to a problem are as numerous at the people trying to solve it. Because of this, creativity is one of the most important parts of coding. This is good news for kids’ problem-solving skills because it teaches them to think of multiple solutions to a problem instead of jumping to the quickest or simplest one.</p>
                                            <p>These skills are perfect for everyday conflict resolution and big decisions where it seems like there is no good next move. With their computer coding skills, your kids will be able to find a solution in almost any situation.  And on the other hand, they’ll also have those logical thinking skills in their pocket to sort through situations where there are too many paths to choose from.</p>



                                            <p>Learning Collaboration Early</p>
                                            <p>Often in programming, it’s not the code that doesn’t work but the people. Even though there is the stereotype of computer programmers in cubicles, the vast majority of coders work with other people to design, develop, and test their code. A typical mobile app, for example, requires programmers to work with graphic designers, marketing experts, and concept artists in their day-to-day routine. Even more often, programmers work together in what is called “pair programming” to develop code as quickly as possible.</p>
                                            <p>When these relationships get difficult, problem-solving skills once again kick in. This time, however, it’s not about fixing a bug or an error—it’s about resolving conflict between people, one of the most fundamental skills in any field. In the programming world, these challenges and stresses come in many forms. Often times, the pressure of trying to deliver a product but getting stuck on a major issue causes problems in smaller groups. Or, within larger groups, difficult chains of command and leadership styles can add to the tension and pressure. Throughout all of this, kids will learn what it feels like to work together with their peers to build to someone else’s specifications—the ultimate test of conflict resolution and problem-solving skills.</p>



                                            <p><b>Coming Together in Kids’ Coding School</b></p>
                                            <p>All of these technological, logical, and relational opportunities to build problem-solving skills are not unique to professional adult coders. Even at a young age, kids can reap the benefits of learning how to code! As a matter of fact, starting kids down the coding path earlier makes them more likely to keep at it in the long run and achieve success. By practicing the problem-solving skills that come with writing code, kids who learn to program have an advantage in not only the job market but also in their daily lives.</p>


                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
