import React from 'react';

import './courses.styles.scss';
import CourseBlocks from '../course-block/course-blocks.component';

class Courses extends React.Component {
    constructor(){
        super();

        let codewizPrograms = [
            {
            id: 5,
            label: "Select An Age",
            courses:
                {
                coding: null,
                gaming: null,
                robotics: null,
                electronics: null,
                competitions: null,
                advancedcoding: null
                }
            },
            {
            id: 7,
            label: "7",
            courses:
                {
                coding: ["dragndrop"],
                gaming: ["roblox", "minecraft"],
                robotics: ["flljr"],
                electronics: null,
                competitions: ["flljr"],
                advancedcoding: null
                }
            },
            {
            id: 8,
            label: "8",
            courses:
                {
                coding: ["dragndrop", "codesters"],
                gaming: ["roblox", "minecraft"],
                robotics: ["flljr"],
                electronics: null,
                competitions: ["flljr"],
                advancedcoding: null
                }
            },
            {
            id: 9,
            label: "9",
            courses:
                {
                coding: ["codesters"],
                gaming: ["roblox", "minecraft"],
                robotics: ["fll"],
                electronics: null,
                competitions: ["fll"],
                advancedcoding: null
                }
            },
            {
            id: 10,
            label: "10",
            courses:
                {
                coding: ["codesters", "codecombat", "htmlcss", "python", "digitalart"],
                gaming: ["roblox", "unity", "minecraft", "legomicrogame"],
                robotics: ["fll"],
                electronics: null,
                competitions: ["fll", "technovation"],
                advancedcoding: null
                }
            },
            {
            id: 11,
            label: "11",
            courses:
                {
                coding: ["codecombat", "htmlcss", "python", "digitalart"],
                gaming: ["roblox", "unity", "legomicrogame"],
                robotics: ["fll"],
                electronics: null,
                competitions: ["fll", "technovation"],
                advancedcoding: null
                }
            },
            {
            id: 12,
            label: "12",
            courses:
                {
                coding: ["codecombat", "htmlcss", "python", "digitalart"],
                gaming: ["roblox", "unity", "minecraft", "legomicrogame"],
                robotics: ["fll"],
                electronics: null,
                competitions: ["fll", "technovation"],
                advancedcoding: null
                }
            },
            {
            id: 13,
            label: "13",
            courses:
                {
                coding: ["python", "java", "htmlcss", "digitalart"],
                gaming: ["unity", "minecraft", "legomicrogame"],
                robotics: ["fll"],
                electronics: null,
                competitions: ["fll", "technovation"],
                advancedcoding: ["vrgaming", "voiceapps", "introtoai"]
                }
            },
            {
            id: 14,
            label: "14",
            courses:
                {
                coding: ["python", "java", "htmlcss", "digitalart"],
                gaming: ["unity", "minecraft", "legomicrogame"],
                robotics: ["fll"],
                electronics: null,
                competitions: ["fll", "technovation"],
                advancedcoding: ["vrgaming", "voiceapps", "introtoai"]
                }
            },
            {
            id: 15,
            label: "15",
            courses:
                {
                coding: ["python", "java", "htmlcss", "digitalart"],
                gaming: ["unity", "minecraft", "legomicrogame"],
                robotics: null,
                electronics: null,
                competitions: ["technovation"],
                advancedcoding: ["vrgaming", "voiceapps", "introtoai"]
                }
            },
            {
            id: 16,
            label: "16",
            courses:
                {
                coding: ["python", "java", "htmlcss", "digitalart"],
                gaming: ["unity", "minecraft", "legomicrogame"],
                robotics: null,
                electronics: null,
                competitions: ["technovation"],
                advancedcoding: ["vrgaming", "voiceapps", "introtoai"]
                }
            },
            {
            id: 17,
            label: "17",
            courses:
                {
                coding: ["python", "java", "htmlcss", "digitalart"],
                gaming: ["unity", "minecraft", "legomicrogame"],
                robotics: null,
                electronics: null,
                competitions: ["technovation"],
                advancedcoding: ["vrgaming", "voiceapps", "introtoai"]
                }
            }
        ];

        this.state = {
            value: '',
            programs: codewizPrograms,
            courses: [],
            selectedTitle: "",
            selectedAgeCourses: {}
        }

        this.handleChange = this.handleChange.bind(this);
        this.showAge = this.showAge.bind(this);
        this.findCourses = this.findCourses.bind(this);
    }


    handleChange(event) {
        this.setState({value: event.target.value});
        let courses = this.state.programs;
        let selectedAge = parseInt(event.target.value);

        for(let i=0; i<courses.length; i++){
            if(courses[i].id === selectedAge){
                console.log("Found the age group! ", courses[i].id);
                this.state.selectedAgeCourses = courses[i].courses;
                //selectedCourse = courses[i].courses;
                break;
            }
        }
        //console.log("Outside the for loop handle change", this.state.selectedAgeCourses);
    }

    showAge () {
        // let courses = this.state.programs;
        // let selectedAge = parseInt(this.state.value);
        // //let selectedCourse = [];
        // for(let i=0; i<courses.length; i++){ 
        //     if(courses[i].id === selectedAge){
        //         //console.log("Found the age group! ", courses[i].id);
        //         this.selectedAgeCourses = courses[i].courses;
        //         //selectedCourse = courses[i].courses;
        //         break;
        //     }
        // }
        //console.log("Found the age appropriate! ", this.selectedAgeCourses);
    }

    findCourses (courses) {
        console.log("Found the courses! ", courses);
    }
    render() {
        return (
            <div className="center">
                <div className="row select-student-age">
                    <select id="select-age" className="form-control col-md-12" value={this.state.value} onChange={this.handleChange}>
                        {this.state.programs.map((program) => <option key={program.id} value={program.id}>{program.label}</option>)}
                    </select>
                    {/* <input className="btn orange-button col-md-2" id="start" type="submit" value="START"/> */}
                    {/* <button className="btn orange-button col-md-2" id="start" onClick={this.showAge()}>START</button> */}
                </div>
                
                <CourseBlocks courses={this.state.selectedAgeCourses}/>
            </div>
                    
        );
    } 
}

export default Courses;

