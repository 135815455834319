import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="reading" fulllocation="reading-ma"/>
        <FacebookMessenger location="reading" FBPageId="2544691755571962" />
        <SummerCamps franchisee="reading" owner="Code Wiz Reading"/>
        <MetaTags>
            <title>February, April and Summer Vacation Programs in Reading MA - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Reading MA" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/reading-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Reading MA" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in reading, reading massachusetts, robotics"></meta>
        </MetaTags>
    </div>
);

export default SummerPage;

