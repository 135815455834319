import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import HowInteractiveClasses from '../assets/images/blog-pictures/how-interactive-learning.jpg';
import HowInteractiveClasses1 from '../assets/images/blog-pictures/how-interactive-learning1.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">September 11, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>HOW INTERACTIVE LEARNING CLASSES PUTS THE SOCIAL INTO HOMESCHOOLING</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">HOW INTERACTIVE LEARNING CLASSES PUTS THE SOCIAL INTO HOMESCHOOLING</h3>
                                            <p>When parents begin to homeschool their kids it is easy for them to focus almost entirely on the educational and ignore the social aspect.</p>
                                            <p>It is understandable why parents/guardians would get wrapped up with the education side of things because it’s called HomeSCHOOLING for a reason.</p>
                                            <p>But parents have to keep in mind that their children’s social wellbeing is just as important as their academic wellbeing.</p>
                                            <p>Children have a developmental need for social interaction whether with their peers or with family. It has been proven that kids benefit from being exposed to a variety of different people and social activities.</p>
                                            <p>Homeschooling has long been accused of being Anti-Social. There has always been concern among its naysayers that the unconventional educational concept socially isolates students from the outside world, alienating them from their peer groups.</p>
                                            <p>However, there are a lot of feasible and beneficial activities that have always been used as a social balancing act to homeschooling such as sports and general outdoor activities, volunteering in the community, field trips, music, and dance lessons, and many more.</p>
                                            <p>But here is the problem.</p>
                                            <p>The majority of these options are typical physical outdoor activities and as you know, we are currently living in unusual times.</p>
                                            <p>With the current lockdown measures put in place, a lot of normal activities and human contact have been prohibited to curb the spread of COVID-19. In some places, whether through government/state protocol or because of plain fear, people are barely even going outside, unless it’s for essential purposes.</p>
                                            <p>So, with no clear end to the pandemic in sight, there’s a strong possibility that digital homeschooling or digital learning will become the “new normal.”</p>
                                            <p>This uncertainty has caused some fear and anxiety for parents. We explored in detail one of the major issues in our <a href="https://www.thecodewiz.com/screen-time-for-kids-how-to-manage-it-during-distance-learning/">previous blog</a>.</p>
                                            <p>In this one, we will examine the problem of socialization while homeschooling kids during the pandemic and how online interactive learning programs are a great solution.</p>
                                            <div className="blog-images"><img src={HowInteractiveClasses} alt="how interactive classes" /></div>

                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">The tricky Homeschooling + Socialization balancing act</h3>
                                            <p>But another hot topic in relation to digital homeschooling is socialization. This has been a topic of debate long before the pandemic but with social distancing in effect, it has become a hot button issue since COVID.</p>
                                            <p>Parents are wondering how can I ensure that my kids are adequately socializing during digital homeschooling with all this social distancing?</p>
                                            <p>The thing is, Homeschooler socialization can happen anywhere and at anytime.</p>
                                            <p>Ensuring that kids get the adequate amount of social interaction they need while homeschooling may take some thought and effort, but it definitely can be done.</p>
                                            <p>While they are plenty of opportunities for children to engage with others considering the plethora of virtual apps and media, and tech devices to explore them on, there is the problem of digital isolation.</p>
                                            <p>Left to their own devices, kids can get deeply engrossed in the cyber world often participating in activities that aren’t positively contributing to their development.</p>
                                            <p>This has raised additional concerns about digital homeschooling with some parents bringing into question the effectiveness of the set-up of some distance learning programs.</p>
                                            <p>The truth is that digital homeschooling is as effective as the instructor/teacher, the materials and methods used, as well as the overall set-up of the program.</p>
                                            <div className="blog-images"><img src={HowInteractiveClasses1} alt="The tricky Homeschooling" /></div>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">How Interactive Learning Classes puts the Social into Homeschooling</h3>
                                            <p>If you’re a parent you know how difficult it can be to get kids to do chores. So for homeschooling to be effective, especially for younger children, the lessons should not remotely come across as such.</p>
                                            <p>The trick is to balance homeschooling with socialization so that students learn in a collaborative and supportive environment where they can engage with peers while learning in a fun, interactive manner.</p>
                                            <p>The chance to socialize and interact with other kids will also be refreshing for them considering how much they miss the connection of being with other kids all day.</p>
                                            <p>Unfortunately, it seems as if we will likely be doing this social distancing and the resulting distance learning thing for a while. But getting your kids involved in the right digital homeschooling program could be a great alternative.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Bringing Kids together while Learning</h3>
                                            <p>If you’re grooming your kids for the future and are interested in them gaining valuable 21st-century skills, we at Code Wiz have always believed in the <a href="https://en.wikipedia.org/wiki/Montessori_education">Montessori Method of educating kids.</a></p>
                                            <p>So our kids learn to code programs were originally designed to bring kids together to create and have fun with their peers in an interactive, engaging, and collaborative way.</p>
                                            <p>Now with our virtual learn to code classes we allow kids to do that but online in a safe space with the usual guidance of a patient and supportive team of coaches.</p>
                                            <p>Kids can join 
                                                <a href="https://www.thecodewiz.com/online-coding-classes-and-camps/">virtual interactive learning classes</a> 
                                                like Minecraft, 
                                                <a href="https://www.thecodewiz.com/roblox-kids-coding/">Roblox</a>
                                                Python, and many others where they can interact and learn to code with kids their age. They can collaborate on their work, they meet new friends with similar interest and they laugh and build within these platforms as a team.</p>

                                            <div className="blog-videos"><iframe src="https://www.facebook.com/plugins/video.php?href=https%3A%2F%2Fwww.facebook.com%2Fcodewizwestford%2Fvideos%2F1336283736577884%2F&show_text=0&width=560" width="560" height="315" style={{border:"none",overflow:"hidden"}} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" allowFullScreen="true"></iframe></div>
                                            
                                            <p>We also have a virtual <a href="https://www.thecodewiz.com/westford-ma/virtual-robotics-competition/">robotics competition</a> that engages students through gaming and competition in STEM, coding, and tech literacy. This competition will allow kids to collaborate within a team.</p>
                                            <p>So while they are learning to code virtually they are interacting with other kids, making new friends and memories while they learn.</p>
                                            <p>The response from parents about our virtual homeschooling alternative for summer camp have been very positive so far.  They have been particularly happy with how engaged they are and the fact that their kids are learning valuable STEM skills in a fun way.</p>
                                            <p>For these parents virtual learn to code classes was not quite the experience they were originally planning for their kids this summer, it proved to be a great alternative.</p>
                                            <p>If you’re struggling with balancing socialization with your child’s homeschooling <a href="https://www.thecodewiz.com/contact-us/">contact us</a> to see how we can help.</p>
                                            
                                            <p><b>P.S</b> <i>If your kids need help with graphical images for their digital homeschooling programs check out <a href="https://www.stickermule.com/">Sticker Mule</a>. Whether they need to increase the resolution of any graphic/photo instantly, or upgrade any image to a high resolution, or instantly remove the background from photos for free, Sticker Mule can help them create high quality, print-ready images. Check out these 3 recently released tools: <a href="https://www.stickermule.com/trace">Trace</a>, <a href="https://www.stickermule.com/upscale">Upscale</a>, & <a href="https://www.stickermule.com/redraw">Redraw</a> </i></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
