import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">December 22, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> CODING EXPLAINED: ROBLOX </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                            
                                            <p>According to Wikipedia, https://en.wikipedia.org/wiki/Roblox, HALF of all kids in the US under the age of 16 play Roblox. That’s no surprise considering they have over 164 million subscribers. </p>
                                            <p>So what has captured millions of people’s attention? Roblox is a community gaming platform where people create games using the programming language Lua allowing developers to create multiplayer online games. Basically, kids can create a game quickly, then share and play that game with their friends in a short amount of time! This platform is massive and even has its own currency called Robux, which can be used to make in-app purchases or to gain admission to a game. </p>
                                            <p>My hunch is that your kids might want to play these games for many hours past what you find to be acceptable limits of screen time. You might be thinking that this might not be the most productive way for your child to spend their day… but what if they were learning to code while playing in their favorite gaming environment? </p>
                                            <p>Code Wiz can turn the fight with your child over Roblox screen time into educational STEM time!</p>
                                            <p>Kids love learning how to create Roblox games with us. Our coaches turn your child into a developer of games instead of just a player of games. Starting with our first 1-hour trial we can teach your child, which could be as young as 7 years old, to make a working game that they can publish that same day! Suddenly instead of playing Jailbreak (a popular Roblox game) they are imagining what THEY could create! </p>
                                            <p>In the Roblox class at Code Wiz, kids will learn to use a 3D editor, create objects and then attach code to these objects. They will update different properties attached to these objects such as color or texture or even things that affect how the Roblox physics engine works with the parts you make such as gravity. They are learning the fundamentals of development, as well as learning what might be their first programming language. </p>
                                            <p>Once they learn the programming language used for Roblox called Lua, it is easy for them to take this foundation and learn the next language. Learning how to program in Roblox will train their mind to think about how to solve problems and gain confidence in themselves. </p>
                                            <p>Sign up for a free Roblox class with one of our experienced coaches here: https://codewizarlington.simplybook.me/v2/#book </p>

                                        

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
