import React from 'react';
import GirlsScoutPage from '../../girls-scout/girls-scout.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseGirlsScoutPage = () => (
    <div>
        <GirlsScoutPage franchisee="reading" fulllocation="reading-ma"/>
        <FacebookMessenger location="reading" FBPageId="2544691755571962" />
        
        <MetaTags>
            <title>Code Wiz - Reading MA | Girl Scouts</title>
        </MetaTags>
    </div>
);

export default FranchiseGirlsScoutPage;