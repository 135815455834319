import React from 'react';
import Header from '../../../components/header/header.component';
import LegoLeague from '../../../components/lego-league/lego-league.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const LegoLeaguePage = () => (
  <div>
    <Header location="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
    <LegoLeague franchisee="medford" formType="coupon" owner="Code Wiz Medford"/>
    <MetaTags>
        <title>Code Wiz - Medford MA | FLL</title>
    </MetaTags>
  </div>
);

export default LegoLeaguePage;