import React from 'react';
import ProgramsPage from '../../programs/programs.component';
import MetaTags from 'react-meta-tags';

const FranchiseProgramsPage = () => (
    <div>
        <ProgramsPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Programs</title>
        </MetaTags>
    </div>
);

export default FranchiseProgramsPage;