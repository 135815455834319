import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';
import AboutPageImg from '../../assets/images/Group-4.png';
import CompetitionTech from '../../assets/images/competition-technovation.png';
import CompetitionFLL from '../../assets/images/competition-first-lego.png';
import TechnovationHeaderImage from '../../assets/images/Technovation.png';
import TechnovationHeaderMobileImg from '../../assets/images/girls-scout-header-1.png';

import './technovation.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';


class TechnovationPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            // defaultdata: {
                state: "",
                city: "",
                territory: ""
            //}
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state;
        }
        return (
            <div>
                <MetaTags>
                    <title>{`Technovation Challenge - Kids Coding and Robotics School | Code Wiz ${aboutdata.city}, ${aboutdata.state}`}</title>
                    <meta name="description" content="Technovation is the world’s largest technology entrepreneurship program for girls aged 10-18. It runs across 100+ countries, supported by well known companies such as; Salesforce.org, Google.org, the Adobe Foundation, Uber, Samsung and more. "/>
                    <meta name="keywords" content="technovation"></meta>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="technovation-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">Technovation</h2> 
                                    {/* <h4 className="header-text cw-dark-blue">&nbsp;</h4> */}
                                    <Button color="blue" link="https://technovationchallenge.org/" size="medium">LEARN MORE</Button>
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={TechnovationHeaderImage} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper technovation-wrapper">
                        <div className="container technovation-top">
                            <div className="row">
                                <div className="image col-md-6 order-md-1">
                                    <img src={CompetitionTech} alt="Technovation"/>
                                </div> 
                                <div className="text col-md-6 order-md-2 cw-dark-blue">
                                    <h3 className="bungee">technovation</h3>
                                    <h5 className="bungee cw-orange">HELPING GIRLS CHANGE THE WORLD</h5>
                                    <p>Through Technovation, the world’s largest technology entrepreneurship competition for girls, the future truly is female. Girls of all ages from all over are invited to solve real problems using technology. Discover how Code Wiz prepares your child for the competition and paves the way for her success!</p>
                                    <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                                </div>
                            </div>
                            {/* <div className="row row-reverse"> 
                                <div className="text col-md-6 order-md-1 cw-dark-blue">
                                    <h3 className="bungee">FIRST LEGO LEAGUE</h3>
                                    <h5 className="bungee cw-orange">BUILDING A BETTER FUTURE</h5>
                                    <p>Let LEGO® inspire, challenge and grow your child like never before. FIRST® LEGO® League invites youth to sharpen their STEM skills with competitions centered around building their very own creation. See how Code Wiz provides the building blocks they need to succeed.</p>
                                    <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                                </div>
                                <div className="image col-md-6 order-md-2 cw-dark-blue">
                                    <img src={CompetitionFLL} alt="FLL"/>
                                </div>
                            </div> */}
                        </div>
                        
                    </div>
                    
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default TechnovationPage;