import React from 'react';
import './floating-menu.styles.scss';

class FloatingMenuItem extends React.Component {
    constructor(props) {
		super(props);
		
	}
	handleClick() {
		this.props.action();
	}
	
	render() {
		// let buttonStyle = {
		// 	backgroundImage: `url(${this.props.icon})`
		// }
		
        let label;
        let iconLink;
        let text;
		
		if (this.props.label) {
			label = <label>{this.props.label}</label>;
        }

        if(this.props.text){
            text = <text>{this.props.text}</text>
        }
        
        if (this.props.link){
            iconLink = 
                <div className="floating-menu-item">
                    {label}
                    <a href={this.props.link}><div className="floating-menu-icon"><i className={this.props.icon}></i></div></a>
                </div>;
        } else {
            iconLink = 
            <div onClick={this.handleClick.bind(this)} className="floating-menu-item">
                {label}
                <div className="floating-menu-icon"><i className={this.props.icon}></i></div>
            </div>;
        }
		
        return (
            // <div
            //     onClick={this.handleClick.bind(this)}
            //     className="floating-menu-item">

            //     {label}
            //     {/* <div className="floating-menu-icon"><i className="material-icons">{this.props.icon}</i></div> */}
            //     {iconLink}
            // </div>
           <div>{iconLink}</div> 
        );
	}
}

export default FloatingMenuItem;