import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';




import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">August 29, 2018</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>  WHAT IT’S LIKE TO BE A GIRL WHO CODES  </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>By Sydney Sauer</p>
                                            <p>From the first day of school, I knew that I would be the odd one out in my computer science class. In the whole class of boys, I was one of only two girls in the entire room! Although I tried not to think about that, it was hard to block out all of the things that made me different—my long, curly hair, painted nails, and cozy sweaters always seemed to stand out from the sweatpants and belted jeans in the other chairs. But what I came to find throughout the year was that my love for computer science meant so much more than my gender.</p>
                                            <p>Right now, there’s a lot of support for women who want to start coding. But it didn’t always used to be that way! If a girl wanted to be a coder even as recent as twenty years ago, there were significant obstacles she had to overcome. And before that, some schools refused to teach computer science to women at all. Today, <a href="https://www.techrepublic.com/blog/software-engineer/it-gender-gap-where-are-the-female-programmers/">only 20% of professional coders</a>, also known as software engineers, are women. That’s why a lot of schools and organizations have decided to dedicate themselves to getting more girls involved in code, even starting in middle school.</p>



                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">Working as a Female Coder</h3>
                                            <p>But what is it even like working in such a male-dominated field? According to most female programmers I’ve talked to as I have gone through school, it’s what you make of it. Some people lack the confidence to put themselves out there and compete with their male counterparts. Some women, on the other hand, fit right into the group and work their way up the company’s ladder very quickly. At the heart of being a female coder is a good attitude and excellent work ethic. With those two things, you can do anything you set your mind to.</p>
                                            <p>That’s not to say you won’t face problems. In my life, I have gone through lots of things that have made me question my decision to pursue computer science, but my love for coding has kept me on the right track. One time in high school, I was working on a huge coding project and had to come in during my lunch bell for a few weeks to finish it up. Although I had a lot of fun working on building my Boggle game, I was always intimidated by walking into the room full of guys wearing headsets and yelling at their computer screens. I was the only girl in the room, and I was definitely aware of it.</p>
                                            <p>Another time, I told an older man about my interest in computer science and he told me that I should be a writer or a translator instead—coding isn’t a career for women, he said, and I should stick to something that suits me better. Of course, that hurt, but I knew that computer science <i>does</i> suit me, girl or not.</p>
                                            <p>The main thing that I realized is that a lot of men in computer science greatly respect women for their bravery. While it may be easy to think that men are judging you or thinking less of you because of your gender, they might not even notice it as much as you do! As long as you are contributing to the project, any man will be happy to have you on the team, even if he does inadvertently make things a little harder for you along the way.</p>

                                        

                                                                             
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3 className="bungee">Getting Started in Computer Science</h3>
                                            <p>Getting started in coding as a girl is just as simple as getting started as a boy. So if you want to start working on your programming skills, don’t worry about your gender—just find opportunities! It’s usually a good idea to start with a free online program like Scratch or Processing and work your way through some simple programs.</p>
                                            <p>After that, though, it might be a good idea to find a kids’ coding school and learn firsthand from an expert. When you’re coding by yourself, it’s easy to get frustrated by small problems that just require a bit of outside knowledge. That’s why it’s such a great idea to find a friend or connect with a local coding class when you first get started.</p>
                                            <p>When you’re first starting out as a female coder, it might seem too difficult to be worth it. But if you truly love coding just like me, then keep working hard—never let your gender get in the way of your dreams.</p>                                                                          

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
