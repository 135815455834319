import React from 'react';
import Header from '../../../components/header/header.component';
import PrivacyPolicy from '../../../components/privacy-policy/privacy-policy.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const PrivacyPolicyPage = () => (
  <div>
    <Header location="reading" fulllocation="reading-ma"/>
    <FacebookMessenger location="reading" FBPageId="2544691755571962" />
    <PrivacyPolicy />
    <MetaTags>
        <title>Code Wiz - Reading MA | Privacy Policy</title>
    </MetaTags>
  </div>
);

export default PrivacyPolicyPage;