import firebase from 'firebase';
const config = {
    apiKey: "AIzaSyAaSnEDJ_IvYbC8oYdnYSAj3RP3PUveUjg",
    authDomain: "codewiz-website.firebaseapp.com",
    databaseURL: "https://codewiz-website.firebaseio.com",
    projectId: "codewiz-website",
    storageBucket: "codewiz-website.appspot.com",
    messagingSenderId: "941896441799",
    appId: "1:941896441799:web:a242f6e4a0f76f5ac283e2",
    measurementId: "G-54Q45J99QR"

};
// const config = {
//     apiKey: "AIzaSyDaXkKqeT4sc0E1PKd_w0Q2SNeFQKWXTrU",
//     authDomain: "codewiz-website-559d0.firebaseapp.com",
//     databaseURL: "https://codewiz-website-559d0-default-rtdb.firebaseio.com",
//     projectId: "codewiz-website-559d0",
//     storageBucket: "codewiz-website-559d0.appspot.com",
//     messagingSenderId: "280420973788",
//     appId: "1:280420973788:web:70b365dd25a2b8d8c00819",
//     measurementId: "G-DNQNTWCBKM"
// };
firebase.initializeApp(config);
export default firebase;