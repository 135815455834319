import React from 'react';
import firebase from '../Firebase/firebase.js';

import Button from '../button/button.component';
import FloatingMenu from '../floating-menu/floating-menu.component';
import VRHeaderImg from '../../assets/images/virtualRobotics.png';
// import VRHeaderImg from '../../assets/images/sessions_hero_image.png';

import IcoCodeImg from '../../assets/images/ico_code.png';
import IcoAgeImg from '../../assets/images/ico_age.png';
import Virtual1Img from '../../assets/images/virtual1.jpg';
import Virtual2Img from '../../assets/images/virtual2.jpg';
import Virtual3Img from '../../assets/images/virtual3.jpg';
import SessionIco2Img from '../../assets/images/session_ico2.png';
import SessionIco8Img from '../../assets/images/session_ico8.png';
import SessionIco4Img from '../../assets/images/session_ico4.png';
import IcoCalendarImg from '../../assets/images/ico_Calendar.png';
import VBFImg from '../../assets/images/virtual_benefits_featured.png';





import './virtual-robotics.styles.scss';


class VirtualRobotics extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "1-844-263-3949",
            email: "info@thecodewiz.com",
            data: {},
            isfranchisee: false
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        if(this.props.franchisee){
            this.state.isfranchisee = true;
            
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }
        return (
            <div className="special-page online-coding" id=""> 
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">virtual cyber robotics competition</h2> 
                                <Button color="blue" link={this.state.data.parentportal} size="medium">REGISTER NOW</Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={VRHeaderImg} alt="virtual robotics"/>
                        </div>
                    </div>
                </div>
                <div className="blue_section online_coding_camps_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="virtual_video_section">
                                    <h2 className="bungee">Fall 2020</h2>
					                <h5 className="bungee">Virtual Cyber Robotics Competition</h5>
                                    <p className="">CoderZ League is a groundbreaking online robotics tournament that includes two levels (Junior, Pro) to accommodate students of every coding experience. The tournament engages students through gaming and competition in STEM, coding, and tech literacy. CoderZ League is cloud-based, available on any device, and uses simulation of virtual 3D Robots to perform complex tasks and missions. The virtual experience makes it easy to design, program and test a simulated robot in a variety of 3D environments. Teams of students work collaboratively to program, plan and complete missions.</p>
                                    
                                    <div className="video_frame">
                                        <iframe width="100%" title ="about video" height="524" src="https://www.youtube.com/embed/lTn56tzCmzk" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>
                                </div>
                                <div className="coding_levels">
                                    <div className="coding_level_single">
                                        <div className="image">
                                            <img src={Virtual1Img} />
                                        </div>
                                        <div className="text">
                                            <h3 className="bungee">CoderZ League Junior​</h3>
                                            <span>Ages 8 - 10</span>
                                            <p>Help encourage the next generation of coders in a first-of-its-kind virtual robotics competition. Students practice coding while developing computational thinking and creative problem-solving skills. Teams compete to unlock new games and challenges in a quest to get their virtual robots to the finals! As part of a team, students are encouraged to work together as they advance through missions and qualify for the next challenges.</p>
                                        </div>
                                        <div className="instructions">
                                            <div className="instruction_single">
                                                <img src={IcoAgeImg} />
                                                <p className="property">Ages</p>
                                                <p className="">8 - 10</p>
                                            </div>
                                            <div className="instruction_single">
                                                <img src={IcoCodeImg} />
                                                <p className="property">Programming Language</p>
                                                <p className="">Blockly</p>
                                            </div>
                                            
                                        </div>
                                        <div className="button">
                                            <Button color="orange" link={this.state.data.parentportal} size="medium">REGISTER NOW</Button>
                                        </div>
                                    </div>

                                    <div className="coding_level_single">
                                        <div className="image">
                                            <img src={Virtual2Img} />
                                        </div>
                                        <div className="text">
                                            <h3 className="bungee">CoderZ League Pro​</h3>
                                            <span>Ages 10 - 12</span>
                                            <p>Your journey on CoderZ Islands is about to start! Program a virtual robot to help it survive on different islands across the globe. Each island contains its own obstacles and challenges to encourage your students to work as a team, collaborate and implement their creativity while they learn advanced programming concepts, physics principles and more.</p>
                                        </div>
                                        <div className="instructions">
                                            <div className="instruction_single">
                                                <img src={IcoAgeImg} />
                                                <p className="property">Ages</p>
                                                <p className="">10 - 12</p>
                                            </div>
                                            <div className="instruction_single">
                                                <img src={IcoCodeImg} />
                                                <p className="property">Programming Language</p>
                                                <p className="">Blockly</p>
                                            </div>
                                            
                                        </div>
                                        <div className="button">
                                            <Button color="orange" link={this.state.data.parentportal} size="medium">REGISTER NOW</Button>
                                        </div>
                                    </div>

                                    <div className="coding_level_single">
                                        <div className="image">
                                            <img src={Virtual3Img} />
                                        </div>
                                        <div className="text">
                                            <h3 className="bungee">CoderZ League Pro​ (Python)</h3>
                                            <span>Ages 13+</span>
                                            <p>Your journey on CoderZ Islands is about to start! Program a virtual robot to help it survive on different islands across the globe. Each island contains its own obstacles and challenges to encourage your students to work as a team, collaborate and implement their creativity while they learn advanced programming concepts, physics principles and more.</p>
                                        </div>
                                        <div className="instructions">
                                            <div className="instruction_single">
                                                <img src={IcoAgeImg} />
                                                <p className="property">Ages</p>
                                                <p className="">13+</p>
                                            </div>
                                            <div className="instruction_single">
                                                <img src={IcoCodeImg} />
                                                <p className="property">Programming Language</p>
                                                <p className="">Blockly</p>
                                            </div>
                                            
                                        </div>
                                        <div className="button">
                                            <Button color="orange" link={this.state.data.parentportal} size="medium">REGISTER NOW</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="virtual_middle_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h2 className="bungee blue_txt">How It Works</h2>
                                <p className="black_txt light_txt">The tournament engages students through gaming and competition in STEM, coding and tech literacy. Competitors will use simulation of virtual 3D Robots to perform complex tasks and missions. The virtual experience makes it easy to design, program and test a simulated robot in a variety of 3D environments. Students work in teams collaboratively to program, plan and complete missions.</p>
                                <p className="black_txt light_txt">Classroom STEM concepts are acquired as students learn 21st century skills in critical thinking, creativity, collaboration, communication, technology literacy and more.</p>
                                <div className="virtual_league_process">
                                    <div className="virtual_league_process_single">
                                        <img src={SessionIco2Img} />
                                        <h4 className="bungee blue_txt">Timing</h4>
                                        <p><span>2 hours/week</span></p>
                                    </div>
                                    <div className="virtual_league_process_single">
                                        <img src={SessionIco8Img} />
                                        <h4 className="bungee blue_txt">Price</h4>
                                        <p><span>$199/month</span></p>
                                    </div>
                                    <div className="virtual_league_process_single">
                                        <img src={SessionIco4Img} />
                                        <h4 className="bungee blue_txt">Team Size</h4>
                                        <p><span>At most</span>4 children per team</p>
                                    </div>
                                    <div className="virtual_league_process_single">
                                        <img src={IcoCalendarImg} />
                                        
                                        <h4 className="bungee blue_txt">Season</h4>
                                        <p><span>Runs from</span>September - December 2020</p>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>

                <div className="blue_section virtual_robotics_section">
                    <div className="container">
		                <div className="row">
			                <div className="col-md-12">
                                <div className="virtual_league_benefits">
                                    <h2 className="bungee">Why sign up for CoderZ League?</h2>
                                    <div className="virtual_league_benefits_inner">
                                        <div className="image">
                                            <img src={VBFImg} />
                                        </div>
                                        <div className="text">
                                            <ul>
                                                <li>
                                                    <strong>Students love it</strong>
                                                    They learn coding and robotics while having some serious fun.
                                                </li>
                                                <li>
                                                    <strong>No hardware is needed</strong>
                                                    web-based and works on any computer, including Chromebooks.
                                                </li>
                                                <li>
                                                    <strong>Promotes diversity</strong>
                                                    CoderZ League is for everyone!
                                                </li>
                                                <li>
                                                    <strong>Accessible</strong>
                                                    CoderZ League is web-based, making coding and robotics accessible to anyone with an internet connection
                                                </li>
                                                <li>
                                                    <strong>No previous experience is necessary</strong>
                                                    CoderZ League is self-paced.
                                                </li>
                                                <li>
                                                    <strong>Helps develop skills</strong>
                                                    Like creative problem solving communication and teamwork.
                                                </li>
                                                <li>
                                                    <strong>Motivational STEM program</strong>
                                                    Get your children involved and excited about the competition
                                                </li>
                                                <li>
                                                    Did we mention CHILDREN LOVE IT?
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="virtual_robotics_bottom_section">
                    <div className="container">
                        <div className="row">
			                <div className="col-md-12">
                                <h2 className="bungee blue_txt">FREQUENTLY ASKED QUESTIONS</h2>
                                <p className="black_txt">Below is some helpful info on CoderZ League and how it all works.</p>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="generalFaqOne">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">                                            
                                                <h5 className="bungee cw-dark-blue">What is CoderZ league?</h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="generalFaqOne" data-parent="#accordion">
                                            <div className="card-body">CoderZ League (formerly CRCC – Cyber Robotics Coding Competition) is an exciting, engaging, and entertaining virtual robotics competition for students of all levels to participate and learn about STEM, robotics, and coding and to ignite their technology career paths. CoderZ League is cloud-based, can be accessed from any computer, and uses simulation of virtual 3D Robots that perform complex tasks and missions. Using strategizing, collaboration, and acquired programming techniques, students will compete within their team, class, school, district, and state to win stages and progress to the finals.</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqTwo">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">                                            
                                                <h5 className="bungee cw-dark-blue"> What is the difference between CoderZ League Junior and CoderZ League PRO? </h5>                                            
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="generalFaqTwo" data-parent="#accordion">
                                            <div className="card-body">The major difference is the difficulty level of the challenge. CoderZ league Junior is a beginner to intermediate level of coding and is great for students with little or no coding background or with basic experience in coding and/or robotics. CoderZ league Pro requires an intermediate to advanced level of coding knowledge.</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqThree">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">                                            
                                                <h5 className="bungee cw-dark-blue"> Who can participate and how?  </h5>                                            
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="generalFaqThree" data-parent="#accordion">
                                            <div className="card-body">CoderZ League is available for teams across the globe, from a small group of up to 5 students to an entire school.
                                                Students work together and earn points as a team based on their achievements. Register and secure your participation.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqFour">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">                                            
                                                <h5 className="bungee cw-dark-blue"> Is the competition international? </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="generalFaqFour" data-parent="#accordion">
                                            <div className="card-body">Yes! Everyone across the globe can register and join CoderZ League: the Virtual Cyber Robotics Competition.</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqFive">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">                                            
                                                <h5 className="bungee cw-dark-blue">  Can my club / team (4-H, Girls who Code, YMCA, Scout camp) participate? </h5>                                            
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="generalFaqFive" data-parent="#accordion">
                                            <div className="card-body">Yes! You can register your team as a club with up to 5 students. Clubs can compete and work together in CoderZ League.</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqSix">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">                                        
                                                <h5 className="bungee cw-dark-blue"> What coding language(s) do I program in? </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseSix" className="collapse" aria-labelledby="generalFaqSix" data-parent="#accordion">
                                            <div className="card-body">In CoderZ League JUNIOR, the programing language is Blockly.<br/>
                                                In CoderZ League PRO, you can program in Blockly or Python.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqSeven">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">                                            
                                                <h5 className="bungee cw-dark-blue"> Do students participate as individuals, teams, classes, or schools? </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseSeven" className="collapse" aria-labelledby="generalFaqSeven" data-parent="#accordion">
                                            <div className="card-body">Students work together as teams! The CoderZ League leaderboard tracks the scores for each team, class, school, or organization.</div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqEight">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">                                        
                                                <h5 className="bungee cw-dark-blue"> Are there leaderboards? How are they accessed? </h5>                                      
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseEight" className="collapse" aria-labelledby="generalFaqEight" data-parent="#accordion">
                                            <div className="card-body">
                                            Classroom leaderboards can be accessed by clicking the scoreboard icon in your CoderZ dashboard. Public leaderboards showing how well your organization, class, or team is performing in your state or region can be accessed by clicking the “Public Leaderboard” link on the upper right-hand side of the scoreboard page.

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqNine">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">                                           
                                                <h5 className="bungee cw-dark-blue"> What does the leaderboard show? </h5>                                                
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseNine" className="collapse" aria-labelledby="generalFaqNine" data-parent="#accordion">
                                            <div className="card-body">There are two types of leaderboards:<br/>
                                            <br/><strong>1. Public leaderboards:</strong> Class performance/Team performance.
                                            <br/><strong>2. Scoreboard:</strong> Individual performances compared with those of classmates.</div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="card">
                                        <div className="card-header" id="generalFaqTen">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">
                                                <h5 className="bungee cw-dark-blue">  What is the role of the teacher?  </h5>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseTen" className="collapse" aria-labelledby="generalFaqTen" data-parent="#accordion">
                                            <div className="card-body">
                                                <ul>
                                                    <li>Foster student motivation and curiosity during each competition phase.</li>
                                                    <li>Help your team/class define a strategy.</li>
                                                    <li>Monitor students’ involvement and progress levels.</li>
                                                    <li>Encourage and empower everyone to contribute to their team/class success.</li>
                                                    <li>Ask questions and help your students get to their own answers.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    
                                    <div className="card">
                                        <div className="card-header" id="generalFaqEleven">
                                            <h5 className="mb-0">
                                            <span className="btn btn-link" data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">
                                                <h5 className="bungee cw-dark-blue"> How long does the virtual robotics competition last? </h5>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseEleven" className="collapse" aria-labelledby="generalFaqEleven" data-parent="#accordion">
                                            <div className="card-body">
                                                The competition officially begins with an opening round on October 15. Phases will be released at various intervals culminating in a final round scheduled for December – specific date TBA.
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="card">
                                        <div className="card-header" id="generalFaqTwelve">
                                            <h5 className="mb-0">
                                            <span className="" data-toggle="collapse" data-target="#collapseTwelve" aria-expanded="true" aria-controls="collapseTwelve">
                                                <h5 className="bungee cw-dark-blue"> Do my students participate daily and for how many hours? </h5>    
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseTwelve" className="collapse" aria-labelledby="generalFaqTwelve" data-parent="#accordion">
                                            <div className="card-body">
                                                This depends on you and your students. Students can spend as little as 1 hour per week participating. Those students who dedicate more time and define their limits based on their current homework or extra-curricular schedules. Students who allocate more time to the competition will have the opportunity to improve their knowledge, skills, results, and scores anytime and anywhere they have access to a computer and the Internet.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqThirteen">
                                            <h5 className="mb-0">
                                            <span className="" data-toggle="collapse" data-target="#collapseThirteen" aria-expanded="true" aria-controls="collapseThirteen">
                                                <h5 className="bungee cw-dark-blue"> What does the schedule look like? </h5>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseThirteen" className="collapse" aria-labelledby="generalFaqThirteen" data-parent="#accordion">
                                            <div className="card-body">
                                                <ul>
                                                    <li><strong>August 24</strong><br/>
                                                    Registration opens!</li>

                                                    <li><strong>September - October</strong><br/>
                                                    Let the fun begin! Explore how the platform works together with team mates. Complete lessons to prep for challenge release!</li>

                                                    <li><strong>Mid-October</strong><br/>
                                                    <i>Opening Round</i><br/>
                                                    Students get access to practice missions that guide them through the platform and introduce them to programming, mechanics, and physics while coding their own virtual robot.</li>

                                                    <li><strong>November</strong><br/>
                                                    <i>Phase 1</i><br/>
                                                    This is the time to shine! Our students will start solving the virtual challenges and earn points! Using the coding commands that they have learned in conjunction with their own creativity, students will attempt to program the best possible solutions and gain maximum points. If our students think that they can do it better and they still have time, they can improve their code and get a higher score.</li>

                                                    <li><strong>Phase 2</strong><br/>
                                                    Earn a spot in the finals! As missions get more complicated and challenging, our students showcase more advanced coding skills that may qualify them for the finals. We’ll keep an eye on our leaderboards, because the top scoring team receive an invite to participate in the finals!</li>

                                                    <li><strong>December</strong><br/>
                                                    <i>CoderZ League – Virtual Finals</i><br/>
                                                    The moment you’ve all been waiting for is here. Celebrate our students achievements during our virtual finals event.</li>
                                                </ul>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="virtual_cta">
                                    <h2 className="bungee blue_txt">Ready to compete?</h2>
                                    <p className="black_txt">Use the link below to register.</p>
                                    <Button color="orange" link={this.state.data.parentportal} size="medium">REGISTER NOW</Button>
                                    <div className="bottom_cta">
                                        <ul>
                                            <li><a href={`tel:${this.state.data.phone}`}><i className="fa fa-phone"></i>&nbsp; {this.state.data.phone}</a></li>
                                            <li><a href={`mailto:${this.state.data.email}`}><i className="fa fa-envelope"></i>&nbsp; {this.state.data.email}</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {floatingdata}
            </div>
        );
    }
}

export default VirtualRobotics
