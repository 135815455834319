import React, {useState} from 'react';
import firebase from '../Firebase/firebase.js';
import Popup from 'reactjs-popup';

import AboutPageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
//import AboutSpark from '../../assets/images/image-2.png';
//import AboutGivingBack from '../../assets/images/giving-back-image.png';
import CityImage from '../../assets/images/location-pin.png';
import CodingRobotics from '../../assets/images/landing-page-coding-robotics.png';
import WhyCodewiz from '../../assets/images/landing-page-why-codewiz.png';

import FranchiseHeaderImg from '../../assets/images/FranchiseeHomePage.jpg';
//import MidBkgImage from '../../assets/images/landing-page-mid bkg.png';
import Verticals from '../verticals/verticals.component.jsx';
import Courses from '../courses/courses.component.jsx';

import Button from '../../components/button/button.component';
import Review from '../../components/review/review.component';
import TeamMember from '../../components/team-member/team-member.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
//import TeamSlider from '../../components/slick-slider/teams-slider.component';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';
import LearningFramework from '../../assets/images/codes-chart.png';
import CodingVerticalOne from '../../assets/images/coding-verticals-1.png';
import CodingVerticalTwo from '../../assets/images/coding-verticals-2.png';
import SimpleSlider from '../../components/slick-slider/simple-slider.component';
import RewardsImg from '../../assets/images/Ellipse-orange.png';
import ProgressImg from '../../assets/images/Ellipse-green.png';
import CommunityImg from '../../assets/images/Ellipse-light-blue.png';
import FreeClassImg from '../../assets/images/Ellipse-purple.png';
import MontesorriImg from '../../assets/images/Ellipse-yellow.png';
// import MashupOneImg from '../../assets/images/mashup-1.png';
// import MashupTwoImg from '../../assets/images/mashup-2.png';
import MashupOneImg from '../../assets/images/image3.png';
import MashupTwoImg from '../../assets/images/image2.png';
import JackImg from '../../assets/images/headshot3.png';
import AvashaImg from '../../assets/images/headshot4.png';
import CharlotteImg from '../../assets/images/headshot1.png';
import FounderMsgImg from '../../assets/images/founder-msg.png';
import RuthImg from '../../assets/images/headshot2.png';
import MembershipImg from '../../assets/images/memberships.png';
import Celebration from '../../assets/images/celebration.png';
import Empowering from '../../assets/images/empowering.png';
import ReviewOne from '../../assets/images/review-1.png';
import CourseWeOfferImg from '../../assets/images/Group12.png';
import MoneyGuaranteeImg from '../../assets/images/money-guarantee-img.png';
import ReviewSlider from '../../components/slick-slider/reviews-slider.component.jsx';

import GoogleMapWestfordComponent from '../../components/google-map/google-map.component-westford';
import GoogleMapReadingComponent from '../../components/google-map/google-map.component-reading';
import GoogleMapWestboroughComponent from '../../components/google-map/google-map.component-westborough';
import GoogleMapArlingtonComponent from '../../components/google-map/google-map.component-arlington';
import GoogleMapJerseyComponent from '../../components/google-map/google-map.component-jersey';
import GoogleMapMedfordComponent from '../../components/google-map/google-map.component-medford';
import GoogleMapNashuaComponent from '../../components/google-map/google-map.component-nashua';

import GoogleMapNeedhamComponent from '../../components/google-map/google-map.component-needham';
import GoogleMapLawrencevilleComponent from '../google-map/google-map.component-lawrenceville';
import GoogleMapPlanoComponent from '../../components/google-map/google-map.component-plano';
import GoogleMapStjohnsComponent from '../../components/google-map/google-map.component-stjohns';
import GoogleMapCypressComponent from '../google-map/google-map.component-cypress.jsx';


import './franchisee.styles.scss';


class Franchisee extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.name,
            data: {},
            team: props.team,
            map: props.name + "-map",
            brochure: "https://getform.io/f/c7aeb3d8-3132-4a1d-8241-6142b5b305e8",
            infopacket: "https://getform.io/f/d7edd058-9c92-4d14-8df5-2cdbd5e1ebca",
            locationemail: props.name + "@thecodewiz.com",
            zoho: props.owner,
            isOpen : false,
            parentConsultUrl: "https://getform.io/f/8449e449-0e85-425a-96b9-a7e353dcea00",
            trialClassFormUrl: "https://getform.io/f/e4a16b97-2152-43dd-8b8f-204dbad83c03"
            // email: "esther@thecodewiz.com"
        }
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal () {
        this.setState({isOpen: true})
    }

    closeModal () {
        this.setState({isOpen: false})
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        const teamsArr = [];
        const teams = this.state.team;
        let renderdaysList = "";
        if(teams.length > 0){
            for (let i = 0; i < teams.length; i++) {
                teamsArr.push(<TeamMember id={i} key={i} name={teams[i].name} picture={teams[i].picture} title={teams[i].title}/>);
            }
        }
        const daysOpenList = this.state.data.daysopen;
        
        if(!daysOpenList){
            renderdaysList =""
        }else {
            renderdaysList = daysOpenList.map((item, index) => 
                               <div key={index}>{item}</div>
                             );
        }

        const map = [];
        if(this.state.location === "westford"){
            map.push(<GoogleMapWestfordComponent/>);
        } else if(this.state.location === "westborough"){
            map.push(<GoogleMapWestboroughComponent/>);
        } else if(this.state.location === "reading"){
            map.push(<GoogleMapReadingComponent/>);
        }else if(this.state.location === "arlington"){
            map.push(<GoogleMapArlingtonComponent/>);
        }else if(this.state.location === "jersey"){
            map.push(<GoogleMapJerseyComponent/>);
        }else if(this.state.location === "medford"){
            map.push(<GoogleMapMedfordComponent/>);
        }else if(this.state.location === "nashua"){
            map.push(<GoogleMapNashuaComponent/>);
        }else if(this.state.location === "needham"){
            map.push(<GoogleMapNeedhamComponent/>);
        }else if(this.state.location === "lawrenceville"){
            map.push(<GoogleMapLawrencevilleComponent/>);
        }else if(this.state.location === "plano"){
            map.push(<GoogleMapPlanoComponent/>);
        }else if(this.state.location === "stjohns"){
            map.push(<GoogleMapStjohnsComponent/>);
        }else if(this.state.location === "cypress"){
            map.push(<GoogleMapCypressComponent/>);
        }

    //     let parentConsultForm = <form id="cw-contact-form grand-opening-form" className="contact-form" action={this.state.parentConsultUrl} method="POST">
    //     <div className="form-row">
    //         <div className="form-group col-md-6">
    //             <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
    //             <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
    //         </div>
    //         <div className="form-group col-md-6">
    //             <label className="required-label" htmlFor="inputLast">Last Name *</label>
    //             <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
    //         </div>
    //     </div>
    //     <div className="form-row">
    //         <div className="form-group col-md-6">
    //             <label className="required-label" htmlFor="inputEmail">Email *</label>
    //             <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
    //         </div>
    //         <div className="form-group col-md-6">
    //             <label className="required-label" htmlFor="inputPhone">Phone *</label>
    //             <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
    //         </div>
    //     </div>
    //     <div className="form-row">
    //         {/* <div className="form-group col-md-6">
    //             <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
    //             <select name="mode" className="form-control" id="inputMode" required>
    //                 <option value="inperson">In-Person Classes</option>
    //             </select>
    //         </div> */}
    //         <div className="form-group col-md-6">
    //             <input type="hidden" id="location" name="location" value={this.state.location}></input>
    //             <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
    //             <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
    //             <input type="hidden" id="formtype" name="formtype" value="parent consult"></input>
    //         </div>
    //     </div>
    //     <div className="form-group">
    //             <div className="form-check">
    //                 <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
    //                 <label className="form-check-label tiny-print" htmlFor="gridCheck">
    //                 {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
    //                 <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
    //                 <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
    //                 </label>
    //             </div>
    //         </div>
    //     {/* <button type="submit">Send</button> */}
        
    //     <button type="submit" className="btn white-button" target="_blank">SEND</button>
    // </form>;

    let trialClassForm = <form id="cw-contact-form grand-opening-form" className="contact-form" action={this.state.trialClassFormUrl} method="POST">
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputLast">Last Name *</label>
                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputEmail">Email *</label>
                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputPhone">Phone *</label>
                <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
            </div>
        </div>
        <div className="form-row">
            {/* <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                <select name="mode" className="form-control" id="inputMode" required>
                    <option value="inperson">In-Person Classes</option>
                </select>
            </div> */}
            <div className="form-group col-md-6">
                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
                <input type="hidden" id="formtype" name="formtype" value="parent consult"></input>
            </div>
        </div>
        <div className="form-group">
                <div className="form-check">
                    <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                    <label className="form-check-label tiny-print" htmlFor="gridCheck">
                    {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                    <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                    <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                    </label>
                </div>
            </div>
        {/* <button type="submit">Send</button> */}
        
        <button type="submit" className="btn white-button" target="_blank">SEND</button>
    </form>;

    // let parentConsultPopUp = <Popup trigger={<button className='btn orange-button'>BOOK A PARENT CONSULT</button>} modal>
    let freeTrialPopUp = <Popup trigger={<button className='btn orange-button'>TRY A FREE CLASS</button>} modal>
    <div className="col-sm-12 cw-dark-blue form-in-modal">
        {/* <h4 className="bungee center">Is your child a <span className='light-green'>budding prodigy?</span></h4>
        <p className="center">Sign up for a parent consult to learn how Code Wiz can help your child be a future innovator!</p> */}
        <h4 className="bungee center">Is your child a <span className='light-green'>budding prodigy?</span></h4>
        <p className="center">Not sure your child will like a class? Sign them up for a FREE trial class!</p>
        {trialClassForm}
    </div>
</Popup>
// console.log("Hours", this.state.data.friday)

        return (
            <div className="subsite franchisee-page grandslam" id={this.state.location}> 
                {/* <div className='dark-color-bkg'> */}
                    <div className="coming-soon-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-12 col-lg-6 center">
                                {/* <img className='coming-soon-image' aria-hidden="true" src={FoundingMemberHeaderImg} alt="Founding member header picture"/> */}

                                <h2 className="center summer-camp-video"><iframe src={this.state.data.youtubevidwelcome} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></h2>
                                
                                <br />
                                <br />
                            </div>
                            <div className="top-header col-sm-12 col-md-12 col-lg-5 "> 
                                <div className="text">
                                    {/* <h2 className="header-title cw-dark-blue bungee">{this.state.data.headertext}</h2>  */}
                                    <h2 className="header-title cw-dark-blue bungee">UNLOCK YOUR CHILD'S INNER <span className='light-green'>GENIUS!</span></h2>
                                    <h3 className="header-text cw-dark-blue map-pin"><span><img aria-hidden="true" src={CityImage} alt="franchise city"/></span>{this.state.data.citystate}</h3> 
                                    
                                    <div className="landing-page-buttons">
                                        {freeTrialPopUp}
                                        <Button className="center" color="blue" link={this.state.data.parentportal} size="medium">VIEW CLASSES & CAMPS</Button> 
                                        {/* <Button color="orange" link={this.state.data.parentportal} size="medium">VIEW CLASSES/CAMPS</Button> */}
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                {/* </div> */}
                {/* As seen on section */}
                <br />
                <div className="as-seen-on">
                        <div className="col-md-12 center">
                            <h4 className="center cw-dark-blue bungee">AS SEEN ON</h4>
                        </div>
                        <div className="col-md-12 as-seen-on-div">
                            <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                        </div>
                </div>
                {/* End of as seen on */}
                <div className='container'>
                    <div className="empty-space-half bw-0"></div> 
                    <h3 className='bungee center dark-blue'>code. create. grow.</h3>
                    <p>At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem-solving. Best of all, anyone can learn to code—no matter their age, tech proficiency, or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                </div>
                <div className="body-wrapper cw-dark-blue">
                    {/* <div className="container"> */}
                    <div className="review-container">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row">
                            <div className="col-md-12 col-lg-6 code-create-grow">
                                {/* <h3 className="bungee">code. create. grow.</h3>
                                <p className="">At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem-solving. Best of all, anyone can learn to code—no matter their age, tech proficiency, or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                                <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                                <h4 className="bungee">client reviews</h4>
                                {/* <p className=''>"My 11 yr old attended a session at Code Wiz and declared it the best session he has attended. Ruth, the owner, is fantastic and made sure the content matched the skill level. He came home each day excited to show what he learned and created. Highly, highly recommend!!"</p>
                                 */}
                                 <div className='review-slider'>
                                    <ReviewSlider/>
                                 </div>
                                
                                {/* <div className='col-md-12 center'><p></p><Button color="orange" link={this.state.data.parentportal} size="medium">BOOK A PARENT CONSULT</Button><p></p></div> */}
                                <div className='col-md-12 center'><p></p>{freeTrialPopUp}<p></p></div>
                            </div>
                            
                            <div className="col-md-12 col-lg-6 form-white grandslam-form box-shadow-div">
                                {/* <h3 className="bungee cw-dark-blue">download our class schedule</h3>
                                <p></p> */}
                                <div className='form-container'>
                                    <div className='orange-form-header'>
                                        <h4 className='bungee black'>We’d love to share more information on why Code Wiz is a good fit for <span className='light-green'>budding prodigies!</span></h4>
                                    </div>
                                    <form id="cw-contact-form" className="contact-form cw-dark-blue" action={this.state.infopacket} method="POST">
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                                                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="required-label" htmlFor="inputLast">Last Name *</label>
                                                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label className="required-label" htmlFor="inputEmail">Email *</label>
                                                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label className="required-label" htmlFor="inputPhone">Phone *</label>
                                                <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                                <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                                                <label className="form-check-label tiny-print" htmlFor="gridCheck">
                                                {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                                                <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                                                <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                                                </label>
                                            </div>
                                            <div className="form-group col-md-6">
                                                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                                                <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                                                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
                                            </div>
                                        </div>
                                        <div className="form-row center">
                                            <div className='col-md-12'>
                                                <button type="submit" className="center btn orange-button" target="_blank">DOWNLOAD INFORMATION PACKET</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="empty-space-half bw-0"></div> 
                    <div className="review-container-dotted box-shadow-div">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row">
                            <div className="col-md-12 col-lg-6 center">
                                <img aria-hidden="true" src={LearningFramework} alt="learning framework"/>
                            </div>
                            <div className="col-md-12 col-lg-6 ">
                                <h4 className="bungee">Our Proprietary Educational Model</h4>
                                <p>Embark on an exciting coding journey with the CODES Model at Code Wiz—an exclusive and proprietary framework that sparks your child's fascination with coding and technology. Coding and Robotics for Kids Ages 5 - 15.</p>
                                <ul className='cw-dark-blue'>
                                    <li><b className='bungee purple'>Contextualize:</b> We show your child how coding connects to their interests and favorite games, making it relevant to the real world.</li>
                                    <li><b className='bungee purple'>Orient:</b> Step-by-step guidance helps them build essential coding skills with creativity and mastery.</li>
                                    <li><b className='bungee purple'>Develop:</b> They actively practice coding through milestones, enhancing problem-solving abilities and critical thinking.</li>
                                    <li><b className='bungee purple'>Evaluate:</b> We evaluate and boost your child's coding skills with tailored feedback, ensuring continuous improvement.</li>
                                    <li><b className='bungee purple'>Synthesize:</b> Skills are integrated across domains, fostering innovation and diverse project creation.</li>
                                </ul>
                            </div>
                        </div>
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row celebrating-empower">
                            <div className="col-md-12 col-lg-6 celebrating">
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <img src={Celebration} />
                                    </div>
                                    <div className='col-md-10'>
                                        <h5 className='dark-blue bungee'>Celebrating Achievements</h5>
                                        <p>We celebrate their coding accomplishments with the Wizbucks reward system, empowering them to progress and achieve higher levels</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-6 empower">
                                <div className='row'>
                                    <div className='col-md-2'>
                                        <img src={Empowering} />
                                    </div>
                                    <div className='col-md-10'>
                                        <h5 className='dark-blue bungee'>Empowering Wizards</h5>
                                        <p>We envision every child as a wizard, empowered to create, share, and inspire, shaping the world through coding.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12 center">
                                {/* <Button color="orange" link={this.state.data.parentportal} size="medium">BOOK A PARENT CONSULT</Button> */}
                                {freeTrialPopUp}
                            </div>
                        </div>
                        <div className="empty-space-half bw-0"></div> 
                    </div>
                    
                    {/* <div className="empty-space-half bw-0"></div>
                    <div className='course-we-offer'>
                        <div className='row'>
                            <div className='col-md-12 center'>
                                <h4 className='bungee dark-blue'>Courses we offer</h4>
                            </div>
                        </div>
                        <Verticals />
                    </div> */}

                    <div className="empty-space-half bw-0"></div>
                    <div className='find-a-course find-a-course-content'>
                        <div className='container'>
                            <div className="center">
                                <h3 className="bungee">Code Wiz Courses</h3>
                                <p>At Code Wiz, we believe that anyone can learn to code—and everyone will fall in love with the process! We create super-fun, highly individualized classes for children from ages 7-17. Whether your child is a budding coder or practically running Python in their sleep, we scale the level of difficulty to fit their skill level so that they continue to learn and grow.</p>
                                <p>Click below to learn more about the options available for your child or reach out to us today for guidance.</p>
                            </div>
                            <div className='col-md-12 center'>
                                <h4 className="bungee">student's age</h4>
                                <Courses />
                            </div>
                        </div>
                    </div>
                    <div className="empty-space-half bw-0"></div>

                    <div className="review-container-dotted box-shadow-div">
                        <div className="empty-space-half bw-0"></div>
                        <div className="row">
                            <div className="col-md-12 center">
                                {/* <Button color="orange" link={this.state.data.parentportal} size="medium">BOOK A PARENT CONSULT</Button> */}
                                {/* <Popup trigger={<button className='btn orange-button'>BOOK A PARENT CONSULT</button>} modal>
                                    <div className="col-sm-12 cw-dark-blue form-in-modal">
                                        <h4 className="bungee center">Hello</h4>
                                        <p className="center">Please give us your information</p>
                                        {parentConsultForm}
                                    </div>
                                </Popup> */}
                                {freeTrialPopUp}
                                <p></p>
                                <img src={CourseWeOfferImg} />
                            </div>
                        </div>
                        <div className="empty-space-half bw-0"></div> 
                    </div>

                    <div className="col-md-12 educational-model-heading"></div>
                    <div className="empty-space-half bw-0"></div> 
                    <div className="whycodewiz review-container box-shadow-div">
                        <div className="empty-space-half bw-0"></div> 
                        <div className='row'>
                            <div className='col-md-6'><h4 className="bungee"> Why Choode Code<span>Wiz</span></h4></div>
                        </div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className="container">
                                    {/* <div className="row"> */}
                                        <div className="reason box-shadow-div">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <img src={MontesorriImg} alt="Montesorri"/>
                                                </div>
                                                <div className="col-md-8 reason-text">
                                                    <h5 className="bungee">MONTESORRI STYLE APPROACH</h5>
                                                    <p>No two kiddos are the same and their learning paths shouldn’t be. Whether they’re interested in games or games and robotics or games and robotics and AI, we’ve got something for them. Student-driven, personalized learning paths for beginners and advanced students </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reason box-shadow-div">
                                            <div className="row">
                                                <div className="col-md-4">
                                                <img src={FreeClassImg} alt="Free class"/> 
                                                </div>
                                                <div className="col-md-8 reason-text">
                                                    <h5 className="bungee">PROJECT BASED</h5>
                                                    <p>Children are full of ideas! Our project-based approach allows them to learn how to code while bringing their ideas to life!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="reason box-shadow-div">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <img src={RewardsImg} alt="Rewards"/>
                                                </div>
                                                <div className="col-md-8 reason-text">
                                                    <h5 className="bungee">WE MAKE LEARNING FUN</h5>
                                                    <p>We mix the veggies in the fruit smoothie. While they are learning so much, they don’t realize it because they’re having so much fun.</p>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                        <div></div>
                                        <div>
                                        {/* <Button color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">TRY A FREE CLASS</Button> */}
                                        </div>
                                    {/* </div> */}
                                    
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <h2 className="center project-video"><iframe src="https://www.youtube.com/embed/WWshAvVc6rU?si=4Il_ELx7ljNkEcNl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></h2>
                                <div className="reason box-shadow-div">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <img src={CommunityImg} alt="Community"/>
                                        </div>
                                        <div className="col-md-8 reason-text">
                                            <h5 className="bungee">NO LONG-TERM CONTRACTS</h5>
                                            <p>You have the flexibility to stop your enrollment if plans change! We only ask for a 30-day heads-up. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="empty-space-half bw-0"></div> 
                    <div className="empty-space-half bw-0"></div> 
                    <div className="col-md-12 educational-model-heading">
			            <h4 className="bungee"> Genius Unleashed: Achieve, Ascend</h4>
                    </div>
                    <div className="review-container-dotted box-shadow-div educational-model">
                        <div className="empty-space-half bw-0"></div> 
                        <div className='row'>
                            <div className='col-md-6 left-education'>
                                <img src={MashupOneImg}/>
                            </div>
                            <div className='col-md-6 right-education'>
                                <img src={MashupTwoImg}/>
                            </div>

                            <div className="col-md-12 empty-space-half bw-0"></div> 
                            <div className='col-md-12 center'>{freeTrialPopUp}</div>
                            <div className="empty-space-half bw-0"></div> 
                        </div>
                        
                    </div>

                    <div className="empty-space-half bw-0"></div> 
                    <div className="col-md-12 educational-model-heading">
			            <h4 className="bungee"> From Wizling to Wizza: Inspiring Success Stories!</h4>
                    </div>
                    <div className="review-container-dotted wizzas-div">
                        <div className="empty-space-half bw-0"></div> 
                        <div className='row'>
                            <div className='col-lg-4 col-md-6 col-sm-12 box-shadow-div wizza'>
                                <div className='wizza-img'><img src={JackImg} /></div>
                                <div className=''>
                                    <h5 className='bungee'><b>Jack Whitman</b></h5>
                                    <h5><span className='cw-orange'>Courses I Teach:</span> Scratch, Roblox, Minecraft, Python, FLL Jr, FLL Competitive and Non-Competitive</h5>
                                    <p>In mid-2017, I saw a Sign for the grand opening of a new business called Code Wiz. Thoroughly fascinated and captivated by programming as a kid, I was there on the very first day. For three years, I attended Code Wiz camps and classes year-round, even winning one of their Codeathon coding competitions. I always joked about becoming an employee at Code Wiz the day I turned 14... and that's exactly what happened!</p>
                                    <p>During the lockdown, I made short inforrnational technology videos on Code Wiz's YouTube channel, and I have been working as a coach for about two years. Helping kids build, collaborate, and solve problems leads them to their goals and fulfills one of ours: empowering creativity through technology.</p>
                                    
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 box-shadow-div wizza mid'>
                                <div className='wizza-img'><img src={AvashaImg} /></div>
                                <div className=''>
                                    <h5 className='bungee'><b>Avashya Singh</b></h5>
                                    <h5><span className='cw-orange'>Courses I Teach:</span> Minecraft, Roblox</h5>
                                    <p>I was a student at Code Wiz from 2015-2018. I enjoyed all of the classes and the projects I got to work on throughout those 3 years and learned a lot about coding and computer science. I went back to Code Wiz in 2022 as a volunteer as I needed some more hours for school and I wanted to see if they needed some help.</p>
                                    <p>After doing some volunteering, I asked if they were looking to hire and ended up getting a job! Now I teach Minecraft Coding and Roblox Game Design to new Wizlings!</p>
                                    
                                </div>
                            </div>
                            {/* <div className='col-lg-4 col-md-6 col-sm-12 box-shadow-div wizza'>
                                <img src={CharlotteImg} />
                                <div className=''>
                                    <h5><span className='cw-orange'>Courses I Teach:</span> Scratch, Minecraft, Roblox, Python</h5>
                                    <p>When I was 9 through 11 years of age, I attended Code Wiz. I was blown away by how passionate and diverse the staffing community was, and found the courses to be very engaging. I felt very included, and Code Wiz kick-started my passion for computer science.</p>
                                    <p>Last summer I wanted to give back by inspiring other children and contnuing to build the supportive and accepting experience I had. In the future, I see myself working in a tech position and continuing to promote inclusion and diversity.</p>
                                    <h5 className='bungee'><b>Charlotte Lokere</b></h5>
                                </div>
                            </div> */}
                        </div>
                        <div className="empty-space-half bw-0"></div> 
                    </div>
                    {/* <img src={MembershipImg} /> */}
                    <div className="membership-div light-blue-bkg ">
                        <div className='row'>
                            <div className="col-md-12 educational-model-heading">
                                <p></p>
                                <h4 className="bungee"> Membership options</h4>
                                <p></p>
                            </div>
                            <div className='col-md-12'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 col-sm-12 membership-option'>
                                        {/* <h5 className='bungee'>lite</h5> */}
                                        <h5 className='bungee'>Included in Both memberships</h5>
                                        <p></p>
                                        <ol className='membership-benefits'>
                                            <li>30 min onboarding with parents to map out student’s 3 year journey with Code Wiz</li>
                                            <li>Take-home “Parent and Me” enrichment packets</li>
                                            <li>Monthly progress reports</li>
                                            <li>Student Binder - WizBucks, Milestones, Learning Reflections and Badges</li>
                                            <li>Training on being a student in an integrated classroom</li>
                                            <li>Exclusive Code Wiz Community Group for Parents</li>
                                            <li>New Parent Orientation</li>
                                            <li>Coding buddy</li>
                                            <li>10% OFF Camps</li>
                                            <li>Quarterly Educational articles & webinars</li> 
                                        </ol>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12 membership-option'>
                                        <h5 className='bungee'>plus</h5>
                                        {/* <h4>$297/month</h4> */}
                                        <p><b>2 hours/week</b></p>
                                        <ol className='membership-benefits'>
                                            <li>Monthly Zoom check-in with parents to discuss progress</li>
                                            <li>Flash cards to break down Computer Science concepts</li>
                                            <li><b>BONUS</b> - Showcase at Annual STEAM event</li>
                                            <li><b>BONUS</b> - Quarterly Career Day</li>
                                            <li><b>BONUS</b> - Code Wiz T-Shirt</li>
                                            <li><b>BONUS</b> - Parent Night Out</li>
                                            <li><b>BONUS</b> - 1 month subscription to Little Passports, Kidpreneur Academy and Think Outside Boxes</li>
                                        </ol>
                                    </div>
                                    <div className='col-lg-4 col-md-6 col-sm-12 membership-option'>
                                        <h5 className='bungee'>Premium</h5>
                                        {/* <h4>$447/month</h4> */}
                                        <p><b>Private 1:1 Class</b></p>
                                        <ol className='membership-benefits'>
                                            <li><i><b>Everything in Plus</b></i></li>
                                            <li>Benefit from undivided focus in our elite 60 minute, 1:1 sessions. individualized learning</li>
                                            <li>Bank of hours you can use at anytime</li>
                                            <li>Daily Progress Reports</li>
                                            <li>Access to coaching outside of class time</li>
                                            <li><b>BONUS</b> - 1 Half Day Camp</li>
                                            <li><b>BONUS</b> - Mentorship Opportunities with Engineers</li>
                                            <li><b>BONUS</b> - 1 Birthday Party/Workshop</li>
                                        </ol>
                                    </div>
                                    {/* <div className='col-md-3 membership-option'>
                                        <h5 className='bungee'>Platinum</h5>
                                        <h4>$447/month</h4>
                                        <p></p>
                                        <ol className='membership-benefits'>
                                            <li><i><b>Everything in Premium</b></i></li>
                                            <li>1:1 Classes</li>
                                            <li>Daily Progress Reports</li>
                                            <li>Access to coaching outside of class time</li>
                                            <li>1 Half Day Camp</li>
                                            <li>BONUS - Mentorship Opportunities with Engineers</li>
                                            <li>BONUS - Code Wiz Nerd Video Vault: Epic tips & tricks from our Nerd in Chief!</li>
                                            <li>BONUS - 1 Birthday Party/Workshop</li>
                                            <li>BONUS - 1 month subscription to Little Passports</li>
                                            <li>BONUS - 1 month subscription to Kidpreneur Academy</li>
                                            <li>BONUS - 1 month subscription to Think Outside Boxes</li>
                                        </ol>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="empty-space-half bw-0"></div> 
                    </div>
                    <div className="guarantee-div box-shadow-div purple-bkg">
                        <div className='row'>
                            <div className='col-md-7'>
                                <h4 className='bungee white'>
                                Value-Verified Guarantee
                                </h4>
                                <p className='white'>With our "Value-Verified" Guarantee, we prioritize your child's tangible growth and the worth of your investment. After an initial immersion period in our coding classes, if you're uncertain about the value derived, we offer a feedback session to address concerns. Based on this, we might adjust the curriculum to your child's interests or provide extra resources. If, even after these adjustments, you're not convinced of the value, we commit to an additional month of coding classes at no extra cost. Our unwavering goal is to ensure every penny spent translates into undeniable value and enriching experiences for your child.</p>
                                {/* <Button className="center" color="orange" link="https://codewiz.simplybook.me/v2/#book" size="medium">BOOK A PARENT CONSULT</Button> */}
                                {freeTrialPopUp}
                            </div>
                            <div className='col-md-5 right'>
                                <img src={MoneyGuaranteeImg} />
                            </div>
                        </div>
                    </div>
                    {/* <div className="empty-space-half bw-0"></div>  */}
                    <div className="founder-message">
                        <div className='row'>
                            <div className='col-lg-4 col-md-12 ruth-img'>
                                <img src={RuthImg} />
                            </div>
                            <div className='col-lg-8 col-md-12 black'>
                                {/* <img src={FounderMsgImg} /> */}
                                <p>Hi, my name is <b>Ruth Agbaji</b>, and I am the Founder of Code Wiz I founded Code Wiz to help kiddos like yours connect with their inner genius!
                                <br/><br/>Growing up in Nigeria, I didn't have full access to computers and the internet, at least not how our kids now have it. So when I had the opportunity to learn how to code, I seized it, and I loved learning to code! The ability to create something out of nothing with a few keystrokes was very empowering.
                                <br/><br/>Anyone who has learned to code knows it's very easy for a newbie to get stuck and entirely overwhelmed by errors! This is the position I was in when I reached out to a stranger and asked to be mentored. This newfound mentor saved me from giving up on learning how to code, and he coached me through my entire learning process.
                                <br/><br/>Having this coach changed the trajectory of my life. I landed a scholarship at Tufts University to study Computer Science and worked as a Software Engineer for Microsoft, all because learned to code and had someone help me discover my inner genius.
                                <br/><br/>I am ready to do the same for others, along with our exceptional local community partners! We look forward to supporting your kids through their journey of self-discovery through coding!</p>
                                <p><b>Your fav Nerd in Chief, <br/>Ruth Agbaji</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="review-container-dotted box-shadow-div">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="container party-options">
                            <div className="cw-dark-blue">
                                <h4 className="bungee">FAQS</h4>
                                <div id="accordion">
                                    <div className="card">
                                        <div className="card-header" id="generalFaqOne">
                                            <h5 className="mb-0">
                                            <span className="faq-question" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <h5 className="bungee cw-dark-blue">Do we get feedback on what our children are working on?</h5>
                                                <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                                <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseOne" className="collapse show" aria-labelledby="generalFaqOne" data-parent="#accordion">
                                            <div className="card-body">
                                                Yes, total transparency is very important to us. At the end of the class, children are encouraged to share the amazing projects they’ve been working on. We also send routine progress report emails that include links to your child’s completed projects.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqTwo">
                                            <h5 className="mb-0">
                                            <span className="faq-question collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                <h5 className="bungee cw-dark-blue">Do you offer group classes or personalized classes?</h5>
                                                <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                                <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseTwo" className="collapse" aria-labelledby="generalFaqTwo" data-parent="#accordion">
                                            <div className="card-body">
                                                All classes are personalized to your child’s interests, skills and goals. We tailor the experience for each child to ensure that they are always learning and working on projects that they are excited for and truly passionate about. 
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqThree">
                                            <h5 className="mb-0">
                                            <span className="faq-question collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <h5 className="bungee cw-dark-blue">How long will it take my child to learn coding?</h5>
                                                <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                                <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="generalFaqThree" data-parent="#accordion">
                                            <div className="card-body">
                                                Learning to code is like learning a new language—the more you practice, the better you get! Some children breeze through their lessons and projects while others take a little more time. That’s why Code Wiz has a project-based curriculum that supports all speeds of learning. For us, the most important takeaway is that your child understands the programming concepts and builds computational thinking skills that will serve them through life.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqFour">
                                            <h5 className="mb-0">
                                            <span className="faq-question collapsed" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <h5 className="bungee cw-dark-blue">Can we do more than one class per week?</h5>
                                                <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                                <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="generalFaqFour" data-parent="#accordion">
                                            <div className="card-body">
                                                Absolutely!
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="generalFaqFive">
                                            <h5 className="mb-0">
                                            <span className="faq-question collapsed" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <h5 className="bungee cw-dark-blue">Pay by class or by session?</h5>
                                                <span className="circle-down"><i className="fa fa-arrow-circle-down cw-orange"></i></span>
                                                <span className="circle-up"><i className="fa fa-arrow-circle-up cw-orange"></i></span>
                                            </span>
                                            </h5>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="generalFaqFive" data-parent="#accordion">
                                            <div className="card-body">
                                                We know flexibility is important, which is why we will never tie you into a long-term contract. We bill monthly and will continue to enroll your child in the next month of courses. If you ever wish to pause classes, all you have to do is provide a 30-day cancellation notice.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="empty-space-half bw-0"></div> 
                    </div>
                    {/* <div className="review-container-dotted box-shadow-div">
                        <div className="empty-space-half bw-0"></div> 
                        <div className='row'>
                            <div className='col-md-12'></div>
                            <div className='col-md-12'></div>
                        </div>
                    </div> */}
                    <div>
                        {/* <div className="empty-space form-empty-space"></div>
                        <div className="row row-reverse coding-robotics">
                            <div className="col-md-12 col-lg-6 ">
                                <h3 className="bungee">coding and robotics classes in {this.state.data.citystate}</h3>
                                <p>Give your child the chance to learn life-shaping skills with fun, flexible programs offered online or at our {this.state.data.citystate} location.</p>
                                <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <img aria-hidden="true" className="midImg" src={CodingRobotics} alt="coding robotics"/>
                            </div>
                        </div>
                        <div className="empty-space-half"></div> */}
                        {/* <div className="row fr-whycodewiz">
                            <div className="col-md-12 col-lg-6 order-md-1 whycodewizimg">
                                <img aria-hidden="true" className="width-100 midImg" src={WhyCodewiz} alt="why codewiz"/>
                            </div>
                            <div className="col-md-12 col-lg-6 order-md-2">
                                <h3 className="bungee whycodewiztitle">why code wiz?</h3>
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">project-driven, student-steered learning</h6>
                                <p>Children create by completing projects rather than lessons or levels</p>
                                
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">really cool rewards</h6>
                                <p>We reward learning with Code Wiz gear and other awesome prizes</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">community connectivity</h6>
                                <p>Students can check their work online and get inspired by peer projects</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">progress at your fingertips</h6>
                                <p>Stay connected to your child's success with automated progress report emails</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">free, no-strings intro class</h6>
                                <p>See how learning to code can change your child's life-all classes are offered in person and online!</p>
                                <br/>

                                <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                            </div>
                        </div>   */}
                    </div>
                </div>
                <div className="container contact-info-contact-page">
                    <div className="mid-layer">
                        <h3 className="bungee center cw-dark-blue">location</h3>
                        <div className="row">
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>ADDRESS:</h5>
                                <p>{this.state.data.addressstreet}<br/> {this.state.data.city}, {this.state.data.state} {this.state.data.zip}</p>
                                <p>{this.state.data.addressDetails}</p>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-phone fa-5x cw-orange"></i></div>
                                <h5>CONTACT:</h5>
                                <p><a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a> <br/>
                                <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a></p>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>HOURS:</h5>
                                <p> 
                                    {renderdaysList} 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-12 center"><Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button></div> */}

                {/* <!-- Location Map --> */}
                {map}

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 center" style={{display: 'none'}}>
                            <h3 className="bungee cw-orange">reviews</h3>
                            <h4 className="bungee cw-dark-blue">Ecstatic students <br/> happy parents</h4>
                            <section className="pt-5 pb-5">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-6"></div>
                                        <div className="col-6 text-right">
                                            <a className="btn btn-primary mb-3 mr-1" href="#parentreviews" role="button" data-slide="prev">
                                                <i className="fa fa-arrow-left"></i>
                                            </a>
                                            <a className="btn btn-primary mb-3 " href="#parentreviews" role="button" data-slide="next">
                                                <i className="fa fa-arrow-right"></i>
                                            </a>
                                        </div>
                                        <div className="col-12">
                                            <div id="parentreviews" className="carousel slide" data-ride="carousel">
                                
                                                <div className="carousel-inner">
                                                    <div className="carousel-item active">
                                                        <div className="row">
                                                            <Review review="text text text 1" author="Satisfied Parent"/>
                                                            <Review review="text text text 2" author="Satisfied Parent 2"/>
                                                        </div>
                                                    </div>
                                                    <div className="carousel-item">
                                                        <div className="row">
                                                            <Review review="text text text 3" author="Satisfied Parent 3"/>
                                                            <Review review="text text text 4" author="Satisfied Parent 4"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <h3 className="bungee cw-dark-blue center">Our Team</h3>
                    <h5 className="center">Meet the {this.state.data.city} Team</h5>
                    <div className="row team-row">
                        <section className="pt-5 pb-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-6">
                                        {/* <!-- <h3 class="mb-3">Carousel cards title </h3> --> */}
                                    </div>
                                    {/* <div className="col-6 text-right">
                                        <a className="btn btn-primary mb-3 mr-1" href="#teamCarousel" role="button" data-slide="prev">
                                            <i className="fa fa-arrow-left"></i>
                                        </a>
                                        <a className="btn btn-primary mb-3 " href="#teamCarousel" role="button" data-slide="next">
                                            <i className="fa fa-arrow-right"></i>
                                        </a>
                                    </div> */}
                                    <div className="col-12">
                                        {/* <div id="teamCarousel" className="carousel slide mobile-version" data-ride="carousel"> */}
                                        <div id="teamCarousel" className="carousel slide" data-ride="carousel">
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="row">
                                                        {teamsArr}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        {/* <div className="desktop-version">
                                            <TeamSlider teams={this.state.team}/>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>

                {/* <Container>
                    <Link href="#"
                        tooltip="Email Us"
                        icon="fa fa-envelope-o" />
                    <Link href="#"
                        tooltip="Try A free class"
                        icon="fa fa-paper-plane-o" />
                        
                    <Button
                        color="blue"
                        size="small"
                        tooltip="The big plus button!"
                        icon="fas fa-plus"
                        rotate={true}
                        onClick={() => alert('FAB Rocks!')}>
                        <i className="fa fa-plus" aria-hidden="true"></i>
                    </Button>
                </Container> */}
                {/* <a href={this.state.data.tryfreeclass} class="float">
                    <i class="fa fa-plus my-float"></i>
                    TRY A FREE CLASS
                </a> */}
                <FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email} facebookId={this.state.data.facebookId}/>
                
            </div>
        );
    }
}

export default Franchisee
