import React from 'react';
import BirthdayPartyPage from '../../birthday-parties/birthday-parties.component';
import MetaTags from 'react-meta-tags';

const FranchiseBirthdayPartiesPage = () => (
    <div>
        <BirthdayPartyPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Birthday Parties</title>
        </MetaTags>
    </div>
);

export default FranchiseBirthdayPartiesPage;