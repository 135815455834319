import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/AI-blog-cover.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BlogItemAICompProg from '../assets/images/AI-Blog-Frame.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">October 4th, 2023</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> Artificial Intelligence and Computer Programming: How tools such as Chat GPT will Shape the Future of Stem </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Evan H.</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Artificial Intelligence (AI) and tools such as ChatGPT have been making waves in the software development industry. It is natural to wonder how AI will affect professions such as computer software engineering, coding, and indeed the world as a whole.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>The ability to automate repetitive tasks, create coding to follow simple commands, and complete small straightforward projects is a remarkable tool. There are tons of possible ways this could be utilized in the tech industry! AI as a whole has the power to change and shape careers and professions. Tasks such as reviewing, testing, and debugging may soon be in the past as AI gradually takes these responsibilities from human developers. The logical, straightforward pathing found in AI is ideal for these tasks.</p>                           
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>However, the issue with AI lies as soon as a problem is not straightforward. If a problem is more complex or requires an abstract solution, then AI tools break down. Human Developers rely heavily on their creativity, intuition, and problem-solving skills. In addition, AI and the development of AI in the future is reliant on human input, human engineering, human data scientists, developers, etc. These professionals aren’t creating a future where their job and professions are overtaken by the machines they create, instead, they are developing tools that enhance human capabilities and productivity. Artificial Intelligence is a tool created by Software Engineers, for Software Engineers. </p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>Of course there are infinite possibilities and applications of AI in today’s world and across professions, but as stated, AI is perfect for quick, logical, straightforward pathing. In fact, many different forms of limited Artificial engineering have been in use already for years!</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>Expert systems that have been in place since the 70’s solve problems with an inference engine utilizing a knowledge base focused on a specialized domain using if-then rules. Machine learning and algorithms are a form of artificial intelligence that are implemented across industries today in everything from finances to social media. Even logic control systems that have been implemented since the first computer can be classified as AI.</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>Now the next wave of artificial intelligence that we are currently seeing is far more diverse in use, easily implemented, and widely accessible. However, it is not replacing jobs, instead it is taking over responsibilities. This is upsetting the status quo as AI shapes professions and industries for the first time. But AI is not going to replace these professions, instead it will be utilized as a very useful tool that can improve quality, productivity, and efficiency.</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>Tasks such as debugging, generating basic code, and data analysis are currently being done by Artificial Engineering. This frees up time for engineers to be more productive and work on other less menial tasks. Through a deep understanding of coding and programming, these Engineers have secured their job and placed themselves in a position to benefit from the creation and implementation of AI.</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>This is why we here at Code Wiz embrace the changes and development that is created as AI is implemented. We see a future where Chat GPT and other AI tools are utilized on a daily basis, such as when an accountant may use Excel to find data trends. </p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>Being an expert in coding and computer engineering will not be unnecessary in the future, instead it will be needed as basic tasks are now completed as easily as one may find the answer to a math equation. What separates human potential from AI potential is humans can utilize the same foundations that AI can and apply it in unique and challenging ways that AI simply can not.</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>AI may learn python better than you, but through a deeper understanding of python you can find potentials AI will never have, and you can even use AI to help you get there. We teach foundations to coding, to problem solving, and soon to AI that will secure your child’s future in a world shaped by evolving technology; where one will soon need a deep and complete understanding of coding and technology to truly be in a profession attached to science, technology, engineering, or mathematics.</p> 
                                        </div>
                                        {/* <div className="blogs_details_block">
                                            <p></p> 
                                        </div> */}
                                        <div className="blogs_details_block">
                                            <h3 className='dark-blue bungee'>Check out what Code Wiz has to offer, find a location near you <a href="https://thecodewiz.com/code-wiz-locations">Code Wiz Locations</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
