import React from 'react';

const AbbrFaqs = () => (
    <div className="accordion" id="accordion">
        <div className="set" id="inclusivityDetails">
            <a data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Inclusivity and Accessibility <i className="fa fa-plus"></i></a>
            <div id="collapseOne" className="collapse accordion_content" aria-labelledby="inclusivityDetails" data-parent="#accordion">
                <p>I consider the needs of diverse users during the design process. I regularly advocate for both myself and others. (CS Practice 1)</p>
            </div>
        </div>
        <div className="set" id="collaboration">
            <a data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">Collaboration <i className="fa fa-plus"></i></a>
            <div id="collapseTwo" className="collapse accordion_content" aria-labelledby="collaboration" data-parent="#accordion">
                <p>I work effectively in teams by accepting different perspectives, giving and receiving feedback, and sharing the workload. (CS Practice 2.)</p>
            </div>
        </div>
            <div className="set" id="problemSolving">
            <a data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">Problem solving <i className="fa fa-plus"></i></a>
            <div id="collapseThree" className="collapse accordion_content" aria-labelledby="problemSolving" data-parent="#accordion">
                <p>I know how to define the problem, break it down into parts, and determine a solution using appropriate programs or procedures. (CS Practice 3)</p>
            </div>
        </div>
        <div className="set" id="abstractions">
            <a data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">Abstractions <i className="fa fa-plus"></i> </a>
            <div id="collapseFour" className="collapse accordion_content" aria-labelledby="abstractions" data-parent="#accordion">
                <p>I recognize patterns and can simplify code by using functions and loops. I can design modules that simulate real world phenomena. (CS Practice 4)</p>
            </div>
        </div>

        <div className="set" id="creativity">
            <a data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">Creativity <i className="fa fa-plus"></i></a>
            <div id="collapseFive" className="collapse accordion_content" aria-labelledby="creativity" data-parent="#accordion">
                <p>I express myself creatively by designing new prototypes, figuring out new ways to solve a problem, or improving on existing products and solutions. (CS Practice 5)</p>
            </div>
        </div>
        <div className="set" id="debugging">
            <a data-toggle="collapse" data-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">Debugging skills <i className="fa fa-plus"></i></a>
            <div id="collapseSix" className="collapse accordion_content" aria-labelledby="debugging" data-parent="#accordion">
                <p>I am able to find and fix errors in programs. (CS Practice 6)</p>
            </div>
        </div>
        <div className="set" id="communication">
            <a data-toggle="collapse" data-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">Communication <i className="fa fa-plus"></i></a>
            <div id="collapseSeven" className="collapse accordion_content" aria-labelledby="communication" data-parent="#accordion">
                <p>I share my ideas clearly by using appropriate language to describe, justify, and document processes and solutions. (CS Practice 7)</p>
            </div>
        </div>
        <div className="set" id="perseverance">
            <a data-toggle="collapse" data-target="#collapseEight" aria-expanded="true" aria-controls="collapseEight">Perseverance <i className="fa fa-plus"></i></a>
            <div id="collapseEight" className="collapse accordion_content" aria-labelledby="perseverance" data-parent="#accordion">
                <p>I do not give up when a problem gets challenging. I try different solutions and strategies until I get it right. (CS Practice M1)</p>
            </div>
        </div>
        <div className="set" id="designThinking">
            <a data-toggle="collapse" data-target="#collapseNine" aria-expanded="true" aria-controls="collapseNine">Design thinking <i className="fa fa-plus"></i></a>
            <div id="collapseNine" className="collapse accordion_content" aria-labelledby="designThinking" data-parent="#accordion">
                <p>I am able to use an iterative process to design innovative prototypes or solutions to problems people care about. (CS Practice S6)</p>
            </div>
        </div>
        <div className="set" id="codingSkills">
            <a data-toggle="collapse" data-target="#collapseTen" aria-expanded="true" aria-controls="collapseTen">Coding skills <i className="fa fa-plus"></i></a>
            <div id="collapseTen" className="collapse accordion_content" aria-labelledby="codingSkills" data-parent="#accordion">
                <p>I can use appropriate computer programming language to write a set of instructions for the computer to follow. (CS Core Concept 4)</p>
            </div>
        </div>
        <div className="set" id="algorithm">
            <a data-toggle="collapse" data-target="#collapseEleven" aria-expanded="true" aria-controls="collapseEleven">Algorithm design <i className="fa fa-plus"></i></a>
            <div id="collapseEleven" className="collapse accordion_content" aria-labelledby="algorithm" data-parent="#accordion">
                <p>I can determine step by step process to accomplish a task or solve a compelling problem. (CS Core Concept 4)</p>
            </div>
        </div>
    </div>
);

export default AbbrFaqs