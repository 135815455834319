import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">June 29, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> WHEN SHOULD KIDS BE INTRODUCED TO CODING</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Seodeveloper</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Coding is an interesting subject that is fast gaining popularity among kids. Not only are kids showing immense interest, parents too encourage <a href="https://dev.thecodewiz.com/engineering-for-kids/">engineering for kids</a> as a part of their academic development. It comes as no surprise that more and more children are enrolling for after-school coding programs these days. Typically, the curriculums of these schools are designed with high-interest content in addition to projects that involve creative input. But before you consider introducing your child to coding, you would want to know if there is a right age for doing so.</p>
                                            <p><b>It’s never too early or too late</b></p>
                                            <p>Though coding may sound a bit too complex for young children, you will be surprised to know that it’s never too early or too late for starting. In a tech-forward world, the basic understanding of computer science and programming will definitely give your child an advantage. And you cannot ignore the fact that it can give them just the right start on the career front. Further, it gives them several advantages in academics, right from developing analytic thinking to unlocking problem-solving skills and making them more creative as well.</p>
                                            <p>Whatever the age of your child is, you can get them started with coding training provided that they have the aptitude and personal interests. Fortunately, coding schools offer tailored programs to match the skill levels and age of the learners. Let us consider some specific factors that can be borne in mind while deciding the best curriculums for kids of different age groups.</p>
                                            <p><b>Elementary School:</b> You can enrol a child for coding classes’ right from the elementary school, even though this may sound too early. However, courses that include visual block interfaces with drag-and-drop elements instead of typing are considered the best for this age group. You can choose a program that suits their interests, from general computer skills to games programming and robotics.</p>
                                            <p><b>Middle School:</b> For the kids of middle school, you can choose a program that is a bit advanced. At this age, children are likely to be acquainted with hardware and software. Further, they are also capable of reading and writing proficiently. The apt curriculum at this stage is one that includes text-based interfaces and challenging projects, which extend their school lessons.</p>
                                            <p><b>High School:</b> For high school students who have advanced skill levels, courses have to be selected in a more future-oriented way. The idea is to select the ones that have the potential for widening their academic and future career prospects. Or they could simply pick a course as a hobby.</p>
                                            <p>When it comes to introducing your child to coding, you cannot take a one-size-fits-all approach to the program that your kid must start with. Moreover, you need to understand that there is much more to coding than just sitting in front of a system screen. Rather it is more about choosing a niche that your child truly would love to explore, be it gaming, art and design, or robotics.</p>
                     

                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
