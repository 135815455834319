import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import FloatingMenu from '../../components/floating-menu/floating-menu.component';

//import Directory from '../../components/directory/directory.component';
import AboutPageImg from '../../assets/images/Group-4.png';
import GirlsScoutHeaderImage from '../../assets/images/girlScouts.jpg';
//import GirlsScoutHeaderMobileImg from '../../assets/images/girls-scout-header-1.png';
import GirlsScoutDaisies from '../../assets/images/girls-scout-daisies.png';
import GirlsScoutBrownies from '../../assets/images/girls-scout-brownies.png';
import GirlsScoutJunior from '../../assets/images/girls-scout-juniors.jpg';
import GirlsScoutCadets from '../../assets/images/girls-scout-cadets.jpg';

import './girls-scout.styles.scss';
import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';

//const GirlsScoutPage = () => (
class GirlsScoutPage extends React.Component {
    constructor(props){
        super(props);
 
        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation,
            state: "",
            city: ""
        }
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations);
        });
    }
    render() {
        let aboutdata = {};
        let floatingdata =[];
        if(this.props.franchisee){
            aboutdata = this.state.data;
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } else {
            aboutdata = this.state;
        } 
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={GirlsScoutHeaderImage} />
                    <title>{`Code Wiz ${aboutdata.city} ${aboutdata.state} | Think Like a Programmer|Earn Girl Scouts Badges| Robotics School`} </title>
                    <meta name="description" content="We provide fun patches at the end of the event, troop leaders are responsible to providing their troop with official girl scout badges."/>
                    <meta name="keywords" content="Girl Scouts Badges, coding for kids, kids, coding, coding camps"></meta>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="girls-scout-page-content">
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6">
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">GIRL SCOUTS</h2> 
                                    <h4 className="header-text cw-dark-blue">Encourage girls to explore STEM! We work closely with troop leaders to craft a custom experience for the girls based on their interests and troop size.</h4>
                                    <Button color="orange" link={`mailto:${this.state.data.email}`} size="medium">CONTACT US</Button>
                                </div>
                            </div>
                            {/* <div className="col-md-12 col-lg-6 mobile-img d-md-block d-lg-none order-1"> */}
                            <div className="col-sm-12 col-md-6 expanded_image">    
                                <img src={GirlsScoutHeaderImage} alt="About"/>
                            </div>
                        </div>
                        {/* <img src={HomePageImg} className="header-image hidden-sm"/> */}
                    </div>
                    <div className="body-wrapper girls-scout-wrapper">
                        <div className="container badges-options-top">
                            <div className="center">
                                <h3 className="bungee">build for badges</h3>
                                <p>We make it easy for young girls to realize their full potential through the power of code. All programs cost just $20/hour per child. Earning badges is easy—in fact, most badges can be obtained in an hour, and multiple badges can often be combined into about two hours of programming. Though Code Wiz rewards girls with fun patches at the end of every event, troop leaders are responsible for providing their troop with official Girl Scout badges.
                                    Learn more about the incredible skills girls will learn at every level!
                                </p>
                            </div>
                            <div className="row gs-daisies row-reverse"> 
                                <div className="text col-md-12 col-lg-6">
                                    <h3 className="bungee">Daisies</h3>
                                    <h5 className="bungee cw-orange">ROBOTICS JOURNEY</h5>
                                    <p>Girls first learn what robots do by watching an informative video about the different types of robots and what makes them tick. Next, Daisies put their knowledge to the test by designing and coding their very own robot using LEGO® WeDo sets.</p>
                                    <h5 className="bungee cw-orange">COMPUTER SCIENCE JOURNEY</h5>
                                    <p>Girls initiate their computer science journey by coding mini animations with a simple drag-and-drop platform. For gaining this foundational experience, they earn a “Think Like a Programmer” badge.</p>
                                </div>
                                <div className="image center col-md-12 col-lg-6"> 
                                    <img src={GirlsScoutDaisies} alt="Daisies"/>
                                </div>
                            </div>
                            <div className="row gs-brownies">
                                <div className="image center col-md-12 col-lg-6">
                                    <img src={GirlsScoutBrownies} alt="Girl's Scout Brownies"/>
                                </div> 
                                <div className="text col-md-12 col-lg-6">
                                    <h3 className="bungee">BROWNIES</h3>
                                    <h5 className="bungee cw-orange">ROBOTICS JOURNEY</h5>
                                    <p>Girls first learn what robots do by watching an informative video about the different types of robots and how they work. Brownies then put their knowledge to the test by designing and coding their very own robot using LEGO® WeDo sets.</p>
                                    <h5 className="bungee cw-orange">COMPUTER SCIENCE JOURNEY</h5>
                                    <p>Girls continue their computer science journey by coding mini animations with a simple drag-and-drop platform. For added coding experience, they earn a “Think Like a Programmer” badge.</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="container badges-options-bottom">
                        <div className="row gs-robotics row-reverse"> 
                            <div className="text col-md-12 col-lg-6 cw-dark-blue">
                                <h3 className="bungee">JUNIORS</h3>
                                <h5 className="bungee cw-orange">ROBOTICS JOURNEY</h5>
                                <p>After learning about different types of robots and how they work, Juniors design and code their own robot using LEGO®  MINDSTORMS® EV3 sets.</p>
                                <h5 className="bungee cw-orange">COMPUTER SCIENCE JOURNEY</h5>
                                <p>Girls get their hands on the Python coding platform and use its easy, drag-and-drop interface to code animations, greeting cards and more. For their efforts, Juniors earn a “Think Like a Programmer” badge towards completing their Computer Science journey.</p>
                            </div>
                            <div className="image center col-md-12 col-lg-6">
                                <img src={GirlsScoutJunior} alt="Girl's Scout Junior"/>
                            </div>
                        </div>
                        <div className="row gs-cadets"> 
                            <div className="image center col-md-12 col-lg-6 cw-dark-blue">
                                <img src={GirlsScoutCadets} alt="Girl's Scout Cadets"/>
                            </div>
                            <div className="text col-md-12 col-lg-6 cw-dark-blue">
                                <h3 className="bungee">CADET</h3>
                                <h5 className="bungee cw-orange">ROBOTICS JOURNEY</h5>
                                <p>Girls first absorb the basics of robotics by watching a video. Cadettes then get to master the skills firsthand by designing and coding a robot using LEGO® MINDSTORMS® EV3 sets.</p>
                                <h5 className="bungee cw-orange">COMPUTER SCIENCE JOURNEY</h5>
                                <p>For diving headfirst into the Python platform to code animations, greeting cards and so much more, Cadettes are awarded a “Think Like a Programmer” badge towards completing their Computer Science journey.</p>
                            </div>
                        </div>
                    </div>
                    <div className="container center cw-dark-blue gs-info">
                        <h6 className="bungee">Contact us to set up a session for your troop!</h6><br />
                        <Button color="orange" link={`mailto:${this.state.data.email}`} size="medium">CONTACT US</Button>
                    </div>

                </div>
                {floatingdata}
            </div>
        );
    }
}

export default GirlsScoutPage;