import React from 'react';
import Header from '../../../components/header/header.component';
import OnlineFallCoding from '../../../components/online-rec-centers/online-fall-coding.component';

const OnlineFallCodingPage = () => (
  <div>
    <Header location="nashua" fulllocation="nashua-nh"/>
    <OnlineFallCoding franchisee="nashua"/>
  </div>
);

export default OnlineFallCodingPage;