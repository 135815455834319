import React from 'react';
import GirlsScoutPage from '../../girls-scout/girls-scout.component';
import MetaTags from 'react-meta-tags';

const FranchiseGirlsScoutPage = () => (
    <div>
        <GirlsScoutPage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Girl Scouts</title>
        </MetaTags>
    </div>
);

export default FranchiseGirlsScoutPage;