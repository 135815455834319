import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/make-robot.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BlogItemAICompProg from '../assets/images/AI-Blog-Frame.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">October 30th, 2023</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>How to Make a Robot: A Guide to FIRST® LEGO® League</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Evan H.</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Are you or your child interested in robotics and looking to participate in the FIRST® LEGO® League (FLL)?</p>
                                            <p>FLL is an exciting competition that challenges young minds to design, build, and program robots using LEGO® Mindstorms kits. We at Code Wiz participate every year in creating teams that work together to tackle incredible problems, learning how to critically think, work together, code, and more all at the same time!</p>
                                            <p>In this guide we will take you through the process of making a robot for the FIRST® LEGO® League. Whether you are a beginner or have some experience, this guide will provide you with valuable insights and tips to create a successful robot.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>Before we begin, what is the FIRST® LEGO® League and what do they do?</h4>
                                            <p>FIRST® LEGO® League is a global robotics program that introduces science, technology, engineering, and math (STEM) to children aged 4-16 through fun, exciting hands-on learning. The program involves designing and programming LEGO® Education robots to complete tasks related to scientific and real-world challenges. Each year, FLL introduces a new theme and a set of missions that the robot needs to complete. Here are some examples of real-world problems that FLL participants have solved in the past:</p> 
                                            <ul>
                                                <li>Water Conservation: In the 2017/2018 FLL Challenge, teams were tasked with finding solutions to problems related to water conservation. Participants had to design and program robots to complete missions related to water treatment, irrigation, and water quality testing.</li>    
                                                <li>Climate Change: In the 2019/2020 FLL Challenge, teams were asked to explore the impact of climate change and develop solutions to mitigate its effects. Participants had to design and program robots to complete missions related to energy efficiency, waste reduction, and sustainable transportation.</li>
                                                <li>Space Exploration: In the 2020/2021 FLL Challenge, teams were challenged to explore space and develop solutions to problems related to long-duration space travel. Participants had to design and program robots to complete missions related to growing food in space, monitoring air quality, and repairing equipment.</li>
                                                <li>Healthcare: In the 2021/2022 FLL Challenge, teams were asked to investigate the role of technology in healthcare and develop solutions to improve patient care. Participants had to design and program robots to complete missions related to telemedicine, patient monitoring, and medical supply delivery.</li>
                                            </ul>                          
                                        </div>
                                        <div className="blogs_details_block">
                                            <p>In this way FLL participants are able to tackle real-world problems related to STEM fields! Now once you have joined an FLL team, there are several crucial steps you need to take to ensure a successful season.</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <h5 className='bungee'>1. Understand the Challenge</h5>
                                            <p>Before diving into building a robot, it is crucial to thoroughly understand the challenge presented by the FIRST® LEGO® League. Each year, FLL introduces a new theme and a set of missions that the robot needs to complete. Our coaches and teams read and analyze the challenge guidelines so they can properly create a robot to solve the challenge.</p> 
                                        </div>
                                        <div className="blogs_details_block">
                                            <h5 className='bungee'>2. Plan and Design</h5>
                                            <p>Designing a robot is a crucial step in the process. Our teams start by brainstorming ideas and sketching out different concepts. They consider the specific requirements of the challenge and think about how your robot can efficiently navigate the field and complete the missions. Here are some key points to consider during the planning and design phase:</p> 
                                            <ul>
                                                <li>Robot Structure: Decide on the size, shape, and structure of your robot. Keep in mind that it should be able to maneuver easily and interact with the mission models.</li>
                                                <li>Attachments: Plan and design attachments that can be added to your robot to perform specific tasks. These attachments should be modular and easy to swap during the competition.</li>
                                                <li>Sensors: Determine which sensors you will need to incorporate into your robot. Sensors such as color sensors, touch sensors, and ultrasonic sensors can help your robot detect and interact with the environment.</li>
                                             
                                            </ul>
                                            <p>Our Coaches help the teams better understand the challenge and how designs and plans may factor into the end product. They also help provide support and ensure that teams are working together to create their robot.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h5 className='bungee'>3. Build the Robot</h5>
                                            <p>Once you have a clear design plan, it's time to start building your robot. Our teams then follow these steps to create and design their robot:</p> 
                                            <ul>
                                                <li>LEGO® Mindstorms Kit: Gather all the necessary components from your LEGO® Mindstorms kit. Make sure you have all the required pieces and organize them for easy access.</li>
                                                <li>Assembly: Begin by constructing the base of your robot. Follow the building instructions provided in the LEGO® Mindstorms kit. As you progress, refer to your design plan and incorporate the necessary attachments and sensors.</li>
                                                <li>Testing: Regularly test your robot as you build to ensure that each component is functioning correctly. This will help you identify any issues early on and make necessary adjustments.</li>
                                             
                                            </ul>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h5 className='bungee'>4. Program the Robot</h5>
                                            <p>Programming is a crucial aspect of making a robot for the FIRST® LEGO® League. Use the LEGO® Mindstorms software or any other programming language compatible with your robot to create a program that controls its movements and interactions. Here are some programming tips:</p> 
                                            <ul>
                                                <li>Mission Sequencing: Break down the challenge into smaller missions and create a sequence of actions for your robot to follow. This will help you program the robot to complete each mission efficiently.</li>
                                                <li>Sensors Integration: Utilize the data from the sensors to make informed decisions during the missions. For example, use color sensors to detect specific colors or ultrasonic sensors to measure distances.</li>
                                                <li>Testing and Iteration: Test your program thoroughly and make adjustments as needed. Iterate on your code to improve the robot's performance and accuracy.</li>
                                                
                                            </ul>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h5 className='bungee'>5. Practice and Refine</h5>
                                            <p>Building a robot is not just about the physical construction and programming; it also requires practice and refinement. Here are some tips to enhance your robot's performance:</p> 
                                            <ul>
                                                <li>Field Familiarization: Get familiar with the competition field and the mission models. Understand the layout and the challenges your robot will face.</li>
                                                <li>Timing and Efficiency: Practice running your robot through the missions, focusing on timing and efficiency. Look for ways to optimize your robot's movements and minimize errors.</li>
                                                <li>Collaboration: Work as a team and collaborate with your fellow FLL participants. Share ideas, strategies, and tips to improve your robot and overall performance.</li>
                                            </ul>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h5 className='bungee'>Conclusion </h5>
                                            <p>Making a robot for the First Lego League is an exciting and rewarding experience. By understanding the challenge, planning and designing effectively, building with precision, programming strategically, and practicing diligently, you can create a robot that performs well in the competition. By joining a team through Code Wiz, you are also ensuring that your kid has additional support through our coaches and resources to guarantee their success and growth during the FLL Season!</p> 
                                            <p>Remember to have fun, embrace the learning process, and enjoy the journey of building and competing with your robot in the FIRST® LEGO® League.</p>
                                            <p>Now, go out there and unleash your creativity and engineering skills to make an incredible robot for the FIRST® LEGO® League.</p>
                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className='dark-blue bungee'>Check out what Code Wiz has to offer, find a location near you <a href="https://thecodewiz.com/code-wiz-locations">Code Wiz Locations</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
