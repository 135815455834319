import React from 'react';
import Header from '../components/header/header.component';
import PrivacyPolicy from '../components/privacy-policy/privacy-policy.component';
import MetaTags from 'react-meta-tags';

const PrivacyPolicyPage = () => (
  <div>
    <MetaTags>
        <title>Code Wiz | Privacy Policy</title>
    </MetaTags>
    <Header />
    <PrivacyPolicy />
  </div>
);

export default PrivacyPolicyPage;