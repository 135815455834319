import React from 'react';
import Header from '../../../components/header/header.component';
import WelcomeMessage from '../../../components/welcome-message/welcome-message.component';

const WelcomeMessagePage = () => (
  <div>
    <Header location="arlington" fulllocation="arlington-ma"/>
    <WelcomeMessage franchisee="arlington"/>
  </div>
);

export default WelcomeMessagePage;