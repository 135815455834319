import React from 'react';
import firebase from '../Firebase/firebase.js';

import FloatingMenu from '../floating-menu/floating-menu.component';
import AcslHeaderImg from '../../assets/images/ASCL.png';
// import AcslHeaderImg from '../../assets/images/acsl_hero_image.png';
import KidTypingImg from '../../assets/images/kid_typing.png';
import MathIllustrationImg from '../../assets/images/math_illustration.png';
import Button from '../button/button.component';



import './acsl-comp.styles.scss';


class Acsl extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        if(this.props.franchisee){
            this.state.isfranchisee = true;
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }
        return (
            <div className="special-page acsl-page" id=""> 
                <div className="container header-text-container">
                    <div className="row">
                        <div className="top-header col-sm-12 col-md-6"> 
                            <div className="text">
                                <h2 className="header-title cw-dark-blue bungee">ACSL COMPETITION</h2> 
                                <h4 className="header-text cw-dark-blue">The American Computer Science League (ACSL) is a non-profit organization devoted to computer science education at the secondary school level. All divisions cover similar material, but in varying levels of detail and difficulty. ACSL administers computer science contests for junior and senior high school students, publishes a newsletter containing the results of each contest and items of interest, and awards prizes (computer equipment, books, and trophies) to outstanding students at local and regional levels.</h4>    
                                <Button color="orange" link="https://www.acsl.org/" size="medium">LEARN MORE</Button>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 expanded_image">
                            <img src={AcslHeaderImg} alt="acsl"/>
                        </div>
                    </div>
                </div>
                <div className="blue_section acsl_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 ">
                                <div className="acsl_box">
					                <p>Now in its 41st year of operation, about 250 school teams in the United States, Canada ,Europe, Africa and Asia have participated. ACSL will provide a unique and exciting educational opportunity for your computer enthusiast! Contest problems motivate students to study computer topics not covered in their school’s curriculum and to pursue classroom topics in depth. </p>
                                    <div className="acsl_table_box">
						                <div className="acsl_table">
                                            <div className="acsl_table_row head">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">Elementary<br/>Division</div>
                                                <div className="acsl_table_block">Junior<br/>Division</div>
                                                <div className="acsl_table_block">Intermediate and<br/>Senior Division</div>
                                            </div>
                                            <div className="acsl_table_row sub_head">
                                                <div className="acsl_table_block">Contest #1</div>
                                                <div className="acsl_table_block">Elementary Computer <br/>number system</div>
                                                <div className="acsl_table_block">Computer Number Systems</div>
                                                <div className="acsl_table_block">Computer Number Systems</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">Recursive Functions</div>
                                                <div className="acsl_table_block">Recursive Functions</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">What does this program do? <br/>Branching</div>
                                                <div className="acsl_table_block">What does this program do?</div>
                                            </div>
                                            <div className="acsl_table_row sub_head">
                                                <div className="acsl_table_block">Contest #2</div>
                                                <div className="acsl_table_block">Elementary Prefix/Infix/Postfix<br/>Notation</div>
                                                <div className="acsl_table_block">Prefix/Infix/Postfix Notation</div>
                                                <div className="acsl_table_block">Prefix/Infix/Postfix Notation</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">Bit-String Flicking</div>
                                                <div className="acsl_table_block">Bit-String Flicking</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">What does this program do?<br/>Loops</div>
                                                <div className="acsl_table_block">LISP</div>
                                            </div>
                                            <div className="acsl_table_row sub_head">
                                                <div className="acsl_table_block">Contest #3</div>
                                                <div className="acsl_table_block">Elementary Boolean Algebra</div>
                                                <div className="acsl_table_block">Boolean Algebra</div>
                                                <div className="acsl_table_block">Boolean Algebra</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">Data Structures</div>
                                                <div className="acsl_table_block">Data Structures</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">What does this program do? <br/>Arrays</div>
                                                <div className="acsl_table_block">FSA/Regular Expressions</div>
                                            </div>
                                            <div className="acsl_table_row sub_head">
                                                <div className="acsl_table_block">Contest #4</div>
                                                <div className="acsl_table_block">Elementary Graph Theory</div>
                                                <div className="acsl_table_block">Graph Theory</div>
                                                <div className="acsl_table_block">Graph Theory</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">Digital Electronics</div>
                                                <div className="acsl_table_block">Digital Electronics</div>
                                            </div>
                                            <div className="acsl_table_row">
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block"></div>
                                                <div className="acsl_table_block">What does this program do? <br/>Strings</div>
                                                <div className="acsl_table_block">Assembly labguage</div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="acsl_bottom_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="acsl_bottom mobile_reverse">
                                    <div className="text">
                                        <h3 className="bungee">Contests</h3>
                                        <p>The competition consists of 4 contests. In each contest, students are given short theoretical and applied questions, and then a programming
                                            problem to solve within the following three days. 
                                            Code Wiz administers the contest and results are returned to ACSL for tabulation. At the end of the year, an Invitational Team All-Star Contest, based upon cumulative scores, is held at a common site.</p>
                                    </div>
                                    <div className="image">
                                        <img src={KidTypingImg} alt="childtyping"/>
                                    </div>
                                </div>
                                
                                
                                <div className="acsl_bottom">
                                
                                    <div claclassNamess="image">
                                        <img src={MathIllustrationImg} alt="mathillustration"/>
                                    </div>
                                    <div className="text">
                                        <h3 className="bungee">Who can join the team?</h3>
                                        <p>Children with strong math skills and an interest in programming! We will focus on using already existing math knowledge to solve programming questions</p>
                                    </div>
                                    
                                </div>
                                
                                <div className="acsl_cta">
                                    <Button color="orange" link="https://www.acsl.org/" size="medium">LEARN MORE</Button>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- lego end --> */}

                {floatingdata}
            </div>
        );
    }
}

export default Acsl
