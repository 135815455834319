import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">July 14, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>CODING SCHOOL FOR KIDS: SKILLS KIDS CAN LEARN</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Seodeveloper </a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Coding and robotics are wonderful skills that can open up lots of opportunities for learners. The field is vast in terms of progressive career options and lucrative job openings, which makes it an investment for a better future. In fact, the decision to explore a <a href="https://dev.thecodewiz.com/coding-school-for-kids/">coding school for kids</a> for your child is the smartest one you can you for them. Apart from the technical benefits that it can open up, there are several additional skills that your child would develop by studying these streams. Here are the soft skills that they would learn with coding.</p>
                                            <h3 className="bungee">5 Soft Skills Kids Can Learn Through Coding</h3>
                                            <p><b>Logic</b></p>
                                            <p>Logic is the core skill that your child would develop naturally by learning the nuances of coding. Writing code requires good interpretation of information, as students move logically from one step to the next. Obviously, they will adopt an analytical mindset that exercises logic every time they write a code. This makes the child good at using logic to solve problems, both within and outside their coding projects.</p>
                                            <p><b>Creativity</b></p>
                                            <p>Though you may deem coding as a STEM subject, it unlocks creativity in learners. Understanding coding concepts empowers them with a holistic toolbox which they can use for expressing their creative ideas through the programs and apps they write. And amazingly, they need not follow a beaten path; rather they can experiment with new ideas and possibilities to create applications that have never been made before.</p>
                                            <p><b>Attention detail</b></p>
                                            <p>Another benefit of sending your child to a coding school is that they will surely  pay attention to detail as they learn the ropes of programming. Writing code requires the understanding of the comprehensive product, while keeping in mind the smallest details that make seamless and positive user experiences. Students also become attentive enough to detect the smallest of errors and address them before moving to the next steps.</p>
                                            <p><b>Perseverance</b></p>
                                            <p>Needless to say, coding is a challenge and your child would probably face many frustrations through their projects. However, being consistent will teach them the lesson of perseverance and patience, which are great life skills as well. You can expect your child to become more committed and patient as they navigate the other challenges of profession and life as a whole.</p>
                                            <p><b>Empathy</b></p>
                                            <p>Surprisingly, the child is also likely to cultivate the trait of empathy at a coding center. This quality is all about understanding the feelings and emotions of others. As kids study together in groups and see their peers struggling and succeeding, they will become more receptive towards the needs, goals, expectations and intentions of family, friends and future colleagues.</p>
                                            <p>On the surface, the benefits of coding appear to be very black and white, like numbers and commands.  But if you dig deeper, you will surely realize that your child can gain a lot in terms of life skills and lessons that would stay with them forever.</p>
                                        

                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
