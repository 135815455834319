import React from 'react';
import Header from '../../../components/header/header.component';
import LegoLeague from '../../../components/lego-league/lego-league.component';
import MetaTags from 'react-meta-tags';
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const LegoLeaguePage = () => (
  <div>
    <PopupModal location="westborough" formType="coupon" owner="Code Wiz Westborough"/>
    <FacebookMessenger location="westborough" FBPageId="104621587747524" />
    <Header location="westborough" fulllocation="westborough-ma"/>
    <LegoLeague franchisee="westborough" formType="coupon" owner="Code Wiz Westborough"/>
    <MetaTags>
        <title>Code Wiz - Westborough MA | FLL</title>
    </MetaTags>
  </div>
);

export default LegoLeaguePage;