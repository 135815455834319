import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/shaping-childs-future-blog-cover.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BlogItemAICompProg from '../assets/images/AI-Blog-Frame.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">November 30th, 2023</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>How Coding Can Shape Your Child's Career</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Evan H.</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block"></div>
                                        <div className="blogs_details_block">
                                            <p></p>
                                            <p></p>
                                            <p></p>
                                            <p>Coding, also known as programming, is the process of designing, writing, testing, and maintaining computer programs. It involves using programming languages to create software, applications, and websites. In today's digital age, coding has become an essential skill that is in high demand across various industries. In this blog post, we will explore the benefits of coding and why you should learn to code.</p>
                                            
                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>1. High Demand for Coders</h4>
                                            <p>The demand for coders is increasing rapidly across various industries, including technology, healthcare, finance, and education. According to the Bureau of Labor Statistics, employment of computer and information technology occupations is projected to grow 11 percent from 2019 to 2029, much faster than the average for all occupations. This means that there will be plenty of job opportunities for coders in the future.</p>
                                           
                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>2. High Earning Potential</h4>
                                            <p>Coders are in high demand, and as a result, they can earn high salaries. According to Glassdoor, the average salary for a software engineer in the United States is $92,046 per year. However, this can vary depending on factors such as experience, location, and industry.</p>
                                            
                                        </div>
                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>3. Creativity and Problem-Solving Skills</h4>
                                            <p>Coding involves creativity and problem-solving skills. When you code, you are essentially creating something from scratch, whether it's a website, application, or software. You need to think creatively to come up with new ideas and solutions to problems. Additionally, coding requires attention to detail, critical thinking, and logical reasoning.</p>
                                           
                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>4. Career Flexibility</h4>
                                            <p>Coding skills are transferable across various industries, which means that you can work in different fields throughout your career. For example, if you start your career as a software engineer in the technology industry, you can later transition to other industries such as finance or healthcare.</p>
                                            
                                        </div>
                                        
                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>5. Improved Cognitive Function</h4>
                                            <p>Learning to code can improve your cognitive function. Coding requires you to use both sides of your brain, which can improve your memory, attention, and problem-solving skills. Additionally, coding can help you develop a growth mindset, which is the belief that you can improve your abilities through hard work and dedication.</p>
                                            
                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>6. Ability to Create Your Own Projects</h4>
                                            <p>One of the most rewarding aspects of coding is the ability to create your own projects. Whether it's a personal website, application, or software, you have the power to bring your ideas to life. This can be a great way to showcase your skills and build your portfolio.</p>                                            
                                        </div>

                                        <div className="blogs_details_block">
                                            <h4 className='bungee'>7. Community and Networking</h4>
                                            <p>Coding has a large and supportive community of developers who are willing to help each other out. Joining coding communities and attending coding events can help you network with other developers and learn from their experiences. Additionally, sharing your own experiences and projects can help you build your online presence and connect with like-minded individuals.</p>
                                        </div>

                                        <div className="blogs_details_block">
                                            <p>Coding is an essential skill that can open up many career opportunities and provide numerous benefits. Learning to code can improve your problem-solving skills, creativity, cognitive function, and career flexibility. Additionally, coding can be a rewarding and fulfilling hobby that allows you to create your own projects and connect with a supportive community of developers.</p>
                                        </div>

                                        <div className="blogs_details_block">
                                            <h3 className='dark-blue bungee'>Check out what Code Wiz has to offer, find a location near you <a href="https://thecodewiz.com/code-wiz-locations">Code Wiz Locations</a></h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
