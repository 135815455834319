import React from 'react';
import Acsl from '../../../components/acsl-competition/acsl-comp.component';
import Header from '../../../components/header/header.component';
import MetaTags from 'react-meta-tags';

const FranchiseAcslPage = () => (
    <div>
        <Header location="reading" fulllocation="reading-ma"/>
        <Acsl franchisee="reading"/>
        <MetaTags>
            <title>Code Wiz - Reading MA | ACSL</title>
        </MetaTags>
    </div>
);

export default FranchiseAcslPage;