import React from 'react';
import { withRouter } from 'react-router-dom';
import {Link} from 'react-router-dom';

import MenuItemDropdown from './menu-dropdown.component';
import './menu-item.styles.scss';

const MenuItem = ({title, history, linkUrl, match, dropdown, htmlId, fontAwesome}) => {
    if (dropdown.length === 0){
        return (
            <li className="nav-item" id={htmlId}><a className="nav-link" href={`${linkUrl}`}><i className={fontAwesome}></i> {title.toUpperCase()}</a></li>
        );
    }
    else {
        return (
            <li className="nav-item dropdown">
                {/* <a className="nav-link dropdown-toggle" id={htmlId} href={`${linkUrl}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{title.toUpperCase()}</a> */}
                <Link className="nav-link dropdown-toggle" to={`${linkUrl}`}>{title.toUpperCase()}</Link>
                <div className="dropdown-menu dropdown-primary" aria-labelledby={htmlId}>{
                    dropdown.map(({id, ...otherDropdownProps}) =>(
                        <MenuItemDropdown key={id} {...otherDropdownProps} />
                    ))
                }
                </div>
            </li>
        );
    }
};

export default withRouter(MenuItem);
