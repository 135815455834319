import React from 'react';
import FAQPage from '../../faqs/faqs';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFaqsPage = () => (
    <div>
        <FAQPage franchisee="houston" fulllocation="houston-tx"/>
        <FacebookMessenger location="houston" FBPageId="107739935196924" />
        <MetaTags>
            <title>Code Wiz - Houston TX | FAQs</title>
        </MetaTags>
        <Helmet>
            <script src="//cdn.rlets.com/capture_configs/4d0/e74/35e/5cf4d92858d6f655db683b8.js" type="text/javascript" />
        </Helmet>
    </div>
);

export default FranchiseFaqsPage;