import React from 'react';
import CompetitionsPage from '../../competitions/competitions.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseCompetitionsPage = () => (
    <div>
        <CompetitionsPage franchisee="westborough" fulllocation="westborough-ma"/>
        <FacebookMessenger location="westborough" FBPageId="104621587747524" />
        <MetaTags>
            <title>Code Wiz - Westborough MA | Competitions</title>
        </MetaTags>
    </div>
);

export default FranchiseCompetitionsPage;