import React from 'react';
import FindACoursePage from '../../find-a-course/find-a-course.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseFindACoursePage = () => (
    <div>
        <FindACoursePage franchisee="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <MetaTags>
            <title>Code Wiz - Medford MA | Find A Course</title>
        </MetaTags>
    </div>
);

export default FranchiseFindACoursePage;