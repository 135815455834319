import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <SummerCamps franchisee="medford" owner="Code Wiz Medford"/>
        <MetaTags>
            <title>February, April and Summer Vacation Programs in Medford MA - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Medford MA" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/medford-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Medford MA" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in medford, medford massachusetts, robotics"></meta>
        </MetaTags>
</div>
);

export default SummerPage;

