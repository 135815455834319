import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BenefitsOfMinecraft from '../assets/images/blog-pictures/benefits-of-minecraft.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 11, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> THE BENEFITS OF MINECRAFT FOR KIDS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>While some parents may be concerned that their kid(s) might spend too much time playing video games, not all games are created equal.</p>
                                            <p>Of course, there have been blockbuster games that have taken the world by storm before.</p>
                                            <p>People got fascinated with birds being angry (Angry Birds) and placed themselves in danger by wandering the streets seeking to capture Pokémons (Pokémon Go). But Minecraft is a different phenomenon.</p>
                                            <p>Since its release seven years ago, <a href="https://www.minecraft.net/en-us/">Minecraft</a> has become a global sensation, captivating a generation of children with over 180 million copies sold and over 100 million active users per month.</p>
                                            <p>But the single best-selling video game of all time commercial success is not necessarily what makes it stand out in comparison to others.</p>
                                            <p>Due to its educational value, Minecraft has been <a href="https://www.nytimes.com/2016/01/20/technology/microsoft-acquires-minecraftedu-tailored-for-schools.html">adopted as a teaching tool in schools across the globe</a>. In this blog post, we’ll look into why the video game has transcended from the sofa of homes to the classroom.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">THE BENEFITS OF MINECRAFT</h3>
                                            <p>The “sandbox” style game encourages kids to tinker, get under the hood, break things and fix them.</p>
                                            <p>Some in the tech world have even said that Minecraft’s potential parallel the heady early days of the digital age in the late ’70s and ’80s where the arrival of personal computers sparked the first generation of kids fluent in computation. </p>
                                            <p>At a time when leaders of our society are urging kids to learn to code, Minecraft has become a stealth gateway to learning the fundamentals of computer science.</p>
                                            <p>So, like <a href="https://dev.thecodewiz.com/how-roblox-coding-teaches-kids-skills-that-grooms-them-for-the-future/">Roblox</a>, used the correct way, the benefits of Minecraft could help give your child a solid background in core STEM (science, technology, engineering, and mathematics) subjects and develop critical 21st-century skills.</p>
                                           

                                        </div>
                                          <div className="blogs_details_block">
                                            <h3 className="bungee"><i>HERE ARE 10 REASONS WHY YOU DON’T NEED TO WORRY IF YOUR CHILD LOVES MINECRAFT.</i></h3>
                                            <ol>
                                            <li><h4 className="bungee">HELPS KIDS LEARN PROBLEM-SOLVING TECHNIQUES</h4></li>
                                            <p>A person’s ability to problem-solve will dictate how well they deal with problems and navigate obstacles in life.</p>
                                            <p>Playing Minecraft encourages critical thinking and teaches kids how to efficiently solve real-world problems. Problem-solving is one of the most vital skills in life at any level both hypothetical and reactively.</p>
                                            <li><h4 className="bungee">MINECRAFT CAN HELP A CHILD LEARN TO CODE</h4></li>
                                                            
                                            <p>With its huge degree of customization, Minecraft facilitates this key learning skill. Using the game’s command blocks kids can edit the original code as mods to alter the game.</p>
                                            <p>If your child wants to take it further, they can modify the program using Java code.</p>
                                            <p>The game also encourages kids to regard logic as well as a great deal of debugging.</p>
                                            <p>Mastering <a href="https://www.dummies.com/games/engineering-with-redstone-in-minecraft/">Redstone</a> requires rigorously logical thinking. It teaches them some of what computer programmers know and wrestle with daily, figuring out what you did wrong and coming up with a fix.</p>
                                            <p>This level of computational thinking is one of Minecraft’s subtle but powerful effects.</p>
                                            <h4 className="bungee">PROGRAMMING WITH JAVA</h4>
                                            <p>By Modding, or altering the original programming code of the game, kids can create their own blocks, ores, and other items, and share them with their friends.</p>
                                            <img src={BenefitsOfMinecraft} alt="benefits of minecraft" />
                                            <p>Kids can create their own tools and objects while coding and learning the fundamentals of Java, one of the most widely-used programming languages out there.</p>
                                            <li><h4 className="bungee">CAN IMPROVE A CHILD’S CREATIVITY AND IMAGINATION</h4></li>
                                            <p>There’s no limit to Minecraft. The gaming platform’s 3D environment provides kids an opportunity to build anything they want as long as it’s with small building blocks.</p>
                                            <p>Minecraft rewards creation so your child will be inspired to think creatively.</p>
                                            <li><h4 className="bungee">MATH AND GEOMETRY</h4></li>
                                            <p>Minecraft has natural connections with mathematics, and it has been used by many teachers to improve engagement and boost results in the subject.</p>
                                            <p>Teachers are allowing kids to solve geometric problems by manipulating blocks and constructing more complex shapes in Minecraft.</p>
                                            <p>One teacher in Los Angeles saw his “Mathcraft program drastically improve the academic culture of his class. The math performance percentage in his class increased astronomically from 18 percent correct at the beginning of the year to 83 percent correct during the end-of-year retesting.</p>
                                            <li><h4 className="bungee">MINECRAFT CAN IMPROVE HAND-EYE COORDINATION</h4></li>
                                            <p>Separate studies on the relationship between surgical skill, aviation, and video gaming concluded that it correlates with laparoscopic surgical skills and may engender motor control and coordination skills improving general piloting skills.</p>
                                            <p>While in survival mode, a good Minecraft player’s goal is to escape obstacles to stay in the game. This improves their hand-eye coordination because it requires precise reflexes and quick thinking, which is what hand-eye coordination is all about.</p>
                                            <li><h4 className="bungee">MINECRAFT OFFERS VALUABLE FUTURE WORK SKILLS</h4></li>
                                            <p>All of the educational aspects we’ve mentioned will position your child for future employment success.</p>
                                            <p>Along with the obvious technical skills, the elements involved with Minecraft can also help to teach kids social and business skills such as relationship-building and trading.</p>
                                            <p>These skills can help your child to become more proficient at negotiation, project planning, and teamwork, all skills which employers today, and in the future, will always be looking for.</p>
                                            <li><h4 className="bungee">ENGINEERING</h4></li>
                                            <p>Redstone, Minecraft’s equivalent of electricity, can teach kids the basics of electrical engineering.</p>
                                            <p>With Redstone, which allows kids to build their own circuits, kids can learn the basic principles of electrical engineering.</p>
                                            <li><h4 className="bungee">IT INCREASES PERCEPTION</h4></li>
                                            <p>When playing Minecraft players are highly involved in interacting with the world around them. So like most video games, it promotes focus and can increase environmental awareness in the real world.</p>
                                            <p>A handful of studies show that video gaming improves a person’s awareness of surroundings or visuospatial attention.</p>
                                            <p>In one of these studies, published in the <a href="http://psycnet.apa.org/journals/xhp/32/6/1465/">Journal of American Psychology,</a> researchers found that, when compared to nongamers, gamers exhibited an enhancement in attentional resources not only in the periphery but also in central vision.</p>
                                            <li><h4 className="bungee">MINECRAFT TEACHES KIDS THE BENEFITS OF TEAMWORK</h4></li>
                                            <p>Collaboration between users is one of the main aptitudes that is known to be developed through gameplay. Actually, sometimes collaborating with other children and teammates is the only way a player can achieve certain goals.</p>
                                            <p>Minecraft requires players to work together positively to achieve their common goal. By planning together and sharing resources whilst playing, children will learn to rely on each other and learn the art of teamwork.</p>
                                            <li><h4 className="bungee">ENHANCES LEARNING</h4></li>
                                            <p>When players are deeply engrossed in a gaming task, they’re in the “zone” or the <a href="https://philpapers.org/rec/CSIFTP">“flow state,”</a> which is highly conducive to learning.</p>
                                            <p>While largely associated with addiction the state can be harnessed to support or advance learning goals.</p>

                                        </ol>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">CONCLUSION</h3>
                                            <p>With the many benefits associated with the game, like those kids of the ’70s and ’80s that became the architects of our modern digital world, Minecraft can help groom this generation of gamers to position themselves to better excel in a future world requiring more and more digital literacy and competence.</p>
                                            <p>From developing academic to social skills, the benefits of Minecraft can help children reach their full potential just by playing the game.</p>
                                           <p><i>Now you can help your child to do so by enrolling them in one of our <a href="https://dev.thecodewiz.com/westford-ma/sessions/">Minecraft summer camps</a>.</i></p>
                                           <p><a href="https://dev.thecodewiz.com/contact-us/">Contact us</a> to get started.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
