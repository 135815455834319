import React from 'react';
import Header from '../../../components/header/header.component';
import VirtualRobotics from '../../../components/virtual-robotics/virtual-robotics.component';
import MetaTags from 'react-meta-tags';

const VirtualRoboticsPage = () => (
  <div>
    <Header location="arlington" fulllocation="arlington-ma"/>
    <VirtualRobotics franchisee="arlington"/>
    <MetaTags>
        <title>Code Wiz - Arlington MA | Virtual Robotics</title>
    </MetaTags>
  </div>
);

export default VirtualRoboticsPage;