import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 13, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>COVID-19 UPDATE – ONLINE CODING CLASSES NOW AVAILABLE  </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Claudia</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p><b>Hello Parents!</b></p>
                                            <p>We’ve been monitoring information from the WHO and CDC and have decided to transition our on-site classes to <b>virtual evening classes </b> that your kids can enjoy from home! We are doing our part to keep your families and our families safe.</p>
                                            <p><b>How do you plan to transition to online classes?</b></p>
                                            <p>Our director and coaches will assist you with any software setup necessary to join our online classes. We will follow up with a <b>PARENT GUIDE</b> created specifically for you, but here’s some of the basic information:</p>
                                            <ul>
                                                <li>Kids will need a computer/chromebook – if you do not have one, we have a limited number of computers we can rent out to families.</li>
                                                <li>Coaches will host classes using Zoom rooms to connect with students.</li>
                                                <li>Students and coaches will screen-share to keep on task and receive support.</li>
                                                <li>Class times will be extended to 90 minutes to account for any potential technical obstacles – <b>3.30PM – 5.00PM and 5.30PM – 7.00PM.</b></li>
                                                <li>All online classes will be closely monitored by management.</li>
                                                <li>Minecraft/Roblox/Unity kids will need a laptop with Minecraft/Roblox/Unity installed. If you bring in a laptop on Friday, March 13th, we will help you set it up!</li>
                                            </ul>
                                            <p><b>Online Robotics??</b></p>
                                            <p>Yes, we understand this is less than ideal for our robotics teams. We plan to offer coding classes during Robotics slots. <b>FLL</b> students will get to work on Python coding and <b>FLL Jr</b> kids who are able to read independently will code using our drag and drop platform. Make up tokens will be added to kids who opt to wait to resume their robotics classes once we re-open.</p>
                                            <p><b>Great, schools are canceled and my kids are home all day – Can you save my sanity?</b></p>
                                            <p>We probably can’t save your sanity, but we can help keep the kids from getting too restless during this unexpected break! We will provide:</p>
                                            <ul>
                                                <li>2 hours blocks of play time on our secure private Minecraft and Roblox servers. We will always have a Code Wiz staff as moderator on servers. <a href="https://app.iclasspro.com/parentportal/codewiz/camps?camptype=16">See details here.</a></li>
                                                <li>2 hour “camps” blocks where kids can attend mini coding camps or work with the coach on their regular coding classes and projects. <a href="https://app.iclasspro.com/parentportal/codewiz/camps?camptype=16">See details here.</a></li>
                                            </ul>
                                            <p><b>Can they work on their Codeathon projects?</b></p>
                                            <p>Yes and we encourage that! It’s a great way to keep busy and stay focused on a goal. They can work on it during their class time or during the 2 hour camp slot if you choose to purchase that or on their own freetime!</p>
                                            <p><b>This is going to be a smooth transition right?</b></p>
                                            <p>We are currently working round the clock to make this transition as smooth as possible. We do ask for patience especially in the first week as work out the kinks in the system. Our goal is to make it as easy for you and the kids as possible!</p>
                                            <p><b>How long will this last?</b></p>
                                            <p>We’re not 100% sure. We are following the school’s lead. When schools reopen, we will re-open the center.</p>
                                            <p><b>Questions?</b> Call or Text <b>978-496-1053</b> or email <a href="mailto:westford@thecodewiz.com"> westford@thecodewiz.com</a>. We imagine we would get a lot of questions, so please be patient as we work through this!</p>
                                            <p>Learn about <a href="https://www.thecodewiz.com/westford-ma/sessions/">Code Wiz Summer Camps.</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
