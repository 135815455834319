import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/free-guy-blog.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';
import GameDevMiddleImg from '../assets/images/game-dev-pic.jpg';

import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">April 25, 2022</span>
                                    <h2 className="header-title cw-dark-blue bungee">Sparking an Interest in Game Development: "Free Guy"</h2> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Nick Martellaro</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details incredible-women-2022">
                                        <div className="blogs_details_block ">
                                            <p className='cw-dark-blue'>Video games are a popular pastime for students of all ages, but they can also be a great way to encourage an interest in coding. Game development is a rapidly growing field, and programmers are constantly in high demand. “Free Guy” (2021) is an awesome action/adventure/comedy film we definitely recommend checking out! Here is our Code Wiz review of how movies like “Free Guy” can spark an interest in game development.</p>
                                            <p className='cw-dark-blue'>“Free Guy” relies on a lot of terminology that many gamers are likely to already be familiar with. A large majority of video games feature side characters known as NPCs, and this idea forms the basis of the film’s plot. Now, for you non-gamers out there, the term “NPC” is an acronym which means non-player character – essentially, an NPC is any character in a role-playing game that is not controlled by a player in real life. In many cases, NPCs are simply used as scenery in the background – people walking down the sidewalk, for example. In “Free Guy”, the main character, Guy (played by Ryan Reynolds) is exactly that – an NPC in a fictional video game known as Free City.</p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <br/>
                                            <br/>
                                            <p>NPCs follow a protocol, or a set of rules, that all depend on the coding the game developers implemented. They are, in a sense, “stuck in a loop” – robotic, and serving a set purpose for the gameplay. But what if it was possible for an NPC to come “alive,” complete with its own backstory, memories, and emotions? Is such artificial intelligence even possible? “Free Guy” explores these complex questions while still being fun and relatable to younger audiences.</p>
                                            <div className='center'><img src={GameDevMiddleImg} class="center "/></div>
                                            <br/>
                                            <br/>
                                            <p>We love how “Free Guy” has real-life references to game design, and the movie is guaranteed to peak the interest of young gamers who want to take their hobby to the next level - literally! 😉  NPCs in games are meticulously programmed with a set of rules, allowing developers to customize everything about a character from their personality traits to how they look. The amount of programming a student would need to create their own NPC depends on the digital character’s complexity - are they just someone standing in the background of a video game, or are they a main character that gives the player quests and objectives to complete? In many ways, NPCs are like empty canvases that can promote creativity. We encourage students learning about game development to use their imagination - in the digital world, anything is possible!</p>
                                            <br/>
                                            <p>“Free Guy” also makes some awesome references to video game culture, including the use of “skins” to customize characters. When creating an NPC, a developer will have to program their physical appearance as well - graphic samples of clothing, objects, and skin (known as “textures” in the industry) are applied to a 3D character model. A lot of work goes into creating an NPC, and “Free Guy” shows how a team of talented developers are able to create a programmable character that can develop real feelings and emotions.</p>
                                        
                                            <br/>
                                            <p>“Free Guy” does an excellent job of showing how coders and game developers can be superheroes not only in their video games, but also in real life. The movie constantly gives examples of how intelligent programmers truly have an all-access pass to the worlds they create. With access to the code itself, developers have a level of control beyond any player – in many ways, they have the ultimate freedom to create and experiment. We love the motivational message this conveys to kids who are thinking about getting into the world of game development! With an active emphasis on coding skills and accurate depictions of the game development industry, “Free Guy” is a wonderful movie that is certain to spark an interest in the field.</p>
                                            <p>As an added bonus, programming skills are in high demand. It’s no secret that technology is a rapidly growing field - and year after year, the need for talented developers increases. Parents of students with ASD may wish to encourage their child to pursue interests in technology that might be valuable in the future.</p>
                                        </div>
                                        <h3 className='cw-dark-blue center'>Want to get started? Check out some of the awesome video game development programs offered by Code Wiz! <a href="https://thecodewiz.com/findmycourse">Click here</a> to use our find my course tool to see all our game development courses!</h3> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
