import React from 'react';
import firebase from '../../components/Firebase/firebase.js';
import MetaTags from 'react-meta-tags';

import Header from '../../components/header/header.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
import BlogHeaderImg from '../../assets/images/blogs_hero.png';
import CommentBubbleImg from '../../assets/images/comment_bubble.png';

import BlogItem1Img from '../../assets/images/blog_item.jpg';
import BlogItem2Img from '../../assets/images/blog_item2.jpg';
import BlogItem3Img from '../../assets/images/blog_item3.jpg';
import BlogItem4Img from '../../assets/images/blog_item4.jpg';
import BlogItem5Img from '../../assets/images/blog_item5.jpg';
import BlogItem6Img from '../../assets/images/blog_item6.jpg';
import BlogItem7Img from '../../assets/images/blog_item7.jpg';
import BlogItem8Img from '../../assets/images/blog_item8.jpg';
import BlogItem9Img from '../../assets/images/blog_item9.jpg';
import BlogItem10Img from '../../assets/images/blog_item10.png';
import BlogItem11Img from '../../assets/images/blog_item11.jpg';
import DataPrivacyImg from '../../assets/images/data_security.jpg';
import BlogItemIncredibleWomenImg from '../../assets/images/incrediblewomenblogpic.png';
import BlogItemAutismImg from '../../assets/images/autismawareness.jpg';
import BlogItemFreeGuyImg from '../../assets/images/game-development.png';
import BlogItemTopProgrammingLang from '../../assets/images/top-coding-languages-for-kids.png';
import BlogItemInDemandCareer from '../../assets/images/in-demand-career.jpg';
import BlogItemCodingFromAYoungAge from '../../assets/images/blog-coding-from-a-young-age.jpg';
import BlogItemAICompProg from '../../assets/images/AI-Blog-Frame.png';
import BlogItemMakeARobot from '../../assets/images/make-robot-cover.png';
import BlogItemShapingChildsFuture from '../../assets/images/shaping-childs-future-blog-cover-square.png';
// import BlogItem13Img from '../../assets/images/blog_item13.png';
// import BlogItem14Img from '../../assets/images/blog_item14.png';
// import BlogItem15Img from '../../assets/images/blog_item15.png';
// import BlogItem16Img from '../../assets/images/blog_item16.png';
// import BlogItem17Img from '../../assets/images/blog_item17.png';
// import BlogItem18Img from '../../assets/images/blog_item18.png';
// import BlogItem19Img from '../../assets/images/blog_item19.png';
// import BlogItem20Img from '../../assets/images/blog_item20.png';

import './blog.styles.scss';


class BlogPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            fulllocation: props.fulllocation
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        if(this.props.franchisee){
            this.state.isfranchisee = true;
        } 
        if(this.state.isfranchisee){
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        }
        return (
            <div>
                <MetaTags>
                    <meta property="og:image" content={BlogHeaderImg} />
                    <title>Code Wiz | Blogs</title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <h2 className="header-title cw-dark-blue bungee">CHILDREN CODING AND TECHNOLOGY BLOG</h2> 
                                    <h4 className="header-text cw-dark-blue">&nbsp;</h4>    
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogHeaderImg} alt="online fall coding"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_listing_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_listing">
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/data-privacy-tips-for-students"><img src={DataPrivacyImg} alt="Data Privacy"/></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">January 26, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/data-privacy-tips-for-students">Data Privacy Tips for Students </a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Martellaro Music</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/covid-19-online-coding-classes"><img src={BlogItem1Img} alt="blog item 1"/></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">March 13, 2020</span>
                                                <h2 className="blog_title bungee"><a href="/covid-19-online-coding-classes">COVID-19 Update – Online Coding Classes Now Available</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Claudia</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/5-reasons-to-send-your-kids-to-a-robotics-school/"><img src={BlogItem2Img} alt="blog item 2" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">April 26, 2020</span>
                                                <h2 className="blog_title bungee"><a href="/5-reasons-to-send-your-kids-to-a-robotics-school/">5 Reasons To Send Your Children To A Robotics School</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Seodeveloper</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/kids-coding-languages/"><img src={BlogItem3Img} alt="blog item 3" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">November 3, 2018</span>
                                                <h2 className="blog_title bungee"><a href="/kids-coding-languages/">Best Children Coding Languages to Learn</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Ruth Agbaji</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" />Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/boston-with-kids/"><img src={BlogItem4Img} alt="blog item 4" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">November 3, 2018</span>
                                                <h2 className="blog_title bungee"><a href="/boston-with-kids/">Boston with children</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Ruth Agbaji </a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/coding-with-kids/"><img src={BlogItem5Img} alt="blog item 5" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">November 3, 2018</span>
                                                <h2 className="blog_title bungee"><a href="/coding-with-kids/">Coding with children: what is coding for children and where to start</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Ruth Agbaji</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/kid-birthday-party-places-ideas/"><img src={BlogItem6Img} alt="blog item 6" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">November 3, 2018</span>
                                                <h2 className="blog_title bungee"><a href="/kid-birthday-party-places-ideas/">Kid birthday party places and ideas </a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Ruth Agbaji</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" />Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/scratch-programming-for-kids/"><img src={BlogItem7Img} alt="blog item 7" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">March 13, 2020</span>
                                                <h2 className="blog_title bungee"><a href="/scratch-programming-for-kids/">Scratch programming for kids</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Claudia</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/women-in-stem-quotes/"><img src={BlogItem8Img} alt="blog item 8" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">April 26, 2020</span>
                                                <h2 className="blog_title bungee"><a href="/women-in-stem-quotes/">What it’s Like to be a Girl Who Codes</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Seodeveloper</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/women-in-stem-quotes/"><img src={BlogItem11Img} alt="blog item 8" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">September 11, 2020</span>
                                                <h2 className="blog_title bungee"><a href="/women-in-stem-quotes/">How Interactive Classes Puts The Social Into Homeschooling</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Wrightjermainejmw</a></span>
                                                    <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /></a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/code-a-thon-codeathon/"><img src={BlogItem9Img} alt="blog item 9" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">November 3, 2018</span>
                                                <h2 className="blog_title bungee"><a href="/code-a-thon-codeathon/">Code-a-thon</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Ruth Agbaji</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/teaching-kids-coding-the-code-advantage/"><img src={BlogItem10Img} alt="blog item 10" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">October 29, 2017</span>
                                                <h2 className="blog_title bungee"><a href="/teaching-kids-coding-the-code-advantage/">The Code Advantage: Problem-Solving Skills in the Real World</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Ruth Agbaji</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/data-privacy-tips-for-students/"><img src={DataPrivacyImg} alt="Data Privacy Tips for Students" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">January 26, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/data-privacy-tips-for-students/">Data Privacy Tips for Students</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Martellaro Music </a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/incredible-women-in-tech/"><img src={BlogItemIncredibleWomenImg} alt="Incredible Women in Technology" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">March 22, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/incredible-women-in-tech/">Women's History Month: Incredible Women in Technology</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Martellaro Music</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/reasons-why-children-with-autism-can-excel-at-coding/"><img src={BlogItemAutismImg} alt="Reasons why children with autism can excel at coding" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">April 2, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/reasons-why-children-with-autism-can-excel-at-coding/">Reasons Why Children With Autism Can Excel at Coding</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Martellaro Music</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/sparking-an-interest-in-game-development"><img src={BlogItemFreeGuyImg} alt="Sparking an Interest in Game Development: “Free Guy”" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">April 25, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/sparking-an-interest-in-game-development">Sparking an Interest in Game Development: “Free Guy”</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Martellaro Music</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/top-coding-languages-and-platforms"><img src={BlogItemTopProgrammingLang} alt="Sparking an Interest in Game Development: “Free Guy”" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">June 13, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/top-coding-languages-and-platforms">Top Coding Languages and Platforms for kids in 2022</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Martellaro Music</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/in-demand-programming-careers-for-students-to-explore"><img src={BlogItemInDemandCareer} alt="In demand careers for students to explore" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">July 8, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/in-demand-programming-careers-for-students-to-explore">In-Demand Programming Careers for Students to Explore</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Theresa P.</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/in-demand-programming-careers-for-students-to-explore"><img src={BlogItemCodingFromAYoungAge} alt="Coding from a young age" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">August 19, 2022</span>
                                                <h2 className="blog_title bungee"><a href="/in-demand-programming-careers-for-students-to-explore">Coding from a young age</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Theresa P.</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/the-future-of-AI"><img src={BlogItemAICompProg} alt="Artificial Intelligence and Computer Programming: How tools such as Chat GPT will Shape the Future of Stem" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">October 3rd, 2023</span>
                                                <h2 className="blog_title bungee"><a href="/the-future-of-AI">Artificial Intelligence and Computer Programming: How tools such as Chat GPT will Shape the Future of Stem</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Code Wiz</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/teaching-robotics-to-kids-and-First-Lego-League"><img src={BlogItemMakeARobot} alt="How to Make a Robot: A Guide to FIRST® LEGO® League" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">October 30th, 2023</span>
                                                <h2 className="blog_title bungee"><a href="/teaching-robotics-to-kids-and-First-Lego-League">How to Make a Robot: A Guide to FIRST® LEGO® League</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Code Wiz</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="blogs_listing_block">
                                            <div className="blogs_listing_block_image">
                                                <a href="/shaping-your-childs-future"><img src={BlogItemShapingChildsFuture} alt="How Coding Can Shape Your Child's Career" /></a>
                                            </div>
                                            <div className="blogs_listing_block_text">
                                                <span className="posted_date">November 30th, 2023</span>
                                                <h2 className="blog_title bungee"><a href="/shaping-your-childs-future">How Coding Can Shape Your Child's Career</a></h2>
                                                <div className="blogs_listing_block_text_footer">
                                                    <span className="post_admin"><a href="#">Code Wiz</a></span>
                                                    <span className="post_comments disabled"><a href="#"><img src={CommentBubbleImg} alt="comment bubble" /> Comments are off</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="blog_pagination">
                        <ul className="pagination">
                            {/* <li><a href=""><i className="fa fa-chevron-left" aria-hidden="true"></i></a></li>
                            <li><a href="">1</a></li>
                            <li><a href="">2</a></li>
                            <li><a href=""><i class="fa fa-ellipsis-h" aria-hidden="true"></i></a></li>
                            <li><a href=""><i class="fa fa-chevron-right" aria-hidden="true"></i></a></li> */}
                        </ul>
                    </div>

                    {floatingdata}
                </div>
            </div>
        );
    }
}

export default BlogPage
