import React from 'react';
import Acsl from '../../../components/acsl-competition/acsl-comp.component';
import Header from '../../../components/header/header.component';
import MetaTags from 'react-meta-tags';

const FranchiseAcslPage = () => (
    <div>
        <Header location="arlington" fulllocation="arlington-ma"/>
        <Acsl franchisee="arlington"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | ACSL</title>
        </MetaTags>
    </div>
);

export default FranchiseAcslPage;