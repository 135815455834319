import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">March 13, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>ROBLOX EDUCATION: HOW THE GAMING PLATFORM IS INSPIRING THE NEXT GENERATION OF INNOVATORS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Roblox, the online gaming platform dubbed the ‘gaming version of Youtube,’ is wildly popular among kids. With over 90 million monthly active users, it’s ranked number one in online entertainment for kids under 13, and second for teens ages 13 – 17.</p>
                                            <p>The UGC (User-generated Content) multiplayer online game creation platform fuels the imaginations of the more than two million independent developers and creators to build the over 56 million interactive 3D experiences.</p>
                                            <p>But there’s another side to Roblox – <i>one that is inspiring the next generation of innovators.</i></p>
                                        </div>
                                        <div className="blogs_details_block">
                                            <h3 className="bungee">THE OTHER SIDE TO ROBLOX</h3>
                                            <p>Roblox has been building an education division that could rival Microsoft’s Minecraft.edu in the future.</p>
                                            <p>Roblox Education is an initiative to teach kids of all ages real coding, game design, digital citizenship, and entrepreneurial skills that will propel them to become the innovators and leaders of the 21st century.</p>
                                            <p>Roblox Studio, the immersive creation engine used to create and build virtual worlds, will be used in more than 500 coding camps and online programs in eight different countries to teach creators coding fundamentals, game design, and entrepreneurial skills.</p>
                                            <p>In 2018, the gaming company reached over 50,000 children in Europe alone after running international summer camps in more than 20 countries, teaching coding, game development and entrepreneurship.</p>
                                            <p>It also offers a free online coding program in partnership with Universal Pictures to foster learning among its kid programmers.</p>
                                            <p>Additionally, Roblox Education is developing several free resources such as tutorials, handouts, lesson plans, etc. for educators and camp directors to incorporate into school and after school programs.</p>
                                            <p>With an established curriculum, the online gaming platform allows educators to tap into a student’s desire to learn by creating their own products and connecting to a larger community of their peers.</p>
                                            <p>Roblox simply wants to make it easy for kids to learn how to code.</p>
                                            <p>And by providing a free platform, free tools, and free curriculum, Roblox is hoping kids will learn highly marketable skills that will facilitate a better future.</p>
                                            <p>Roblox’s educational initiative can also inspire the underserved.</p>
                                            <p>Many kids from disadvantaged communities believe that STEM (Science, Technology, Engineering, and Math) careers are not for them. Roblox investing big into <a href="https://en.wikipedia.org/wiki/STEAM_fields">STEAM</a> (Science, Technology, Engineering, Arts and Math) Education could help provide the inspiration that attracts the underserved.</p>

                                        </div>
                                          <div className="blogs_details_block">
                                            <h3 className="bungee">THE STEAM IN ROBLOX EDUCATION</h3>
                                            <p>Much has been made about the need for more STEM programs in schools. The logic is that future economic prosperity lies in a workforce that is well-versed in STEM.</p>
                                            <p>But that logic mostly represents the old way of educating, which is built on the philosophy of teaching to ensure students get a “good job.”</p>
                                            <p>While STEM provides students experiential and problem-solving learning opportunities, the critical process of creativity and innovation is missing.</p>
                                            <p>The current economy and rapidly changing job market and society require much more than an understanding of science, technology, engineering, and math.</p>
                                            <p><a href="http://www.nea.org/tools/52217.htm">The 4Cs of 21st-century skills</a> – Collaboration, Creativity, Critical Thinking and Communication are easily woven into the concept of STEAM which works through the creative process and embraces collaboration. </p>
                                            <p>STEAM takes the benefits of STEM such as experiential learning, problem-solving, and integrates it with the creative process to foster creativity and ingenuity.</p>
                                            <p>This is one of the key <a href="https://www.thecodewiz.com/how-roblox-coding-teaches-kids-skills-that-grooms-them-for-the-future/">benefits of Roblox</a>.</p>
                                            <p>While combining education and gaming isn’t novel, Roblox is a great example of how an online gaming platform can capitalize on the advances in technology to allow today’s youth to learn to code by playing games.</p>
                                            <p>By making coding a large component of its platform to add to the facilitation of a collaborative, immersive space for shared experiences, makes Roblox unique.</p>
                                            <p>The education aspect of Roblox will push its young users to channel their imagination and tap into their creativity even more, potentially further inspiring the innovators, educators, and leaders of the 21st century.</p>
                                            <p>You can give your child an opportunity to start their journey with one of our <a href="https://www.thecodewiz.com/westford-ma/sessions/">Roblox summer coding camps for kids</a> where they’ll learn how to make their own custom Obby course and move on to more complex games. It doesn’t matter if they’re new to coding or have never designed their own virtual world. </p>
                                            <p><i>Codewiz is a Roblox Education Partner.</i></p>
                                            <p>You can <a href="https://www.thecodewiz.com/westford-ma/contact-us/">contact us</a> to learn more.</p>
                                        </div>
                                         
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
