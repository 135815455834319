import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import {Helmet} from "react-helmet";
import PopupModal from '../../../components/popup-modal/popup-modal.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseInPersonClassesPage = () => (
    <div>
        <PopupModal location="reading" formType="coupon" owner="Code Wiz Reading"/>
        <FacebookMessenger location="reading" FBPageId="2544691755571962" />
        <FallClassesPage franchisee="reading" fulllocation="reading-ma" formType="coupon" owner="Code Wiz Reading"/>
        <MetaTags>
            <title>Code Wiz - Reading MA | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;