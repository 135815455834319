import React from 'react';
import Header from '../../../components/header/header.component';

import SummerCamps from '../../../components/summer-camp/sessions.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const SummerPage = () => (
    <div>
        <Header location="westborough" fulllocation="westborough-ma"/>
        <FacebookMessenger location="westborough" FBPageId="104621587747524" />
        <SummerCamps franchisee="westborough" owner="Code Wiz Westborough"/>
        <MetaTags>
            <title>February, April and Summer Vacation Programs in Westborough MA - Code Wiz</title>
            <meta name="description" content="February, April and Summer Vacation Programs in Westborough MA" />
            <meta property="og:type" content="website" /> 
            <meta property="og:url" content="www.thecodewiz.com/westborough-ma/camps" />
            <meta property="og:title" content="February, April and Summer Vacation Programs in Westborough MA" />
            <meta name="keywords" content="coding, camps, vacation camps, coding in westborough, westborough massachusetts, robotics"></meta>
        </MetaTags>
    </div>
);

export default SummerPage;

