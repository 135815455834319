import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import MassachusettsWoman from '../assets/images/blog-pictures/massachusetts-woman.jpg';

import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">July 14, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>MASSACHUSETTS WOMAN OWNED BUSINESS OF 2020!</span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>Ruth Agbaji, founder of Code Wiz has been honored as the <b>Massachusetts Woman-Owned Business of 2020</b> by U.S. Small Business Administration!</p>
                                            <p>“Every year since 1963, the President of the United States has issued a proclamation announcing National Small Business Week, which recognizes the critical contributions of America’s entrepreneurs and small business owners. U.S. Small Business Administration takes the opportunity to highlight the impact of outstanding entrepreneurs, small business owners, and others from all 50 states and U.S. territories.” <a href="https://www.sba.gov/about-sba/sba-newsroom/press-releases-media-advisories/brian-geisel-top-sbas-class-2020-small-business-week-awardees-massachusetts">U.S. Small Business Administration!</a></p>
                                            <p><b>About Ruth Agbaji:</b></p>
                                            <p>Ruth Agbaji is the CEO and Nerd-In-Chief of Code Wiz and has a passion for helping kids connect with their inner genius. She hacked her way into the world of computer science by teaching herself to code. The promise of creating something from nothing – with just a few keywords and a laptop – ignited her imagination. Agbaji knew she had found her calling. Agbaji received her Masters in Computer Science from Tufts University and worked as a software engineer for tech giants like Microsoft and Kronos. Her entrepreneurial spirit and desire to impact the kids’ lives by sparking their interest in STEM led Agbaji to quit her day job and launch Code Wiz.</p>
                                            <p>Agbaji’s initial vision was to have a center where an interactive and project-driven approach to technology would inspire kids of all abilities to unleash their imagination and their potential. Her systems, processes, and curriculum make the Code Wiz model the premier learn-to-code child education franchise.</p>
                                            <p><b>Code Wiz is more than a child education franchise. It’s an outgrowth of our commitment to transforming the way that kids engage with technology.</b></p>

                                            <div className="blog-images"><img className="small-img" src={MassachusettsWoman} alt="Massachusetts Woman" /></div>

                                            <p className="center"><b>Woman Owned Business of the Year</b></p>
                                            <p className="center">Ruth Agbaji, CEO</p>
                                            <p className="center"><a href="https://www.dev.thecodewiz.com/">Code Wiz, Westford, MA</a></p>

                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
