import React from 'react';
import Header from '../../../components/header/header.component';
import WelcomeMessage from '../../../components/welcome-message/welcome-message.component';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const WelcomeMessagePage = () => (
  <div>
    <Header location="westborough" fulllocation="westborough-ma"/>
    <FacebookMessenger location="westborough" FBPageId="104621587747524" />
    <WelcomeMessage franchisee="westborough"/>

  </div>
);

export default WelcomeMessagePage;