import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';


import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">June 8, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span>TIPS TO CHOOSE THE BEST AFTER SCHOOL CODING CLASSES FOR KIDS </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <p>When it comes to finding ways to keep children busy after school, there is always a dearth of good ideas. You would want them to do something productive, while ensuring that it matches their interest and aptitude. It’s great if your kid is interested in coding and robotics because this field of study opens huge opportunities for them. Now everything boils down to finding the best after school coding classes for them. While there may be many options around, you would want only the best for your child. Here are some tips that you can follow for choosing the best coding school for kids.</p>
                                            <p><b>Find one with a good reputation</b></p>
                                            <p>When it comes to your child’s education, it always makes sense to choose a reputed school, even for after school classes. Look for one that has been around for some years and has a good reputation as well. Ask around for recommendations and go through online reviews to find one you can trust to cultivate the right skills in your child. Verify factors such as the qualifications of the coaches and student coach ratio as well. You will probably find a reputed place to send your child for after school classes.</p>
                                            <p><b>Prioritize tailored curriculum</b></p>
                                            <p>The problem with many schools is that they end up following a cookie-cutter approach with their curriculum. But that’s the last thing you would want for your budding genius. Go the extra mile to find a school that offers a tailored curriculum to match the interest and skills levels of the students. Obviously, you cannot expect a beginner to grasp the same level as an advanced student. Conversely, a curriculum that covers the basics wouldn’t be apt for someone with higher skill levels.</p>
                                            <p><b>Opt for a convenient location</b></p>
                                            <p>Another fact that you should bear in mind while choosing a kids coding school is the feasibility of its location. One that is nearby and easy to reach always makes a great pick if you need to pick and drop or even if the child needs to commute on their own. Check out the hours of classes as well because they should easily fit into the child’s schedule, without creating any unnecessary pressure.</p>
                                            <p><b>Pricing matters as well</b></p>
                                            <p>While finalizing the <a href="https://www.thecodewiz.com/">school for training your child in coding and robotics</a>, you would want to know about their pricing as well. It is always a smart approach to look for a center that works on monthly membership models rather than long-term contracts because the child may need to take a break during school exams and rejoin later. Also, some schools offer free trial classes for first timers, which is a good idea if the child has some doubts.</p>
                                            <p>Apart from these factors, make sure that the coding school for kids has a good environment that blends theoretical learning with practical training. Most importantly, the place should be safe and student-friendly as well.</p>


                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
