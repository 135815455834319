import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">July 15, 2020</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> HOW TO KEEP KIDS ENGAGED AND PRODUCTIVE OVER SUMMER </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Ruth</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={BlogDetailHeaderImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                            
                                            <p>By Jennifer Parker</p>
                                            <p>Summer is a free spirited and fun time to explore new interests and develop old ones. Parents may find it tricky to strike the balance between giving their children freedom to play and explore but not so much time they get bored and into trouble. Below are some suggestions for keeping kids engaged and productive this summer.</p>

                                            <ol>
                                                <li><b>Try to keep a routine:</b> Making sure children get enough sleep and eat regularly can impact their mood tremendously. Maintaining a routine also helps children transition in and out of the school year more easily. While it might be tempting to let children stay up late or sleep in as they wish to avoid bed time fights, the tumult an irregular schedule creates will result in daytime crankiness. Try to negotiate a daily and weekend schedule with kids so they feel in control of their summer break. Include chores and learning goals but let them brainstorm and contribute to the schedule.</li>
                                                <li><b>Plan enrichment activities:</b> Include extra-curricular or educational activities in your negotiated schedule. Enroll children in a daily <a href="https://dev.thecodewiz.com/arlington-ma/sessions/">virtual summer camp</a> or a weekly <a href="https://dev.thecodewiz.com/findmycourse/">online class</a>. This is a great time for students to learn a new skill, such as coding or game development, or to cultivate a passion for a particular subject. In order to see what type of enrichment activity your child would like most, help them make a personal interest inventory. Give them 10 minutes to sit quietly and make a list of things under the following categories: school subjects/projects, hobbies/sports, current events, arts/entertainment. Remind children that there are no right or wrong answers during a brainstorming session and the goal is to write as many ideas as possible. When their 10 minutes is up, have students circle their top 3-5 items that they find the most fun. Have them star the 3-5 that they think would be most useful for them to learn. Hopefully, there is an item that has a circle and a star or two items that relate to one another. For instance, if they circle <i>computer games</i> and star <i>computer skills</i>, consider a <a>virtual summer camp</a> that uses video games to teach coding or computer programming.</li>
                                                <li><b>Go outside:</b> Encourage children to go outside at least once a day so they can exercise and absorb rays. Exercise and sunshine boost the moods of children. A study in the European Journal of Pediatrics found that vitamin D from sunshine not only helps with mood but is essential for maintaining bone health. Vitamin D helps metabolize calcium and phosphate, both of which are crucial to bone development. While outside, encourage children to exercise to improve their heart health and produce endorphins, which are responsible for good moods.</li>
                                                <li><b>Keep Learning:</b> Find <a  href="https://dev.thecodewiz.com/online-coding-classes-and-camps/">creative ways</a> to keep children learning so their summer learning loss is reduced. For instance, learning using moderated online games like <a href="https://dev.thecodewiz.com/arlington-ma/online-coding-classes-for-kids/">Minecraft</a> increases critical thinking and cooperative communication skills. Studies show that summer learning loss is worse for younger children than teenagers. Make a learning goal each week and have the whole family get involved by discussing what each other has learned at family dinner at the end of the week. Children retain information by teaching and telling it to others.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
