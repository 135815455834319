import React from 'react';
import FloatingMenuItem from './floating-menu-item.component';
import { MessengerChat } from "react-messenger-chat-plugin";
// import './facebook-messenger.styles.scss';

import './floating-menu.styles.scss';

class FloatingMenu extends React.Component {
	constructor(props) {
		super(props);
		
		this.state = {
            toggled: false,
            link: props.link,
            freeclasslink: ''
        }
	}
    

	toggleMenu() {
		this.setState({toggled: !this.state.toggled});
	}
	
	render() {
		let buttons = [];
		let className = "floating-menu";
        let icon = "fa fa-plus";
        let propLink = this.props;
		let newsletter = "https://www.thecodewiz.com";
		let locations = "/locations";
        //let contactLink = "/"
        
		
		if (this.state.toggled) {
			className += " open";
			icon = "fa fa-times"; 
			if(this.props.franchise) {
				buttons.push(
					<FloatingMenuItem label="EMAIL US" icon="fa fa-envelope" action="" key="l1" link={`mailto:${propLink.contactlink}`}/>);
				buttons.push(
					<FloatingMenuItem label="CALL US" icon="fa fa-phone" action="" key="l2" link={`tel:${propLink.phone}`}/>);
				buttons.push(
					<FloatingMenuItem label="FIND A CENTER" icon="fa fa-location-arrow" action="" key="l3" link={locations}/>);
				
			} else {
				buttons.push(
					<FloatingMenuItem label="VIEW CAMP OPTIONS!" icon="fa fa-university" action="" key="i5" link={propLink.camplink}/>);
				buttons.push(
						<FloatingMenuItem label="EMAIL US" icon="fa fa-envelope" action="" key="i1" link={`mailto:${propLink.contactlink}`}/>);
				// buttons.push(
				//         <FloatingMenuItem label="Subscribe to Newsletter" icon="fa fa-rss" action="" key="i2" link={newsletter}/>);
				buttons.push(
						<FloatingMenuItem label="TRY A FREE CLASS" icon="fa fa-share" action="" key="i3" link={propLink.link}/>);
				
			}
		}
		
		buttons.push(<FloatingMenuItem label="" icon={icon} action={this.toggleMenu.bind(this)} key="m"/>); 
		
		return <div></div>
		// <div className="container float">
		// 	<div className={className}>
		// 	{buttons}
		// 	</div>
		// </div>;
		// return <div className="FacebookMessengerDiv">
        // <MessengerChat
        //     pageId={facebookId}
        //     language="en_US"
        //     themeColor={"#06324e"}
        //     bottomSpacing={200}
        //     loggedInGreeting="Hello! Do you have any questions for us?"
        //     loggedOutGreeting=""
        //     greetingDialogDisplay={"show"}
        //     debugMode={true}
        //     onMessengerShow={() => {
        //     console.log("onMessengerShow");
        //     }}
        //     onMessengerHide={() => {
        //     console.log("onMessengerHide");
        //     }}
        //     onMessengerDialogShow={() => {
        //     console.log("onMessengerDialogShow");
        //     }}
        //     onMessengerDialogHide={() => {
        //     console.log("onMessengerDialogHide");
        //     }}
        //     onMessengerMounted={() => {
        //     console.log("onMessengerMounted");
        //     }}
        //     onMessengerLoad={() => {
        //     console.log("onMessengerLoad");
        //     }}
        // />
        // </div>;
		
	}
}

export default FloatingMenu;

