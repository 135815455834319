import React from 'react';
import firebase from '../../components/Firebase/firebase';
import '../../assets/js/slick';
import MetaTags from 'react-meta-tags';

import Button from '../../components/button/button.component';
import Header from '../../components/header/header.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
import AbbrFaqs from '../../components/abbr-faqs/abbr-faqs.component';
//import FallClassHeaderImg from '../../assets/images/sessions_hero_image.png';
import FallClassHeaderImg from '../../assets/images/bubble-33.png';
// import InPersonClassHeaderImg from '../../assets/images/bubble-in-person.png';
import InPersonClassHeaderImg from '../../assets/images/bubble-31.png'
import FallClassesSlider from '../../components/slick-slider/fall-classes-slider.component';
import FallClassesMobileSlider from '../../components/slick-slider/fall-classes-slider-mobile.component';

import TechLogo1Img from '../../assets/images/fall_tech_logo1.jpg';
// import TechLogo2Img from '../../assets/images/fall_tech_logo2.jpg';
import TechLogo2Img from '../../assets/images/fll-logo-horizontal.png';
import TechLogo3Img from '../../assets/images/fall_tech_logo3.jpg';
import TechLogo4Img from '../../assets/images/fall_tech_logo4.jpg';
// import TechLogo5Img from '../../assets/images/fall_tech_logo5.jpg';
// import TechLogo6Img from '../../assets/images/fall_tech_logo6.jpg';
import TechLogo5Img from '../../assets/images/java-python-stack-html5.jpeg';
import TechLogo6Img from '../../assets/images/scratchlogo.png';
import AllTechLogoImg from '../../assets/images/all_tech_logos.jpg';
import KidCodingImg from '../../assets/images/kid_codin.jpg';
// import FallRoboticsImg from '../../assets/images/fall-robotics-pic.png';
import FallRoboticsImg from '../../assets/images/in-person-mid-image.jpg'
import CodeWizAdvImage from '../../assets/images/cw-advantage-no-qr.png';

import './fall-classes.styles.scss';
const couponUrl ="https://getform.io/f/3f2a0e4c-03e3-4c9a-948d-6b79076b5b96";
const grandOpeningUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e";
const cyberSalesUrl = "https://getform.io/f/4e628ef8-f8b5-4d19-a244-8a235f3bd9dc";

class FallClassesPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false,
            calltoaction: '/locations',
            calltoactionlabel: 'FIND A CENTER NEAR ME',
            fulllocation: props.fulllocation,
            formType: props.formType,
            owner: props.owner,
            locationemail: props.franchisee + "@thecodewiz.com",
            leadsource: props.leadsource,
            heading: props.heading,
            description: props.description
        }
    
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        let floatingdata =[];
        let formHeader = "";
        let formHeaderDesc = "";
        let formUrl ="";
        let coupontype="Cyber Sales";
       

        if(this.props.franchisee){
            this.state.isfranchisee = true;
            floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        } 
        if(this.state.formType == "grandopening"){
            formUrl = grandOpeningUrl;
            if(this.state.heading){
                formHeader = this.state.heading;
            } else {
            formHeader = "Grand Opening Sale!!";
            }
            if(this.state.description){
                formHeaderDesc = this.state.description;
            } else {
            formHeaderDesc = "Founding families enjoy **31.4159%** off their memberships for the first 6 months!";
            }
        } else if(this.state.formType == "coupon"){
            formUrl = couponUrl;
            formHeader = "We'd Love to Keep You Updated!";
            formHeaderDesc = "We'll send you a coupon for 5% OFF as a Thank You!";
            // formUrl = cyberSalesUrl;
            // formHeader = "Cyber Sales Week!!";
            // formHeaderDesc = "Get 20% off Code Wiz Gift Cards!";
        } else if ( this.state.formType == "grandopeninglifetime"){
            formUrl = grandOpeningUrl;
            if(this.state.heading){
                formHeader = this.state.heading;
            } else {
            formHeader = "Grand Opening Sale!!";
            }
            if(this.state.description){
                formHeaderDesc = this.state.description;
            } else {
            formHeaderDesc = "Founding families enjoy **31.4159%** off their memberships for life!";
            }
        }
        
        return (
            <div className="">
                <MetaTags>
                    <meta property="og:image" content={InPersonClassHeaderImg} />
                    <title>Code Wiz | Classes</title>
                </MetaTags>
                <Header location={this.state.location} fulllocation={this.state.fulllocation}/>
                <div className="special-page fall-coding in-person-classes" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    {/* <h2 className="header-title cw-dark-blue bungee">ONLINE CLASSES</h2>  
                                    <h4 className="header-text cw-dark-blue">Learn to code from home, supplement your learning and make new friends!</h4> 
                                    <div className="landing-page-buttons">
                                        <Button color="orange" link={this.state.data.parentportal} size="medium">ONLINE CLASS SCHEDULE</Button>
                                        <Button color="blue" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                                    </div>*/}
                                    <h2 className="header-title cw-dark-blue bungee">CODING CLASSES</h2> 
                                    <h4 className="header-text cw-dark-blue">In-Person classes available! <br/> Learn to code, unlock your inner genius and socialize with like minded peers!</h4> 
                                    <div className="landing-page-buttons">
                                        <Button color="orange" link={this.state.data.parentportal} size="medium">CLASS SCHEDULE</Button>
                                        <Button color="blue" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={InPersonClassHeaderImg} alt="fall coding"/>
                            </div>
                        </div>
                    </div>
                    <div className="blue_section fall_class_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sessions_image_section">
                                        <div className="image">
                                            <img src={FallRoboticsImg} alt="child coding"/>
                                        </div>
                                        <div className="text">
                                            {/* <h2 className="bungee">Connect with children your age, code at your own pace.</h2>
                                            <p><strong>Ready to reconnect with other kids who love technology? Ready to learn something new? Well, come on over! We are open for in-person classes.</strong></p>
                                            <p>With the world reopening and your kids getting tired of zoom classes, we are providing an opportunity for them to reconnect with other kids in a fun and safe environment at our Code Wiz center. Our coding and robotics classes don't just provide an environment for children to collaborate and socialize, they help them get ahead of the curve by learning fundamental and cutting edge technology such as Scratch, Python, First Lego League Robotics, Java, HTML, Artificial Intelligence and more!</p> */}
                                            {/* <p className="bungee orange_txt">Ages 7 – 17</p> */}
                                            <div className="form-white bg-white">
                                                <div className="cw-coupon-form-header">
                                                    <h3 className="bungee cw-dark-blue center">{formHeader}</h3>
                                                    <h4 className="center cw-dark-blue">{formHeaderDesc}</h4>
                                                </div>
                                                <form id="cw-contact-form" className="contact-form cw-dark-blue" action={formUrl} method="POST">
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputFirst">First Name</label>
                                                            <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputLast">Last Name</label>
                                                            <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputEmail">Email</label>
                                                            <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
                                                        </div>
                                                        <div className="form-group col-md-6">
                                                            <label htmlFor="inputPhone">Phone</label>
                                                            <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <input type="hidden" id="location" name="location" value={this.state.location}></input>
                                                        <input type="hidden" id="zoho" name="zoho" value={this.state.owner}></input>
                                                        <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
                                                        <input type="hidden" id="leadsource" name="lead-source" value={this.state.leadsource}></input>
                                                        <input type="hidden" id="coupontype" name="coupon-type" value={coupontype}></input>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="form-group col-md-6">
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <div className="form-check">
                                                            <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                                                            <label className="form-check-label tiny-print" htmlFor="gridCheck">
                                                            {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                                                            <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                                                            <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {/* <button type="submit">Send</button> */}
                                                    <button type="submit" className="btn orange-button" target="_blank">GET OFFER</button>
                                                </form>
                                            </div>
                                        </div> 
                                    </div>  

                                    <div className="online_coding_tech_slider">
                                        <h2 className="bungee white_txt">Start your learning today,<br/>safe and fun at our Code Wiz {this.state.location} location!</h2>
                                        <div className="slider technologies_slider desktop-slider">
                                            <FallClassesSlider />
                                        </div>
                                        <div className="slider technologies_slider mobile-slider">
                                            <FallClassesMobileSlider />
                                        </div>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="center lonely-btn">
                        <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                    </div>
                    <div className="fall_class_middle_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="fall_class_techs">
                                        <div className="fall_class_tech_single">
                                            <div className="tech_logo">
                                                <img src={TechLogo1Img} alt="tech logo"/>							
                                            </div>
                                            <h3 className="bungee blue_txt">Coding with <br/>Roblox</h3>
                                            <h4 className="bungee orange_txt">Ages 7 – 9 & 10+</h4>
                                            <p className="">Designed to teach students how to make their own world or gamemode and learn the ins and outs of Roblox!</p>
                                        </div>
                                        <div className="fall_class_tech_single">
                                            <div className="tech_logo">
                                                <img src={TechLogo2Img} alt="tech logo two" />							
                                            </div>
                                            <h3 className="bungee blue_txt">Robotics <br/>Competition</h3>
                                            <h4 className="bungee orange_txt">Ages 8 – 12</h4>
                                            <p className="">Using STEM, coding and tech literacy, students will perform tournament missions to compete in FLL & FLL Jr.!</p>
                                        </div>
                                        
                                        <div className="fall_class_tech_single">
                                            <div className="tech_logo">
                                                <img src={TechLogo3Img} alt="tech logo three" />							
                                            </div>
                                            <h3 className="bungee blue_txt">Minecraft <br/>World Builder</h3>
                                            <h4 className="bungee orange_txt">Ages 7 – 12</h4>
                                            <p className="">Student are tasked with working together to build a functioning civilization on an empty plot in this unique collaborative class!</p>
                                        </div>
                                        
                                        <div className="fall_class_tech_single">
                                            <div className="tech_logo">
                                                <img src={TechLogo4Img} alt="tech logo four" />							
                                            </div>
                                            <h3 className="bungee blue_txt">Game Design <br/>with Unity</h3>
                                            <h4 className="bungee orange_txt">Ages 10+</h4>
                                            <p className="">Unleash your creative side and learn the fundamentals of coding concepts creating your own 2D/3D games. Gaming will never be the same again!</p>
                                        </div>
                                        
                                        <div className="fall_class_tech_single">
                                            <div className="tech_logo">
                                                <img src={TechLogo5Img} alt="tech logo five" />							
                                            </div>
                                            <h3 className="bungee blue_txt">Text Based Coding</h3>
                                            <h4 className="bungee orange_txt">Ages 10+</h4>
                                            <p className="">Learn and advance in one or more text based coding languages. Choose from HTML/CSS, Python, Java, or Javascript. Want to learn an advanced topic? Take our Artificial Intelligence or Apps for Alexa course! </p>
                                        </div>
                                        
                                        <div className="fall_class_tech_single">
                                            <div className="tech_logo">
                                                <img src={TechLogo6Img} alt="tech logo six"/>							
                                            </div>
                                            <h3 className="bungee blue_txt">Scratch/<br/>Codesters</h3>
                                            <h4 className="bungee orange_txt">Ages 7 - 10</h4>
                                            <p className="">Dive into the world of code by coding your own space shooter games, trivia games, music bands, beautiful computer-generated art and math simulations or tell a story using drag n drop or drag to text techniques! </p>
                                        </div>
                                    </div> 
                                    {/* <div className="fall_class_tech_options">
                                        <div className="fall_class_tech_option_left">
                                            <h3 className="bungee">More Course Options</h3>
                                            <p className="">Have a favorite coding platform you have tried before, or are you interested in a different platform than mentioned above? Don’t worry we still have Python, HTML, CSS, Scratch, Java, Minecraft coding and more!</p>
                                        </div>
                                        <div className="fall_class_tech_option_right">
                                            <img src={AllTechLogoImg} alt="all tech logo"/>
                                        </div>
                                        
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="blue_section fall_class_section">
                        <div className="sessions-cw-advantage container">
                            <img className="cwadvantage" src={CodeWizAdvImage} alt="The Code Wiz Advantage"/>
                        </div>
                    </div>

                    <div className="sessions_bottom_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">	
                                    <h2 className="bungee">FREQUENTLY ASKED QUESTIONS</h2>
                                    <div id="accordion">
                                        {/* <div className="card">
                                            <div className="card-header" id="onlineCampFaqOne">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseOne" aria-expanded="true" aria-controls="onlineCampCollapseOne">
                                                    <h5 className="bungee cw-dark-blue"> What kind of equipment does my child need to participate? </h5>   
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseOne" className="collapse show" aria-labelledby="onlineCampFaqOne" data-parent="#accordion">
                                                <div className="card-body">
                                                    • A modern PC or Mac computer with at least 4GB of RAM and 256MB processor speed. Tablets are not supported for online class/camp use.<br/>
                                                    • Minecraft, Roblox and Unity classes PCs or MACs. Chromebooks will not suffice<br/>
                                                    • A USB headset<br/>
                                                    • A web camera<br/>
                                                    • High speed internet  
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqTwo">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseTwo" aria-expanded="true" aria-controls="onlineCampCollapseTwo">
                                                    <h5 className="bungee cw-dark-blue"> How do you ensure children are actually learning online? </h5> 
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseTwo" className="collapse" aria-labelledby="onlineCampFaqTwo" data-parent="#accordion">
                                                <div className="card-body">
                                                    Our staff has been trained extensively in how to use Zoom to manage a classroom remotely. The classroom participants will meet limits and restrictions for features like chat, file sharing, and even speaking out of turn. Instructors have the ability to mute or remove disruptive students, and the entire session is recorded by default for accountability purposes.
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqThree">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseThree" aria-expanded="true" aria-controls="onlineCampCollapseThree">
                                                    <h5 className="bungee cw-dark-blue">What ages are coding classes designed for? </h5>  
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseThree" className="collapse" aria-labelledby="onlineCampFaqThree" data-parent="#accordion">
                                                <div className="card-body">
                                                    All of our classes are geared at children with a basic reading proficiency. They need to be able to read the instructions on the screen independently to fully participate in our courses.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqFour">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseFour" aria-expanded="true" aria-controls="onlineCampCollapseFour"> 
                                                    <h5 className="bungee cw-dark-blue"> My child is younger than 1st grade and not yet reading,  do you have anything for them? </h5>   
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseFour" className="collapse" aria-labelledby="onlineCampFaqFour" data-parent="#accordion">
                                                <div className="card-body">
                                                At this time, no. All our coding classes are designed for children who can read independently. However, we work with several other providers who are bringing classes for younger children, and we’re happy to connect you to them! Contact us to discuss more
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqFive">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseFive" aria-expanded="true" aria-controls="onlineCampCollapseFive">
                                                    <h5 className="bungee cw-dark-blue"> Is this self-guided? </h5>  
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseFive" className="collapse" aria-labelledby="onlineCampFaqFive" data-parent="#accordion">
                                                <div className="card-body">
                                                While our courses are self paced, our classes offer an easy way for your child to participate in a classroom environment with a coach. 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqSix">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseSix" aria-expanded="true" aria-controls="onlineCampCollapseSix">
                                                    <h5 className="bungee cw-dark-blue"> Do you offer make up classes? </h5>
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseSix" className="collapse" aria-labelledby="onlineCampFaqSix" data-parent="#accordion">
                                                <div className="card-body">
                                                We only offer make up classes for our evening classes. We are unfortunately unable to offer makeup classes for camps.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqSeven">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseSeven" aria-expanded="true" aria-controls="onlineCampCollapseSeven">
                                                    <h5 className="bungee cw-dark-blue">  Who are your coaches?  </h5> 
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseSeven" className="collapse" aria-labelledby="onlineCampFaqSeven" data-parent="#accordion">
                                                <div className="card-body">
                                                Our instructors have backgrounds in computer science, engineering, and/or math as well as experience working with children. With a strong understanding of how to teach coding to young children, your child gets a fun, educational class experience. All our instructors undergo background checks.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-header" id="onlineCampFaqEight">
                                                <h5 className="mb-0">
                                                <span data-toggle="collapse" data-target="#onlineCampCollapseEight" aria-expanded="true" aria-controls="onlineCampCollapseEight"> 
                                                    <h5 className="bungee cw-dark-blue">  Can we do a trial class? </h5>   
                                                </span>
                                                </h5>
                                            </div>
                                            <div id="onlineCampCollapseEight" className="collapse" aria-labelledby="onlineCampFaqEight" data-parent="#accordion">
                                                <div className="card-body">
                                                Trial classes are available for our evening classes. To book a trial class, please visit our <a className="faq-link" href={this.state.data.tryfreeclass}>booking page</a>
                                                </div>
                                            </div>
                                        </div>						 
                                    </div>
                                    <div className="bottom_cta">
                                        {/* <p>Have more questions? Please see our full class <a href={`${this.state.fulllocation}/faqs`}>FAQ</a></p> */}
                                        <p>Have more questions? Please see our full class <a href="faqs">FAQ</a></p>
                                        <ul>
                                            <li><a href={`tel:${this.state.data.phone}`}><i className="fa fa-phone"></i>&nbsp; {this.state.data.phone}</a></li>
                                            <li><a href={`mailto:${this.state.data.email}`}><i className="fa fa-envelope"></i> {this.state.data.email}</a></li> 
                                        </ul>
                                        <a className="regular_button" href={this.state.data.parentportal}>View In-Person Class Schedule</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="sessions_detail">
                                        <div className="text">
                                            <h2 className="bungee">Code Wiz Alignment with</h2>
                                            <h4>K – 12 Computer Science Framework</h4>
                                            <p>Code Wiz aligns its curriculum with the K–12 Computer Science Framework represents a vision in which all students engage in the concepts and practices of computer science.</p>
                                            <p>“Beginning in the earliest grades and continuing through 12th grade, students will develop a foundation of computer science knowledge and learn new approaches to problem solving that harness the power of computational thinking to become both users and creators of computing technology. By applying computer science as a tool for learning and expression in a variety of disciplines and interests, students will actively participate in a world that is increasingly influenced by technology”</p>
                                            <p>The framework promotes a vision in which all students critically engage in computer science issues; approach problems in innovative ways; and create computational artifacts with a practical, personal, or societal intent.</p>
                                            <Button color="orange" link={this.state.data.parentportal} size="large">VIEW IN-PERSON CLASS SCHEDULE</Button>
                                        </div>
                                        <AbbrFaqs />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {floatingdata}
                </div>
            </div>
        );
    }
}

export default FallClassesPage
