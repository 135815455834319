import React from 'react';
import firebase from '../components/Firebase/firebase.js';

import Header from '../components/header/header.component';
//import FloatingMenu from '../components/floating-menu/floating-menu.component';
import BlogDetailHeaderImg from '../assets/images/blog_deails_hero_image.png';
import CommentBubbleImg from '../assets/images/comment_bubble.png';

import BestKidsCodingLanguages from '../assets/images/blog-pictures/best-kids-coding-languages.jpg';
import DataPrivacyImg from '../assets/images/data_security.jpg';





import './blog-pages.styles.scss';


class BlogDetailPage extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.franchisee,
            tryfreeclass: "https://codewiz.simplybook.me/v2/#book",
            findacenter: "/locations",
            fallclasses: "",
            phone: "",
            data: {},
            isfranchisee: false
        }
    
    }

    // componentDidMount(){
    //     let that = this;
    //     const locationRef = firebase.database().ref(that.state.location);
    //     locationRef.on('value', (snapshot) => {
    //         let locations = snapshot.val();
    //         this.setState({
    //             data: locations
    //         })
    //     });
    // }

    render(){
        // let floatingdata =[];
        // if(this.props.franchisee){
        //     this.state.isfranchisee = true;
        //     floatingdata.push(<FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/>);
        // } 
       
        return (
            <div className="blogs_details_page">
                <Header location={this.state.location}/>
                <div className="special-page online-coding" id=""> 
                    <div className="container header-text-container">
                        <div className="row">
                            <div className="top-header col-sm-12 col-md-6"> 
                                <div className="text">
                                    <span className="posted_date">January 26, 2022</span>
                                    <h1 className="header-title cw-dark-blue bungee"> <span> Data Privacy Tips for Students </span></h1> 
                                    <div className="blogs_listing_block_text_footer">
                                        <span className="post_admin"><a href="#">Martellaro Music</a></span>
                                        <span className="post_comments"><a href="#"><img src={CommentBubbleImg} alt="comment bubble"/></a></span>
                                    </div>  
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6 expanded_image">
                                <img src={DataPrivacyImg} alt="blog details header"/>
                            </div>
                        </div>
                    </div>
                    <div className="blogs_details_section">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="blogs_details">
                                        <div className="blogs_details_block">
                                            <h4 className="bungee center cw-dark-blue">Data Privacy Tips for Students</h4>
                                            <p>Data privacy is an often overlooked subject in today’s world, but it’s important for parents to be informed when it comes to their children’s online activity. Here are some helpful data privacy tips for students!</p>
                                            <h3><b>1. Consider what you share.</b></h3>
                                            <p>Perhaps the largest problem with social media today is the willingness many users have to publicly share personal information. Not surprisingly, this issue is compounded with children and teens who are still growing up and maturing. The best tip we have is remember this rule: you should consider anything you post online to be permanent. The last thing anyone wants is to lose a future job opportunity because of an embarrassing social media post they shared years prior, so we urge parents to talk to their kids about what information they divulge to online audiences.</p>


                                        </div>
                                        <div className="blogs_details_block">
                                            <h3><b>2. Remain anonymous when possible.</b></h3>
                                            <p>Just over a decade ago, it was fairly common practice to avoid using your real name on the internet. With the advent of social media and professional linking platforms, this rule seemingly no longer applies. Even so, we recommend kids use “handles” whenever possible for privacy reasons. The large majority of social media networks still allow accounts to be created with usernames, and this is a good way to maintain some degree of privacy.</p>
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3><b>3. Don’t respond to strangers.</b></h3>
                                            <p>The classic “don’t talk to strangers” rule applies just as much to online activity as it does in real life. We recommend kids only respond to DMs (direct messages) and emails from people they actually know – friends, family, classmates, etc. Most importantly, remember that questionable or concerning messages should be blocked and potentially reported to the social media network.</p>
                                            {/* <img src={BestKidsCodingLanguages} alt="best kids coding languages" /> */}
                                        </div>
                                         <div className="blogs_details_block">
                                            <h3><b>4. Be mindful of your passwords.</b></h3>
                                            <p>We’re sorry to be the bearer of bad news, but in today’s day and age your pet’s name with a number simply doesn’t cut it any more when it comes to password security! People will often say, “Well, it’s not like my bank account is going to get hacked. They’re a huge company!” And while they are correct, what usually happens is that a smaller website – one where you happened to use the same email and password combination – experiences a data breach. Then, scammers proceed to try this login information on as many websites as possible. Because people commonly reuse passwords, it’s easy to see how this situation can escalate quickly. <br/><br/>The best option is to use a password manager service to keep track of your logins, which will allow you to use a unique password for every website. At the bare minimum, make sure you have 2FA (two-factor authentication) set up on your most important logins – many banking institutions have this by default and will text a code to your phone when logging in on a new device for the first time. If you’re curious to see if your email, phone number, or password has been listed in a data breach, check out <a href="https://www.haveibeenpwned.com">haveibeenpwned.com.</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {floatingdata} */}
                </div>
            </div>
        );
    }
}

export default BlogDetailPage
