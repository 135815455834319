import React from 'react';
import Header from '../../../components/header/header.component';
import LegoLeague from '../../../components/lego-league/lego-league.component';
import MetaTags from 'react-meta-tags';

const LegoLeaguePage = () => (
  <div>
    <Header location="arlington" fulllocation="arlington-ma"/>
    <LegoLeague franchisee="arlington" formType="coupon" owner="Code Wiz Arlington"/>
    <MetaTags>
        <title>Code Wiz - Arlington MA | FLL</title>
    </MetaTags>
  </div>
);

export default LegoLeaguePage;