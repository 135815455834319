import React from 'react';
import FindACoursePage from '../../find-a-course/find-a-course.component';
import MetaTags from 'react-meta-tags';

const FranchiseFindACoursePage = () => (
    <div>
        <FindACoursePage franchisee="arlington" fulllocation="arlington-ma"/>
        <MetaTags>
            <title>Code Wiz - Arlington MA | Find A Course</title>
        </MetaTags>
    </div>
);

export default FranchiseFindACoursePage;