import React, {useState} from 'react';
import firebase from '../Firebase/firebase.js';
import Popup from 'reactjs-popup';

import AboutPageMobileImg from '../../assets/images/camps-and-summer-header-1.png';
//import AboutSpark from '../../assets/images/image-2.png';
//import AboutGivingBack from '../../assets/images/giving-back-image.png';
import CityImage from '../../assets/images/location-pin.png';
import CodingRobotics from '../../assets/images/landing-page-coding-robotics.png';
import WhyCodewiz from '../../assets/images/landing-page-why-codewiz.png';

import FranchiseHeaderImg from '../../assets/images/FranchiseeHomePage.jpg';
import ComingSoonHeaderImg from '../../assets/images/cw-coming-soon.png';
import FoundingMemberHeaderImg from '../../assets/images/cw-founding-member.png';
//import MidBkgImage from '../../assets/images/landing-page-mid bkg.png';

import Button from '../../components/button/button.component';
import Review from '../../components/review/review.component';
import TeamMember from '../../components/team-member/team-member.component';
import FloatingMenu from '../../components/floating-menu/floating-menu.component';
//import TeamSlider from '../../components/slick-slider/teams-slider.component';
import AsSeenOnImg from '../../assets/images/as-seen-on.png';
import NeedhamFamily from '../../assets/images/needham-fam.jpg';
import LawrencevilleFamily from '../../assets/images/cheris-family-lawrenceville.jpeg';
import PlanoFamily from '../../assets/images/naila-family-plano-tx.jpg';
import StjohnsFamily from '../../assets/images/eluid-rebeca-family-stjohns-1.jpeg';
import HoustonFamily from '../../assets/images/joy-houston.jpeg';
import TierrasantaFamily from '../../assets/images/Rich-and-Davon.jpg';
import DurhamFamily from '../../assets/images/Durham-family.jpg';
import FishHawkFamily from '../../assets/images/moore-family.png';
import CypressFamily from '../../assets/images/cypress-family.jpeg';
import KatyFamily from '../../assets/images/katy-family.jpg';
import KellerEastFamily from '../../assets/images/Juan-kellereast.jpg';
import QuincyFamily from '../../assets/images/milton-family.jpeg';
import ColleyvilleFamily from '../../assets/images/colleyville-fam.JPG';
import RutherfordFamily from '../../assets/images/rutherford-fam.jpg';
import OwassoFamily from '../../assets/images/owasso-fam.jpeg';
import PlainsboroFamily from '../../assets/images/Kevin.jpeg';
import MabletonFamily from '../../assets/images/mableton-family.jpg';
import BeaumontFamily from '../../assets/images/beaumont-family.jpg';
import EdisonFamily from '../../assets/images/edison-family.jpg';
import KirklandFamily from '../../assets/images/kirkland-family.jpeg';
import NorthstpeteFamily from '../../assets/images/northstpete-family.jpg';

import GoogleMapNashuaComponent from '../../components/google-map/google-map.component-nashua';
import GoogleMapNeedhamComponent from '../../components/google-map/google-map.component-needham';
import GoogleMapLawrencevilleComponent from '../../components/google-map/google-map.component-lawrenceville';
import GoogleMapPlanoComponent from '../../components/google-map/google-map.component-plano';
import GoogleMapStjohnsComponent from '../../components/google-map/google-map.component-stjohns';
import GoogleMapHoustonComponent from '../../components/google-map/google-map.component-houston.jsx';
import GoogleMapDurhamComponent from '../../components/google-map/google-map.component-durham.jsx';
import GoogleMapCypressComponent from '../../components/google-map/google-map.component-cypress.jsx';
import GoogleMapTierrasantaComponent from '../../components/google-map/google-map.component-tierrasanta.jsx'
import GoogleMapCarmelComponent from '../../components/google-map/google-map.component-carmel.jsx';
import GoogleMapFishhawkComponent from '../../components/google-map/google-map.component-fishhawk.jsx';
import GoogleMapKatyComponent from '../../components/google-map/google-map.component-katy.jsx';
import GoogleMapKellerEastComponent from '../../components/google-map/google-map.component-kellereast.jsx';
import GoogleMapQuincyComponent from '../../components/google-map/google-map.component-quincy.jsx';
import GoogleMapColleyvilleComponent from '../../components/google-map/google-map.component-colleyville.jsx';
import GoogleMapRutherfordComponent from '../../components/google-map/google-map.component-rutherford.jsx';
import GoogleMapOwassoComponent from '../../components/google-map/google-map.component-owasso.jsx';
import GoogleMapPlainsboroComponent from '../../components/google-map/google-map.component-plainsboro.jsx';
import GoogleMapMabeltonComponent from '../../components/google-map/google-map.component-mableton.jsx';
import GoogleMapBeaumontComponent from '../../components/google-map/google-map.component-beaumont.jsx';
import GoogleMapEdisonComponent from '../../components/google-map/google-map.component-edison.jsx';
import GoogleMapKirklandComponent from '../../components/google-map/google-map.component-kirkland.jsx';
import GoogleMapNorthStPeteComponent from '../google-map/google-map.component-northstpete.jsx';



import './franchisee.styles.scss';


class ComingSoonFranchisee extends React.Component {
    constructor (props){
        super(props);

        this.state = {
            location: props.name,
            data: {},
            team: props.team,
            map: props.name + "-map",
            brochure: "https://getform.io/f/c7aeb3d8-3132-4a1d-8241-6142b5b305e8",
            grandOpeningUrl: "https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e",
            locationemail: props.name + "@thecodewiz.com",
            zoho: props.owner,
            isOpen : false
            // email: "esther@thecodewiz.com"
        } 
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal () {
        this.setState({isOpen: true})
    }

    closeModal () {
        this.setState({isOpen: false})
    }

    componentDidMount(){
        let that = this;
        const locationRef = firebase.database().ref(that.state.location);
        locationRef.on('value', (snapshot) => {
            let locations = snapshot.val();
            this.setState({
                data: locations
            })
            //console.log("Data", locations)
        });
    }

    render(){
        // const [open, setOpen] = useState(false);
        // const closeModal = () => setOpen(false);
        const formUrl ="https://getform.io/f/5d6f03fd-919c-4472-971c-c2956be2d98e"; //grandOpeningUrl
        const teamsArr = [];
        let renderdaysList = "";
        const teams = this.state.team;
        if(teams.length > 0){
            for (let i = 0; i < teams.length; i++) {
                teamsArr.push(<TeamMember id={i} key={i} name={teams[i].name} picture={teams[i].picture} title={teams[i].title}/>);
            }
        }
        const daysOpenList = this.state.data.daysopen;
        
        if(!daysOpenList){
            renderdaysList =""
        }else {
            renderdaysList = daysOpenList.map((item, index) => 
                               <div key={index}>{item}</div>
                             );
        }
        
        let discountPrice = Math.floor(parseInt(this.state.data.classprice) * 0.68586);
        let zeeFamilyPicture = "";
        let zeeStory = "";
        let zeeStoryTwo = "";
        let zeeStoryThree = "";
        let zeeStoryFour = "";
        let zeeStoryFive ="";


        let zeeSummary = "";
        let zeeFullStory = "";
        let zeeName = "";
        let zeeFullStoryButton = false;
        let grandopeningoffer = "Founding families enjoy **31.4159%** off their memberships for the first 6 Months!";
        

        const map = [];
        if(this.state.location === "nashua"){
            map.push(<GoogleMapNashuaComponent/>);
        }else if(this.state.location === "plano"){
            map.push(<GoogleMapPlanoComponent/>);
            zeeFamilyPicture = PlanoFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Plano, TX - Naila and Ali!</p>
                <p>Naila and Ali are super excited to be part of an educational platform that enhances critical thinking, problem solving and creativity while having fun!</p>
                <p>As the world continues to be more digitalized, they are looking forward to seeing kids and families enjoy a skill set that will have a long lasting effect on their futures. They love kids and are excited to play, build, and have fun with coding and robotics!</p>
            </div>;
            zeeName = "Naila & Ali";
            zeeFullStoryButton = false;
        }else if(this.state.location === "houston") {
            map.push(<GoogleMapHoustonComponent/>);
            zeeFamilyPicture = HoustonFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Oak Forest, TX - Joy!</p>
                <p>Joy has a huge passion for kids STEM/STEAM! Through out her career, she has been involved in different initiatives and outreaches to get kids excited about STEM.</p>
                <p>Her passion drove her to become an FAA Part 107 certified drone pilot! Combining that certification and her passion for kids STEM/STEAM, Joy is dedicating her full time to make a difference! She is excited to be a part of Code Wiz that will realize her dream of impacting kids, making a difference and getting them excited about STEM/STEAM!</p>
            </div>;
            zeeName = "Joy";
            zeeFullStoryButton = false;
        }else if(this.state.location === "durham") {
            map.push(<GoogleMapDurhamComponent/>);
            zeeFamilyPicture = DurhamFamily;
            zeeFullStory = <div><p>Meet the owner of Code Wiz Durham, NC - Teresa!</p>
            <p>Children are our future, and the future will be driven by technology.  Teresa joined the Code Wiz family to provide children with the opportunity to be the building blocks for our future by learning to code - the foundation of technology.</p>
            <p>For over 20 years, Teresa has been dedicated to lifelong learning.  She has an unquenchable thirst for knowledge that has driven her from the admissions process assistant at a university to creating an innovative solution for all university admissions processes.</p>
            <p>Teresa started her own business, purchased several franchises, and is also a franchise consultant helping others find freedom and flexibility through business ownership. She is a strong leader and community advocate.</p>
            <p>Teresa believes that the future is technology, and coding is the foundation. 2 out of 3 new STEM jobs are in Computer Science, but only 3% of university students graduate with a degree in Computer Science, and coding is very rarely taught at the grade school, middle school, and high school levels. There are over 500,000 current Computer Science job openings. These jobs can earn 40 - 60% more than the college average, yet they go unfilled at an alarming rate.</p>
            <p>Code Wiz Durham is a place to give children a chance to invest in their future by learning the language of the future through various STEM pathways.  Teresa believes that learning should be fun and creative which is why she chose to bring the Code Wiz franchise to her community.</p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Durham, NC - Teresa!</p>
                <p>Children are our future, a future that will be driven by technology. Teresa joined the Code Wiz family to provide youth with the opportunity to be the building blocks of our future by learning to code – the foundation of technology.</p>
                <p>Teresa has an entrepreneurial background, and is a strong leader and community advocate. She is driven to help children invest in their future and reach their full potential through various STEM learning pathways.</p>
                <p>Passionate about education, Teresa believes learning should be fun and creative, which is why she chose to bring a Code Wiz franchise to her community!</p>
            </div>;
            zeeName = "Teresa";
            zeeFullStoryButton = true;
        }else if(this.state.location === "cypress") {
            map.push(<GoogleMapCypressComponent/>);
            zeeFamilyPicture = CypressFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Cypress, TX - Ton!</p>
                <p>The children of today will create the technology of tomorrow, and Code Wiz Cypress owner Ton knows the preparation starts now.</p>
                <p>A native of Fort Worth, Ton moved to Houston with his wife Jun over 10 years ago to attend school. They fell in love with the area – especially Northwest Houston – and decided to make it their permanent home.</p>
                <p>Ton is proud of his new venture and is eager to share his excitement with families in the community. Not only is coding a great foundation for future success, it’s also a lot of fun. “I’m excited to help our local children reach their full potential,” he says.</p>
                
            </div>;
            zeeName = "Ton";
            zeeFullStoryButton = false;
        }else if(this.state.location === "fishhawk") {
            map.push(<GoogleMapFishhawkComponent/>);
            zeeFamilyPicture = FishHawkFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz FishHawk, FL - Reginald & April!</p>
                <p>A true passion for education and technology led Reggie and April to join the Code Wiz family. Along with their two children, they are eager to welcome local families to their new center and introduce them to all that Code Wiz offers.</p>
                <p>Reggie has a tech background, having served as a network architect in the military. On the flip side, April comes armed with a degree in Elementary Education. Code Wiz embodies their shared love of coding, education and community, and they are excited to impact youth and help develop the brilliant minds of tomorrow.</p>
            </div>;
            zeeName = "Reginald & April";
            zeeFullStoryButton = "";
        }else if(this.state.location === "katy") {
            map.push(<GoogleMapKatyComponent/>);
            zeeFamilyPicture = KatyFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Katy, TX - Tim!</p>
                <p>Tim is a Cloud Engineer by profession, with over 8 years of experience in Information Technology, Coding, and Cloud Engineering. He has a vast demonstrated history of working in the computer software industry and is skilled in various technologies.</p>
                <p>Tim is eager to open a Code Wiz in Katy, TX, to provide a retail learning center where kids can learn coding concepts in a fun and safe environment. He hopes to create a space where kids can enjoy creating their projects and also get involved in community Robotics competitions. He is excited to be a part of the Code Wiz family and make a positive impact on the lives of kids through the power of coding and technology.</p>
            </div>;
            zeeName = "Tim Sonaike";
            zeeFullStoryButton = "";
        }else if(this.state.location === "quincy") {
            map.push(<GoogleMapQuincyComponent/>);
            grandopeningoffer = "Founding families enjoy **31.4159%** off their memberships for life!";
            zeeFamilyPicture = QuincyFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Quincy, MA - Bernard & Iris!</p>
                <p>Bernard and Iris join the Code Wiz family with the goal of providing the opportunity for kids to learn, as well as provide them with tools for their future endeavors.</p>
                <p>Both Bernard and Iris have a healthcare background, and over the years, they have witnessed how technology and automation have positively shaped the field of medicine. When their son developed an interest in coding, they looked to enroll him in a coding course and were surprised by the limited options available in their area.</p>
                <p>Nonetheless, they fortunately found Code Wiz and decided to enroll him. They experienced first hand the effectiveness of our courses, what they could achieve, and they were impressed by how our Franchises operate. They now seek to help other kids find and nurture their love for coding, just as their son did, by bringing a Code Wiz location to their community!</p>
            </div>;
            zeeName = "Bernard and Iris";
            zeeFullStoryButton = "";
        }else if(this.state.location === "rutherford") {
            map.push(<GoogleMapRutherfordComponent/>);
            zeeFamilyPicture = RutherfordFamily;
            zeeFullStory = "";
            zeeSummary = <div>
                <p>Meet the Owner of Code Wiz Rutherford, NJ- Shipra</p>
                {/* <p>Code Wiz allows us to contribute to children's lives and help shape them, allowing them to reach a better future!</p> */}
                <p>Shipra has over 15 years in the software industry and is a certified automation and functional QA. Throughout her career she has been in many roles, from mentor to leading test practice.</p> 
                <p>She has always been passionate about work and challenging herself to keep learning new skills and to continuously grow and improve. She wishes to allow Code Wiz to be an innovative and creative space that kids would not only love to be a part of, but could also help better her community.</p>
                <p>Code Wiz allows us to contribute to kids' lives and help shape them, allowing them to reach a better future!</p>
                </div>;
            zeeName = "Shipra";
            zeeFullStoryButton = "";
        }else if(this.state.location === "owasso") {
            map.push(<GoogleMapOwassoComponent/>);
            zeeFamilyPicture = OwassoFamily;
            zeeFullStory = <div>
                
                <p>Troy and DeEtta Bumgardener moved to the Tulsa area in late 2001. At that time, Owasso was a small growing suburb with the beginnings of a commercial growth spot. Now Owasso is a thriving community and, 22 years and four kids later, they are both thrilled to be bringing the first Code Wiz to Oklahoma.</p>
                <p>After growing up in Nebraska, DeEtta served in the U.S. Marines right out of high school, which she has since turned into a career in the trucking industry that has spanned over 25 years. After 28 years and raising four kids she looks forward to serving the next generation of kids in her community. By developing young minds and helping kids find their inner genus, they’ll be able to learn valuable skills like coding that could greatly impact their educational and professional futures.</p>
                <p>Throughout a 20 year career as a geographic information systems professional, Troyhas learned the importance of staying technologically curious and adapting to the needs of the future workforce. He uses Python on a daily basis and finds the opportunity to help teach and pass on this valuable knowledge to young minds in a fun learning environment to be an incredible opportunity.</p>
                <p>Troy is also an Eagle Scout who still serves his local troop with his son, he loves coaching and leading his kids’ activities, and is really looking forward to the chance to work with kids in Owasso and its surrounding areas.</p>
                <p>Through their own kids’ educational experience and their own professional development, they both realize the value that STEM and it’s skills have not just in education, but also in a multitude of professional areas.</p>
                <p>They both look forward to bringing Code Wiz to both Oklahoma and their community. They are excited to partner with local businesses, support their community, and give children the opportunities of tomorrow that they would not have otherwise had.</p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Owasso, OK - Troy & DeEtta!</p>
                <p>Troy and DeEtta Bumgardener moved to the Tulsa area in late 2001. At that time, Owasso was a small growing suburb with the beginnings of a commercial growth spot. Now Owasso is a thriving community and, 22 years and four kids later, they are both thrilled to be bringing the first Code Wiz to Oklahoma.</p>
                <p>DeEtta served in the U.S. Marines right out of high school, which she has since turned into a career in the trucking industry that has spanned over 25 years. Troy has worked for over 20 years as a geographic information system professional and knows the value of coding as he uses Python on a daily basis.</p>
                <p>After raising four kids they look forward to serving the next generation of kids. By developing young minds and helping kids find their inner genus, they’ll be able to learn valuable skills like coding that could greatly impact their educational and professional futures.</p>
                
            </div>;
            zeeName = "Troy and DeEtta";
            zeeFullStoryButton = true;
        }else if(this.state.location === "plainsboro") {
            map.push(<GoogleMapPlainsboroComponent/>);
            zeeFamilyPicture = PlainsboroFamily;
            zeeFullStory = <div>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owner of Code Wiz Plainsboro, NJ - Kevin!</p>
                <p>Kevin is  excited about the prospect of empowering children and  teaching them valuable coding skills! <br/><br />
                He has over 10 years of experience across product strategy, management consulting, and entrepreneurship. He graduated from Penn State University, obtaining a dual degree in Finance and Economics and received his Masters of Business Administration degree from University of Pennsylvania.</p>
                <p>He decided to open a Code Wiz location because he saw that a need existed in his community. He knows that there is an increasing demand for coding skills, while there is a lack of coding education options, especially for children. Code Wiz addresses this gap in his community by providing specialized coding programs for kids. Code Wiz helps children to develop coding and problem-solving skills from an early age, ensuring opportunities and success in STEM related fields.</p>
                <p>He is excited to be able to witness the growth and progress of his students as they develop problem-solving abilities, logical thinking, and creativity through coding projects in a very rewarding experience as he helps his community!</p>
                {/* <p></p>
                <p></p> */}
            </div>;
            zeeName = "Kevin";
            zeeFullStoryButton = false;
        }
        else if(this.state.location === "mableton") {
            map.push(<GoogleMapMabeltonComponent/>);
            zeeFamilyPicture = MabletonFamily;
            zeeFullStory = <div>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Mableton, GA - Anthony and Dalaina!</p>
                <p> They share our passion for unlocking every child's potential through coding and robotics. Code Wiz's mission is to help kids of all abilities discover their inner genius. With franchise partners like Anthony and Dalaina, we're committed to making this mission a reality.
                    <br/><br />
                    Anthony and Dalaina expressed their enthusiasm, noting that Code Wiz aligns well with their family. Both work in technology, making the mission fitting. They see their family's love and actions in the community as reflective of Code Wiz's Vision. They believe in teaching coding to prepare the next generation for the future.    
                </p>
                <p>
                    Our approach is rooted in providing unwavering support to kids as they pursue their goals. We recognize and nurture the unique skill sets in every child. Leveraging technology, we foster imagination and enthusiasm as fundamental to learning. 
                </p>
                {/* <p>Anthony and Dalaina are embarking on an exciting journey to inspire and empower the next generation in Mableton, GA. Join us as we build a brighter future, one young mind at a time.</p> */}
                {/* <p></p>
                <p></p> */}
            </div>;
            zeeName = "Anthony & Dalaina";
            zeeFullStoryButton = false;
        }else if(this.state.location === "beaumont") {
            map.push(<GoogleMapBeaumontComponent/>);
            zeeFamilyPicture = BeaumontFamily;
            zeeFullStory = <div>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Beaumont, TX - Jim & Bridgett!</p>
                <p>Jim is a seasoned project manager in global finance, and recognizes the urgent need for coding, robotics, and AI education. Witnessing industries shift towards automation, Jim envisions empowering the next generation for success in this dynamic landscape. <br/><br />
                Bridgett, a dedicated stay-at-home mom to twin boys, understands firsthand the power of engagement, particularly through her son's love for gaming. She's excited to channel this passion into teaching invaluable skills. <br/> <br/>
                Together, Jim and Bridgett are on a mission to ignite countless "ah-ha" moments and inspire young minds at Code Wiz Beaumont.
                </p>
                <p>Our approach emphasizes unwavering support, nurturing each child's unique skills, and fostering imagination and enthusiasm through technology. We believe in the power of community and belonging. Jim and Bridgett are spearheading the mission to inspire and empower the next generation in Beaumont, TX! Join us in shaping a brighter future, one young mind at a time.</p>
                
            </div>;
            zeeName = "Jim & Bridgett";
            zeeFullStoryButton = false;
        }else if(this.state.location === "edison") {
            map.push(<GoogleMapEdisonComponent/>);
            zeeFamilyPicture = EdisonFamily;
            zeeFullStory = <div>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Edison, NJ - Rajesh & Seema!</p>
                <p>Rajesh is a seasoned IT professional with over 35 years in corporate IT, holding dual master's degrees in Computer Applications and Business Administration. Witnessing the transformative impact of technology, he is excited to equip the next generation with the skills to thrive in a digital future. <br/><br />
                Dr. Seema Sahni, his wife, is a full-time professor at Rutgers University and a vital partner in this new venture. With extensive experience in education and a deep understanding of student needs, Seema's insights are crucial as they embark on this journey together. Rajesh and Seema are blessed with two successful daughters.
                </p>
                <p>
                Together, Rajesh and Seema are committed to unlocking every child's potential through coding and robotics at Code Wiz Edison. Our approach is rooted in unwavering support, nurturing each child's unique skills, and fostering imagination and enthusiasm through technology. We believe in the power of community and that everyone belongs. Join us as we create a brighter future, one young mind at a time.
                </p>
                
            </div>;
            zeeName = "Rajesh";
            zeeFullStoryButton = false;
        }
        else if(this.state.location === "kirkland") {
            map.push(<GoogleMapKirklandComponent/>);
            zeeFamilyPicture = KirklandFamily;
            zeeFullStory = <div>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz Kirkland, WA - Poojan!</p>
                <p> Poojan, with her extensive background as a Manager in a Management Consulting firm specializing in IT Audit and Cybersecurity, brings a wealth of expertise to Code Wiz. She also holds an MBA in Accounting and Finance. Poojan is deeply committed to equipping the next generation with the skills necessary to navigate a technology-driven future.
                <br/><br/>
                Poojan delights in the growth and curiosity of her four-year-old son and his friends, a joy she hopes to extend to every student that comes to Code Wiz Kirkland.
                </p>
                <p>
                Poojan is dedicated to creating numerous "ah-ha" moments and nurturing the next generation of innovators at Code Wiz. Our approach is centered on strong support, cultivating each child's unique talents, and stimulating creativity and excitement through technology. Embracing community and a sense of belonging, Poojan leads our mission to inspire and empower the upcoming generation in Kirkland, WA. Join us in forging a brighter future, one young mind at a time.
                </p>
                
            </div>;
            zeeName = "Poojan";
            zeeFullStoryButton = false;
        }
        else if(this.state.location === "northstpete") {
            map.push(<GoogleMapNorthStPeteComponent/>);
            zeeFamilyPicture = NorthstpeteFamily;
            zeeFullStory = <div>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>;
            zeeSummary = <div>
                <p>Meet the owners of Code Wiz North St Pete, FL - Scott & Jennifer!</p>
                <p>Scott brings 15 years of experience in the credit union space, where he has successfully led student-run branches and conducted financial literacy workshops. His deep passion for mentoring and his established connections within the education community position him to effectively empower the next generation. <br/><br/>
                Jennifer, boasting 23 years in education, excels in classroom and virtual teaching, as well as curriculum development and training. As a mom to two avid gamers, she is excited to turn their passion into teaching valuable skills.
                </p>
                <p>
                Scott and Jennifer are committed to sparking numerous "lightbulb" moments and motivating young minds at Code Wiz St. Pete. Their goal is to foster an environment where children can flourish, tapping into their creativity and realizing their potential.
                <br/>
                Our approach emphasizes unwavering support, nurturing each child's unique skills, and fostering imagination and enthusiasm through technology. We believe in the power of community and belonging. Scott and Jennifer are spearheading the mission to inspire and empower the next generation in St. Pete, FL!
                </p>
                
            </div>;
            zeeName = "Scott & Jennifer";
            zeeFullStoryButton = false;
        }

        let grandOpeningFrom = <form id="cw-contact-form grand-opening-form" className="contact-form" action={this.state.grandOpeningUrl} method="POST">
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputFirst" required>First Name *</label>
                <input name="firstname" type="text" className="form-control" id="inputFirst" placeholder="first name" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputLast">Last Name *</label>
                <input name="lastname" type="text" className="form-control" id="inputLast" placeholder="last name" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputEmail">Email *</label>
                <input name="email" type="email" className="form-control" id="inputEmail" placeholder="email" required/>
            </div>
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputPhone">Phone *</label>
                <input name="phone" type="tel" className="form-control" id="inputPhone" placeholder="123-456-7890" required/>
            </div>
        </div>
        <div className="form-row">
            <div className="form-group col-md-6">
                <label className="required-label" htmlFor="inputMode">What Are You Interested In? *</label>
                <select name="mode" className="form-control" id="inputMode" required>
                    <option value="inperson">In-Person Classes</option>
                </select>
            </div>
            <div className="form-group col-md-6">
                <input type="hidden" id="location" name="location" value={this.state.location}></input>
                <input type="hidden" id="zoho" name="zoho" value={this.state.zoho}></input>
                <input type="hidden" id="locationemail" name="location-email" value={this.state.locationemail}></input>
            </div>
        </div>
        <div className="form-group">
            <div className="form-check">
                    <input type="checkbox" id="infoconsent" name="infoconsent" required></input><span> I agree to receive SMS and email messages from Code Wiz</span><br/><br/>
                    <label className="form-check-label tiny-print" htmlFor="gridCheck">
                    {/* <span className="center">Required fields are indicated with "*".</span> <br/> */}
                    <span>By checking this box, I consent to receive informational and promotional text messages at the mobile number provided and emails at the address provided. I understand that my consent is not a condition of purchase. Message and data rates may apply for SMS. I can opt-out of SMS communications at any time by replying STOP, and unsubscribe from email communications by clicking the “Unsubscribe” link in any email.</span>
                    <br/><span>For more information, please review our <a href="https://thecodewiz.com/privacy-policy">Privacy Policy and Terms of Service.</a></span>
                    </label>
                </div>
            </div>
        {/* <button type="submit">Send</button> */}
        
        <button type="submit" className="btn white-button" target="_blank">SEND</button>
    </form>

// console.log("Hours", this.state.data.friday)

        return (
            <div className="subsite franchisee-page" id={this.state.location}> 
                <div className='coming-soon-container'>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-6 center">
                            <h3 class="header-text cw-dark-blue center bungee" aria-level="1">Grand Opening <span class="cw-light-blue">Sale!</span></h3>
                            {/* <img className='coming-soon-image' aria-hidden="true" src={FoundingMemberHeaderImg} alt="Founding member header picture"/> */}
                            <p className="center summer-camp-video"><iframe src={this.state.data.youtubevid} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></p>
                            {/* <p className="center"><Button color="orange" link={this.state.data.parentportal} size="medium">VIEW CLASS SCHEDULE</Button></p> */}
                            <h2 className="header-text cw-dark-blue map-pin center"><span><img aria-hidden="true" src={CityImage} alt="franchise city"/></span>  {this.state.data.citystate}</h2> 
                            
                            <Button className="center" color="blue" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button>
                            <br />
                            <br />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 form-in-the-header">
                            {/* <h4 className="bungee center">Lock in ${discountPrice} a month for life!</h4> */}
                            <h4 className="bungee center">Grand Opening Sale!!</h4>
                            <p className="center">{grandopeningoffer}</p>
                            
                            {grandOpeningFrom}
                        </div>
                    </div>
                    <div className="as-seen-on">
                        <div className="row">
                            <div className="col-md-3 as-seen-on-div">
                                <h2 className="center cw-dark-blue">AS SEEN ON</h2>
                            </div>
                            <div className="col-md-9 as-seen-on-div">
                                <img src={AsSeenOnImg} alt="Code Wiz Featured" />
                            </div> 
                        </div>   
                    </div>
                </div>
                <div className="body-wrapper cw-dark-blue">
                    <div className="container">
                        <div className="empty-space-half bw-0"></div> 
                        <div className="row">
                            
                            <div className="col-md-12 col-lg-6 zeeBio">
                                <h3 className="bungee">coding and robotics classes in {this.state.data.citystate}</h3>
                                {zeeSummary}
                                <p>Give your child the chance to learn life-shaping skills with fun, flexible programs offered at our {this.state.data.citystate} location.</p>
                                {/* <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                                {zeeFullStoryButton ?
                                <Popup trigger={<button className='btn blue-button'>Learn More About The Owner</button>} modal>
                                    <div className="col-sm-12 cw-dark-blue form-in-modal">
                                    <h4 className="bungee center">Meet {zeeName}</h4>
                                    {zeeFullStory}
                                    </div>
                                </Popup> : ""}
                            </div>
                            <div className="col-md-12 col-lg-6 zee-family-picture">
                                <img aria-hidden="true" className="familyImg" src={zeeFamilyPicture} alt="coding robotics"/>
                            </div>
                            
                        </div>
                        <div className="empty-space form-empty-space"></div>
                        <div className="row row-reverse coding-robotics">
                            <div className="col-md-12 col-lg-6 code-create-grow">
                                <h3 className="bungee">code. create. grow.</h3>
                                <p className="">At Code Wiz, we believe that learning to code is the easiest way to shape your child’s future. Our students develop programming skills along with invaluable approaches to critical thinking and problem-solving. Best of all, anyone can learn to code—no matter their age, tech proficiency, or personal interests. We’ve designed fun, flexible programs to ensure every child becomes a wiz!</p>
                                {/* <Button color="orange" link="/" size="x-large">LEARN ABOUT OUR FOUNDING MEMBER OFFER</Button> */}
                                <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                                    <div className="col-sm-12 cw-dark-blue form-in-modal">
                                        <h4 className="bungee center">Grand Opening Sale!!</h4>
                                        <p className="center">Sign up and enjoy **31.4159%** off your membership for the first 6 months!</p>
                                        {grandOpeningFrom}
                                    </div>
                                </Popup>
                            </div>
                            <div className="col-md-12 col-lg-6">
                                <img aria-hidden="true" className="midImg" src={CodingRobotics} alt="coding robotics"/>
                            </div>
                        </div>
                        <div className="empty-space-half"></div>
                        <div className="row fr-whycodewiz">
                            <div className="col-md-12 col-lg-6 order-md-1 whycodewizimg">
                                <img aria-hidden="true" className="width-100 midImg" src={WhyCodewiz} alt="why codewiz"/>
                            </div>
                            <div className="col-md-12 col-lg-6 order-md-2">
                                <h3 className="bungee whycodewiztitle">why code wiz?</h3>
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">project-driven, student-steered learning</h6>
                                <p>Children create by completing projects rather than lessons or levels</p>
                                
                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">really cool rewards</h6>
                                <p>We reward learning with Code Wiz gear and other awesome prizes</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">community connectivity</h6>
                                <p>Students can check their work online and get inspired by peer projects</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">progress at your fingertips</h6>
                                <p>Stay connected to your child's success with automated progress report emails</p>

                                <span className="whywizicon"><i className="fa fa-check-circle-o" aria-hidden="true"></i></span>
                                <h6 className="bungee">free, no-strings intro class</h6>
                                <p>See how learning to code can change your child's life-all classes are offered in person!</p>
                                <br/>

                                {/* <Button color="orange" link={this.state.data.tryfreeclass} size="medium">TRY A FREE CLASS</Button> */}
                            </div>
                        </div>  
                    </div>
                </div>
                <div className="container contact-info-contact-page">
                    
                </div>
                <div className="col-md-12 center">
                    <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                        <div className="col-sm-12 cw-dark-blue form-in-modal">
                            <h4 className="bungee center">Grand Opening Sale!!</h4>
                            <p className="center">Founding families enjoy **31.4159%** off their memberships for the first 6 months!</p>
                            {grandOpeningFrom}
                        </div>
                    </Popup>
                </div>

                

                <div className="container">
                    <div className="row">
                        
                    </div>
                </div>
                <div className="container contact-info-contact-page">
                    <div className="mid-layer">
                        <h3 className="bungee center cw-dark-blue">location</h3>
                        <div className="row">
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>ADDRESS:</h5>
                                <p>{this.state.data.addressstreet}<br/> {this.state.data.city}, {this.state.data.state} {this.state.data.zip}</p>
                                <p>{this.state.data.addressDetails}</p>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-phone fa-5x cw-orange"></i></div>
                                <h5>CONTACT:</h5>
                                <p><a href={`tel:${this.state.data.phone}`}>{this.state.data.phone}</a> <br/>
                                <a href={`mailto:${this.state.data.email}`}>{this.state.data.email}</a></p>
                            </div>
                            <div className="col">
                                <div className="contact-circle"><i className="fa fa-map-marker fa-5x cw-orange"></i></div>
                                <h5>HOURS:</h5>
                                <p> 
                                    {renderdaysList} 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Location Map --> */}
                {map}
                
                <div className="col-md-12 center">
                    <Popup trigger={<button className='btn orange-button'>FOUNDING MEMBER OFFER</button>} modal>
                        <div className="col-sm-12 cw-dark-blue form-in-modal">
                            <h4 className="bungee center">Grand Opening Sale!!</h4>
                            <p className="center">Founding families enjoy **31.4159%** off their memberships for the first 6 months!</p>
                            {grandOpeningFrom}
                        </div>
                    </Popup>
                </div>
                

                {/* <FloatingMenu link={this.state.data.tryfreeclass} camplink={this.state.data.parentportal} contactlink={this.state.data.email}/> */}
                
            </div>
        );
    }
}

export default ComingSoonFranchisee
