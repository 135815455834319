import React from 'react';
import BlogPage from '../../blog/blog.component';
import MetaTags from 'react-meta-tags';
import FacebookMessenger from '../../../components/facebook-messenger/facebook-messenger';

const FranchiseBlogPage = () => (
    <div>
        <BlogPage franchisee="medford" fulllocation="medford-ma"/>
        <FacebookMessenger location="medford" FBPageId="104541002332885" />
        <MetaTags>
            <title>Code Wiz - Medford MA | Blogs</title>
        </MetaTags>
    </div>
);

export default FranchiseBlogPage;