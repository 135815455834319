import React from 'react';
import FallClassesPage from '../../fall-classes/in-person-classes.component';
import MetaTags from 'react-meta-tags';
import PopupModal from '../../../components/popup-modal/popup-modal.component';

const FranchiseInPersonClassesPage = () => (
    <div>
        <PopupModal location="arlington" formType="coupon" owner="Code Wiz Arlington" />
        <FallClassesPage franchisee="arlington" fulllocation="arlington-ma" formType="coupon" owner="Code Wiz Arlington" />
        <MetaTags>
            <title>Code Wiz - Arlington MA | In-Person Classes</title>
        </MetaTags>
    </div>
);

export default FranchiseInPersonClassesPage;